var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Injector, OnDestroy, OnInit, Type } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd, PRIMARY_OUTLET } from "@angular/router";
import "rxjs/add/operator/filter";
import { SessionService } from "../session-maintenance/session.service";
import { Store } from "@ngxs/store";
import { ApplicationState } from "../state/app.state";
import { combineLatest } from "rxjs";
import { DI, getOwnProtypeExtension } from "../state/core";
import { BreadcrumbsService } from "./breadcrumbs.service";
import { PersistAppState, RestoreAppState } from "../state/app.actions";
import { RecordRouteNaviation } from "./state/router.actions";
import { first } from "rxjs/operators";
var ROUTE_DATA_BREADCRUMB = "breadcrumb";
var ɵ0 = function () { return ActivatedRoute; }, ɵ1 = function () { return Router; }, ɵ2 = function () { return SessionService; }, ɵ3 = function () { return BreadcrumbsService; }, ɵ4 = function () { return Store; };
var BreadcrumbComponent = /** @class */ (function (_super) {
    __extends(BreadcrumbComponent, _super);
    function BreadcrumbComponent(_injector) {
        var _this = _super.call(this, _injector) || this;
        _this.show = false;
        _this.store.dispatch(new RestoreAppState());
        return _this;
    }
    BreadcrumbComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.breadcrumbs = [];
        //subscribe to the NavigationEnd event
        this.subscription = combineLatest([
            this.router.events.filter(function (event) { return event instanceof NavigationEnd; }),
            this.store.select(ApplicationState.popupMode)
        ]).subscribe(function (_a) {
            var event = _a[0], popupMode = _a[1];
            return __awaiter(_this, void 0, void 0, function () {
                var routeParams, root;
                var _this = this;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, this.service.resolveActivatedRoute().params.pipe(first()).toPromise()];
                        case 1:
                            routeParams = _b.sent();
                            //set breadcrumbs
                            this.sessionService.updateSessionLastAccessedTime();
                            this.store.dispatch(new RecordRouteNaviation(event));
                            root = this.activatedRoute.root;
                            this.breadcrumbs = [
                                {
                                    params: [],
                                    url: '/home',
                                    label: 'Home',
                                    passive: popupMode
                                }
                            ].concat(this.getBreadcrumbs(root, popupMode).map(function (breadcrumb) { return _this.parseBreadCrumb(breadcrumb, routeParams); }));
                            if (this.breadcrumbs.length === 1) {
                                this.show = false;
                            }
                            else {
                                this.show = true;
                            }
                            return [2 /*return*/];
                    }
                });
            });
        });
    };
    BreadcrumbComponent.prototype.parseBreadCrumb = function (breadcrumb, routeParams) {
        if (breadcrumb.url) {
            return __assign({}, breadcrumb, { url: breadcrumb.url.replace(/:[a-z0-9_]+/ig, function (match, _token) { return routeParams[match.substring(1)] || match; }) });
        }
        return breadcrumb;
    };
    BreadcrumbComponent.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    BreadcrumbComponent.prototype.beforeunloadEventHandler = function () {
        var component = this.service.resolveComponentType(this.router.url, false);
        this.store.dispatch(new PersistAppState(getOwnProtypeExtension(component).persistStateOnRefresh));
    };
    BreadcrumbComponent.prototype.getBreadcrumbs = function (route, popupMode, url, breadcrumbs) {
        if (url === void 0) { url = ""; }
        if (breadcrumbs === void 0) { breadcrumbs = []; }
        //get the child routes
        var children = route.children;
        //return if there are no more children
        if (children.length === 0) {
            return breadcrumbs;
        }
        //iterate over each children
        for (var _i = 0, children_1 = children; _i < children_1.length; _i++) {
            var child = children_1[_i];
            //verify primary route
            if (child.outlet !== PRIMARY_OUTLET) {
                continue;
            }
            if (child.snapshot.data[ROUTE_DATA_BREADCRUMB] instanceof Array) {
                for (var _a = 0, _b = child.snapshot.data[ROUTE_DATA_BREADCRUMB]; _a < _b.length; _a++) {
                    var bread = _b[_a];
                    var breadCrumb = {
                        label: bread.label,
                        passive: this.isPassiveBreadCrumb(bread.path, popupMode),
                        params: bread.params ? bread.params : [],
                        url: bread.path
                    };
                    breadcrumbs.push(breadCrumb);
                }
                return breadcrumbs;
            }
            //verify the custom data property "breadcrumb" is specified on the route
            if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB) && !child.snapshot.data.hasOwnProperty('0')) {
                return this.getBreadcrumbs(child, popupMode, url, breadcrumbs);
            }
            //get the route's URL segment
            var routeURL = this.getRouteUrl(child, url);
            var bData = void 0;
            if (child.snapshot.data.hasOwnProperty('0')) {
                bData = child.snapshot.data['0'].breadcrumb;
            }
            else {
                bData = child.snapshot.data[ROUTE_DATA_BREADCRUMB];
            }
            //add breadcrumb
            var breadcrumb = {
                label: bData,
                passive: this.isPassiveBreadCrumb(routeURL, popupMode),
                params: child.snapshot.params,
                url: routeURL
            };
            breadcrumbs.push(breadcrumb);
            //recursive
            return this.getBreadcrumbs(child, popupMode, url, breadcrumbs);
        }
        //we should never get here, but just in case
        return breadcrumbs;
    };
    BreadcrumbComponent.prototype.getRouteUrl = function (route, url) {
        var routeURL = route.snapshot.url.map(function (segment) { return segment.path; }).join("/");
        if (route.parent.snapshot.url) {
            url += "" + route.parent.snapshot.url;
        }
        return (url ? url + '/' : '') + routeURL;
    };
    BreadcrumbComponent.prototype.isPassiveBreadCrumb = function (routeUrl, popupMode) {
        var component = this.service.resolveComponentType(routeUrl, false);
        return popupMode && !(component instanceof Type && getOwnProtypeExtension(component).popupModeVisibility);
    };
    __decorate([
        DI.Inject(ɵ0),
        __metadata("design:type", ActivatedRoute)
    ], BreadcrumbComponent.prototype, "activatedRoute", void 0);
    __decorate([
        DI.Inject(ɵ1),
        __metadata("design:type", Router)
    ], BreadcrumbComponent.prototype, "router", void 0);
    __decorate([
        DI.Inject(ɵ2),
        __metadata("design:type", SessionService)
    ], BreadcrumbComponent.prototype, "sessionService", void 0);
    __decorate([
        DI.Inject(ɵ3),
        __metadata("design:type", BreadcrumbsService)
    ], BreadcrumbComponent.prototype, "service", void 0);
    __decorate([
        DI.Inject(ɵ4),
        __metadata("design:type", Store)
    ], BreadcrumbComponent.prototype, "store", void 0);
    return BreadcrumbComponent;
}(DI.AutoDependencyInjector));
export { BreadcrumbComponent };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
