import { Feature } from "../feature-checker.service";
import { FeatureFunction } from "../feature-function";

export class CheckObjectFieldFunction implements FeatureFunction<boolean> {
    static readonly reference = 'CheckObjectFieldFeatureFunction';

    public apply(feature: Feature, object: any): Promise<boolean> {
        const found = (feature.metadata2 || '').split(/ *, */)
            .find(match => object && object[feature.metadata1] === match);
        return Promise.resolve(!!found);
    }
}
