<!-- Begin Login Section -->
<span class="clearfix"></span>
<div  class="container-fluid">
   <div class="login-container" style="text-align: center;">
      <div class="row">
         <div class="col-md-8 col-lg-9" id="left">
            <a [href]="'/info/qsol/news/new_employee_login_aad.html' | resource: 'QSOL'" target="_blank">
            <img-xhr [style]="{'width':'100%' , 'margin-bottom': '0px', 'background-color': '#fff', 'border': '1px solid #ddd', 'padding': '4px'}"
            [src]="getAssetsLink('/images/login/info-banner.JPG')" styleClass="img-responsive"></img-xhr>
            </a>
            
         </div>
         <div class="col-xs-4 col-xs-offset-4 col-md-4 col-md-offset-0  col-lg-3 col-lg-offset-0" id="right">
      <form class="form-signin" id = "LoginForm" #LoginForm="ngForm">
         <h2 class="form-signin-heading text-center vert-offset-bottom-2">{{ 'Login' | translate }}</h2>
         <hr style="border: 1px solid #ccc; width: 85%;"/>
         <div class="form-group">
            <button (click)="saveRequest();" [disabled]="disabledLogin" class ="btn btn-lg btn-primary btn-block btn-login" type="submit">{{ 'Login' | translate }}</button>
                  <a [href]="'/protected/files/qsol/cms/new_employee_login_aad.pdf' | resource: 'QSOL'" target="_blank">
                  WWID User Login Trouble ?
                  </a>
            <mat-progress-bar *ngIf="showProgress" color="primary" mode="indeterminate"></mat-progress-bar>
         </div>
         <div class="form-group">
            <strong>Or</strong>
         </div>
         <label class="hide">new to Quick Serve</label>
         <div class="form-group">
            <a class="btn btn-lg  btn-block btn-sign-up" [href]="subscriptionLink" target="_blank">{{'Create an Account' | translate}}</a>
         </div>
      </form>
   </div>
</div>
    </div>
</div>

<!-- 
    Agreement
-->

    <p-dialog *responsive="{bootstrap: ['lg','md','xl']}" width = "500" [(visible)]="agreementModal"  [modal] = "true" blockScroll = "true"  [closeOnEscape] = "false" [closable] = "false" [resizable] = "true">
            <div class="align-justify">
                    By registering for and using QuickServe Online, you agree to Cummins Inc. <a [href]="quickserveLinks.terms" target="_blank"><u>terms of use</u></a> and <a target="_blank" href= "https://www.cummins.com/privacy-and-legal" rel="noopener"><u>privacy policy</u></a>, including our use of cookies. To manage your consent settings, click on My Profile.
            </div>
            <div class="align-center">
                    <br>
                <form>
                    <button [disabled]="disabledContinue" (click) = "disabledContinue = true; accept = 'yes'; showProgressAccept = 'true'; login();"  id = "reLogin" class ="btn btn-sm btn-primary btn-login">{{ 'Continue' | translate }}</button>
                    <mat-progress-bar style="margin-top: 10px;" *ngIf="showProgressAccept" color="primary" mode="indeterminate"></mat-progress-bar>
                </form>
            </div>
            <br>    
    </p-dialog>

    <p-dialog *responsive="{bootstrap: ['sm','xs']}"   [(visible)]="agreementModal"  [modal] = "true" blockScroll = "true"  [closeOnEscape] = "false" [closable] = "false" [resizable] = "true">
        <div class="align-justify">
                By registering for and using QuickServe Online, you agree to Cummins Inc. <a [href]="quickserveLinks.terms" target="_blank"><u>terms of use</u></a> and <a target="_blank" href= "https://www.cummins.com/privacy-and-legal"><u>privacy policy</u></a>, including our use of cookies. To manage your consent settings, click on My Profile.
        </div>
        <div class="align-center">
                <br>
            <form>
                <button [disabled]="disabledContinue" (click) = "disabledContinue = true; accept = 'yes'; showProgressAccept = 'true'; login();"  id = "reLogin" class ="btn btn-sm btn-primary btn-login">{{ 'Continue' | translate }}</button>
                <mat-progress-bar style="margin-top: 10px;" *ngIf="showProgressAccept" color="primary" mode="indeterminate"></mat-progress-bar>
            </form>
        </div>
        <br>    
    </p-dialog>


<!--
    Complete Subscription on QS
-->

    <p-dialog *responsive="{bootstrap: ['lg','md','xl']}" width = "500" [(visible)]="completeSubscription"  [modal] = "true" blockScroll = "true"  [closeOnEscape] = "false" [closable] = "false" [resizable] = "true">
    
        <div class="align-center">
            Please, complete your Subscription on QuickServe Online.
        </div>
        <div class="align-center">
                <br>
            <form ngNoForm  [action] = "QUICK_SERVE_COLA" method="POST">
                <input type="hidden" name="target"[value]="PARTS_HOST_NAME" />
                <input type="hidden" name="username" [value]="credentials.user" />
                <input type="hidden" name="password" [value]="credentials.pass" />
                <input type="hidden" name="attempts" value="3" />
                <input type="hidden" name="updatetype" value="password" />
                <button (click) = "showProgressSub = true;"  class ="btn btn-sm btn-primary btn-login" type="submit">{{ 'Continue' | translate }}</button>
                <mat-progress-bar style="margin-top: 10px;" *ngIf="showProgressSub" color="primary" mode="indeterminate"></mat-progress-bar>
            </form>
        </div>
            
    </p-dialog>


    <p-dialog *responsive="{bootstrap: ['sm','xs']}"   [(visible)]="completeSubscription"  [modal] = "true" blockScroll = "true"  [closeOnEscape] = "false" [closable] = "false" [resizable] = "true">
    
        <div class="align-center">
                Please, complete your Subscription on QuickServe Online.
        </div>
        <div class="align-center">
                <br>
            <form ngNoForm  [action] = "QUICK_SERVE_COLA" method="POST">
                <input type="hidden" name="target" [value]="PARTS_HOST_NAME" />
                <input type="hidden" name="username" [value]="credentials.user" />
                <input type="hidden" name="password" [value]="credentials.pass" />
                <input type="hidden" name="attempts" value="3" />
                <input type="hidden" name="updatetype" value="password" />
                <button  (click) = "showProgressSub = true;"  class ="btn btn-sm btn-primary btn-login" type="submit">{{ 'Continue' | translate }}</button>
                <mat-progress-bar style="margin-top: 10px;" *ngIf="showProgressSub" color="primary" mode="indeterminate"></mat-progress-bar>
            </form>
        </div>
    </p-dialog>