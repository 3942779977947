import { OnInit, EventEmitter, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
export function setErrorImage(_a) {
    var element = _a.element, errorImagePath = _a.errorImagePath, useSrcset = _a.useSrcset;
    AjaxImageLoader.LAZYLOAD_ERROR_SUBJECT.next(errorImagePath);
    element.src = 'assets/images/about-us/cbimage.jpg';
}
var AjaxImageLoader = /** @class */ (function () {
    function AjaxImageLoader() {
        var _this = this;
        this.lazyLoad = false;
        this.onerror = new EventEmitter();
        this.onLoad = new EventEmitter();
        this.subscription = AjaxImageLoader.LAZYLOAD_ERROR_SUBJECT.filter(function (src) { return src === _this.src; }).subscribe(function (src) { return _this.handleError(src); });
    }
    Object.defineProperty(AjaxImageLoader.prototype, "src", {
        get: function () {
            return this.imageSrc;
        },
        set: function (src) {
            this.showAlt = false;
            this.imageSrc = src;
        },
        enumerable: true,
        configurable: true
    });
    AjaxImageLoader.prototype.ngOnInit = function () {
        this.showAlt = this.showAlt || false;
        this.alt = this.alt || "Image not Available";
        this.styleClass = this.styleClass || '';
        this.width = this.width || 'auto';
        this.height = this.height || 'auto';
        this.style = this.style || {
            width: this.width,
            height: this.height
        };
    };
    AjaxImageLoader.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    AjaxImageLoader.prototype.handleError = function (e) {
        this.showAlt = true;
        this.onerror.emit(e);
    };
    AjaxImageLoader.prototype.imageLoaded = function (event) {
        if (event.target.src === this.src) {
            this.showAlt = false;
            this.onLoad.emit(event);
        }
    };
    AjaxImageLoader.LAZYLOAD_ERROR_SUBJECT = new Subject();
    return AjaxImageLoader;
}());
export { AjaxImageLoader };
