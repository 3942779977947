import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import { Store } from './core';

export interface ApplicationStateModel {
    popupMode: boolean;
}

@Injectable()
@State<ApplicationStateModel>({
    name: 'app',
    defaults: {
        popupMode: false
    }
})
export class ApplicationState {
    @Store.Selector([ApplicationState])
    static popupMode(state: ApplicationStateModel) {
        return state.popupMode;
    }
}
