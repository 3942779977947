import { Injectable, Injector } from '@angular/core';
import { AppSettings } from '../app.settings';
import { LocalStorageService, SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { Cart } from './cart';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';

import { IntegrationService } from '../integration/integration.service';
import { CookieService } from '../cookie-popup/cookie.service';
import { catchError, distinctUntilChanged, filter, flatMap, map, share, take, debounceTime } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppState } from '../app.service';
import { LoginSharedService } from '../login/login-shared.service';
import { Store } from '@ngxs/store';
import { ApplicationState } from '../state/app.state';
import { DI } from '../state/core';

export const CART_KEY = 'Cart';

@Injectable()
export class CartService extends DI.AutoDependencyInjector {
    @SessionStorage('USERID')
    private userId: string;

    @SessionStorage('esn')
    private esn: string;

    private _cart: BehaviorSubject<any>;
    private completeCaller: () => void;
    private cartLoadCompleteNotifier = new Subject<any>();

    @DI.Inject(() => HttpClient)
    private _http: HttpClient;

    @DI.Inject(() => LocalStorageService)
    private storage: LocalStorageService;

    @DI.Inject(() => IntegrationService)
    private integrationService: IntegrationService;

    @DI.Inject(() => CookieService)
    private _cookieService: CookieService;

    @DI.Inject(() => SessionStorageService)
    private sessionStorage: SessionStorageService;

    @DI.Inject(() => AppState)
    private appState: AppState;

    @DI.Inject(() => LoginSharedService)
    private loginService: LoginSharedService;

    @DI.Inject(() => Store)
    private store: Store;

    constructor(_injector: Injector) {
        super(_injector);
        this._cart = new BehaviorSubject<any>(null);
        this.storage.observe(CART_KEY).subscribe(cart => this._cart.next(cart));

        this.completeCaller = () => this.cartLoadCompleteNotifier.complete();
        const subscription = new Subscription(() => this.cartLoadCompleteNotifier.next());
        subscription.add(this.loginService.notificationLoginInfo$
            .pipe(filter(userAttributes => userAttributes.length > 0))
            .pipe(debounceTime(0)).subscribe(async _ => {
                const userConsent = this.appState.hasUserConsent;
                const browserCart = await this.isBrowserCart().toPromise();
                if (!browserCart && (this.loginService.isIntegrationAccess() || userConsent)) {
                    subscription.unsubscribe();
                    this.afterLogin();
                } else if (browserCart || userConsent === null) {
                    subscription.unsubscribe();
                    browserCart && this.cleanCart();
                    setTimeout(() => {
                        this.completeCaller()
                        this._cart.next(this.storage.retrieve(CART_KEY));
                    }, 100);
                }
            }));

        this.isBrowserCart(0)
            .pipe(distinctUntilChanged(), filter(isBrowserCart => isBrowserCart && subscription.closed))
            .subscribe(() => this.cleanCart());
    }

    public addCart(cart: Cart): Promise<Cart> {

        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        

        let url = `${AppSettings.API_ENDPOINTS.get('shopparts')}/protected/cart/addCart`;
        return this._http.post(url, JSON.stringify(cart), { headers: headers }).toPromise().then((res:any) => res);
    }

    public async deleteList(): Promise<any> {
        let cart = this.storage.retrieve(CART_KEY);
        if(!await this.isBrowserCart().toPromise()) {
            return this._http.delete(`${AppSettings.API_ENDPOINTS.get('shopparts')}/protected/cart/${cart.cartId}`)
                .toPromise().then(() => {
                    this.updateCart(null);
                    return this.integrationService.getIntegrationUser().pipe(take(1))
                        .toPromise().then(integrationUser => {
                            if (integrationUser) {
                                this.integrationService.data = Object.assign(this.integrationService.data, {
                                    vendorCartId: ''
                                });
                            }
                        });
                });
        } else {
            this.updateCart(null);
            return Promise.resolve();
        }
    }

    public afterLogin(mergeCartItems: boolean = this.loginService.loginInfo.mergeCartItems): Promise<string> {

        //TODO: Coming from Dealer Commerce
        let sessionCart = this.storage.retrieve(CART_KEY);
        const popupMode = this.store.selectSnapshot(ApplicationState.popupMode);
        let pickListItems;
        if (sessionCart != null && sessionCart.cartItemsList != null && sessionCart.cartItemsList.length > 0 && mergeCartItems) {
            pickListItems = sessionCart.cartItemsList;
        } else {
            pickListItems = [];
        }

        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        
        let url = `${AppSettings.API_ENDPOINTS.get('shopparts')}/protected/cart/current`;
        let vendorUsername, vendorCartId;
        const vendor=this.integrationService.data.vendor || 'IAC';
        const userType = this.integrationService.data.userType || '';
        if(userType.toLowerCase() === 'partner' && this.integrationService.data.vendorCartId === 'PACCAR') {
            vendorUsername=this._cookieService.getCookie("PACCAR_USER");
        } else if(this.integrationService.data.vendorCartId) {
            vendorCartId=this.integrationService.data.vendorCartId;
        }
        return (popupMode ? of(this.storage.retrieve(CART_KEY)) : this._http.post(url,{
            items:pickListItems,vendor:vendor,vendorUsername: vendorUsername,
            vendorCartId:vendorCartId
        }, { headers: headers })).toPromise()
            .then((cart:any) => {
                if (this.loginService.isLoggedIn || cart) {
                    this.updateCart(cart);
                    if (cart) {
                        return cart.cartId;
                    }
                }
                return null;
            })
            .catch(x => {
                console.log("Error: " + JSON.stringify(x));
                return Promise.reject(x);
            }).finally(this.completeCaller);
    }

    public addItemToCart(part: any,butype?:any,serialNumber?:any): Promise<any> {

        let type = null;
        let partNumber = part.partNo;
        let supersededPartNo = null;
        if (part.supersession) {
            const isFilter = part.supersession.find(x => x.sellable == 'Y'  && x.sequence == '1');
            if (isFilter) {
                type = 'S';
                partNumber = isFilter.partNo;
                supersededPartNo = part.supersession
                    .filter(x => x.partDispCd == '3' && x.sellable != 'Y')
                    .map(x => x.partNo);
            }
        }
        if (part.kit) {
            type = 'K';
        }
        let entry;
        if(butype){
            entry = {
                "serialNum": serialNumber,
                "partNo": partNumber,
                "partDesc": part.partDesc,
                "quantity": 1,
                "partType": type,
                "supersededPartNo": supersededPartNo,
                "bu":butype
            };
        }else{
            entry = {
                "serialNum": this.esn,
                "partNo": partNumber,
                "partDesc": part.partDesc,
                "quantity": 1,
                "partType": type,
                "supersededPartNo": supersededPartNo
            };
        }

        let sessionCart = this.storage.retrieve(CART_KEY);
        if (sessionCart == null) {
            sessionCart = {
               "cartItemsList": []
           };
              sessionCart.cartItemsList.push(entry);
              return this.createWSCart(sessionCart);

       } else {

              return this.cartAlreadyExist(entry,sessionCart);
       }

    }

    public async createWSCart(sessionCart:any): Promise<any>{
        if (!await this.isBrowserCart().toPromise()) { //The User is Logged In
            //Create a Cart using the WS

            let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            
            let url = `${AppSettings.API_ENDPOINTS.get('shopparts')}/protected/cart/current`;
            let vendorUsername, vendorCartId;
            const vendor=this.integrationService.data.vendor || 'IAC';
            const userType = this.integrationService.data.userType || '';
            if (userType.toLowerCase() === 'partner' && this.integrationService.data.vendorCartId === 'PACCAR') {
                vendorUsername=this._cookieService.getCookie("PACCAR_USER");
            } else if (this.integrationService.data.vendorCartId) {
                vendorCartId=this.integrationService.data.vendorCartId;
            }


           return this._http.post(url, {items: sessionCart.cartItemsList,vendor:vendor,vendorUsername:vendorUsername,
            vendorCartId:vendorCartId}, { headers: headers })
                .toPromise()
                .then((cart: any )=> this.updateCart(cart))
                .catch(x => {
                    console.log("Error: " + JSON.stringify(x));
                });


        } else {

            this.updateCart(sessionCart);
            return Promise.resolve(sessionCart);

        }
    }
    public async cartAlreadyExist(entry:any,sessionCart: any): Promise<any>{
        if (!await this.isBrowserCart().toPromise()) {   

            let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            
            let url = `${AppSettings.API_ENDPOINTS.get('shopparts')}/protected/cart/${sessionCart.cartId}/part`;
            return this._http.post(url, JSON.stringify(entry), { headers: headers })
                .toPromise().then(flag => {

                    if(flag) {
                        sessionCart.cartItemsList.push(entry);
                        this.updateCart(sessionCart);
                    }

                });


        } else {
            sessionCart.cartItemsList.push(entry);
            this.updateCart(sessionCart);
            return Promise.resolve(sessionCart);

        }
    }
    public isItemAdded(partNo: any): boolean {
        const sessionCart = this.storage.retrieve(CART_KEY);
        let result = false;
        if (sessionCart != null && sessionCart.cartItemsList != null && sessionCart.cartItemsList.length > 0) {
            result = sessionCart.cartItemsList.filter(item => item.partNo === partNo).length > 0;
        }
        return result;
    }


    public updatePart(objObserv): Observable<any> {

        let part = objObserv.part;
        let cartSession = this.storage.retrieve(CART_KEY);

        return this.isBrowserCart().pipe(flatMap(isBrowserCart => {
            if (!isBrowserCart) {
    
                let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
                
                let url = `${AppSettings.API_ENDPOINTS.get('shopparts')}/protected/cart/${cartSession.cartId}/part/${part.partNo}`;
                return this._http.put(url, part.quantity, { headers: headers })
                    .pipe(map(() => {
                        let response = cartSession.cartItemsList.find(x => x.partNo === part.partNo);
                        
                            response.quantity = part.quantity;
                            this.updateCart(cartSession);
                        //}
                        return response;
                    })).pipe(catchError(() => {
                        const cartSessionInfo = this.storage.retrieve(CART_KEY);
                        const oldPart = cartSessionInfo.cartItemsList.find(x => x.partNo === part.partNo);
                        return of(oldPart);
                    }));
    
            } else {
                const cartSessionInfo = this.storage.retrieve(CART_KEY);
                cartSessionInfo.cartItemsList.find(x => x.partNo === part.partNo).quantity = part.quantity;
                this.updateCart(cartSessionInfo);
                return of(part);
            }
        }));

    }

    public deletePart(part): Observable<any> {
        let sessionCart = this.storage.retrieve(CART_KEY);
        return this.isBrowserCart().pipe(flatMap(isBrowserCart => {
            if (!isBrowserCart) {
                let url = `${AppSettings.API_ENDPOINTS.get('shopparts')}/protected/cart/${sessionCart.cartId}/part/${part.partNo}`;
                return this._http.delete(url)
                    .pipe(map(() => {
                        
                            for (let j = 0; j < sessionCart.cartItemsList.length; j++) {
                                if (sessionCart.cartItemsList[j].partNo == part.partNo) {
                                    sessionCart.cartItemsList.splice(j, 1);
                                    break;
                                }
                            }
                            this.updateCart(sessionCart);
                        //}
                        return of(part);
                    })).pipe(catchError(() => {
                        return of(part);
                    }));
    
            } else {
    
                for (let i = 0; i < sessionCart.cartItemsList.length; i++) {
                    if (sessionCart.cartItemsList[i].partNo === part.partNo) {
                        sessionCart.cartItemsList.splice(i, 1);
                    }
                }
                this.updateCart(sessionCart);
                return of(part);
            }
        }));

    }

    public sendEmail(cart: Cart): Promise<any> {

        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
       

        let url = `${AppSettings.API_ENDPOINTS.get('shopparts')}/protected/cart/sendEmail`;
        let cartSession = this.storage.retrieve(CART_KEY);
        cartSession.emailId = cart.email;
        cartSession.captcha = cart.captcha;
        cartSession.username = cart.username;
        cartSession.sender = cart.sender;
        cartSession.phone = cart.phone;
        cartSession.comments = cart.comments;

        return this._http.post(url, JSON.stringify(cartSession), { headers: headers })
            .toPromise()
            .then((res:Response) => res.text);
    }

    public sendCartToBMS(desc: string) {

        let cart = this.storage.retrieve(CART_KEY);
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        

        let url = `${AppSettings.API_ENDPOINTS.get('shopparts')}/protected/cart/${cart.cartId}/send?to=bms`;
        return this._http.post(url + `&name=${desc || 'qsol'}`, cart.cartItemsList, { headers: headers })
            .toPromise()
            .then((res : Response) => {
                this.sessionStorage.store("Items_Send", this.storage.retrieve(CART_KEY).cartItemsList);
                this.updateCart(null);
                return res;
            });
    }

    public sendCartToIntegration(userId: string): Promise<string> {
        if (userId) {
            if (this.integrationService.data.vendorCartId) {
                return Promise.resolve(this.integrationService.data.vendorCartId);
            }
            return this.afterLogin(true)
                .then(cartId => {
                    if (!cartId) {
                        return Promise.reject({
                            message: 'Cart syncing is unsuccessful'
                        } as Error);
                    }
                    this.integrationService.data = Object.assign(this.integrationService.data, {
                        vendorCartId: cartId
                    });
                    return cartId;
                });
        }
        return Promise.reject({
            message: 'Invalid invocation to sendCart'
        } as Error);
    }
    
    public initializePaccarSession() {
        return this._http.get(`${AppSettings.API_ENDPOINTS.get('shopparts')}/protected/paccarSession`).toPromise()
            .then((res : Response) => res);
    }

    private isBrowserCart(limit: number = 1): Observable<boolean> {
        const observable = this.integrationService.getIntegrationUser()
            .pipe(map(integrationUser => {
                if (integrationUser) {
                    return !this.integrationService.data.vendorCartId;
                }
                return this.userId === 'Login';
            }));
        if (limit > 0) {
            return observable.pipe(take(limit));
        }
        return observable;
    }

    public getCart(): Observable<any> {
        return this._cart.pipe(map(cart => cart || {}));
    }

    public getCartItemsCount(): Observable<number> {
        return this.getCart().pipe(map(cart => cart.cartItemsList))
            .pipe(map(cartItems => (cartItems || []).length))
            .pipe(distinctUntilChanged(), share());
    }

    private cleanCart() {
        const cartItemsList = (this.storage.retrieve(CART_KEY) || {}).cartItemsList;
        this.updateCart(cartItemsList && { cartItemsList });
    }

    private updateCart(cart: any) {
        if (cart) {
            this.storage.store(CART_KEY, cart);
        } else {
            this.storage.clear(CART_KEY);
        }
        this._cart.next(cart);
    }

    notifyCartLoaded(completeCallback: () => void, initCallback: () => void = () => { /* do nothing */}): Subscription {
        return this.cartLoadCompleteNotifier.subscribe(initCallback, () => { /* do nothing */}, completeCallback);
    }
}
