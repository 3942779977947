var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit, Injector } from "@angular/core";
import { LocalStorageService } from "ngx-webstorage";
import { of, Subject, throwError } from "rxjs";
import { environment } from "../../environments/environment";
import { map, filter, distinctUntilChanged, catchError, flatMap } from 'rxjs/operators';
import { Store } from "@ngxs/store";
import { ApplicationState } from "../state/app.state";
import { DI } from "../state/core";
import * as i0 from "@angular/core";
var ɵ0 = function () { return LocalStorageService; }, ɵ1 = function () { return Store; };
var SessionService = /** @class */ (function (_super) {
    __extends(SessionService, _super);
    function SessionService(_injector) {
        var _this = _super.call(this, _injector) || this;
        _this.ngOnInit();
        return _this;
    }
    SessionService.prototype.ngOnInit = function () {
        var _this = this;
        this.subject = new Subject();
        this.observeSession().subscribe(function (session) { return _this.session = session; });
        this.observeCurrentSession().subscribe(function (session) { return _this.monitorSessionUpdate(session); });
    };
    SessionService.prototype.updateSessionLastAccessedTime = function () {
        this.updateSession({ lastAccessedTime: Date.now() });
    };
    SessionService.prototype.updateSessionCallState = function (active, currentSession) {
        var session = active ? { sessionCallActive: active, lastSessionCall: Date.now() }
            : { sessionCallActive: active, currentSession: currentSession };
        this.updateSession(session);
    };
    SessionService.prototype.updateSession = function (sessionUpdate) {
        var session = this.localStorage.retrieve(SessionService.STORAGE_KEY);
        this.localStorage.store(SessionService.STORAGE_KEY, Object.assign({}, session, sessionUpdate));
    };
    SessionService.prototype.isSessionCallActive = function () {
        var session = this.session || { sessionCallActive: false, lastSessionCall: 0 };
        return session.sessionCallActive && (Date.now() - session.lastSessionCall < 500)
            && session.currentSession;
    };
    SessionService.prototype.getCurrentSession = function () {
        try {
            var currentSession = atob((this.session || {}).currentSession);
            return currentSession ? JSON.parse(currentSession) : null;
        }
        catch (error) {
            if (!environment.production) {
                console.warn(error);
            }
            return null;
        }
    };
    SessionService.prototype.getLastAccessedTime = function () {
        var session = this.session || { lastAccessedTime: null, lastSessionCall: null };
        return Math.max(session.lastAccessedTime || -1, session.lastSessionCall || -1);
    };
    SessionService.prototype.updateCurrentSession = function (handler, initialize) {
        var _this = this;
        if (initialize === void 0) { initialize = true; }
        if (!this.isSessionCallActive()) {
            this.updateSessionCallState(true);
            return this.store.selectOnce(ApplicationState.popupMode).pipe(flatMap(function (popupMode) {
                if (initialize && popupMode) {
                    return of(_this.getCurrentSession());
                }
                return handler();
            }), map(function (response) {
                _this.updateSessionCallState(false, btoa(JSON.stringify(response)));
                return response;
            }), catchError(function (error) {
                _this.updateSessionCallState(false, btoa(null));
                return throwError(error);
            }));
        }
        return this.observeCurrentSession();
    };
    SessionService.prototype.monitorSessionUpdate = function (session) {
        var _this = this;
        this.clearTimeout();
        this.subject.next({ type: 'clear-session-update' });
        var timer = session.tokenExpiresAt - Date.now() - SessionService.TOKEN_EXPIRATION_OFFSET;
        if (!environment.production) {
            console.log(new Date(), 'Initiating timer for sessions', timer);
        }
        this.timer = setTimeout(function () { return _this.refreshSession(); }, timer);
    };
    SessionService.prototype.refreshSession = function () {
        var _this = this;
        var now = Date.now();
        var currentSession = this.getCurrentSession();
        var maxInactiveInterval = currentSession.maxInactiveInterval - SessionService.TOKEN_EXPIRATION_OFFSET;
        var inActiveInterval = now - this.getLastAccessedTime();
        if (inActiveInterval / maxInactiveInterval <= 0.95) {
            if (!environment.production) {
                console.log(new Date(), 'Session Update (Automatic)');
            }
            this.subject.next({ type: 'auto-session-update' });
        }
        else {
            var now_1 = Date.now();
            var lastAccessedTime = (this.session || { lastAccessedTime: now_1 }).lastAccessedTime;
            if (now_1 - lastAccessedTime >= SessionService.MAX_WINDOW_INACTIVE) {
                if (!environment.production) {
                    console.log(new Date(), 'Window InActive (Session Reset Notification)');
                }
                this.subject.next({ type: 'notify-window-inactive' });
            }
            else if (inActiveInterval <= currentSession.tokenExpiresAt) {
                if (!environment.production) {
                    console.log(new Date(), 'Session Update (Confirmation Dialog)');
                }
                this.subject.next({ type: 'confirm-session-update' });
                this.clearTimeout();
                this.timer = setTimeout(function () { return _this.notifySessionReset(); }, currentSession.tokenExpiresAt - now_1);
            }
            else {
                this.notifySessionReset();
            }
        }
    };
    SessionService.prototype.notifySessionReset = function () {
        if (!environment.production) {
            console.log(new Date(), 'Session Update (Reset Notification)');
        }
        this.subject.next({ type: 'notify-session-reset' });
    };
    SessionService.prototype.observeCurrentSession = function () {
        var _this = this;
        return this.observeSession()
            .pipe(map(function (session) { return session.currentSession; }), filter(function (session) { return !_this.isSessionCallActive() && session !== null && session !== undefined; }), distinctUntilChanged(function (prev, curr) { return prev === curr; }), map(function () { return _this.getCurrentSession(); }), filter(function (session) { return !_this.isSessionCallActive() && session !== null && session !== undefined; }));
    };
    SessionService.prototype.observeSession = function () {
        return this.localStorage.observe(SessionService.STORAGE_KEY)
            .pipe(filter(function (session) { return session !== null && session !== undefined; }));
    };
    SessionService.prototype.registerForSessionUpdates = function () {
        return this.subject.asObservable();
    };
    SessionService.prototype.clearTimeout = function () {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    };
    SessionService.STORAGE_KEY = 'SESSION';
    SessionService.TOKEN_EXPIRATION_OFFSET = 1000 * 60 * 5;
    SessionService.MAX_WINDOW_INACTIVE = 1000 * 60 * 60 * 8;
    SessionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionService_Factory() { return new SessionService(i0.ɵɵinject(i0.INJECTOR)); }, token: SessionService, providedIn: "root" });
    __decorate([
        DI.Inject(ɵ0),
        __metadata("design:type", LocalStorageService)
    ], SessionService.prototype, "localStorage", void 0);
    __decorate([
        DI.Inject(ɵ1),
        __metadata("design:type", Store)
    ], SessionService.prototype, "store", void 0);
    return SessionService;
}(DI.AutoDependencyInjector));
export { SessionService };
export { ɵ0, ɵ1 };
