import { Injectable, Injector } from '@angular/core';
import { Subject ,  BehaviorSubject }    from 'rxjs';
import { Toast } from 'angular2-toaster';
import { SharedInfoService } from '../../partsCatalog/sharedInfo.service';
import {PowerGenSharedInfoService} from '../../power-gen/powerGenSharedInfo.service'
import { Store } from '@ngxs/store';
import { EngineBOM } from 'src/app/partsCatalog/state/engine-bom.actions';
import { DI } from 'src/app/state/core';

@Injectable()
export class NotificationService extends DI.AutoDependencyInjector {
    public setGlobal:string = '';
    public setType:string = '';
    public setBUType:string = '';
    public setVinEsn:string = '';
    public setRoute:string = '';
    public setPackageNo:string = '';
    public isPackageESN:boolean = false;
    public setBu:string = "";

    public buDataResults: any[];
    
    private _sharedInfoService: SharedInfoService;
    private _PowerGensharedInfoService: PowerGenSharedInfoService;

    @DI.Inject(() => Store)
    private store: Store;

    constructor(_injector: Injector) {
        super(_injector);
    }

    public sharedInfoInstance(_sharedInfoService: SharedInfoService) {
        this._sharedInfoService = _sharedInfoService;
    }

    public getSharedInfoInstance(): SharedInfoService {
        return this._sharedInfoService;
    }

    public getRoute(): string {
        const route = this.setRoute;
        this.setRoute = null;
        return route;
    }
     public powerGenSharedInfoInstance(_PowerGensharedInfoService: PowerGenSharedInfoService) {
        this._PowerGensharedInfoService = _PowerGensharedInfoService;
    }

    public clearSharedInfoInstance(field: string) {
        if (!this._sharedInfoService) return;

        const data = {};
        for (const key in this._sharedInfoService[field]) data[key] = undefined;

        if (field === 'currentEsnInfo') {
            this.store.dispatch(new EngineBOM.SetESNInfo(data));
        } else {
            this._sharedInfoService[field] = data;
        }
    }

    public setGlobalvalue(val:string){
        this.setGlobal = val;
    }

    public getGlobalValue(){
        return this.setGlobal;
    }

    public setPackageNoValue(val:string){
        this.setPackageNo = val;
    }

    public getPackageNoValue(){
        return this.setPackageNo;
    }

    public setIsPackageESNvalue(val:boolean){
        this.isPackageESN = val;
    }

    public getIsPackageESNValue(){
        return this.isPackageESN;
    }

    public clearPackageData(){
        this.setPackageNoValue(null);
    }

 
    private notifications = new Subject<Toast>();
    notifications$ = this.notifications.asObservable();
    
    public notify(toast : Toast):void{
        this.notifications.next(toast);
    }
    
     public translateInfo: any = [];
     public saveEsnData:any = [];

    private notificationtranslate = new BehaviorSubject<string>('en');
    notificationTranslateView$ = this.notificationtranslate.asObservable();

    public notificationtranslate1(selectedLanguage:any): void {
        this.notificationtranslate.next(selectedLanguage);
    }

    private notificationEBUSearch = new BehaviorSubject<string>('');
    notificationEBUSearchView$ = this.notificationEBUSearch.asObservable().filter(value=>!!value);

    public notificationEBUUpdated(value:any): void {
        this.notificationEBUSearch.next(value);
    }

    private notificationPGBUSearch = new BehaviorSubject<string>('');
    notificationPGBUSearch$ = this.notificationPGBUSearch.asObservable();

    public notificationPGBUUpdated(value:any): void {
        this.notificationPGBUSearch.next(value);
    }

    private notificationCESSearch = new BehaviorSubject<string>('');
    notificationCESSearch$ = this.notificationCESSearch.asObservable();

    public notificationCESUpdated(value:any): void {
        this.notificationCESSearch.next(value);
    }

    private notificationCTTSearch = new BehaviorSubject<string>('');
    notificationCTTSearch$ = this.notificationCTTSearch.asObservable();

    public notificationCTTUpdated(value:any): void {
        this.notificationCTTSearch.next(value);
    }
}
