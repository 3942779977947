var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Injector } from '@angular/core';
import { AppSettings } from './app.settings';
import { Subject } from "rxjs";
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { IntegrationService } from './integration/integration.service';
import { LoginSharedService } from './login/login-shared.service';
import { map, filter, flatMap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionService } from './session-maintenance/session.service';
import { Router } from '@angular/router';
import { DI } from './state/core';
export var USER_ID_KEY = 'USERID';
var ɵ0 = function () { return LocalStorageService; }, ɵ1 = function () { return SessionStorageService; }, ɵ2 = function () { return IntegrationService; }, ɵ3 = function () { return LoginSharedService; }, ɵ4 = function () { return SessionService; }, ɵ5 = function () { return HttpClient; }, ɵ6 = function () { return Router; };
var AppState = /** @class */ (function (_super) {
    __extends(AppState, _super);
    function AppState(_injector) {
        var _this = _super.call(this, _injector) || this;
        _this._state = {};
        _this.sessionChangesNotifier = new Subject();
        _this.sessionService.registerForSessionUpdates().pipe(filter(function (event) { return event.type === 'auto-session-update' || event.type === 'notify-session-reset'
            || (!_this.loginShared.isLoggedIn() && event.type === 'confirm-session-update'); }), flatMap(function () { return _this.refreshToken(new HttpHeaders({ 'Expire-Cookies': 'None' })); })).subscribe();
        _this.sessionService.observeCurrentSession()
            .pipe(filter(function (_) { return _this.router.navigated; }))
            .subscribe(function (res) { return _this.processResult(res); });
        return _this;
    }
    AppState.prototype.processResult = function (loginInfo) {
        this.loginShared.setLoginInfo(loginInfo);
        if (!this.loginShared.isPublicAcc()) {
            this.storage.store(USER_ID_KEY, loginInfo.name);
            this.localStorageService.store(USER_ID_KEY, loginInfo.name);
        }
        else {
            this.storage.store(USER_ID_KEY, 'Login');
            this.localStorageService.clear(USER_ID_KEY);
        }
    };
    AppState.prototype.getIntegrationSession = function (token, integrationData) {
        var _this = this;
        return this.sessionService.updateCurrentSession(function () { return _this.http.post(AppSettings.AUTH_ENDPOINT + "/login/integrationUser", integrationData, { headers: new HttpHeaders({ 'Authorization': token, 'Content-Type': 'application/json' }) }); })
            .pipe(map(function (res) { return _this.parseResponse(res); })).toPromise();
    };
    AppState.prototype.getInitialToken = function (saml, integrationData) {
        var _this = this;
        if (saml === void 0) { saml = false; }
        var responseObservable;
        if (!saml) {
            responseObservable = this.getPublicToken(true);
        }
        else {
            responseObservable = this.sessionService.updateCurrentSession(function () {
                var url = AppSettings.AUTH_ENDPOINT + "/getCurrentSession";
                var headers = new HttpHeaders();
                if (integrationData) {
                    headers = headers.append('Content-Type', 'application/json');
                }
                var obs = integrationData ? _this.http.post(url, integrationData, { headers: headers })
                    : _this.http.get(url, { headers: headers });
                return obs.pipe(map(function (res) { return Object.assign({}, res, { mergeCartItems: saml }); }));
            });
        }
        return responseObservable.pipe(map(function (res) { return _this.parseResponse(res); }));
    };
    AppState.prototype.parseResponse = function (res) {
        this.storage.clear('target');
        this.storage.clear('INTEGRATION_DATA');
        var jsonRes = res;
        this.integrationService.data = res.extraAttributes.integrationData;
        return Object.assign({}, res, { json: function () { return jsonRes; } });
    };
    AppState.prototype.refreshToken = function (headers, authorize, integrationData) {
        var _this = this;
        if (authorize === void 0) { authorize = true; }
        var milliseconds = new Date().getTime() + '';
        var token = milliseconds.slice(0, 3) + "secure" + milliseconds.slice(3, 6) + "IAC" + milliseconds.slice(6, milliseconds.length);
        var authorizationToekn = milliseconds.slice(0, 4) + "auth" + milliseconds.slice(3, milliseconds.length) + "token" + milliseconds.slice(4, 8);
        headers = headers || new HttpHeaders();
        if (authorize) {
            headers = headers.append('Authorization', btoa(token));
        }
        return this.sessionService.updateCurrentSession(function () {
            var url = AppSettings.AUTH_ENDPOINT + "/refreshToken?authToken=" + btoa(authorizationToekn);
            if (integrationData) {
                headers = headers.append('Content-Type', 'application/json');
            }
            return integrationData ? _this.http.post(url, integrationData, { headers: headers })
                : _this.http.get(url, { headers: headers });
        }, false).pipe(map(function (response) {
            _this.processResult(response);
            return response;
        }));
    };
    AppState.prototype.getPublicToken = function (responseHandled) {
        var _this = this;
        if (responseHandled === void 0) { responseHandled = false; }
        var milliseconds = new Date().getTime() + '';
        var token = milliseconds.slice(0, 3) + "secure" + milliseconds.slice(3, 6) + "IAC" + milliseconds.slice(6, milliseconds.length);
        return this.makeCallAsObservable(new HttpHeaders({ 'Authorization': btoa(token) }))
            .pipe(map(function (response) {
            if (!responseHandled) {
                _this.integrationService.data = response.extraAttributes.integrationData;
            }
            return response;
        }));
    };
    AppState.prototype.makeCallAsObservable = function (headers) {
        var _this = this;
        return this.sessionService.updateCurrentSession(function () { return _this.http.get(AppSettings.AUTH_ENDPOINT + "/login/publicUser", { headers: headers }); });
    };
    Object.defineProperty(AppState.prototype, "state", {
        // already return a clone of the current state
        get: function () {
            return this._state = this._clone(this._state);
        },
        // never allow mutation
        set: function (value) {
            throw new Error('do not mutate the `.state` directly');
        },
        enumerable: true,
        configurable: true
    });
    AppState.prototype.get = function (prop) {
        // use our state getter for the clone
        var state = this.state;
        return state.hasOwnProperty(prop) ? state[prop] : state;
    };
    AppState.prototype.set = function (prop, value) {
        // internally mutate our state
        return this._state[prop] = value;
    };
    AppState.prototype._clone = function (object) {
        // simple object clone
        return JSON.parse(JSON.stringify(object));
    };
    AppState.prototype.publishSessionChange = function (res) {
        this.sessionChangesNotifier.next(res);
    };
    AppState.prototype.registerForSessionChange = function (subscriber) {
        return this.sessionChangesNotifier.asObservable().subscribe(subscriber);
    };
    AppState.prototype.getAssetsLink = function (path, isLocalAssetFolder) {
        if (isLocalAssetFolder === void 0) { isLocalAssetFolder = false; }
        if (isLocalAssetFolder || path.startsWith('/images') || path.startsWith('dealer-newsletter'))
            return "/assets" + path;
        return AppSettings.API_ENDPOINTS.get('IACDataServices') + "/assets" + path;
    };
    Object.defineProperty(AppState.prototype, "hasUserConsent", {
        get: function () {
            return this.loginShared.isLoggedIn() ? this.loginShared.consented : null;
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        DI.Inject(ɵ0),
        __metadata("design:type", LocalStorageService)
    ], AppState.prototype, "localStorageService", void 0);
    __decorate([
        DI.Inject(ɵ1),
        __metadata("design:type", SessionStorageService)
    ], AppState.prototype, "storage", void 0);
    __decorate([
        DI.Inject(ɵ2),
        __metadata("design:type", IntegrationService)
    ], AppState.prototype, "integrationService", void 0);
    __decorate([
        DI.Inject(ɵ3),
        __metadata("design:type", LoginSharedService)
    ], AppState.prototype, "loginShared", void 0);
    __decorate([
        DI.Inject(ɵ4),
        __metadata("design:type", SessionService)
    ], AppState.prototype, "sessionService", void 0);
    __decorate([
        DI.Inject(ɵ5),
        __metadata("design:type", HttpClient)
    ], AppState.prototype, "http", void 0);
    __decorate([
        DI.Inject(ɵ6),
        __metadata("design:type", Router)
    ], AppState.prototype, "router", void 0);
    return AppState;
}(DI.AutoDependencyInjector));
export { AppState };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
