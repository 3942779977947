import { Component, AfterContentChecked } from '@angular/core';
import { NotificationService } from '../shared/header/notification.service';
import { AppSettings } from '../app.settings';
import { AppState } from '../app.service';
import { SpriteMap } from '../shared-components/sprite.map';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'home',
    templateUrl: 'home.html',
    styleUrls: ['./home.css']
})
export class HomeComponent implements AfterContentChecked {

    public languageFlag: string;
    public storeUrl: string = `${AppSettings.PUBLICATIONS_STORE}`;

    public components: Array<any>;
    public componentsMap: SpriteMap = {
        format: 'GRID',
        numberOfHorizontalImages: 2,
        numberOfVerticalImages: 4
    };

    public banners: Array<any>;
    public bannersMap: SpriteMap = {
        format: 'GRID',
        numberOfHorizontalImages: 2,
        numberOfVerticalImages: 4
    }

    public subscription : Subscription;

    constructor(private notificationService: NotificationService, private appService: AppState, private router: Router) {
        this.components = [
            {link: '/esn-entry', href: null, name: 'Engine Parts', position: 0},
            {link: null, href: 'https://catalog.cumminsfiltration.com/catalog/CatalogSearch.do?_locale=en&_region', name: 'Filtration', position: 1},
            {link: '/gsn-entry', href: null, name: 'Power Generation', position: 2},
            {link: '/ctt-entry', href: null, name: 'Turbochargers', position: 3},
            {link: '/esn-entry/npbu', href: null, name: 'Electrified Power Products', position: 7},
            {link: '/atsn-entry', href: null, name: 'Aftertreatment', position: 4},
            {link: null, href: this.storeUrl, name: 'Publications', position: 5},
            {link: '/mst-entry/main/home-page', href: null, name: 'Service Tools and Consumables', position: 6},
        ]

        this.banners = [
            { position: 0, link: null, href: 'http://www.cummins.com/genuinepartsauthentication', name: 'Genuine_Parts_Anti_Counterfeit' },
            { position: 1, link: null, href: 'https://www.cummins.com/parts-and-service/genuine-parts-qsk', name: 'qsk_tiad_banner' },
            { position: 2, link: '/resource/iac/Features-and-updates/why-genuine.html', name: 'genuine_cummins_parts' },
            { position: 3, link: '/ctt-entry/main', name: 'holset_banner' },
            { position: 4, link: '/resource/iac/about-us.html', name: 'nothing_but_genuine_banner' },
            { position: 5, link: null, name: 'translations_banner' },
            { position: 6, link: '/resource/iac/Features-and-updates/promotions/overhaulkit.html', name: 'overhaulkit' },
        ]
    }

    public navigate(component: any, target?: string) {
        if (component.href) window.open(component.href, target);
        else if (component.link) this.router.navigate([component.link]);
    }

    public getAssetsLink(path: string, bannerPosition: number = 0): string {
        return bannerPosition >= 0 ? this.appService.getAssetsLink(path) : 'NA';
    }

    public ngAfterContentChecked() {
        this.notificationService.notificationTranslateView$
            .subscribe(message => this.languageFlag = message || 'en');
    }

    public getEffectiveLanguage(banner: string): string {
        if (this.isDefaultBanner(banner)) 
        return 'en';
        else if (this.languageFlag === 'pt-br') return 'pt';
        return this.languageFlag || 'en';
    }

    public getBannerMap(banner: string): any {
        let language = this.getEffectiveLanguage(banner);
        return Object.assign({}, this.bannersMap, { 
            numberOfVerticalImages: language === 'en' ? 4 : 3,
            numberOfHorizontalImages : language === 'en' ? 2 : 2
     });
    }

    public getPosition(pos: number) {
        let cnt = 0;
        const language = this.getEffectiveLanguage(this.banners[pos].name);
        if (language != 'en') 
        {
            for (let i = pos - 1; i >= 0; i--) {
                if (this.isDefaultBanner(this.banners[i].name))
                    cnt++;
            }
        }
        return pos - cnt;
    }

    private isDefaultBanner(banner: string): boolean {
        return (banner === 'Genuine_Parts_Anti_Counterfeit' || banner === 'overhaulkit')
    }
}
