/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cookie.popup.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./cookie.popup.component";
import * as i5 from "./cookie.service";
import * as i6 from "ngx-webstorage";
var styles_CookiePopupComponent = [i0.styles];
var RenderType_CookiePopupComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_CookiePopupComponent, data: {} });
export { RenderType_CookiePopupComponent as RenderType_CookiePopupComponent };
function View_CookiePopupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["slider", 1]], null, 15, "div", [["class", "slider-bottom hidden-print"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "slider-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [["class", "cancel slider-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.allowAccess() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["x"])), (_l()(), i1.ɵeld(4, 0, null, null, 11, "div", [["class", "slider-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", "."])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ". ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, null, 2, "a", [["href", "https://www.cummins.com/privacy-and-legal#cookies"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["."]))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("We use cookies on this site to enhance your experience")); _ck(_v, 6, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("By clicking any link on this page, you give consent for us to set cookies")); var currVal_2 = i1.ɵunv(_v, 9, 1, i1.ɵnov(_v, 11).transform("For more information, see our")); _ck(_v, 9, 0, currVal_1, currVal_2); var currVal_3 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("Privacy and Legal page")); _ck(_v, 13, 0, currVal_3); }); }
export function View_CookiePopupComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { self: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CookiePopupComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.access; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CookiePopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cookie-popup", [], null, null, null, View_CookiePopupComponent_0, RenderType_CookiePopupComponent)), i1.ɵdid(1, 4243456, null, 0, i4.CookiePopupComponent, [i5.CookieService, i6.LocalStorageService], null, null)], null, null); }
var CookiePopupComponentNgFactory = i1.ɵccf("cookie-popup", i4.CookiePopupComponent, View_CookiePopupComponent_Host_0, {}, {}, []);
export { CookiePopupComponentNgFactory as CookiePopupComponentNgFactory };
