import { Component } from '@angular/core';
import { NotificationService } from './notification.service';

@Component({
    selector: 'generic-notification',
    templateUrl: 'generic-notification.html',
    styleUrls: ['./generic-notification.css']
})
export class GenericNotificationComponent {

    constructor(public notificationService: NotificationService) { }

}
