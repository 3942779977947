import { Injectable }       from '@angular/core';

import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { LoginSharedService } from './login/login-shared.service';

@Injectable()
export class LoginAuthGuard implements  CanActivate {
  constructor(private loginShared: LoginSharedService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const path: string = route.url.join("/");
    if(this.loginShared.userAttributes.length === 0) {
      setTimeout(() => this.router.navigate(['access', path]));
      return false;
    }
    const parameters: any[] = this.loginShared.registeredFeatures.get(path);
    parameters.push(true);
    const flag = this.loginShared.isLoggedIn() && this.loginShared.checkFeatureAccess.apply(this.loginShared, parameters);
    flag || setTimeout(() => this.router.navigate(['login']));
    return flag;
  }

}
