var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Injector } from '@angular/core';
import { debounceTime, map, share } from 'rxjs/operators';
import { LoginSharedService } from '../login/login-shared.service';
import { DI } from '../state/core';
var ɵ0 = function () { return LoginSharedService; };
var IntegrationService = /** @class */ (function (_super) {
    __extends(IntegrationService, _super);
    function IntegrationService(_injector) {
        var _this = _super.call(this, _injector) || this;
        _this.valid = false;
        return _this;
    }
    Object.defineProperty(IntegrationService.prototype, "data", {
        get: function () {
            return this._data || {
                features: [],
                language: 'en',
                redirectTo: '/home',
                vendor: 'IAC',
                vendorCartId: '',
                userType: '',
                searchCriteria: '',
                transactionId: ''
            };
        },
        set: function (_data) {
            this.valid = _data ? true : false;
            this._data = _data;
        },
        enumerable: true,
        configurable: true
    });
    IntegrationService.prototype.getIntegrationUser = function () {
        var _this = this;
        return this.loginService.notificationLoginInfo$
            .pipe(map(function (loginInfo) {
            if (!_this.loginService.isIntegrationAccess(loginInfo)) {
                return undefined;
            }
            var regex = /^INTEGRATION_FLOW \((.+)\)$/;
            var attributes = loginInfo || [];
            for (var _i = 0, attributes_1 = attributes; _i < attributes_1.length; _i++) {
                var attribute = attributes_1[_i];
                for (var _a = 0, _b = (attribute.iacFeaturesCollection || []); _a < _b.length; _a++) {
                    var feature = _b[_a];
                    if (regex.test(feature.featureDescription)
                        && (feature.contexts || []).filter(function (x) { return x === 'IACDataServices'; }).length === 1) {
                        return feature.featureDescription.replace(regex, "$1");
                    }
                }
            }
            return undefined;
        })).pipe(share(), debounceTime(0));
    };
    IntegrationService.prototype.isIntegrationAccess = function () {
        return this.loginService.isIntegrationAccess();
    };
    __decorate([
        DI.Inject(ɵ0),
        __metadata("design:type", LoginSharedService)
    ], IntegrationService.prototype, "loginService", void 0);
    return IntegrationService;
}(DI.AutoDependencyInjector));
export { IntegrationService };
export { ɵ0 };
