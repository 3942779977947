import { Injectable, OnInit, Injector } from "@angular/core";
import { Session } from "./session";
import { LocalStorageService } from "ngx-webstorage";
import { Observable, of, Subject, throwError } from "rxjs";
import { environment } from "../../environments/environment";
import { map, filter, distinctUntilChanged, catchError, flatMap } from 'rxjs/operators';
import { Store } from "@ngxs/store";
import { ApplicationState } from "../state/app.state";
import { DI } from "../state/core";

@Injectable({ providedIn: 'root' })
export class SessionService extends DI.AutoDependencyInjector implements OnInit {
    private static STORAGE_KEY: string = 'SESSION';
    private static TOKEN_EXPIRATION_OFFSET: number = 1000 * 60 * 5;
    private static MAX_WINDOW_INACTIVE: number = 1000 * 60 * 60 * 8;

    private session: Session;
    private subject: Subject<any>;
    private timer: any;

    @DI.Inject(() => LocalStorageService)
    private localStorage: LocalStorageService;

    @DI.Inject(() => Store)
    private store: Store;

    constructor(_injector: Injector) {
        super(_injector);
        this.ngOnInit();
    }

    ngOnInit() {
        this.subject = new Subject<any>();
        this.observeSession().subscribe((session: Session) => this.session = session);
        this.observeCurrentSession().subscribe(session => this.monitorSessionUpdate(session));
    }

    public updateSessionLastAccessedTime() {
        this.updateSession({ lastAccessedTime: Date.now() });
    }

    private updateSessionCallState(active: boolean, currentSession?: any) {
        const session = active ? { sessionCallActive: active, lastSessionCall: Date.now() }
            : { sessionCallActive: active, currentSession: currentSession };
        this.updateSession(session);
    }

    private updateSession(sessionUpdate: any) {
        const session = this.localStorage.retrieve(SessionService.STORAGE_KEY);
        this.localStorage.store(SessionService.STORAGE_KEY, Object.assign({}, session, sessionUpdate));
    }

    public isSessionCallActive(): boolean {
        const session = this.session || { sessionCallActive: false, lastSessionCall: 0 };
        return session.sessionCallActive && (Date.now() - session.lastSessionCall < 500)
            && session.currentSession;
    }

    public getCurrentSession(): any {
        try {
            const currentSession = atob((this.session || {} as any).currentSession);
            return currentSession ? JSON.parse(currentSession) : null;
        } catch (error) {
            if (!environment.production) {
                console.warn(error);
            }
            return null;
        }
    }

    public getLastAccessedTime(): number {
        const session = this.session || { lastAccessedTime: null, lastSessionCall: null };
        return Math.max(session.lastAccessedTime || -1, session.lastSessionCall || -1);
    }

    public updateCurrentSession(handler: (...params: any[]) => Observable<any>, initialize: boolean = true): Observable<any> {
        if (!this.isSessionCallActive()) {
            this.updateSessionCallState(true);
            return this.store.selectOnce(ApplicationState.popupMode).pipe(
                flatMap(popupMode => {
                    if (initialize && popupMode) {
                        return of(this.getCurrentSession());
                    }
                    return handler();
                }),
                map((response: any) => {
                    this.updateSessionCallState(false, btoa(JSON.stringify(response)));
                    return response;
                }),
                catchError(error => {
                    this.updateSessionCallState(false, btoa(null));
                    return throwError(error);
                })
            );
        }
        return this.observeCurrentSession();
    }

    private monitorSessionUpdate(session: any) {
        this.clearTimeout();
        this.subject.next({ type: 'clear-session-update' });
        const timer = session.tokenExpiresAt - Date.now() - SessionService.TOKEN_EXPIRATION_OFFSET;
        if (!environment.production) {
            console.log(new Date(), 'Initiating timer for sessions', timer);
        }
        this.timer = setTimeout(() => this.refreshSession(), timer);
    }

    private refreshSession() {
        const now = Date.now();
        const currentSession = this.getCurrentSession();
        const maxInactiveInterval = currentSession.maxInactiveInterval - SessionService.TOKEN_EXPIRATION_OFFSET;
        const inActiveInterval = now - this.getLastAccessedTime();

        if (inActiveInterval / maxInactiveInterval <= 0.95) {
            if (!environment.production) {
                console.log(new Date(), 'Session Update (Automatic)');
            }
            this.subject.next({ type: 'auto-session-update' });
        } else {
            const now = Date.now();
            const lastAccessedTime = (this.session || { lastAccessedTime: now }).lastAccessedTime;
            if (now - lastAccessedTime >= SessionService.MAX_WINDOW_INACTIVE) {
                if (!environment.production) {
                    console.log(new Date(), 'Window InActive (Session Reset Notification)');
                }
                this.subject.next({ type: 'notify-window-inactive' });
            } else if (inActiveInterval <= currentSession.tokenExpiresAt) {
                if (!environment.production) {
                    console.log(new Date(), 'Session Update (Confirmation Dialog)');
                }
                this.subject.next({ type: 'confirm-session-update' });
                this.clearTimeout();
                this.timer = setTimeout(() => this.notifySessionReset(), currentSession.tokenExpiresAt - now);
            } else {
                this.notifySessionReset();
            }
        }
    }

    private notifySessionReset() {
        if (!environment.production) {
            console.log(new Date(), 'Session Update (Reset Notification)');
        }
        this.subject.next({ type: 'notify-session-reset' });
    }

    public observeCurrentSession(): Observable<any> {
        return this.observeSession()
            .pipe(
                map((session: Session) => session.currentSession),
                filter((session: string) => !this.isSessionCallActive() && session !== null && session !== undefined),
                distinctUntilChanged((prev, curr) => prev === curr),
                map(() => this.getCurrentSession()),
                filter((session: any) => !this.isSessionCallActive() && session !== null && session !== undefined)
            );
    }

    private observeSession(): Observable<Session> {
        return this.localStorage.observe(SessionService.STORAGE_KEY)
            .pipe(filter((session: Session) => session !== null && session !== undefined));
    }

    public registerForSessionUpdates(): Observable<any> {
        return this.subject.asObservable();
    }

    private clearTimeout() {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    }
}
