import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { AppState } from './app.service';
import { Router, NavigationEnd } from "@angular/router";
import { LoginSharedService } from './login/login-shared.service';
import { LoginService } from './login/login.service';
import { NotificationService } from './shared/header/notification.service';
import { Subscription } from 'rxjs';
import { SessionService } from './session-maintenance/session.service';
import { HttpHeaders } from '@angular/common/http';
import { LanguageService } from './language.service';
import { HeaderComponent } from './shared/header/header.component';
@Component({
    selector: 'my-app',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./app.component.css'],
    templateUrl: 'app.component.html',
    providers:[LanguageService,HeaderComponent]
})
export class AppComponent implements OnInit, OnDestroy {

    public agreementModal: boolean;
    public showProgressAccept: boolean;
    public disabledContinue: boolean;
    public sessionEvents: any = {
        clearCookies: false,
        showSessionRefreshConfirmation: false,
        showSessionRefreshConfirmationProgress: false,
        showSessionResetNotification: false,
        showWindowInactiveNotification: false
    };

    private subscription: Subscription;
    public getBrowserLanguage:any;

    constructor(
        public appState: AppState,
        public router: Router,
        private loginShared: LoginSharedService, private loginService: LoginService,
        private notificationService: NotificationService, private sessionService: SessionService,
        private languageService:LanguageService,
        private headerComponent:HeaderComponent
    ) {
        this.agreementModal = false;
        this.showProgressAccept = false;
        this.disabledContinue = false;

        this.loadSession = this.loadSession.bind(this);
        this.getBrowserLanguage = this.languageService.translate.getBrowserLang();

    }

    ngOnInit() {
        this.languageService.translate.use(this.getBrowserLanguage);
        this.headerComponent.switchLanguage(this.getBrowserLanguage);
        this.subscription = this.appState.registerForSessionChange(res => {
            if (res) {
                this.appState.processResult(res.json());
                if (this.appState.hasUserConsent === false) {
                    this.agreementModal = true;
                }
                else this.loadSession();
            }
        });
        this.subscription.add(this.loginShared.registerSessionEvent().subscribe(event => {
            switch (event) {
                case 'Clear Cookies':
                    this.sessionEvents.clearCookies = true;
                    break;
            }
        }));
        this.subscription.add(this.sessionService.registerForSessionUpdates()
            .filter(event => (this.loginShared.isLoggedIn() && event.type !== 'auto-session-update')
                || event.type === 'notify-window-inactive' || event.type === 'clear-session-update'
                || event.type === 'notify-session-reset')
            .subscribe(event => {
                this.sessionEvents.showSessionRefreshConfirmationProgress = false;
                this.sessionEvents.showSessionRefreshConfirmation = event.type === 'confirm-session-update';
                this.sessionEvents.showSessionResetNotification = event.type === 'notify-session-reset'
                    || (event.type === 'clear-session-update' && this.sessionEvents.showSessionResetNotification);
                this.sessionEvents.showWindowInactiveNotification = event.type === 'notify-window-inactive';
            }));
    }
  
    ngOnDestroy() {
        this.subscription && this.subscription.unsubscribe();
    }
  
    public acceptConsent() {
        this.showProgressAccept = true;
        this.disabledContinue = true;
        this.loginService.provideUserConsent().then(res => {
            this.appState.processResult(res);
            this.loadSession();
        }).catch(err => {
            this.showProgressAccept = false;
            this.disabledContinue = false;
            this.notificationService.notify({ type: 'info', title: 'Something went wrong!', timeout: 2500 });
        });
    }

    private loadSession() {
        this.showProgressAccept = false;
        this.disabledContinue = false;
        if (this.appState.hasUserConsent === false) {
            this.notificationService.notify({ type: 'info', title: 'Something went wrong!', timeout: 2500 });
            return;
        }
        this.agreementModal = false;
    }

    public refreshSession(refreshCall: boolean = false) {
        if (refreshCall) {
            this.sessionEvents.showSessionRefreshConfirmationProgress = true;
            this.appState.refreshToken(new HttpHeaders({ 'Expire-Cookies': 'None' }))
                .subscribe(() => {
                    this.sessionService.updateSessionLastAccessedTime();
                    this.sessionEvents.showSessionRefreshConfirmationProgress = false;
                });
        }
    }

}
