var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EMPTY } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionStorageService } from 'ngx-webstorage';
import { catchError, map, tap, concatMap, flatMap } from 'rxjs/operators';
import { ToasterService } from 'angular2-toaster';
import { CMSParentComponent } from '../../common/cms-parent';
import { AppSettings } from '../../../app.settings';
import { TranslateService } from '@ngx-translate/core';
import { LoginSharedService } from '../../../login/login-shared.service';
import { Store } from '@ngxs/store';
import { ApplicationState } from 'src/app/state/app.state';
/**
 * Azure blob services to store, list or delete the files
 */
var BlobStorageService = /** @class */ (function (_super) {
    __extends(BlobStorageService, _super);
    function BlobStorageService(http, sessionStorageService, toasterService, translation, loginSharedService, store) {
        var _this = _super.call(this, translation, sessionStorageService, toasterService) || this;
        _this.http = http;
        _this.sessionStorageService = sessionStorageService;
        _this.toasterService = toasterService;
        _this.translation = translation;
        _this.loginSharedService = loginSharedService;
        _this.store = store;
        _this._url = AppSettings.API_ENDPOINTS.get('IACDataServices') + "/protected/cms";
        return _this;
    }
    /**
     * Upload or save HTML data on Azure blob services
     * @param data HTML Data to store on Azure blob
     * @param filename Name of the File
     * @param username Name of the user/owner who is uploading file
     */
    BlobStorageService.prototype.uploadFile = function (data, serverData) {
        var _this = this;
        var formData = new FormData();
        formData.append('file', data);
        formData.append('path', serverData.path);
        formData.append('lang', serverData.lang ? serverData.lang : this.getLang());
        formData.append('reference', serverData.reference);
        if (serverData.metadata) {
            serverData.metadata.map(function (obj) {
                formData.append(obj.name, obj.path);
            });
        }
        var _url = AppSettings.API_ENDPOINTS.get('IACDataServices') + "/protected/cms/uploadBlob";
        return this.http.post(_url, formData).pipe(catchError(function (error) {
            return _super.prototype.catchIACError.call(_this, error);
        }));
    };
    BlobStorageService.prototype.menu = function (path) {
        var _this = this;
        var _url = AppSettings.API_ENDPOINTS.get('cmsReaderServices') + "/protected/summary?path=" + path;
        var headers = new HttpHeaders({
            'accept': 'application/json',
            'accept-language': this.getLang(),
            'x-application': 'IACDataServices'
        });
        return this.store.selectOnce(ApplicationState.popupMode).pipe(flatMap(function (popupMode) { return popupMode ? EMPTY : _this.http.get(_url, { headers: headers }); }), map(function (data) { return _this.fetchMenuItem(data.cmseditor.summary, path); }), map(function (data) { return _this.parseMenuItem(data); }), map(function (data) {
            data.name = path;
            return data;
        }), catchError(function (error) {
            return _super.prototype.catchIACError.call(_this, error);
        }));
    };
    BlobStorageService.prototype.fetchMenuItem = function (data, path) {
        if (data.path === path)
            return data;
        var result = null;
        for (var _i = 0, _a = (data.children || []); _i < _a.length; _i++) {
            var child = _a[_i];
            result = this.fetchMenuItem(child, path);
            if (!result)
                break;
        }
        return result;
    };
    BlobStorageService.prototype.parseMenuItem = function (item) {
        var _this = this;
        var parts = item.path.split('/');
        item.title = item.name;
        item.name = parts[parts.length - 1].replace(/\.xml$/, '.html');
        (item.children || []).sort(function (a, b) { return a.path.localeCompare(b.path); });
        (item.children || []).forEach(function (child) { return _this.parseMenuItem(child); });
        return item;
    };
    BlobStorageService.prototype.rename = function (data) {
        var _this = this;
        return this.http.post(AppSettings.API_ENDPOINTS.get('IACDataServices') + "/protected/cms/rename", data, { headers: new HttpHeaders({
                'x-source': 'editor'
            }) }).pipe(catchError(function (error) {
            return _super.prototype.catchIACError.call(_this, error);
        }));
    };
    /**
     * Get File content
     * @param fileName File name to get the content
     */
    BlobStorageService.prototype.getCMSContent = function (filename, type, source) {
        var _this = this;
        var url = null;
        var headers = {};
        if (source) {
            url = AppSettings.API_ENDPOINTS.get('IACDataServices');
            if (this.loginSharedService.isLoggedIn()) {
                url += "/protected";
            }
            url += "/downloadBlob?path=" + filename + "&lang=" + this.getLang();
            headers['x-source'] = source;
        }
        else {
            var isHtmlRequest = filename.endsWith('.html');
            var endpoint = isHtmlRequest ? '/content' : '/resource';
            url = AppSettings.API_ENDPOINTS.get('cmsReaderServices') + "/protected" + endpoint + "?path=" + filename;
            if (isHtmlRequest) {
                headers['accept'] = 'text/html';
                headers['accept-language'] = this.getLang();
            }
            headers['x-application'] = 'IACDataServices';
        }
        return this.http.get(url, { headers: headers, responseType: 'blob' }).toPromise()
            .then(function (res) {
            if (type == 'site') {
                return res;
            }
            else {
                return _this.readBase64(res, type);
            }
        }).catch(function (error) {
            return _this.readBase64(error.error, type).then(function (e) { return _super.prototype.catchIACError.call(_this, JSON.parse(e)); });
        });
    };
    /**
     * Get File content
     * @param fileName File name to get the content
     */
    BlobStorageService.prototype.getTemplateContent = function (filename, type, source, application) {
        var _this = this;
        var url = null;
        var headers = {};
        if (source === 'editor') {
            url = AppSettings.API_ENDPOINTS.get('IACDataServices') + "/protected/downloadTemplate?path=" + filename + "&lang=" + this.getLang();
            headers['x-source'] = source;
        }
        else {
            url = AppSettings.API_ENDPOINTS.get('cmsReaderServices') + "/protected/content?path=" + filename;
            headers['accept'] = 'text/html';
            headers['accept-language'] = this.getLang();
            headers['x-application'] = 'IACDataServices';
        }
        return this.http.get(url, { responseType: "blob", headers: headers }).toPromise().then(function (res) {
            return _this.readBase64(res, type);
        }).catch(function (error) {
            return _super.prototype.catchIACError.call(_this, error);
        });
    };
    /**
    * Delete the file from Azuer blob
    * @param filename Name of the file to delete from Azure blob
    */
    BlobStorageService.prototype.delete = function (request, source, application) {
        var _this = this;
        if (request) {
            var _url_1 = AppSettings.API_ENDPOINTS.get('IACDataServices') + "/protected/cms/deleteContent";
            var options_1 = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'x-source': 'editor'
                }),
            };
            var workflowURL = this._url = AppSettings.API_ENDPOINTS.get('IACDataServices') + "/protected/cms/workflow/";
            var _authURL_1 = AppSettings.AUTH_API_ENDPOINTS.get('adminservices') + "/cmseditor3/content?path=" + request[0].path;
            return this.http.post(workflowURL, request[0]).pipe(tap(function (res) { return console.log('First result', res); }), concatMap(function (res) { return _this.http.delete(_authURL_1); }), tap(function (res) { return console.log('Second result', res); }), concatMap(function (res) { return _this.http.post(_url_1, request, options_1); }), tap(function (res) { return console.log('Third result', res); }));
        }
    };
    BlobStorageService.prototype.getHTML = function (filename) {
        var _this = this;
        var url = "" + AppSettings.HOST_NAME + "/assets/" + filename;
        return this.http.get(url, { responseType: 'text' }).pipe(catchError(function (error) {
            return _super.prototype.catchIACError.call(_this, error);
        }));
    };
    BlobStorageService.prototype.generateSummary = function (data) {
        var _this = this;
        return this.http.post(AppSettings.API_ENDPOINTS.get('IACDataServices') + "/protected/cms/summary", data, { headers: new HttpHeaders({
                'x-source': 'editor'
            }) }).pipe(catchError(function (error) {
            return _super.prototype.catchIACError.call(_this, error);
        }));
    };
    return BlobStorageService;
}(CMSParentComponent));
export { BlobStorageService };
