import { Injectable } from "@angular/core";
import { HttpResponse, HttpHeaders } from '@angular/common/http';
import { AppSettings } from "../app.settings";
import { of ,  EMPTY as empty ,  Observable, Subject} from "rxjs";
import {  map } from 'rxjs/operators';

import { LocalStorageService } from "ngx-webstorage";

const CACHE_ACCEPT_PATTERNS: Array<RegExp> = [
    new RegExp(`^(\\.{1,2}/)*assets/(.+/)?.+(\\.png|\\.jpg|\\.jpeg|\\.gif|\\.svg)$`, 'i'),
    new RegExp(`^${AppSettings.HOST_NAME}(:.{4})?/assets/(.+/)?.+(\\.png|\\.jpg|\\.jpeg|\\.gif|\\.svg)$`, 'i'),
    // new RegExp(`^${AppSettings.API_ENDPOINTS.get('IACDataServices')}/assets/images/.+\\..{3,4}$`, 'i'),
    new RegExp(`^${AppSettings.API_ENDPOINTS.get('IACDataServices')}/graphics/engines/.+\\..{3,4}$`, 'i'),
    // new RegExp(`^${AppSettings.API_ENDPOINTS.get('IACDataServices')}/assets/(.+/)?.+(\\.png|\\.jpg|\\.jpeg|\\.gif|\\.svg)$`),
];
const CACHE_IGNORE_PATTERNS: Array<RegExp> = [];
const CACHE_CLEAR_ON: Date = null;
const CACHE_LIMIT: number = 100;

@Injectable()
export class ResourceCacheService {
    
    private cache: string = `images-caches-limited`;
    private cachedRequests: Array<CachedRequests>;
    private _cacheStore: any;
    
    private static NO_CACHE: boolean = false;
    private static CACHE_IGNORE_PATTERNS: Array<RegExp> = CACHE_IGNORE_PATTERNS;
    private static CACHE_ACCEPT_PATTERNS: Array<RegExp> = CACHE_ACCEPT_PATTERNS;
    private static CACHE_CLEAR_ON: number = CACHE_CLEAR_ON ? CACHE_CLEAR_ON.getTime() : null;
    private static CACHE_LIMIT: number = CACHE_LIMIT;
    
    private static NO_CACHE_SUPPORT: boolean;
    private static CACHE_ACTIVE_TIME: number = 1000 * 60 * 60 * 24 * 7;

    constructor(private _localStore: LocalStorageService) {
        this._cacheStore = window.caches || {};
        this.cachedRequests = [];
        ResourceCacheService.NO_CACHE_SUPPORT = !window.caches;
        this.refreshCaches();
        this.refreshCachedRequests();
    }

    public save(requestUrl: string, response: any): Promise<void> {
        if(!this.isCacheSupported()){
           Observable.empty().toPromise();
        } 
        let savedResponse = response;
        if(savedResponse instanceof HttpResponse) savedResponse = this.toResponse(savedResponse);
        if(!savedResponse || !this.isCacheable(requestUrl)) return null;
        const contentType =response.headers.get('Content-Type');
        return savedResponse.clone().blob().then(blobContent => {
            const blobUrl = URL.createObjectURL(blobContent);
            this.checkIfBlobIsCorrect(blobUrl, contentType)
                .filter(flag => flag)
                .subscribe(() => {
                    this._cacheStore.open(this.cache).then((cache: Cache) => {
                        cache.put(requestUrl, savedResponse as Response);
                        this.updateCachedRequests(requestUrl, savedResponse as Response);
                    });
            });
        }).catch(() => null);
    }

    public get(requestUrl: string): Promise<any> {
        if(!this.isCacheSupported() || !this.isCacheable(requestUrl)) return of(null).toPromise();
        return this._cacheStore.match(requestUrl).then((response: Response) => {
            if(response) {
                const contentType =response.headers.get('Content-Type');
                return response.clone().blob()
                    .then(blobContent => this.checkIfBlobIsCorrect(URL.createObjectURL(blobContent), contentType).toPromise())
                    .then(flag => {
                        if(!flag) {
                            this.delete(requestUrl);
                            return null;
                        }
                        const newResponse = response.clone();
                        this._cacheStore.open(this.cache).then((cache: Cache) => {
                            newResponse.headers.set('Cache-Control', `max-age=${this.getCacheMaxAge()}`);
                            cache.put(requestUrl, newResponse).then(() => {
                                this.cachedRequests = (this.cachedRequests || [])
                                    .filter((cachedRequest: CachedRequests) => !cachedRequest.requestUrl.endsWith(requestUrl));
                                this.cachedRequests.push({
                                    requestUrl: requestUrl,
                                    expiryTime: parseInt(response.headers.get('Cache-Control').replace('max-age=', ''))
                                });
                            });
                        });
                        return response;
                    });
            }
        }).then(response => this.toHttpResponse(response))
        .catch(() => null);
    }

    private checkIfBlobIsCorrect(blobUrl: string, contentType: string): Observable<boolean> {
        if(!contentType.startsWith("image/")) return of(true);
        const img = new Image();
        const eventPublisher = new Subject<boolean>();
        const returnValue = eventPublisher.asObservable()
            .take(1)
            .pipe(map(flag => {
                URL.revokeObjectURL(blobUrl);
                return flag;
            }));
        img.src = blobUrl;
        img.onload = () => eventPublisher.next(true);
        img.onerror = () => eventPublisher.next(false);
        return returnValue;
    }
    
    private updateCachedRequests(requestUrl: string, response: Response) {
        if(!this.isCacheSupported() || !this.isCacheable(requestUrl)) return;
        this.cachedRequests.push({
            expiryTime: parseInt(response.headers.get('Cache-Control').replace('max-age=', '')),
            requestUrl: requestUrl
        });
        this.deleteOverloadedCache();
    }

    private refreshCaches() {
        if(!this.isCacheSupported()) return;
        const LAST_CLEARED_ON: number = (this._localStore.retrieve('CACHE_CLEARED_ON') || 0);
        this._localStore.store('CACHE_CLEARED_ON', ResourceCacheService.CACHE_CLEAR_ON);
        const CLEAR: boolean = ResourceCacheService.CACHE_CLEAR_ON && LAST_CLEARED_ON < ResourceCacheService.CACHE_CLEAR_ON;
        this._cacheStore.keys()
            .then(caches => caches.filter(cache => CLEAR || cache !== this.cache))
            .then(caches => caches.forEach(cache => this._cacheStore.delete(cache)))
            .catch(() => ResourceCacheService.NO_CACHE_SUPPORT = true);
    }

    private refreshCachedRequests() {
        if(!this.isCacheSupported()) return;
        const currentTime: number = Math.floor(new Date().getTime() / 1000);
        this._cacheStore.open(this.cache)
            .then((cache: Cache) => cache.keys().then(requests => requests.map(request => request.url)))
            .then(requestUrls => {
                return Promise.all(requestUrls
                    .map(requestUrl => this._cacheStore.match(requestUrl)
                        .then(response => {
                            return {
                                expiryTime: parseInt(response.headers.get('Cache-Control').replace('max-age=', '')), 
                                requestUrl: requestUrl,
                                response: response
                            };
                    })));
            })
            .then((cachedRequests: Array<CachedRequests>) => {
                cachedRequests = cachedRequests.filter((cachedRequest: CachedRequests) => {
                    if(!this.isCacheable(cachedRequest.requestUrl)) return false;
                    if(cachedRequest.expiryTime < currentTime) {
                        this.delete(cachedRequest.requestUrl);
                        return false;
                    }
                    return true;
                });
                cachedRequests.sort((cache1: CachedRequests, cache2: CachedRequests) => cache1.expiryTime - cache2.expiryTime);
                this.cachedRequests = cachedRequests;
                this.deleteOverloadedCache();
            }).catch(() => ResourceCacheService.NO_CACHE_SUPPORT = true);
    }

    private deleteOverloadedCache() {
        setTimeout(() => {
            if((this.cachedRequests || []).length > ResourceCacheService.CACHE_LIMIT) {
                const elem = this.cachedRequests.shift();
                this._cacheStore.open(this.cache)
                    .then((cache: Cache) => cache.delete(elem ? elem.requestUrl : ''))
                if((this.cachedRequests || []).length > ResourceCacheService.CACHE_LIMIT) this.deleteOverloadedCache();
            }
        });
    }

    public getCacheMaxAge(): number {
        return Math.floor((new Date().getTime() + ResourceCacheService.CACHE_ACTIVE_TIME) / 1000);
    }

    public delete(requestUrl: string): Promise<boolean> {
        if(!this.isCacheSupported()) return of(false).toPromise();
        return this._cacheStore.open(this.cache).then((cache: Cache) => cache.delete(requestUrl));
    }

    public toResponse(httpResponse: any): Response {
        if(!this.isCacheSupported() || !httpResponse) return null;
        const contentType = httpResponse.headers.get('Content-Type').toLowerCase();
        const headers = {
            headers: {'Cache-Control': `max-age=${this.getCacheMaxAge()}`}
        };
        if(contentType === 'application/json') return new Response(httpResponse.json(), headers);
        else if(contentType.startsWith('image/')) return new Response(httpResponse.blob(), headers);
        else if(contentType.startsWith('text/')) return new Response(httpResponse.text(), headers);
        else return null;
    }

    public toHttpResponse(response: Response): Promise<any> {
        if(!this.isCacheSupported() || !response) return of(null).toPromise();
        const contentType = response.headers.get('Content-Type').toLowerCase();
        const headers: HttpHeaders = new HttpHeaders();
        headers.append('Content-Type', contentType);
        let promiseFn: (content: Blob | string | any) => any = content => new HttpResponse<any>({
            body: content,
            headers: headers,
            status: response.status
        });
        if(contentType === 'application/json') return response.json().then(promiseFn);
        else if(contentType.startsWith('image/')) return response.blob().then(promiseFn);
        else if(contentType.startsWith('text/')) return response.text().then(promiseFn);
        else return null;
    }

    public isCacheable(requestUrl: string): boolean {
        let isCacheableFlag = !ResourceCacheService.NO_CACHE_SUPPORT && !ResourceCacheService.NO_CACHE;
        isCacheableFlag = isCacheableFlag && (ResourceCacheService.CACHE_IGNORE_PATTERNS.length === 0 || 
            ResourceCacheService.CACHE_IGNORE_PATTERNS
                .filter(ignorePattern => ignorePattern.test(requestUrl)).length === 0);
        isCacheableFlag = isCacheableFlag && (ResourceCacheService.CACHE_ACCEPT_PATTERNS.length === 0 || 
            ResourceCacheService.CACHE_ACCEPT_PATTERNS
                .filter(acceptPattern => acceptPattern.test(requestUrl)).length >= 1);
        return isCacheableFlag;
    }

    public isCacheSupported(): boolean {
        return false && !ResourceCacheService.NO_CACHE_SUPPORT;
    }

}

interface CachedRequests {
    expiryTime: number;
    requestUrl: string;
}
