import { Component, Injector } from '@angular/core';
import { AppSettings } from '../../app.settings';
import { AppState } from '../../app.service';
import { Select } from '@ngxs/store';
import { ApplicationState } from 'src/app/state/app.state';
import { Observable } from 'rxjs';
import { DI } from 'src/app/state/core';

@Component({
    selector: 'footer',
    templateUrl: 'footer.html',
    styleUrls: [`./footer.component.css`]
})
export class FooterComponent extends DI.AutoDependencyInjector {
    public storeUrl: string = `${AppSettings.PUBLICATIONS_STORE}`;

    @Select(ApplicationState.popupMode)
    popupMode$: Observable<boolean>;

    @DI.Inject(() => AppState)
    private appService: AppState;

    constructor(_injector: Injector) {
        super(_injector);
    }

    public getAssetsLink(path: string): string {
        return this.appService.getAssetsLink(path, true);
    }
}
