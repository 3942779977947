import { Injectable } from '@angular/core';

import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild
} from '@angular/router';
import { PowerGenSharedInfoService } from './power-gen/powerGenSharedInfo.service';
import { NotificationService } from './shared/header/notification.service';

@Injectable()
export class GsnAuthGuard implements CanActivateChild {
  constructor(private router: Router, private powergenSharedService: PowerGenSharedInfoService, private notificationService: NotificationService) { }


  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const source = route.params['source'];
    if (source === 'parts-search-global' || source === 'part') {
      this.setPartsCatalogView(route.params.partNo, source);
    }

    if (state.url.startsWith('gsn-entry/main/system-detail//option-details/part-details')) {
      this.setPartsCatalogView(route.params.partNo, 'gsn');
    }


    if (state.url.startsWith('gsn-entry/main')) {
      this.setPartsCatalogView(route.params.partNo, 'gsn');
    }
    if (this.powergenSharedService.gsnEntry || (state.url == '/gsn-entry/main/gsn-error') || (state.url.startsWith('/gsn-entry/main/part-details') || (state.url == '/gsn-entry/main/how-to-find-gsn'))) {
      return true;
    } else {
      this.router.navigate(['/gsn-entry/main']);
      return false;
    }
  }

  private setPartsCatalogView(data: any, viewString: string) {
    setTimeout(() => {

      this.notificationService.setGlobalvalue(data);
      this.notificationService.setType = viewString;
      this.notificationService.notificationPGBUUpdated(data);
    }, 0);
  }

}
