import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject, BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { SetCurrentOption } from '../optionDetail/state/option-detail.actions';
import { OptionDetailComponentState } from '../optionDetail/state/option-detail.state';
import { EngineBOM } from './state/engine-bom.actions';
import { EngineBOMState } from './state/engine-bom.state';

@Injectable()
export class SharedInfoService {
    get currentEsnInfo(): any {
        return this.store.selectSnapshot(EngineBOMState.getESNInfo);
    }
    get currentEsnInfo$(): Observable<any> {
        return this.store.select(EngineBOMState.getESNInfo);
    }
    set currentEsnInfo(currentEsnInfo: any) {
        this.store.dispatch(new EngineBOM.SetESNInfo(currentEsnInfo))
    }

    public _currentDataplate: any;

    get currentDataplate(): any {
        return this._currentDataplate;
    }
    set currentDataplate(currentDataplate: any) {
        this._currentDataplate = currentDataplate;
    }

    public get currentOptionList(): any {
        return this.store.selectSnapshot(EngineBOMState.getCurrentOptionList);
    }

    set currentEsn(value: any) {
        this.store.dispatch(new EngineBOM.SetCurrentESN(value));
    }
    get currentEsn() {
        return this.store.selectSnapshot(EngineBOMState.getCurrentESN);
    }

    get currentOption(): any {
        return this.store.selectSnapshot(OptionDetailComponentState.getCurrentOption);
    }
    set currentOption(currentOption: any) {
        this.store.dispatch(new SetCurrentOption(currentOption));
    }

    public inputEsn: string;
    public atsnEntry: string;
    public gsnEntry: string;
    public currentSystem: any = [];
    public currentPart: any;
    public savedESNs;
    public upfitOptionsMap: any;
    public globalSearchPath: boolean = false;
    public relatedPartsData: any[];
    public ebuInnerSearch: string;
    public isMenuCollapsed: BehaviorSubject<boolean>;
    public previousESN: any;
    public isDifferentESN = false;
    public esnType: string;
    public esnObject: any;

    getValue(): Observable<boolean> {
        return this.isMenuCollapsed.asObservable();
    }

    setValue(newValue): void {
        this.isMenuCollapsed.next(newValue);
    }

    constructor(private store: Store) {
        this.subjectMap = {} as { key: string, value: Subject<any> };
        this.isMenuCollapsed = new BehaviorSubject<boolean>(true);
    }

    private subjectMap: { key: string, value: Subject<any> };

    public publish(key: string, data?: any) {
        this.getSubject(key).next(data);
    }

    public subscribe(key: string, subscriber?: any): Observable<any> {
        const observer = this.getSubject(key).asObservable();
        return subscriber ? observer.pipe(map(subscriber)) : observer;
    }

    private getSubject(key: string): Subject<any> {
        this.subjectMap[key] = this.subjectMap[key] || new Subject<any>();
        return this.subjectMap[key];
    }

    notificationSystemView$ = this.store.select(OptionDetailComponentState.getCurrentOption)
        .pipe(debounceTime(100));

    private notificationEngineGraphicView = new Subject<string>();
    notificationEngineGraphicView$ = this.notificationEngineGraphicView.asObservable();

    private notificationDataplate = new Subject<string>();
    notificationDataplate$ = this.notificationDataplate.asObservable();

    private notificationOptionView = new Subject<string>();
    notificationOptionView$ = this.notificationOptionView.asObservable();

    private notificationSystemDetailView = new Subject<any>();
    notificationSystemDetailView$ = this.notificationSystemDetailView.asObservable();

    private notificationArchiveOptionView = new Subject<any>();
    notificationArchiveOptionView$ = this.notificationArchiveOptionView.asObservable();

    private notificationGasketSetsView = new Subject<any>();
    notificationGasketSetsView$ = this.notificationGasketSetsView.asObservable();

    private notificationKitSetsView = new Subject<any>();
    notificationKitSetsView$ = this.notificationKitSetsView.asObservable();

    public optionViewUpdated(): void {
        this.notificationOptionView.next("new");
    }

    public dataplateUpdated(): void {
        this.notificationDataplate.next("new");
    }

    public systemDetailViewUpdated(): void {
        this.notificationSystemDetailView.next("new");
    }

    public engineGraphicUpdated(): void {
        this.notificationEngineGraphicView.next("new");
    }

    public archiveOptionViewUpdated(): void {
        this.notificationArchiveOptionView.next("new");
    }

    public gasketSetsViewUpdated(): void {
        this.notificationGasketSetsView.next("new");
    }

    public kitSetsViewUpdated(): void {
        this.notificationKitSetsView.next("new");
    }
}
