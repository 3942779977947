import { Injectable } from "@angular/core";
import { SessionStorageService } from "ngx-webstorage";
import { ToasterService,Toast } from "angular2-toaster";
import { throwError as observableThrowError } from 'rxjs';
import { AppSettings } from "../../app.settings";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class CMSParentComponent {

  public imageData = new Map<any, any>();
  protected folderRegExp = new RegExp('^[a-zA-Z ]*$');
  protected fileRegExp = new RegExp('^[A-Za-z0-9_. ]*$');
  
  protected regxFileExt = new RegExp('^([a-zA-Z0-9\s_-])+(.doc|.docx|.pdf|.xls|.xlsx|.txt|.ppt|.pptx|.pps|.ppsx|.xlsm|.ogg|.mp4|.mp3|.flv|.swf|.ogv|.webm)*$');
  protected regxGraphicsExt = new RegExp('^([a-zA-Z0-9\s_-])+(.jpg|.png|.jpeg|.eot|.woff|.gif|.ttf|.woff2|.eot|.otf)*$');

  constructor(protected translation: TranslateService,
    protected sessionStorageService?: SessionStorageService,
    protected toasterService?: ToasterService,
    ) {
      this.clearSession = this.clearSession.bind(this);
  }

  public clearImageMap() {
    this.imageData.clear();
  }

  public getImageMap(): Map<any, any> {
    return this.imageData;
  }

  public getSessionStoreValue(param): string {
    if (param) {
      return this.sessionStorageService.retrieve(param);
    }
  }

  public setSessionStoreParamter(param, value): void {
    if (param) {
      this.sessionStorageService.store(param, value);
    }
  }

  protected getFilePath(path){
    if (path) {
      return path.substr(0,(path.lastIndexOf('/')));
    }
  }
  public clearSession() {
    this.setSessionStoreParamter('action', null);
    this.setSessionStoreParamter('path', null);
    this.setSessionStoreParamter('editorValue', null);
  }

  public getFilename(filePath): string {
    if (filePath) {
      let path:string = filePath.toString();
      return path.substr((path.lastIndexOf("/")+1), path.length);
    }
    return '';
  }

  public getBlobPath(filePath): any {

    if (filePath) {
      let path = filePath.toString();
      return path.substr(0, (path.indexOf("/")));
    }
  }

  public readBase64(file, process): Promise<any> {
    var reader = new FileReader();
    var future = new Promise((resolve, reject) => {
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.addEventListener("error", function (event) {
        reject(event);
      }, false);

      if (process == 'upload') {
        reader.readAsDataURL(file);
      } else {
        reader.readAsText(file);
      }

    });
    return future;
  }

  /**
   * 
   * @param error Exception handling
   */
  catchIACError(error):any{

    var toast: Toast = {
      type: 'error',
      title: error.error ? (error.error.message ? error.error.message:'Bad request')  : 'Bad request',
      showCloseButton: true,
      timeout: 5000

    };
    this.toasterService.pop(toast);
    throw error;
  }

  public isFolder(data){
    return  (data.type === 'D' || data.type === 'L');
  }

  protected getType(name){
    
    if(name){
      return name.substr(name.indexOf('.'),name.length);
    }
  }

   /**
  * Get DOM element
  */
  protected getDOM(): any {
    let doctype = document.implementation.createDocumentType('html', '-//W3C//DTD XHTML 1.0 Strict//EN',
      'http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd');
    return document.implementation.createDocument('http://www.w3.org/1999/xhtml', 'html', doctype);
  }
  
  protected showMsg(msg,msgType,timer?:number){
    var toast: Toast = {
      type: msgType,
      title: msg,
      showCloseButton: true,
      timeout: 5000
    };
    this.toasterService.pop(toast);
  }

  protected validateName(name,type){

    if(type == 'F'){
      return this.fileRegExp.test(name)
    }else{
      return this.folderRegExp.test(name)
    }
  }
  
  protected getBasePath(){
    return `${AppSettings.CMS_BLOB_NAME}` + this.getLang();
  }
  
  protected getProtectedBasePath(){
    return `${AppSettings.CMS_PROTECTED_BLOB_NAME}`;
  }

  protected getProtectedFilePath(){
    return this.getProtectedBasePath() + '/' + `${AppSettings.CMS_FILES_ROOT}` + '/';
  }

  protected getBaseImagePath(){
    return `${AppSettings.CMS_IMAGE_ROOT}`;
  }
  protected getFPath(node){
      return (node.parentPath !='/') ? node.parentPath + "/"+ node.name 
              : node.parentPath + node.name;
  }

  protected getGPath(node){

    let filename = node.parentPath + "/"+ node.name;
    
    if(filename.indexOf('graphics')>0){
      filename = filename.replace('/graphics/','');
    }
    return (node.parentPath !='/') ? this.getBaseImagePath() + filename 
            : node.parentPath + node.name;
  }
  
  protected getLang(){
    return this.translation.currentLang ? this.translation.currentLang: this.translation.defaultLang;
  }

  protected isEmpty(name){
    if(!name){
       this.showMsg('Invalid name','error');
    }
    return name.trim().length == 0 ? true:false;
  }
  
  protected closeAllEditable(data){
    for(let item of data){
      if(item.isEditable){
        item.isEditable = false;
      }
    }
  }
  
  protected isFile(name){
    return this.regxFileExt.test(name);
  }

  protected isGraphics(name){
    return this.regxGraphicsExt.test(name);
  }
}