import { NgModule, ModuleWithProviders } from '@angular/core';
import { NGXS_PLUGINS } from '@ngxs/store';
import { NavigationRouteHandlerPlugin } from './navigation.plugin';
import { PesistStateOnPageRefreshPlugin } from './page-refresh.plugin';

@NgModule()
export class ApplicationStatePluginModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: ApplicationStatePluginModule,
            providers: [
                {
                    provide: NGXS_PLUGINS,
                    useClass: PesistStateOnPageRefreshPlugin,
                    multi: true
                },
                {
                    provide: NGXS_PLUGINS,
                    useClass: NavigationRouteHandlerPlugin,
                    multi: true
                }
            ]
        };
    }
}
