import { Directive, Input, ElementRef, OnInit, OnChanges, SimpleChanges, Renderer2 } from "@angular/core";
import { SpriteMap } from "./sprite.map";
import { AjaxBackGroundLoader } from "./background-xhr-image.component";

@Directive({
    selector: '[sprite]'
})
export class SpriteDirective implements OnInit, OnChanges {

    @Input() public url: string;
    @Input("sprite") public src: string | number;
    @Input() public style: any;
    @Input() public map: SpriteMap;

    @Input('background') public backgroundXHREnabled: AjaxBackGroundLoader;

    constructor(private element: ElementRef, private renderer: Renderer2) {}

    ngOnInit() {
        if(!this.backgroundXHREnabled){
            if(!this.url) throw `URL can't be null.`;
        }
        if(!this.map || Object.keys(this.map).length === 0) throw `CSS-Sprite Mapping can't be null or empty.`;
        if(!this.src && Number.isNaN(parseInt(this.src as string))) {
            const urlParts = this.url.split("/|\\");
            this.src = urlParts[urlParts.length - 1];
        }
        this.style = this.style || {};
    }

    ngOnChanges(changes: SimpleChanges) {
        Object.keys(changes || {}).length > 0 && this.setStyle();
    }

    private setStyle(): void {
        const index:number = Number.isNaN(parseInt(this.src as string)) ? this.map.cssMap[(this.src as string).toLowerCase()] : this.src;
        const posX: number = index % this.map.numberOfHorizontalImages;
        const posY: number = Math.floor(index / this.map.numberOfHorizontalImages);
        let style = {} as any;
        if(!this.backgroundXHREnabled) style.background = `url('${this.url}') ${-posX * 100}% ${-posY * 100}%`;
        else style.backgroundPosition = `${-posX * 100}% ${-posY * 100}%`;
        style.backgroundSize = `${this.map.numberOfHorizontalImages * 100}% ${this.map.numberOfVerticalImages * 100}%`;
        style = Object.assign({}, this.style, style);
        for(let key in style) {
            if(this.backgroundXHREnabled) this.renderer.setStyle(this.element.nativeElement.children[0], key, style[key]);
            else this.renderer.setStyle(this.element.nativeElement, key, style[key]);
        }
    }

}