import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
@Injectable()
export class PowerGenSharedInfoService {

    public gsnEntry: string;
    private _gsnProductData: any = [];
    set gsnProductData(value) {
        this._gsnProductData = value;
        this.topLevelBOMObjs = null;
    }
    get gsnProductData() {
        return this._gsnProductData;
    }
    public esnProductData: any[] = [];
    public partsManuals: any = [];
    public hasPartsManual: boolean;
    public hasInjectorData: boolean;
    public esnEntry: string;
    public productInfo: any = [];
    public showAsndata: boolean = false;
    public serials: any[];
    public esns: any[] = [];
    public esndataplates: any[] = [];
    public asns: any[] = [];
    public asnDataplates: any = [];
    public asnsUpdated: boolean[] = [];
    public externalSN: any = {};
    public sbom: any = [];
    public systemOptions: any = [];
    public currentEsn: any;
    public systemView: any = [];
    public systemName: any[] = [];
    public optionName: any[];
    private _partOptionsBOM: any;
    set partOptionsBOM(value: any) {
        this._partOptionsBOM = value;
    }
    private _option: any;
    set option(value: any) {
        this._option = value;
        if (this._option) {
            this._partOptionsBOM = null;
        }
    }
    get option(): any {
        return this._partOptionsBOM || this._option;
    }
    public major: string;
    public graphic: string;
    public currentOptionList: any;
    public currentOption: any;
    public singleProduct: boolean;
    public serialType: boolean = false;
    public seperate: boolean = false;
    public globalSearchPath: boolean = false;
    public currentPart: any;
    public esnInjectorTrims: any = [];
    public topLevelBOMObjs?: any;
    public servicePartListObjs?: any;

    private subjectMap: { key: string, value: Subject<any> };
    constructor() {
        this.subjectMap = {} as { key: string, value: Subject<any> };
    }


    private notificationPartsContent = new Subject<string>();
    notificationPartsContent$ = this.notificationPartsContent.asObservable();

    private notificationDataplate = new Subject<string>();
    notificationDataplate$ = this.notificationDataplate.asObservable();

    private notificationSystemView = new Subject<string>();
    notificationSystemView$ = this.notificationSystemView.asObservable();

    private notificationEngineGraphicView = new Subject<string>();
    notificationEngineGraphicView$ = this.notificationEngineGraphicView.asObservable();

    private notificationOptionView = new Subject<string>();
    notificationOptionView$ = this.notificationOptionView.asObservable();

    private notificationSystemDetailView = new Subject<any>();
    notificationSystemDetailView$ = this.notificationSystemDetailView.asObservable();


    private notificationPartsSearch = new Subject<string>();
    notificationPartsSearch$ = this.notificationPartsSearch.asObservable();

    public publish(key: string, data?: any) {
        this.getSubject(key).next(data);
    }
    public subscribe(key: string, subscriber?: any): Observable<any> {
        const observer = this.getSubject(key).asObservable();
        return subscriber ? observer.map(subscriber) : observer;
    }

    private getSubject(key: string): Subject<any> {
        this.subjectMap[key] = this.subjectMap[key] || new Subject<any>();
        return this.subjectMap[key];
    }

    public partsContentViewUpdated(): void {
        this.notificationPartsContent.next("new");
    }


    public dataplateUpdated(): void {
        this.notificationDataplate.next("new");
    }
    public systemViewUpdated(): void {
        this.notificationSystemView.next("new");
    }

    public optionViewUpdated(): void {
        this.notificationOptionView.next("new");
    }

    public systemDetailViewUpdated(): void {
        this.notificationSystemDetailView.next("new");
    }


    public engineGraphicUpdated(): void {
        this.notificationEngineGraphicView.next("new");
    }


    public partsSearchUpdated(): void {
        this.notificationPartsSearch.next("new");
    }

}
