import { Router } from "@angular/router";
import { SessionStorageService } from "ngx-webstorage";
import { AppState } from "../app.service";
import { BlobStorageService } from "../cms-editor/services/api-services/cms-blob.service";
import { SharingServices } from "../cms-editor/services/sharing-service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../app.service";
import * as i3 from "../cms-editor/services/api-services/cms-blob.service";
import * as i4 from "../cms-editor/services/sharing-service";
import * as i5 from "ngx-webstorage";
var SamlService = /** @class */ (function () {
    function SamlService(router, appState, blobservice, sharing, _sessionStore) {
        this.router = router;
        this.appState = appState;
        this.blobservice = blobservice;
        this.sharing = sharing;
        this._sessionStore = _sessionStore;
        this.updateSession = this.updateSession.bind(this);
    }
    SamlService.prototype.handleSessionInitiation = function (sessionInitCaller) {
        var _this = this;
        var target = this._sessionStore.retrieve('target') || { url: '/home', params: {} };
        return sessionInitCaller().toPromise()
            .then(function (res) { return ({ target: target, response: res }); })
            .then(this.updateSession).then(function (_res) {
            _this.blobservice.menu('/iac').subscribe(function (res) {
                if (res) {
                    _this.sharing.publishToken.next(res);
                }
            }, function (error) { return console.error('Error Calling Menu', error); });
            return _res;
        }).catch(function (error) { return Promise.reject(error); });
    };
    SamlService.prototype.updateSession = function (data) {
        if (!data.response.hasOwnProperty('json')) {
            data.response = this.appState.parseResponse(data.response);
        }
        this.appState.publishSessionChange(data.response);
        return this.router.navigate([data.target.url], { queryParams: data.target.params })
            .then(function (didNavigate) {
            if (!didNavigate)
                throw 'Error Initiating Session. Please retry to login';
            return didNavigate;
        });
    };
    SamlService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SamlService_Factory() { return new SamlService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AppState), i0.ɵɵinject(i3.BlobStorageService), i0.ɵɵinject(i4.SharingServices), i0.ɵɵinject(i5.SessionStorageService)); }, token: SamlService, providedIn: "root" });
    return SamlService;
}());
export { SamlService };
