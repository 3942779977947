var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Injector } from '@angular/core';
import { AppSettings } from '../../app.settings';
import { AppState } from '../../app.service';
import { Select } from '@ngxs/store';
import { ApplicationState } from 'src/app/state/app.state';
import { Observable } from 'rxjs';
import { DI } from 'src/app/state/core';
var ɵ0 = function () { return AppState; };
var FooterComponent = /** @class */ (function (_super) {
    __extends(FooterComponent, _super);
    function FooterComponent(_injector) {
        var _this = _super.call(this, _injector) || this;
        _this.storeUrl = "" + AppSettings.PUBLICATIONS_STORE;
        return _this;
    }
    FooterComponent.prototype.getAssetsLink = function (path) {
        return this.appService.getAssetsLink(path, true);
    };
    __decorate([
        Select(ApplicationState.popupMode),
        __metadata("design:type", Observable)
    ], FooterComponent.prototype, "popupMode$", void 0);
    __decorate([
        DI.Inject(ɵ0),
        __metadata("design:type", AppState)
    ], FooterComponent.prototype, "appService", void 0);
    return FooterComponent;
}(DI.AutoDependencyInjector));
export { FooterComponent };
export { ɵ0 };
