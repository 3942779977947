/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i2 from "@angular/material/progress-bar";
import * as i3 from "@angular/platform-browser/animations";
import * as i4 from "@angular/forms";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@angular/common";
import * as i7 from "../../../node_modules/primeng/components/dialog/dialog.ngfactory";
import * as i8 from "primeng/components/dom/domhandler";
import * as i9 from "primeng/components/dialog/dialog";
import * as i10 from "ngx-responsive";
import * as i11 from "./notices.component";
var styles_NoticesComponent = [];
var RenderType_NoticesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NoticesComponent, data: {} });
export { RenderType_NoticesComponent as RenderType_NoticesComponent };
function View_NoticesComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_NoticesComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["color", "primary"], ["mode", "indeterminate"], ["role", "progressbar"], ["style", "margin-top: 10px;"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i1.View_MatProgressBar_0, i1.RenderType_MatProgressBar)), i0.ɵdid(1, 4374528, null, 0, i2.MatProgressBar, [i0.ElementRef, i0.NgZone, [2, i3.ANIMATION_MODULE_TYPE], [2, i2.MAT_PROGRESS_BAR_LOCATION]], { color: [0, "color"], mode: [1, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "primary"; var currVal_4 = "indeterminate"; _ck(_v, 1, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = (((i0.ɵnov(_v, 1).mode === "indeterminate") || (i0.ɵnov(_v, 1).mode === "query")) ? null : i0.ɵnov(_v, 1).value); var currVal_1 = i0.ɵnov(_v, 1).mode; var currVal_2 = i0.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_NoticesComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["align", "center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 9, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 4).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 16384, null, 0, i4.ɵangular_packages_forms_forms_z, [], null, null), i0.ɵdid(4, 4210688, null, 0, i4.NgForm, [[8, null], [8, null]], null, null), i0.ɵprd(2048, null, i4.ControlContainer, null, [i4.NgForm]), i0.ɵdid(6, 16384, null, 0, i4.NgControlStatusGroup, [[4, i4.ControlContainer]], null, null), (_l()(), i0.ɵeld(7, 0, null, null, 2, "button", [["class", "btn btn-sm btn-primary btn-login"], ["id", "acceptDialog"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.click.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NoticesComponent_4)), i0.ɵdid(11, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.showProgress; _ck(_v, 11, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 6).ngClassValid; var currVal_5 = i0.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 6).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.showProgress; _ck(_v, 7, 0, currVal_7); var currVal_8 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform("Continue")); _ck(_v, 8, 0, currVal_8); }); }
function View_NoticesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "p-dialog", [["blockScroll", "true"]], null, null, null, i7.View_Dialog_0, i7.RenderType_Dialog)), i0.ɵprd(512, null, i8.DomHandler, i8.DomHandler, []), i0.ɵdid(2, 12763136, null, 2, i9.Dialog, [i0.ElementRef, i8.DomHandler, i0.Renderer2, i0.NgZone], { resizable: [0, "resizable"], width: [1, "width"], modal: [2, "modal"], closeOnEscape: [3, "closeOnEscape"], closable: [4, "closable"], blockScroll: [5, "blockScroll"], visible: [6, "visible"] }, null), i0.ɵqud(603979776, 1, { headerFacet: 1 }), i0.ɵqud(603979776, 2, { footerFacet: 1 }), (_l()(), i0.ɵeld(5, 0, null, 1, 2, "div", [["align", "justify"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NoticesComponent_2)), i0.ɵdid(7, 540672, null, 0, i6.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵand(16777216, null, 1, 1, null, View_NoticesComponent_3)), i0.ɵdid(9, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(10, 0, null, 1, 0, "br", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = _co.width; var currVal_2 = true; var currVal_3 = false; var currVal_4 = false; var currVal_5 = "true"; var currVal_6 = _co.visible; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.content; _ck(_v, 7, 0, currVal_7); var currVal_8 = _co.showActions; _ck(_v, 9, 0, currVal_8); }, null); }
function View_NoticesComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_NoticesComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["color", "primary"], ["mode", "indeterminate"], ["role", "progressbar"], ["style", "margin-top: 10px;"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i1.View_MatProgressBar_0, i1.RenderType_MatProgressBar)), i0.ɵdid(1, 4374528, null, 0, i2.MatProgressBar, [i0.ElementRef, i0.NgZone, [2, i3.ANIMATION_MODULE_TYPE], [2, i2.MAT_PROGRESS_BAR_LOCATION]], { color: [0, "color"], mode: [1, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "primary"; var currVal_4 = "indeterminate"; _ck(_v, 1, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = (((i0.ɵnov(_v, 1).mode === "indeterminate") || (i0.ɵnov(_v, 1).mode === "query")) ? null : i0.ɵnov(_v, 1).value); var currVal_1 = i0.ɵnov(_v, 1).mode; var currVal_2 = i0.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_NoticesComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["align", "center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 9, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 4).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 16384, null, 0, i4.ɵangular_packages_forms_forms_z, [], null, null), i0.ɵdid(4, 4210688, null, 0, i4.NgForm, [[8, null], [8, null]], null, null), i0.ɵprd(2048, null, i4.ControlContainer, null, [i4.NgForm]), i0.ɵdid(6, 16384, null, 0, i4.NgControlStatusGroup, [[4, i4.ControlContainer]], null, null), (_l()(), i0.ɵeld(7, 0, null, null, 2, "button", [["class", "btn btn-sm btn-primary btn-login"], ["id", "acceptDialog"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.click.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NoticesComponent_8)), i0.ɵdid(11, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.showProgress; _ck(_v, 11, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 6).ngClassValid; var currVal_5 = i0.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 6).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.showProgress; _ck(_v, 7, 0, currVal_7); var currVal_8 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform("Continue")); _ck(_v, 8, 0, currVal_8); }); }
function View_NoticesComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "p-dialog", [["blockScroll", "true"]], null, null, null, i7.View_Dialog_0, i7.RenderType_Dialog)), i0.ɵprd(512, null, i8.DomHandler, i8.DomHandler, []), i0.ɵdid(2, 12763136, null, 2, i9.Dialog, [i0.ElementRef, i8.DomHandler, i0.Renderer2, i0.NgZone], { resizable: [0, "resizable"], modal: [1, "modal"], closeOnEscape: [2, "closeOnEscape"], closable: [3, "closable"], blockScroll: [4, "blockScroll"], visible: [5, "visible"] }, null), i0.ɵqud(603979776, 3, { headerFacet: 1 }), i0.ɵqud(603979776, 4, { footerFacet: 1 }), (_l()(), i0.ɵeld(5, 0, null, 1, 2, "div", [["align", "justify"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NoticesComponent_6)), i0.ɵdid(7, 540672, null, 0, i6.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵand(16777216, null, 1, 1, null, View_NoticesComponent_7)), i0.ɵdid(9, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(10, 0, null, 1, 0, "br", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = true; var currVal_2 = false; var currVal_3 = false; var currVal_4 = "true"; var currVal_5 = _co.visible; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.content; _ck(_v, 7, 0, currVal_6); var currVal_7 = _co.showActions; _ck(_v, 9, 0, currVal_7); }, null); }
export function View_NoticesComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 3, null, View_NoticesComponent_1)), i0.ɵdid(1, 147456, null, 0, i10.ResponsiveDirective, [i0.TemplateRef, i10.ResponsiveState, i0.ViewContainerRef, i0.ChangeDetectorRef, i0.PLATFORM_ID], { responsive: [0, "responsive"] }, null), i0.ɵpad(2, 3), i0.ɵpod(3, { bootstrap: 0 }), (_l()(), i0.ɵand(16777216, null, null, 3, null, View_NoticesComponent_5)), i0.ɵdid(5, 147456, null, 0, i10.ResponsiveDirective, [i0.TemplateRef, i10.ResponsiveState, i0.ViewContainerRef, i0.ChangeDetectorRef, i0.PLATFORM_ID], { responsive: [0, "responsive"] }, null), i0.ɵpad(6, 2), i0.ɵpod(7, { bootstrap: 0 })], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, _ck(_v, 2, 0, "lg", "md", "xl")); _ck(_v, 1, 0, currVal_0); var currVal_1 = _ck(_v, 7, 0, _ck(_v, 6, 0, "sm", "xs")); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_NoticesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "notice-dialog", [], null, null, null, View_NoticesComponent_0, RenderType_NoticesComponent)), i0.ɵdid(1, 49152, null, 1, i11.NoticesComponent, [], null, null), i0.ɵqud(603979776, 1, { content: 0 })], null, null); }
var NoticesComponentNgFactory = i0.ɵccf("notice-dialog", i11.NoticesComponent, View_NoticesComponent_Host_0, { content: "content", showProgress: "showProgress", visible: "visible", width: "width", showActions: "showActions" }, { click: "onClick" }, []);
export { NoticesComponentNgFactory as NoticesComponentNgFactory };
