import { Component, ViewEncapsulation, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { CookieService } from './cookie.service';
import { LocalStorageService } from 'ngx-webstorage';

@Component( {
    selector: 'cookie-popup',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './cookie.popup.component.html',
    styleUrls: ['./cookie.popup.component.css']
} )
export class CookiePopupComponent implements AfterViewInit {
    @ViewChild("slider",{static:false}) private self: ElementRef;

    public access: boolean = true;
    private hook: number = 0.0;

    constructor(private cookieService: CookieService, 
        private localStorageService: LocalStorageService) {
        if(this.localStorageService.retrieve("ALLOW_ACCESS"))
        this.access = this.localStorageService.retrieve("ALLOW_ACCESS").includes("iac");
        else
        this.access = false;
        //this.access = this.cookieService.getCookie("ALLOW_ACCESS").includes("iac");
        this.adjustView = this.adjustView.bind(this);
        if(document.body.style.paddingBottom) document.body.style.paddingBottom = 0 + "px";
    }

    public ngAfterViewInit() {
        if(!this.access) {
            this.adjustView();
            window.addEventListener("resize", this.adjustView);
        }
    }

    public adjustView() {
        if(this.self){
            document.body.style.paddingBottom = this.self.nativeElement.offsetHeight + "px";
        }
        
    }

    public allowAccess() {
        //let cookie = this.cookieService.getCookie("ALLOW_ACCESS");
        let cookie = this.localStorageService.retrieve("ALLOW_ACCESS");
        if(cookie && cookie !== "") cookie += ","+"iac";
        else
        cookie = "iac"
        //this.cookieService.setCookie("ALLOW_ACCESS", cookie + "iac");
        this.localStorageService.store("ALLOW_ACCESS", cookie);
        this.access = true;
        window.removeEventListener("resize", this.adjustView);
        document.body.style.paddingBottom = "inherit";
    }

}
