import { Observable } from "rxjs/Observable";
import { environment } from "../environments/environment";
import { hashes } from "./app-plugins/sri-hashes";
import * as i0 from "@angular/core";
var ResourceLoaderService = /** @class */ (function () {
    function ResourceLoaderService() {
        this.resources = new Map();
    }
    ResourceLoaderService.prototype.getResourceElement = function (resource) {
        var resourceElement = null;
        if (resource.type === 'script') {
            var script = resource;
            resourceElement = document.createElement('script');
            resourceElement.src = script.src;
            resourceElement.type = 'text/javascript';
            resourceElement.async = script.async || false;
            resourceElement.defer = script.defer || false;
            resourceElement.noModule = script.noModule || false;
        }
        else if (resource.type === 'link') {
            resourceElement = document.createElement('link');
            resourceElement.rel = 'stylesheet';
            resourceElement.href = resource.src;
        }
        else {
            throw new Error('Trying to load unknown resource.');
        }
        return resourceElement;
    };
    ResourceLoaderService.prototype.isResourceAttached = function (resource) {
        if (resource.type === 'script') {
            return !!document.querySelector("script[src=\"" + resource.src + "\"]");
        }
        else if (resource.type === 'link') {
            return !!document.querySelector("link[href=\"" + resource.src + "\"]");
        }
        return false;
    };
    ResourceLoaderService.prototype.attachResource = function (resourceElement) {
        if (resourceElement.tagName.toLowerCase() === 'script') {
            document.getElementsByTagName('body')[0].appendChild(resourceElement);
        }
        else if (resourceElement.tagName.toLowerCase() === 'link') {
            document.getElementsByTagName('head')[0].appendChild(resourceElement);
        }
    };
    ResourceLoaderService.prototype.getResourceLoader = function (resource, strictSRICheck) {
        var _this = this;
        var loaded = this.isResourceAttached(resource);
        var observers = [];
        function next(observer) {
            if (observer) {
                observer.next(loaded);
                return;
            }
            observers.forEach(next);
        }
        function complete(observer) {
            if (observer) {
                observer.complete();
                return;
            }
            observers.forEach(complete);
        }
        function error(value) {
            observers.forEach(function (observer) { return observer.error(value); });
        }
        return new Observable(function (observer) {
            observers.push(observer);
            if (loaded)
                complete(observer);
            else if (observers.length === 1) {
                var integrityHash = hashes[resource.src] || resource.integrity;
                strictSRICheck = environment.production && strictSRICheck;
                if (strictSRICheck && !integrityHash) {
                    throw _this.getError('SRI Missing', 'Can\'t load script dynamically without Subresource Integrity.');
                }
                var resourceElement = _this.getResourceElement(resource);
                if (strictSRICheck) {
                    resourceElement.integrity = integrityHash;
                    resourceElement.crossOrigin = integrityHash && 'anonymous';
                }
                resourceElement.onload = function () {
                    loaded = true;
                    next();
                    complete();
                };
                resourceElement.onerror = error;
                _this.attachResource(resourceElement);
                return {
                    unsubscribe: function () { return observers.splice(observers.indexOf(observer), 1); }
                };
            }
        });
    };
    ResourceLoaderService.prototype.loadResource = function (resource, strictSRICheck) {
        if (strictSRICheck === void 0) { strictSRICheck = true; }
        if (!resource.src) {
            throw this.getError('Source Missing', 'Can\'t load script dynamically without resource source.');
        }
        var resourceLoader = this.resources.get(resource.src);
        if (!this.resources.has(resource.src))
            resourceLoader = this.getResourceLoader(resource, strictSRICheck);
        return resourceLoader;
    };
    ResourceLoaderService.prototype.getError = function (name, message) {
        return { name: name, message: message };
    };
    ResourceLoaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ResourceLoaderService_Factory() { return new ResourceLoaderService(); }, token: ResourceLoaderService, providedIn: "root" });
    return ResourceLoaderService;
}());
export { ResourceLoaderService };
