
        <div [title]="title || ''" [ngStyle]="style" [class]="styleClass || ''" [class.alternate]="showAlt">
            <div class="bodyDiv" *ngIf="bodyTemplate && !showAlt">
                <ng-container *ngTemplateOutlet="bodyTemplate"></ng-container>
            </div>
            <div class="altDiv" *ngIf="showAlt">
                <ng-container *ngTemplateOutlet="altTemplate || defaultAltTemplate"></ng-container>
            </div>
            
            <ng-template #defaultAltTemplate>
                <span>{{alt || 'Image Not Available'}}</span>
            </ng-template>
        </div>
    