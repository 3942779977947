import { Injectable, Injector } from "@angular/core";
import { SessionStorageService } from "ngx-webstorage";
import { STORAGE_PREFIX, STORAGE_SEPARATOR } from "./app.module";
import { DI } from "./state/core";
import { NGXS_STORE_STATE_KEY } from "./state/page-refresh.plugin";

const EMPTY_WEAK_MAP = <any>{
    delete(_key) {
        return false;
    },
    get(_key) {
        return null;
    },
    set(_key, _value) {
        return null;
    }
};

@Injectable({ providedIn: 'root' })
export class StateStorageService extends DI.AutoDependencyInjector {
    private readonly storageKey: string;

    @DI.Inject(() => SessionStorageService)
    private sessionStore: SessionStorageService;

    constructor(_injector: Injector) {
        super(_injector);
        this.storageKey = `${STORAGE_PREFIX}${STORAGE_SEPARATOR}${NGXS_STORE_STATE_KEY}`.toLowerCase();

        if (!this.sessionStoreSupported) {
            const state = this.fetchState(true);
            if (state) {
                this.clearState(true);
                this.persistState(state);
            }
        }
    }

    public fetchState(initial: boolean = false): any {
        if (initial) {
            if (this.sessionStoreSupported) return null;

            const state = this.openerWindowStates.get(window);
            return state;
        }

        return this.sessionStore.retrieve(NGXS_STORE_STATE_KEY);
    }

    public clearState(initial: boolean = false): boolean {
        if (initial) {
            if (this.sessionStoreSupported) return false;
            return this.openerWindowStates.delete(window);
        }

        this.sessionStore.clear(NGXS_STORE_STATE_KEY);
        return !this.sessionStore.retrieve(NGXS_STORE_STATE_KEY);
    }

    public persistState(state: any, win?: Window) {
        if (!state) return;

        if (this.sessionStoreSupported || !win) {
            if (win) {
                win.sessionStorage.setItem(this.storageKey, JSON.stringify(state));
            } else {
                this.sessionStore.store(NGXS_STORE_STATE_KEY, state);
            }
        } else if (win) {
            this.windowStates.set(win, state);
        }
    }

    private get openerWin(): any {
        return window.opener || {};
    }

    private get windowStates(): WeakMap<Window, any> {
        try {
            return (<any>window).OPENED_WIN_STATES || EMPTY_WEAK_MAP;
        } catch {
            return EMPTY_WEAK_MAP;
        }
    }

    private get openerWindowStates(): WeakMap<Window, any> {
        try {
            return this.openerWin.OPENED_WIN_STATES || EMPTY_WEAK_MAP;
        } catch {
            return EMPTY_WEAK_MAP;
        }
    }

    private get sessionStoreSupported(): boolean {
        const notSupported = !!isIE();
        return !notSupported;
    }
}
