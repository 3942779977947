import { Pipe, PipeTransform } from "@angular/core";
import { AppSettings } from "../app.settings";
import { environment } from "../../environments/environment";

@Pipe({ name: 'env' })
export class EnvironmentPipe implements PipeTransform {
    transform(value: string, ..._args: any[]) {
        const envValue = `${AppSettings[value] || environment[value]}`;
        if (envValue) {
            return envValue;
        }
        throw new Error(`Missing '${value}' prop from environment variables.`);
    }

}
