import { PipeTransform } from "@angular/core";
import { AppSettings } from "../app.settings";
import { environment } from "../../environments/environment";
var EnvironmentPipe = /** @class */ (function () {
    function EnvironmentPipe() {
    }
    EnvironmentPipe.prototype.transform = function (value) {
        var _args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            _args[_i - 1] = arguments[_i];
        }
        var envValue = "" + (AppSettings[value] || environment[value]);
        if (envValue) {
            return envValue;
        }
        throw new Error("Missing '" + value + "' prop from environment variables.");
    };
    return EnvironmentPipe;
}());
export { EnvironmentPipe };
