import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppState } from '../app.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../shared/header/notification.service';
import { IACTranslationsService } from '../iacTranslations/iacTranslations.service';
import { SessionStorageService } from 'ngx-webstorage';
import { of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Integration } from './Integration';
import { NotificationService as GenericNotificationService } from '../shared/generic-notification/notification.service';
import { SamlService } from '../saml/saml.service';

@Component({
    selector: 'integration',
    templateUrl: 'integration.html'
})
export class IntegrationComponent implements OnInit, OnDestroy {
    public static readonly LOADING_MESSAGE = 'Loading Integration..!';

    public error: number = 0;
    public exception: string;
    private subscription: Subscription;

    constructor(private route: ActivatedRoute, private router: Router, public appState: AppState,
        private notificationService: NotificationService, private translate: TranslateService,
        private iacTranslationsService: IACTranslationsService, private _sessionStore: SessionStorageService,
        private genericNotificationService: GenericNotificationService, private samlService: SamlService) {
        this.navigateWithLang = this.navigateWithLang.bind(this);
        this.genericNotificationService.loadingEvent.next({ showLoading: true, loadingMessage: IntegrationComponent.LOADING_MESSAGE });
    }

    ngOnInit() {
        this.subscription = this.route.queryParams.subscribe(params => {
            const token = params.tokenID;
            const vendor = atob(params.vendor as string || '');
            const mappedRequest = {
                searchCriteria: (params.searchCriteria || params.serialNumber) as string,
                language: params.language as string || 'en',
                redirectTo: params.redirectTo as string || '',
                userType: atob(params.userType as string || ''),
                transactionId: atob(params.transactionId as string || ''),
                vendor: vendor || 'IAC',
                vendorCartId: atob(params.vendorCartId as string || ''),
                features: vendor === 'DLRCOM' ? ['DLR_COMM'] : []
            } as Integration;

            if (vendor && !mappedRequest.vendorCartId) {
                this.genericNotificationService.loadingEvent.next({
                    showLoading: true,
                    loadingMessage: 'Error Initiating App..!'
                });
                return;
            }

            const postStep = this.navigateWithLang(mappedRequest, atob(params.metadata as string || ''));

            if (token) {
                this.handleExternalIntegration(token, mappedRequest, postStep);
            } else {
                postStep.then(target => {
                    this._sessionStore.store('target', target);
                    this.samlService.handleSessionInitiation(() => this.appState.refreshToken(null, false, mappedRequest))
                        .then(_res => {
                            this.genericNotificationService.loadingEvent.next({ showLoading: false });
                        }).catch(error => {
                            error = error.error;
                            if (error.status === 401) {
                                this._sessionStore.store('INTEGRATION_DATA', mappedRequest);
                                this.genericNotificationService.loadingEvent.next({
                                    showLoading: true,
                                    loadingMessage: 'Navigating to Login App..!'
                                });
                                window.location.href = error.redirectTo;
                            } else {
                                this.genericNotificationService.loadingEvent.next({
                                    showLoading: true,
                                    loadingMessage: 'Error Initiating App..!'
                                });
                            }
                        });
                });
            }
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    private handleExternalIntegration(token: string, request: Integration, nextHandler: Promise<any>) {
        this.appState.getIntegrationSession(token, request).then(res => {
            if (res) {
                this.appState.processResult(res.json());
                nextHandler.then(target => {
                    this.router.navigate([target.url], { queryParams: target.params })
                        .then(_ => this.genericNotificationService.loadingEvent.next({ showLoading: false }));
                });
            }
        }).catch((err) => {
            this.genericNotificationService.loadingEvent.next({ showLoading: false });
            err = err.error;
            this.error = err.status;
            this.exception = err.message;
            const startTime = new Date().getTime();
            this.appState.getInitialToken()
                .subscribe(res => {
                    if (res) {
                        this.appState.processResult(res.json());
                        const timeout = 1000 - new Date().getTime() + startTime;
                        setTimeout(() => this.router.navigate(['home']), timeout < 0 ? 0 : timeout);
                    }
                });
        });
    }

    private navigateWithLang(navigator: Integration, metadataQueryParams: string): Promise<any> {
        const target = this.resolveTarget(navigator);
        const queryParams = {};
        if (!!metadataQueryParams) {
            queryParams['skip_validation'] = true;
            metadataQueryParams.split('&').map(x => x.split(/ *= */))
                .forEach(x => queryParams[x[0]] = x[1]);
        }
        if (navigator.language && this.iacTranslationsService.isValidLanguage(navigator.language)) {
            return this.translate.use(navigator.language).pipe(map(() => {
                this.notificationService.notificationtranslate1(navigator.language);
                return { url: target, params: queryParams };
            })).toPromise();
        } else return of(target).toPromise();
    }

    private resolveTarget(navigator: Integration): string {
        if ('pick-list' === navigator.redirectTo) {
            return '/wish-list/main';
        }
        return ['/global-search/main/', navigator.searchCriteria, navigator.redirectTo].join('/');
    }
}
