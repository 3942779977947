var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Injector } from '@angular/core';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { SharedInfoService } from '../partsCatalog/sharedInfo.service';
import { map } from 'rxjs/operators';
import { IntegrationService } from '../integration/integration.service';
import { DI } from '../state/core';
var ɵ0 = function () { return SharedInfoService; }, ɵ1 = function () { return IntegrationService; };
var LoginSharedService = /** @class */ (function (_super) {
    __extends(LoginSharedService, _super);
    function LoginSharedService(_injector) {
        var _this = _super.call(this, _injector) || this;
        _this.notificationLoginInfo = new BehaviorSubject([]);
        _this.notificationLoginInfo$ = _this.notificationLoginInfo.asObservable();
        _this.registeredFeatures = new Map();
        _this.sessionEvent = new Subject();
        return _this;
    }
    LoginSharedService.prototype.setLoginInfo = function (loginInfo) {
        this._loginInfo = loginInfo;
        this.sharedInfoService.savedESNs = null;
        this.notificationLoginInfo.next(this.userAttributes);
    };
    LoginSharedService.prototype.checkFeature = function (attributes, featureString, context) {
        for (var _i = 0, attributes_1 = attributes; _i < attributes_1.length; _i++) {
            var attribute = attributes_1[_i];
            for (var _a = 0, _b = (attribute.iacFeaturesCollection || []); _a < _b.length; _a++) {
                var feature = _b[_a];
                if (feature.featureDescription === featureString
                    && (feature.contexts || []).filter(function (x) { return x === context; }).length === 1) {
                    return true;
                }
            }
        }
        return false;
    };
    LoginSharedService.prototype.checkFeatureAccess = function (feature, context, oneTime) {
        var _this = this;
        return oneTime ? this.checkFeature(this.userAttributes, feature, context || 'IACDataServices') :
            this.notificationLoginInfo$.pipe(map(function (data) { return _this.checkFeature(data, feature, context || 'IACDataServices'); }));
    };
    LoginSharedService.prototype.checkViewFeatureAccess = function (feature, oneTime) {
        var viewFeatures = this.integrationService.data.features || [];
        var matches = viewFeatures.filter(function (viewfeature) { return viewfeature === feature; });
        var matched = matches.length === 1;
        return oneTime ? matched : of(matched);
    };
    LoginSharedService.prototype.isLoggedIn = function () {
        return !this.isPublicAcc();
    };
    LoginSharedService.prototype.publishSessionEvent = function (x) {
        this.sessionEvent.next(x);
    };
    LoginSharedService.prototype.registerSessionEvent = function () {
        return this.sessionEvent.asObservable();
    };
    LoginSharedService.prototype.isPublicAcc = function () {
        return this.userId === 'PUBLIC' || /^guest\|.+\|$/.test(this.userId) || this.isIntegrationAccess();
    };
    LoginSharedService.prototype.isIntegrationAccess = function (userAttributes) {
        return (userAttributes || this.userAttributes).filter(function (i) { return i.personType === 'Integration'; }).length > 0;
    };
    Object.defineProperty(LoginSharedService.prototype, "userAttributes", {
        get: function () {
            return this.loginInfo.userAttributes || [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginSharedService.prototype, "consented", {
        get: function () {
            return this.loginInfo.consented || false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginSharedService.prototype, "loginInfo", {
        get: function () {
            return this._loginInfo || {};
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginSharedService.prototype, "userId", {
        get: function () {
            return this.loginInfo.name || this.loginInfo.principal || 'PUBLIC';
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        DI.Inject(ɵ0),
        __metadata("design:type", SharedInfoService)
    ], LoginSharedService.prototype, "sharedInfoService", void 0);
    __decorate([
        DI.Inject(ɵ1, { lazy: true }),
        __metadata("design:type", IntegrationService)
    ], LoginSharedService.prototype, "integrationService", void 0);
    return LoginSharedService;
}(DI.AutoDependencyInjector));
export { LoginSharedService };
export { ɵ0, ɵ1 };
