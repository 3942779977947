import { Pipe, PipeTransform } from '@angular/core';
/*
 *  
 * Usage:
 *   stringValue | indexOf:character:index
 * Example:
 * {{ 4907152 - 0.50 mm Oversize | indexOf:-:0 }}
 *   formats to: 4907152
 * {{ 4907152 - 0.50 mm Oversize | indexOf:-:1 }}
 *   formats to:  - 0.50 mm Oversize
*/
@Pipe({name: 'indexOf'})
export class IndexOfPipe implements PipeTransform {
  transform(stringValue: string, character:string,index: number): any {
    if(index == 0){
        return stringValue.substring(index,stringValue.indexOf(character)-1);
    } else if(index == 1){
        return stringValue.substring(stringValue.indexOf(character)-1,stringValue.length);
    }
  }
}