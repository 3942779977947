import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LanguageService {

    constructor(public translate: TranslateService) {
        let browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|ge/) ? browserLang : 'en');
    }
}