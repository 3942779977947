import { Subject } from 'rxjs';
var PowerGenSharedInfoService = /** @class */ (function () {
    function PowerGenSharedInfoService() {
        this._gsnProductData = [];
        this.esnProductData = [];
        this.partsManuals = [];
        this.productInfo = [];
        this.showAsndata = false;
        this.esns = [];
        this.esndataplates = [];
        this.asns = [];
        this.asnDataplates = [];
        this.asnsUpdated = [];
        this.externalSN = {};
        this.sbom = [];
        this.systemOptions = [];
        this.systemView = [];
        this.systemName = [];
        this.serialType = false;
        this.seperate = false;
        this.globalSearchPath = false;
        this.esnInjectorTrims = [];
        this.notificationPartsContent = new Subject();
        this.notificationPartsContent$ = this.notificationPartsContent.asObservable();
        this.notificationDataplate = new Subject();
        this.notificationDataplate$ = this.notificationDataplate.asObservable();
        this.notificationSystemView = new Subject();
        this.notificationSystemView$ = this.notificationSystemView.asObservable();
        this.notificationEngineGraphicView = new Subject();
        this.notificationEngineGraphicView$ = this.notificationEngineGraphicView.asObservable();
        this.notificationOptionView = new Subject();
        this.notificationOptionView$ = this.notificationOptionView.asObservable();
        this.notificationSystemDetailView = new Subject();
        this.notificationSystemDetailView$ = this.notificationSystemDetailView.asObservable();
        this.notificationPartsSearch = new Subject();
        this.notificationPartsSearch$ = this.notificationPartsSearch.asObservable();
        this.subjectMap = {};
    }
    Object.defineProperty(PowerGenSharedInfoService.prototype, "gsnProductData", {
        get: function () {
            return this._gsnProductData;
        },
        set: function (value) {
            this._gsnProductData = value;
            this.topLevelBOMObjs = null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PowerGenSharedInfoService.prototype, "partOptionsBOM", {
        set: function (value) {
            this._partOptionsBOM = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PowerGenSharedInfoService.prototype, "option", {
        get: function () {
            return this._partOptionsBOM || this._option;
        },
        set: function (value) {
            this._option = value;
            if (this._option) {
                this._partOptionsBOM = null;
            }
        },
        enumerable: true,
        configurable: true
    });
    PowerGenSharedInfoService.prototype.publish = function (key, data) {
        this.getSubject(key).next(data);
    };
    PowerGenSharedInfoService.prototype.subscribe = function (key, subscriber) {
        var observer = this.getSubject(key).asObservable();
        return subscriber ? observer.map(subscriber) : observer;
    };
    PowerGenSharedInfoService.prototype.getSubject = function (key) {
        this.subjectMap[key] = this.subjectMap[key] || new Subject();
        return this.subjectMap[key];
    };
    PowerGenSharedInfoService.prototype.partsContentViewUpdated = function () {
        this.notificationPartsContent.next("new");
    };
    PowerGenSharedInfoService.prototype.dataplateUpdated = function () {
        this.notificationDataplate.next("new");
    };
    PowerGenSharedInfoService.prototype.systemViewUpdated = function () {
        this.notificationSystemView.next("new");
    };
    PowerGenSharedInfoService.prototype.optionViewUpdated = function () {
        this.notificationOptionView.next("new");
    };
    PowerGenSharedInfoService.prototype.systemDetailViewUpdated = function () {
        this.notificationSystemDetailView.next("new");
    };
    PowerGenSharedInfoService.prototype.engineGraphicUpdated = function () {
        this.notificationEngineGraphicView.next("new");
    };
    PowerGenSharedInfoService.prototype.partsSearchUpdated = function () {
        this.notificationPartsSearch.next("new");
    };
    return PowerGenSharedInfoService;
}());
export { PowerGenSharedInfoService };
