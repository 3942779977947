import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import { EngineBOM } from "./engine-bom.actions";
import { patch } from "@ngxs/store/operators";
import { Store } from "src/app/state/core";

export interface EngineBOMStateModel {
    esnInfo: any;
    currentESN?: string;
}

@Injectable()
@State<EngineBOMStateModel>({
    name: 'engineBOM'
})
export class EngineBOMState {
    @Action(EngineBOM.SetESNInfo)
    setESNInfo(ctx: StateContext<EngineBOMStateModel>, { esnInfo }: EngineBOM.SetESNInfo) {
        ctx.setState({ esnInfo });
    }

    @Action(EngineBOM.SetCurrentESN)
    SetCurrentESN(ctx: StateContext<EngineBOMStateModel>, { esn }: EngineBOM.SetCurrentESN) {
        ctx.patchState({ currentESN: esn });
    }

    @Action(EngineBOM.SetKitSets)
    setKitSetsForESN(ctx: StateContext<EngineBOMStateModel>, payload: EngineBOM.SetKitSets) {
        ctx.setState(patch({
            esnInfo: patch({
                ...payload,
                nokit: (payload.kitSets || []).length === 0
            })
        }));
    }

    @Action(EngineBOM.SetGaskets)
    setGasketsForESN(ctx: StateContext<EngineBOMStateModel>, payload: EngineBOM.SetGaskets) {
        ctx.setState(patch({
            esnInfo: patch({
                ...payload,
                nogasket: (payload.gasketSets || []).length === 0
            })
        }));
    }

    @Action(EngineBOM.SetDataplate)
    setESNDataplate(ctx: StateContext<EngineBOMStateModel>, payload: EngineBOM.SetDataplate) {
        ctx.setState(patch({
            esnInfo: patch({ ...payload })
        }));
    }

    @Action(EngineBOM.SetPackageDataplate)
    setPackageDataplate(ctx: StateContext<EngineBOMStateModel>, payload: EngineBOM.SetPackageDataplate) {
        ctx.setState(patch({
            esnInfo: patch({
                packageSN: patch({ ...payload })
            })
        }));
    }

    @Action(EngineBOM.SetPackageInfo)
    setPackageESNInfo(ctx: StateContext<EngineBOMStateModel>, { packageInfo }: EngineBOM.SetPackageInfo) {
        ctx.setState(patch({
            esnInfo: patch({
                packageSN: packageInfo,
                packageAsset: packageInfo.esn
            })
        }));
    }

    @Action(EngineBOM.SetInjectorTrims)
    setInjectorTrimsForESN(ctx: StateContext<EngineBOMStateModel>, payload: EngineBOM.SetInjectorTrims) {
        ctx.setState(patch({
            esnInfo: patch({
                ...payload,
                hasInjector: (payload.injectorTrims || []).length > 0
            })
        }));
    }

    @Action(EngineBOM.Package.SetEngineInfo)
    setEngineInfoForPSN(ctx: StateContext<EngineBOMStateModel>, { esnInfo }: EngineBOM.Package.SetEngineInfo) {
        ctx.setState(patch({
            esnInfo: patch({
                ebuSN: esnInfo
            })
        }));
    }

    @Action(EngineBOM.Package.SetEngineDataplate)
    setEngineDataplateForPSN(ctx: StateContext<EngineBOMStateModel>, payload: EngineBOM.Package.SetEngineDataplate) {
        ctx.setState(patch({
            esnInfo: patch({
                ebuSN: patch({ ...payload })
            })
        }));
    }

    @Action(EngineBOM.Package.SetEngineInjectorTrims)
    setEngineInjectorTrimsForESN(ctx: StateContext<EngineBOMStateModel>, payload: EngineBOM.Package.SetEngineInjectorTrims) {
        ctx.setState(patch({
            esnInfo: patch({
                ebuSN: patch({
                    ...payload,
                    hasInjector: (payload.injectorTrims || []).length > 0
                })
            })
        }));
    }

    @Store.Selector([EngineBOMState])
    static getCurrentESN(state: EngineBOMStateModel) {
        return state.currentESN || '';
    }

    @Store.Selector([EngineBOMState])
    static getESNInfo(state: EngineBOMStateModel) {
        return state.esnInfo || {};
    }

    @Store.Selector([EngineBOMState.getCurrentESN, EngineBOMState.getESNInfo])
    static getCurrentESNInfo(esn: string, esnInfo: any) {
        if (esn && esnInfo.esn !== esn) {
            if (esnInfo.mbom === 'package') esnInfo = esnInfo.ebuSN;
            else if (esnInfo.packageAsset) esnInfo = esnInfo.packageSN;
        }
        return esnInfo;
    }

    @Store.Selector([EngineBOMState.getCurrentESNInfo])
    static getCurrentOptionList(esnInfo: any) {
        return {
            esn: esnInfo.esn || '',
            mbom: esnInfo.mbom || '',
            optionList: esnInfo.optionList || []
        };
    }
}
