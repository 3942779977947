import { Injectable, Injector } from '@angular/core';
import { Response } from '@angular/http';
import { AppSettings } from './app.settings';
import { Observable, Subject, Subscription } from "rxjs";
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { IntegrationService } from './integration/integration.service';
import { LoginSharedService } from './login/login-shared.service';
import { map, filter, flatMap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionService } from './session-maintenance/session.service';
import { Integration } from './integration/Integration';
import { Router } from '@angular/router';
import { DI } from './state/core';

export const USER_ID_KEY = 'USERID';

export type InternalStateType = {
  [key: string]: any
};

@Injectable()
export class AppState extends DI.AutoDependencyInjector {
  public _state: InternalStateType = {};

  private sessionChangesNotifier: Subject<any>;

  @DI.Inject(() => LocalStorageService)
  private localStorageService: LocalStorageService;

  @DI.Inject(() => SessionStorageService)
  private storage: SessionStorageService;

  @DI.Inject(() => IntegrationService)
  private integrationService: IntegrationService;

  @DI.Inject(() => LoginSharedService)
  private loginShared: LoginSharedService;

  @DI.Inject(() => SessionService)
  private sessionService: SessionService;

  @DI.Inject(() => HttpClient)
  private http: HttpClient;

  @DI.Inject(() => Router)
  private router: Router;

  public constructor(_injector: Injector) {
    super(_injector);
    this.sessionChangesNotifier = new Subject<any>();

    this.sessionService.registerForSessionUpdates().pipe(
      filter(event => event.type === 'auto-session-update' || event.type === 'notify-session-reset'
        || (!this.loginShared.isLoggedIn() && event.type === 'confirm-session-update')),
      flatMap(() => this.refreshToken(new HttpHeaders({ 'Expire-Cookies': 'None' })))
    ).subscribe();

    this.sessionService.observeCurrentSession()
      .pipe(filter(_ => this.router.navigated))
      .subscribe(res => this.processResult(res));
  }

  public processResult(loginInfo: any): void {
    this.loginShared.setLoginInfo(loginInfo);
    if (!this.loginShared.isPublicAcc()) {
      this.storage.store(USER_ID_KEY, loginInfo.name);
      this.localStorageService.store(USER_ID_KEY, loginInfo.name);
    } else {
      this.storage.store(USER_ID_KEY, 'Login');
      this.localStorageService.clear(USER_ID_KEY);
    }
  }

  public getIntegrationSession(token: string, integrationData: Integration): Promise<any> {
    return this.sessionService.updateCurrentSession(() => this.http.post(`${AppSettings.AUTH_ENDPOINT}/login/integrationUser`
      , integrationData, { headers: new HttpHeaders({ 'Authorization': token, 'Content-Type': 'application/json' }) }))
      .pipe(map((res: any) => this.parseResponse(res))).toPromise();
  }

  public getInitialToken(saml = false, integrationData?: Integration): Observable<any> {

    let responseObservable: Observable<any>;

    if (!saml) {
      responseObservable = this.getPublicToken(true);
    } else {
      responseObservable = this.sessionService.updateCurrentSession(() => {
        const url = `${AppSettings.AUTH_ENDPOINT}/getCurrentSession`;
        let headers = new HttpHeaders();
        if (integrationData) {
          headers = headers.append('Content-Type', 'application/json');
        }
        const obs = integrationData ? this.http.post(url, integrationData, { headers })
          : this.http.get(url, { headers });
        return obs.pipe(map(res => Object.assign({}, res, { mergeCartItems: saml })));
      });
    }

    return responseObservable.pipe(map(res => this.parseResponse(res)));
  }

  public parseResponse(res): Response {
    this.storage.clear('target');
    this.storage.clear('INTEGRATION_DATA');
    const jsonRes = res;
    this.integrationService.data = res.extraAttributes.integrationData;
    return Object.assign({}, res, { json: () => jsonRes }) as Response;
  }

  public refreshToken(headers?: HttpHeaders, authorize: boolean = true, integrationData?: Integration): Observable<Response> {
    let milliseconds = new Date().getTime() + '';
    let token = `${milliseconds.slice(0, 3)}secure${milliseconds.slice(3, 6)}IAC${milliseconds.slice(6, milliseconds.length)}`;
    let authorizationToekn = `${milliseconds.slice(0, 4)}auth${milliseconds.slice(3, milliseconds.length)}token${milliseconds.slice(4, 8)}`;
    headers = headers || new HttpHeaders();
    if (authorize) {
      headers = headers.append('Authorization', btoa(token));
    }
    return this.sessionService.updateCurrentSession(() => {
      const url = `${AppSettings.AUTH_ENDPOINT}/refreshToken?authToken=${btoa(authorizationToekn)}`;
      if (integrationData) {
        headers = headers.append('Content-Type', 'application/json');
      }
      return integrationData ? this.http.post(url, integrationData, { headers })
        : this.http.get(url, { headers });
    }, false).pipe(map((response: any) => {
      this.processResult(response);
      return response;
    }));
  }

  public getPublicToken(responseHandled: boolean = false): Observable<Response> {

    let milliseconds = new Date().getTime() + '';
    let token = `${milliseconds.slice(0, 3)}secure${milliseconds.slice(3, 6)}IAC${milliseconds.slice(6, milliseconds.length)}`;
    return this.makeCallAsObservable(new HttpHeaders({ 'Authorization': btoa(token) }))
      .pipe(map(response => {
        if (!responseHandled) {
          this.integrationService.data = response.extraAttributes.integrationData;
        }
        return response;
      }));
  }

  private makeCallAsObservable(headers: HttpHeaders): Observable<any> {
    return this.sessionService.updateCurrentSession(() => this.http.get(`${AppSettings.AUTH_ENDPOINT}/login/publicUser`,
      { headers: headers }));
  }

  // already return a clone of the current state
  public get state() {
    return this._state = this._clone(this._state);
  }
  // never allow mutation
  public set state(value) {
    throw new Error('do not mutate the `.state` directly');
  }

  public get(prop?: any) {
    // use our state getter for the clone
    const state = this.state;
    return state.hasOwnProperty(prop) ? state[prop] : state;
  }

  public set(prop: string, value: any) {
    // internally mutate our state
    return this._state[prop] = value;
  }

  private _clone(object: InternalStateType) {
    // simple object clone
    return JSON.parse(JSON.stringify(object));
  }

  public publishSessionChange(res) {
    this.sessionChangesNotifier.next(res);
  }

  public registerForSessionChange(subscriber): Subscription {
    return this.sessionChangesNotifier.asObservable().subscribe(subscriber);
  }

  public getAssetsLink(path: string, isLocalAssetFolder: boolean = false): string {
    if (isLocalAssetFolder || path.startsWith('/images') || path.startsWith('dealer-newsletter')) return `/assets${path}`;
    return `${AppSettings.API_ENDPOINTS.get('IACDataServices')}/assets${path}`;
  }


  public get hasUserConsent() {
    return this.loginShared.isLoggedIn() ? this.loginShared.consented : null;
  }
}
