import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Subject, Observable, of } from 'rxjs';
import { SharedInfoService } from '../partsCatalog/sharedInfo.service';
import { map } from 'rxjs/operators';
import { IntegrationService } from '../integration/integration.service';
import { DI } from '../state/core';

@Injectable()
export class LoginSharedService extends DI.AutoDependencyInjector {
    private _loginInfo: any;
    public sessionEvent: Subject<any>;
    public registeredFeatures: Map<string, Array<any>>;

    private notificationLoginInfo = new BehaviorSubject<any>([]);
    notificationLoginInfo$ = this.notificationLoginInfo.asObservable();

    @DI.Inject(() => SharedInfoService)
    private sharedInfoService: SharedInfoService;

    @DI.Inject(() => IntegrationService, { lazy: true })
    private integrationService: IntegrationService;

    constructor(_injector: Injector) {
        super(_injector);
        this.registeredFeatures = new Map<string, Array<any>>();
        this.sessionEvent = new Subject<any>();
    }

    public setLoginInfo(loginInfo: any): void {
        this._loginInfo = loginInfo;
        this.sharedInfoService.savedESNs = null;
        this.notificationLoginInfo.next(this.userAttributes);
    }

    private checkFeature(attributes: any, featureString: string, context: string): boolean {
        for (let attribute of attributes) {
            for (let feature of (attribute.iacFeaturesCollection || [])) {
                if (feature.featureDescription === featureString
                    && (feature.contexts || []).filter(x => x === context).length === 1) {
                    return true;
                }
            }
        }
        return false;
    }

    public checkFeatureAccess(feature: string, context?: string, oneTime?: boolean): any {
        return oneTime ? this.checkFeature(this.userAttributes, feature, context || 'IACDataServices') :
            this.notificationLoginInfo$.pipe(map((data) => this.checkFeature(data, feature, context || 'IACDataServices')));
    }

    public checkViewFeatureAccess(feature, oneTime?: boolean): any {
        const viewFeatures = this.integrationService.data.features || [];
        const matches = viewFeatures.filter(viewfeature => viewfeature === feature);
        const matched = matches.length === 1;
        return oneTime ? matched : of(matched);
    }

    public isLoggedIn(): boolean {
        return !this.isPublicAcc();
    }

    public publishSessionEvent(x: any): void {
        this.sessionEvent.next(x);
    }

    public registerSessionEvent(): Observable<any> {
        return this.sessionEvent.asObservable();
    }

    public isPublicAcc() {
        return this.userId === 'PUBLIC' || /^guest\|.+\|$/.test(this.userId) || this.isIntegrationAccess();
    }

    public isIntegrationAccess(userAttributes?): boolean {
        return (userAttributes || this.userAttributes).filter(i => i.personType === 'Integration').length > 0;
    }

    public get userAttributes() {
        return this.loginInfo.userAttributes || [];
    }

    public get consented() {
        return this.loginInfo.consented || false;
    }

    public get loginInfo() {
        return this._loginInfo || {};
    }

    private get userId() {
        return this.loginInfo.name || this.loginInfo.principal || 'PUBLIC';
    }
}
