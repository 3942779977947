import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from "@angular/core";
import { SetErrorImageProps } from 'ng-lazyload-image';
import { Subject, Subscription } from "rxjs";

export function setErrorImage({ element, errorImagePath, useSrcset }: SetErrorImageProps) {
    AjaxImageLoader.LAZYLOAD_ERROR_SUBJECT.next(errorImagePath);
    (element as HTMLImageElement).src = 'assets/images/about-us/cbimage.jpg';
}

@Component({
    selector: 'img-xhr',
    template: `
        <div *ngIf="showAlt; else showImage;" [class]="(altStyleClass || '') + ' alt-image-style'">
            <img src="assets/images/about-us/cbimage.jpg" [class]="styleClass || ''" [class.text-center]="true" [ngStyle]="style"
                [alt]="alt" />
        </div>
        <ng-template #showImage>
            <div [class]="parentClass || ''" *ngIf="src">
                <ng-container *ngIf="lazyLoad; else nonLazyLoadTemplate;">
                    <img [lazyLoad]="src" [class]="styleClass || ''" [title]="title || ''" [class.text-center]="true" [ngStyle]="style"
                        [defaultImage]="'../../assets/icons/loader_icon.gif'" [offset]="100" (load)="imageLoaded($event);" [errorImage]="src" [alt]="alt" />
                </ng-container>
                <ng-template #nonLazyLoadTemplate>
                    <img [src]="src" [class]="styleClass || ''" [title]="title || ''" [class.text-center]="true" [ngStyle]="style"
                        (load)="imageLoaded($event);" (error)="handleError($event)" [alt]="alt" />
                </ng-template>
            </div>
        </ng-template>
    `,
    styles: [
        `
            .alt-image-style {
                position: relative;
                height: 100%;
            }
            .alt-image-style > img {
                margin: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            .alt-text {
                position: relative;
                background-color: rgba(150, 150, 150, 0.15);
                cursor: pointer;
                pointer-events: none;
                transition: height 0.25s ease-in;
            }
            .alt-text:hover {
                background-color: rgba(150, 150, 150, 0.25);
            }
            .alt-text > .text-center {
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                text-align: center;
            }
        `
    ]
})
export class AjaxImageLoader implements OnInit, OnDestroy {

    static LAZYLOAD_ERROR_SUBJECT: Subject<any> = new Subject<any>();

    private imageSrc: string;
    @Input() set src(src: string) {
        this.showAlt = false;
        this.imageSrc = src;
    }
    get src(): string {
        return this.imageSrc;
    }
    @Input() public showAlt: boolean;
    @Input() public alt: string;
    @Input() public style: any;
    @Input() public altStyleClass: string;
    @Input() public styleClass: string;
    @Input() public parentClass: string;
    @Input() public width: string;
    @Input() public height: string;
    @Input() public title: string;
    @Input() public lazyLoad: boolean = false;

    @Output() public onerror: EventEmitter<any>;
    @Output() public onLoad: EventEmitter<any>;

    private subscription: Subscription;

    constructor() {
        this.onerror = new EventEmitter<any>();
        this.onLoad = new EventEmitter<any>();

        this.subscription = AjaxImageLoader.LAZYLOAD_ERROR_SUBJECT.filter(src => src === this.src).subscribe(src => this.handleError(src));
    }

    ngOnInit() {
        this.showAlt = this.showAlt || false;
        this.alt = this.alt || "Image not Available";
        this.styleClass = this.styleClass || '';
        this.width = this.width || 'auto';
        this.height = this.height || 'auto';
        this.style = this.style || {
            width: this.width,
            height: this.height
        };
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public handleError(e: any): void {
        this.showAlt = true;
        this.onerror.emit(e);
    }

    public imageLoaded(event: any) {
        if (event.target.src === this.src) {
            this.showAlt = false;
            this.onLoad.emit(event);
        }
    }

}