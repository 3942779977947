import { Subject } from "rxjs";
import { share } from "rxjs/operators";
import * as i0 from "@angular/core";
var NotificationService = /** @class */ (function () {
    function NotificationService() {
        this.loadingEvent = new Subject();
        this.loading$ = this.loadingEvent.asObservable().pipe(share());
    }
    NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };
