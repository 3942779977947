import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { SessionStorageService } from "ngx-webstorage";
import { Observable } from "rxjs-compat";
import { AppState } from "../app.service";
import { BlobStorageService } from "../cms-editor/services/api-services/cms-blob.service";
import { SharingServices } from "../cms-editor/services/sharing-service";

@Injectable({
    providedIn: 'root'
})
export class SamlService {
    constructor(private router: Router, private appState: AppState, private blobservice: BlobStorageService,
        private sharing: SharingServices, private _sessionStore: SessionStorageService) {
        this.updateSession = this.updateSession.bind(this);
    }

    public handleSessionInitiation(sessionInitCaller: () => Observable<any>): Promise<boolean | Error> {
        const target = this._sessionStore.retrieve('target') || { url: '/home', params: {} };
        return sessionInitCaller().toPromise()
            .then(res => ({ target: target, response: res }))
            .then(this.updateSession).then(_res => {
                this.blobservice.menu('/iac').subscribe(res => {
                    if (res) {
                        this.sharing.publishToken.next(res);
                    }
                }, error => console.error('Error Calling Menu', error));
                return _res;
            }).catch(error => Promise.reject(error));
    }

    private updateSession(data) {
        if (!data.response.hasOwnProperty('json')) {
            data.response = this.appState.parseResponse(data.response);
        }
        this.appState.publishSessionChange(data.response);
        return this.router.navigate([data.target.url], { queryParams: data.target.params })
            .then(didNavigate => {
                if (!didNavigate) throw 'Error Initiating Session. Please retry to login';
                return didNavigate;
            });
    }
}
