/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/common";
import * as i3 from "./safe.pipe";
import * as i4 from "@angular/platform-browser";
import * as i5 from "./error.component";
var styles_ErrorComponent = [".link[_ngcontent-%COMP%] {\n                font-size: 10pt;\n                text-decoration: underline;\n                color: blue;\n                cursor: pointer\n            }\n            .link[_ngcontent-%COMP%]:hover {\n                text-decoration: none;\n            }"];
var RenderType_ErrorComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ErrorComponent, data: {} });
export { RenderType_ErrorComponent as RenderType_ErrorComponent };
function View_ErrorComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "h2", [["class", "text-danger"]], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), i0.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 0, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent, 0), i0.ɵunv(_v, 0, 0, i0.ɵnov(_v, 1).transform(_co.errorInfo.header)), "html")); _ck(_v, 0, 0, currVal_0); }); }
function View_ErrorComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.errorInfo.details)); _ck(_v, 1, 0, currVal_0); }); }
function View_ErrorComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(3, 0, null, null, 2, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToInfoURL() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵted(6, null, [" ", ". "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("Please")); _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform("click here")); _ck(_v, 4, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform("for more information")); _ck(_v, 6, 0, currVal_2); }); }
function View_ErrorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "col-md-12 text-center"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ErrorComponent_2)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ErrorComponent_3)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ErrorComponent_4)), i0.ɵdid(6, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorInfo.header; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.errorInfo.details; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.errorInfo.infoRoute; _ck(_v, 6, 0, currVal_2); }, null); }
function View_ErrorComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "h5", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setEsn() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Click here"])), (_l()(), i0.ɵted(-1, null, [" to reset the previous ESN ("])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵted(-1, null, [")"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sharedInfoService.previousESN.esn; _ck(_v, 5, 0, currVal_0); }); }
function View_ErrorComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "span", [["class", "text-center vert-offset-top-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h2", [["class", "text-danger"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["No results were found."])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Please modify the information entered and try again."])), (_l()(), i0.ɵeld(6, 0, null, null, 3, "h5", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["For help, click on the "])), (_l()(), i0.ɵeld(8, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-question-circle"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" next to the search box for additional information."])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ErrorComponent_6)), i0.ɵdid(11, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.sharedInfoService.previousESN && _co.sharedInfoService.previousESN.esn); _ck(_v, 11, 0, currVal_0); }, null); }
export function View_ErrorComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i3.SafePipe, [i4.DomSanitizer]), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ErrorComponent_1)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ErrorComponent_5)), i0.ɵdid(6, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.errorInfo.invalidateEsn; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.errorInfo.invalidateEsn; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_ErrorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "iac-error", [], null, null, null, View_ErrorComponent_0, RenderType_ErrorComponent)), i0.ɵdid(1, 49152, null, 0, i5.ErrorComponent, [i0.Injector], null, null)], null, null); }
var ErrorComponentNgFactory = i0.ɵccf("iac-error", i5.ErrorComponent, View_ErrorComponent_Host_0, {}, {}, []);
export { ErrorComponentNgFactory as ErrorComponentNgFactory };
