var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Router } from "@angular/router";
import { getActionTypeFromInstance, InitState } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { StateStorageService } from "../state-storage.service";
import { Navigation, PersistAppState } from "./app.actions";
import { ApplicationState } from "./app.state";
import { DI } from "./core";
import { patchState } from "./operators.state";
var OPENED_WIN_TARGETS = new Map();
function isWindowAlreadyClosed(win) {
    return win.closed || win.opener !== window;
}
function autoCleanOpenedWindows() {
    return setInterval(function () {
        OPENED_WIN_TARGETS.forEach(function (win, target) {
            if (isWindowAlreadyClosed(win)) {
                OPENED_WIN_TARGETS.delete(target);
            }
        });
    }, 30 * 60 * 1000); // 30 min timer
}
var ɵ0 = function () { return Router; }, ɵ1 = function () { return StateStorageService; };
var NavigationRouteHandlerPlugin = /** @class */ (function (_super) {
    __extends(NavigationRouteHandlerPlugin, _super);
    function NavigationRouteHandlerPlugin(_injector) {
        return _super.call(this, _injector) || this;
    }
    NavigationRouteHandlerPlugin.prototype.handle = function (state, action, next) {
        var _this = this;
        var actionType = getActionTypeFromInstance(action);
        if (actionType === Navigation.PopupRoute.type || actionType === Navigation.TabRoute.type) {
            var _a = this.openWindow(action, actionType), openedWin = _a.openedWin, route = _a.route;
            var resolvedState = {
                router: { url: route.path },
                state: this.resolveModfiedState(state, action, actionType)
            };
            if (openedWin.focus) {
                this.stateStorage.persistState(resolvedState, openedWin);
                if (!route.isExternal && !isWindowAlreadyClosed(openedWin)) {
                    openedWin.focus();
                }
            }
        }
        else if (actionType === Navigation.Route.type) {
            state = this.resolveModfiedState(state, action, actionType);
        }
        else if (actionType === InitState.type) {
            this.intervalId = autoCleanOpenedWindows();
        }
        else if (actionType === PersistAppState.type) {
            clearInterval(this.intervalId);
            OPENED_WIN_TARGETS.forEach(function (win) {
                if (!isWindowAlreadyClosed(win)) {
                    win.close();
                }
            });
        }
        return next(state, action).pipe(tap(function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(actionType === Navigation.Route.type)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.router.navigate(action.url, this.getOptions(action).extras)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); }));
    };
    NavigationRouteHandlerPlugin.prototype.getOptions = function (navigation) {
        return navigation.options || {};
    };
    NavigationRouteHandlerPlugin.prototype.resolveModfiedState = function (state, action, actionType) {
        var stateModifiers = this.getOptions(action).stateModifiers || [];
        var resolvedState = actionType === Navigation.Route.type ? state
            : patchState(ApplicationState, { popupMode: true })(state);
        ;
        for (var _i = 0, stateModifiers_1 = stateModifiers; _i < stateModifiers_1.length; _i++) {
            var modifier = stateModifiers_1[_i];
            resolvedState = modifier(resolvedState);
        }
        return resolvedState;
    };
    NavigationRouteHandlerPlugin.prototype.getTargetRouteFromNavigation = function (navigation) {
        var routeDefinition = {
            isExternal: false,
            url: null, path: null,
            host: location.protocol + "//" + location.host
        };
        var url;
        var pathExp = navigation.url.join('/');
        try {
            url = new URL(pathExp);
            var urlHostDerived = url.protocol + "//" + url.host;
            routeDefinition.isExternal = urlHostDerived !== routeDefinition.host;
        }
        catch (_a) {
            url = new URL(routeDefinition.host + "/" + pathExp);
        }
        routeDefinition.url = url.toString();
        routeDefinition.path = "" + url.pathname + url.search;
        return routeDefinition;
    };
    NavigationRouteHandlerPlugin.prototype.openWindow = function (navigation, navigationType) {
        var route = this.getTargetRouteFromNavigation(navigation);
        var windowTarget = this.getOptions(navigation).windowTarget || '_blank';
        var windowFeatures = route.isExternal ? 'noreferrer,noopener' : undefined;
        if (navigationType === Navigation.PopupRoute.type) {
            windowFeatures = "popup=1,resizable=1,scrollbars=1," + (windowFeatures || '');
        }
        var createWin = function () {
            if (isIE() && !windowFeatures) {
                return window.open(route.url, windowTarget);
            }
            return window.open(route.url, windowTarget, windowFeatures) || {};
        };
        var openedWin = createWin();
        if (isPopupAlreadyOpened(openedWin)) {
            openedWin.close();
            openedWin = createWin();
        }
        if ((openedWin.name || 'null') !== 'null') {
            OPENED_WIN_TARGETS.set(openedWin.name, openedWin);
        }
        return { openedWin: openedWin, route: route };
    };
    __decorate([
        DI.Inject(ɵ0),
        __metadata("design:type", Router)
    ], NavigationRouteHandlerPlugin.prototype, "router", void 0);
    __decorate([
        DI.Inject(ɵ1),
        __metadata("design:type", StateStorageService)
    ], NavigationRouteHandlerPlugin.prototype, "stateStorage", void 0);
    return NavigationRouteHandlerPlugin;
}(DI.AutoDependencyInjector));
export { NavigationRouteHandlerPlugin };
function isPopupAlreadyOpened(openedWin) {
    try {
        return openedWin.name && (openedWin.location.origin || 'null') !== 'null'
            && openedWin.location.origin !== window.location.origin;
    }
    catch (_a) {
        return true;
    }
}
export { ɵ0, ɵ1 };
