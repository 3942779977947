import { Injector } from "@angular/core";
import { Router } from "@angular/router";
import { getActionTypeFromInstance, InitState, NgxsNextPluginFn, NgxsPlugin, StateOperator } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { StateStorageService } from "../state-storage.service";
import { Navigation, PersistAppState } from "./app.actions";
import { ApplicationState, ApplicationStateModel } from "./app.state";
import { DI } from "./core";
import { patchState } from "./operators.state";

const OPENED_WIN_TARGETS = new Map<string, Window>();

function isWindowAlreadyClosed(win: Window): boolean {
    return win.closed || win.opener !== window;
}

function autoCleanOpenedWindows(): NodeJS.Timeout {
    return setInterval(() => {
        OPENED_WIN_TARGETS.forEach((win: Window, target: string) => {
            if (isWindowAlreadyClosed(win)) {
                OPENED_WIN_TARGETS.delete(target);
            }
        });
    }, 30 * 60 * 1000); // 30 min timer
}

interface RouteDefinition {
    url: string;
    path: string;
    host: string;
    isExternal: boolean;
}

export class NavigationRouteHandlerPlugin extends DI.AutoDependencyInjector implements NgxsPlugin {
    @DI.Inject(() => Router)
    private router: Router;

    @DI.Inject(() => StateStorageService)
    private stateStorage: StateStorageService;

    private intervalId: NodeJS.Timeout;

    constructor(_injector: Injector) {
        super(_injector);
    }

    public handle(state: any, action: any, next: NgxsNextPluginFn) {
        const actionType = getActionTypeFromInstance(action);

        if (actionType === Navigation.PopupRoute.type || actionType === Navigation.TabRoute.type) {
            const { openedWin, route } = this.openWindow(action, actionType);
            const resolvedState = {
                router: { url: route.path },
                state: this.resolveModfiedState(state, action, actionType)
            };
            if (openedWin.focus) {
                this.stateStorage.persistState(resolvedState, openedWin);
                if (!route.isExternal && !isWindowAlreadyClosed(openedWin)) {
                    openedWin.focus();
                }
            }
        } else if (actionType === Navigation.Route.type) {
            state = this.resolveModfiedState(state, action, actionType);
        } else if (actionType === InitState.type) {
            this.intervalId = autoCleanOpenedWindows();
        } else if (actionType === PersistAppState.type) {
            clearInterval(this.intervalId);
            OPENED_WIN_TARGETS.forEach(win => {
                if (!isWindowAlreadyClosed(win)) {
                    win.close();
                }
            });
        }

        return next(state, action).pipe(tap(async () => {
            if (actionType === Navigation.Route.type) {
                await this.router.navigate(action.url, this.getOptions(action).extras);
            }
        }));
    }

    private getOptions(navigation: any) {
        return navigation.options || {};
    }

    private resolveModfiedState(state, action: any, actionType: string) {
        const stateModifiers: StateOperator<any>[] = this.getOptions(action).stateModifiers || [];

        let resolvedState = actionType === Navigation.Route.type ? state
            : patchState<ApplicationStateModel>(ApplicationState, { popupMode: true })(state);;
        for (const modifier of stateModifiers) {
            resolvedState = modifier(resolvedState);
        }
        return resolvedState;
    }

    private getTargetRouteFromNavigation(navigation: any): RouteDefinition {
        const routeDefinition: RouteDefinition = {
            isExternal: false,
            url: null, path: null,
            host: `${location.protocol}//${location.host}`
        }

        let url: URL;
        const pathExp = navigation.url.join('/');
        try {
            url = new URL(pathExp);
            const urlHostDerived = `${url.protocol}//${url.host}`;
            routeDefinition.isExternal = urlHostDerived !== routeDefinition.host;
        } catch {
            url = new URL(`${routeDefinition.host}/${pathExp}`);
        }
        routeDefinition.url = url.toString();
        routeDefinition.path = `${url.pathname}${url.search}`;
        return routeDefinition;
    }

    private openWindow(navigation: any, navigationType: string): { openedWin: Window, route: RouteDefinition } {
        const route = this.getTargetRouteFromNavigation(navigation);
        const windowTarget = this.getOptions(navigation).windowTarget || '_blank';
        let windowFeatures = route.isExternal ? 'noreferrer,noopener' : undefined;

        if (navigationType === Navigation.PopupRoute.type) {
            windowFeatures = `popup=1,resizable=1,scrollbars=1,${windowFeatures || ''}`;
        }

        const createWin = (): Window => {
            if (isIE() && !windowFeatures) {
                return window.open(route.url, windowTarget);
            }
            return window.open(route.url, windowTarget, windowFeatures) || <any> {};
        };
        let openedWin = createWin();
        if (isPopupAlreadyOpened(openedWin)) {
            openedWin.close();
            openedWin = createWin();
        }

        if ((openedWin.name || 'null') !== 'null') {
            OPENED_WIN_TARGETS.set(openedWin.name, openedWin);
        }
        return { openedWin, route };
    }
}

function isPopupAlreadyOpened(openedWin: Window): boolean {
    try {
        return openedWin.name && (openedWin.location.origin || 'null') !== 'null'
            && openedWin.location.origin !== window.location.origin;
    } catch {
        return true;
    }
}
