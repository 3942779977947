/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./background-xhr-image.component";
var styles_AjaxBackGroundLoader = ["div.alternate[_ngcontent-%COMP%] {\n                position: relative;\n                min-width: 150px;\n                min-height: 100px;\n            }\n            div.bodyDiv[_ngcontent-%COMP%] {\n                display: inherit;\n            }\n            div.altDiv[_ngcontent-%COMP%] {\n                position: absolute;\n                top: 47.5%;\n                left: 0;\n                width: 100%;\n                display: inline-block;\n                color: black;\n                cursor: pointer;\n                text-align: center;\n            }"];
var RenderType_AjaxBackGroundLoader = i0.ɵcrt({ encapsulation: 0, styles: styles_AjaxBackGroundLoader, data: {} });
export { RenderType_AjaxBackGroundLoader as RenderType_AjaxBackGroundLoader };
function View_AjaxBackGroundLoader_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_AjaxBackGroundLoader_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "bodyDiv"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AjaxBackGroundLoader_2)), i0.ɵdid(2, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.bodyTemplate; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AjaxBackGroundLoader_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_AjaxBackGroundLoader_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "altDiv"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AjaxBackGroundLoader_4)), i0.ɵdid(2, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.altTemplate || i0.ɵnov(_v.parent, 7)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_AjaxBackGroundLoader_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.alt || "Image Not Available"); _ck(_v, 1, 0, currVal_0); }); }
export function View_AjaxBackGroundLoader_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [], [[8, "title", 0], [8, "className", 0], [2, "alternate", null]], null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgStyleImpl, i1.ɵNgStyleR2Impl, [i0.ElementRef, i0.KeyValueDiffers, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgStyle, [i1.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AjaxBackGroundLoader_1)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AjaxBackGroundLoader_3)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, [["defaultAltTemplate", 2]], null, 0, null, View_AjaxBackGroundLoader_5))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.style; _ck(_v, 2, 0, currVal_3); var currVal_4 = (_co.bodyTemplate && !_co.showAlt); _ck(_v, 4, 0, currVal_4); var currVal_5 = _co.showAlt; _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.title || ""); var currVal_1 = (_co.styleClass || ""); var currVal_2 = _co.showAlt; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_AjaxBackGroundLoader_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "background-xhr-image", [], null, null, null, View_AjaxBackGroundLoader_0, RenderType_AjaxBackGroundLoader)), i0.ɵdid(1, 770048, null, 2, i2.AjaxBackGroundLoader, [], null, null), i0.ɵqud(603979776, 1, { bodyTemplate: 0 }), i0.ɵqud(603979776, 2, { altTemplate: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AjaxBackGroundLoaderNgFactory = i0.ɵccf("background-xhr-image", i2.AjaxBackGroundLoader, View_AjaxBackGroundLoader_Host_0, { src: "background", alt: "alt", showAlt: "showAlt", style: "style", title: "title", styleClass: "styleClass", bodyTemplate: "bodyTemplate", altTemplate: "altTemplate" }, {}, []);
export { AjaxBackGroundLoaderNgFactory as AjaxBackGroundLoaderNgFactory };
