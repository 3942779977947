var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Injector } from '@angular/core';
import { AppSettings } from '../app.settings';
import { LocalStorageService, SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { BehaviorSubject, of, Subject, Subscription } from 'rxjs';
import { IntegrationService } from '../integration/integration.service';
import { CookieService } from '../cookie-popup/cookie.service';
import { catchError, distinctUntilChanged, filter, flatMap, map, share, take, debounceTime } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppState } from '../app.service';
import { LoginSharedService } from '../login/login-shared.service';
import { Store } from '@ngxs/store';
import { ApplicationState } from '../state/app.state';
import { DI } from '../state/core';
export var CART_KEY = 'Cart';
var ɵ0 = function () { return HttpClient; }, ɵ1 = function () { return LocalStorageService; }, ɵ2 = function () { return IntegrationService; }, ɵ3 = function () { return CookieService; }, ɵ4 = function () { return SessionStorageService; }, ɵ5 = function () { return AppState; }, ɵ6 = function () { return LoginSharedService; }, ɵ7 = function () { return Store; };
var CartService = /** @class */ (function (_super) {
    __extends(CartService, _super);
    function CartService(_injector) {
        var _this = _super.call(this, _injector) || this;
        _this.cartLoadCompleteNotifier = new Subject();
        _this._cart = new BehaviorSubject(null);
        _this.storage.observe(CART_KEY).subscribe(function (cart) { return _this._cart.next(cart); });
        _this.completeCaller = function () { return _this.cartLoadCompleteNotifier.complete(); };
        var subscription = new Subscription(function () { return _this.cartLoadCompleteNotifier.next(); });
        subscription.add(_this.loginService.notificationLoginInfo$
            .pipe(filter(function (userAttributes) { return userAttributes.length > 0; }))
            .pipe(debounceTime(0)).subscribe(function (_) { return __awaiter(_this, void 0, void 0, function () {
            var userConsent, browserCart;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        userConsent = this.appState.hasUserConsent;
                        return [4 /*yield*/, this.isBrowserCart().toPromise()];
                    case 1:
                        browserCart = _a.sent();
                        if (!browserCart && (this.loginService.isIntegrationAccess() || userConsent)) {
                            subscription.unsubscribe();
                            this.afterLogin();
                        }
                        else if (browserCart || userConsent === null) {
                            subscription.unsubscribe();
                            browserCart && this.cleanCart();
                            setTimeout(function () {
                                _this.completeCaller();
                                _this._cart.next(_this.storage.retrieve(CART_KEY));
                            }, 100);
                        }
                        return [2 /*return*/];
                }
            });
        }); }));
        _this.isBrowserCart(0)
            .pipe(distinctUntilChanged(), filter(function (isBrowserCart) { return isBrowserCart && subscription.closed; }))
            .subscribe(function () { return _this.cleanCart(); });
        return _this;
    }
    CartService.prototype.addCart = function (cart) {
        var headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        var url = AppSettings.API_ENDPOINTS.get('shopparts') + "/protected/cart/addCart";
        return this._http.post(url, JSON.stringify(cart), { headers: headers }).toPromise().then(function (res) { return res; });
    };
    CartService.prototype.deleteList = function () {
        return __awaiter(this, void 0, void 0, function () {
            var cart;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        cart = this.storage.retrieve(CART_KEY);
                        return [4 /*yield*/, this.isBrowserCart().toPromise()];
                    case 1:
                        if (!(_a.sent())) {
                            return [2 /*return*/, this._http.delete(AppSettings.API_ENDPOINTS.get('shopparts') + "/protected/cart/" + cart.cartId)
                                    .toPromise().then(function () {
                                    _this.updateCart(null);
                                    return _this.integrationService.getIntegrationUser().pipe(take(1))
                                        .toPromise().then(function (integrationUser) {
                                        if (integrationUser) {
                                            _this.integrationService.data = Object.assign(_this.integrationService.data, {
                                                vendorCartId: ''
                                            });
                                        }
                                    });
                                })];
                        }
                        else {
                            this.updateCart(null);
                            return [2 /*return*/, Promise.resolve()];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    CartService.prototype.afterLogin = function (mergeCartItems) {
        var _this = this;
        if (mergeCartItems === void 0) { mergeCartItems = this.loginService.loginInfo.mergeCartItems; }
        //TODO: Coming from Dealer Commerce
        var sessionCart = this.storage.retrieve(CART_KEY);
        var popupMode = this.store.selectSnapshot(ApplicationState.popupMode);
        var pickListItems;
        if (sessionCart != null && sessionCart.cartItemsList != null && sessionCart.cartItemsList.length > 0 && mergeCartItems) {
            pickListItems = sessionCart.cartItemsList;
        }
        else {
            pickListItems = [];
        }
        var headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        var url = AppSettings.API_ENDPOINTS.get('shopparts') + "/protected/cart/current";
        var vendorUsername, vendorCartId;
        var vendor = this.integrationService.data.vendor || 'IAC';
        var userType = this.integrationService.data.userType || '';
        if (userType.toLowerCase() === 'partner' && this.integrationService.data.vendorCartId === 'PACCAR') {
            vendorUsername = this._cookieService.getCookie("PACCAR_USER");
        }
        else if (this.integrationService.data.vendorCartId) {
            vendorCartId = this.integrationService.data.vendorCartId;
        }
        return (popupMode ? of(this.storage.retrieve(CART_KEY)) : this._http.post(url, {
            items: pickListItems, vendor: vendor, vendorUsername: vendorUsername,
            vendorCartId: vendorCartId
        }, { headers: headers })).toPromise()
            .then(function (cart) {
            if (_this.loginService.isLoggedIn || cart) {
                _this.updateCart(cart);
                if (cart) {
                    return cart.cartId;
                }
            }
            return null;
        })
            .catch(function (x) {
            console.log("Error: " + JSON.stringify(x));
            return Promise.reject(x);
        }).finally(this.completeCaller);
    };
    CartService.prototype.addItemToCart = function (part, butype, serialNumber) {
        var type = null;
        var partNumber = part.partNo;
        var supersededPartNo = null;
        if (part.supersession) {
            var isFilter = part.supersession.find(function (x) { return x.sellable == 'Y' && x.sequence == '1'; });
            if (isFilter) {
                type = 'S';
                partNumber = isFilter.partNo;
                supersededPartNo = part.supersession
                    .filter(function (x) { return x.partDispCd == '3' && x.sellable != 'Y'; })
                    .map(function (x) { return x.partNo; });
            }
        }
        if (part.kit) {
            type = 'K';
        }
        var entry;
        if (butype) {
            entry = {
                "serialNum": serialNumber,
                "partNo": partNumber,
                "partDesc": part.partDesc,
                "quantity": 1,
                "partType": type,
                "supersededPartNo": supersededPartNo,
                "bu": butype
            };
        }
        else {
            entry = {
                "serialNum": this.esn,
                "partNo": partNumber,
                "partDesc": part.partDesc,
                "quantity": 1,
                "partType": type,
                "supersededPartNo": supersededPartNo
            };
        }
        var sessionCart = this.storage.retrieve(CART_KEY);
        if (sessionCart == null) {
            sessionCart = {
                "cartItemsList": []
            };
            sessionCart.cartItemsList.push(entry);
            return this.createWSCart(sessionCart);
        }
        else {
            return this.cartAlreadyExist(entry, sessionCart);
        }
    };
    CartService.prototype.createWSCart = function (sessionCart) {
        return __awaiter(this, void 0, void 0, function () {
            var headers, url, vendorUsername, vendorCartId, vendor, userType;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.isBrowserCart().toPromise()];
                    case 1:
                        if (!(_a.sent())) { //The User is Logged In
                            headers = new HttpHeaders({ 'Content-Type': 'application/json' });
                            url = AppSettings.API_ENDPOINTS.get('shopparts') + "/protected/cart/current";
                            vendorUsername = void 0, vendorCartId = void 0;
                            vendor = this.integrationService.data.vendor || 'IAC';
                            userType = this.integrationService.data.userType || '';
                            if (userType.toLowerCase() === 'partner' && this.integrationService.data.vendorCartId === 'PACCAR') {
                                vendorUsername = this._cookieService.getCookie("PACCAR_USER");
                            }
                            else if (this.integrationService.data.vendorCartId) {
                                vendorCartId = this.integrationService.data.vendorCartId;
                            }
                            return [2 /*return*/, this._http.post(url, { items: sessionCart.cartItemsList, vendor: vendor, vendorUsername: vendorUsername,
                                    vendorCartId: vendorCartId }, { headers: headers })
                                    .toPromise()
                                    .then(function (cart) { return _this.updateCart(cart); })
                                    .catch(function (x) {
                                    console.log("Error: " + JSON.stringify(x));
                                })];
                        }
                        else {
                            this.updateCart(sessionCart);
                            return [2 /*return*/, Promise.resolve(sessionCart)];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    CartService.prototype.cartAlreadyExist = function (entry, sessionCart) {
        return __awaiter(this, void 0, void 0, function () {
            var headers, url;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.isBrowserCart().toPromise()];
                    case 1:
                        if (!(_a.sent())) {
                            headers = new HttpHeaders({ 'Content-Type': 'application/json' });
                            url = AppSettings.API_ENDPOINTS.get('shopparts') + "/protected/cart/" + sessionCart.cartId + "/part";
                            return [2 /*return*/, this._http.post(url, JSON.stringify(entry), { headers: headers })
                                    .toPromise().then(function (flag) {
                                    if (flag) {
                                        sessionCart.cartItemsList.push(entry);
                                        _this.updateCart(sessionCart);
                                    }
                                })];
                        }
                        else {
                            sessionCart.cartItemsList.push(entry);
                            this.updateCart(sessionCart);
                            return [2 /*return*/, Promise.resolve(sessionCart)];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    CartService.prototype.isItemAdded = function (partNo) {
        var sessionCart = this.storage.retrieve(CART_KEY);
        var result = false;
        if (sessionCart != null && sessionCart.cartItemsList != null && sessionCart.cartItemsList.length > 0) {
            result = sessionCart.cartItemsList.filter(function (item) { return item.partNo === partNo; }).length > 0;
        }
        return result;
    };
    CartService.prototype.updatePart = function (objObserv) {
        var _this = this;
        var part = objObserv.part;
        var cartSession = this.storage.retrieve(CART_KEY);
        return this.isBrowserCart().pipe(flatMap(function (isBrowserCart) {
            if (!isBrowserCart) {
                var headers = new HttpHeaders({ 'Content-Type': 'application/json' });
                var url = AppSettings.API_ENDPOINTS.get('shopparts') + "/protected/cart/" + cartSession.cartId + "/part/" + part.partNo;
                return _this._http.put(url, part.quantity, { headers: headers })
                    .pipe(map(function () {
                    var response = cartSession.cartItemsList.find(function (x) { return x.partNo === part.partNo; });
                    response.quantity = part.quantity;
                    _this.updateCart(cartSession);
                    //}
                    return response;
                })).pipe(catchError(function () {
                    var cartSessionInfo = _this.storage.retrieve(CART_KEY);
                    var oldPart = cartSessionInfo.cartItemsList.find(function (x) { return x.partNo === part.partNo; });
                    return of(oldPart);
                }));
            }
            else {
                var cartSessionInfo = _this.storage.retrieve(CART_KEY);
                cartSessionInfo.cartItemsList.find(function (x) { return x.partNo === part.partNo; }).quantity = part.quantity;
                _this.updateCart(cartSessionInfo);
                return of(part);
            }
        }));
    };
    CartService.prototype.deletePart = function (part) {
        var _this = this;
        var sessionCart = this.storage.retrieve(CART_KEY);
        return this.isBrowserCart().pipe(flatMap(function (isBrowserCart) {
            if (!isBrowserCart) {
                var url = AppSettings.API_ENDPOINTS.get('shopparts') + "/protected/cart/" + sessionCart.cartId + "/part/" + part.partNo;
                return _this._http.delete(url)
                    .pipe(map(function () {
                    for (var j = 0; j < sessionCart.cartItemsList.length; j++) {
                        if (sessionCart.cartItemsList[j].partNo == part.partNo) {
                            sessionCart.cartItemsList.splice(j, 1);
                            break;
                        }
                    }
                    _this.updateCart(sessionCart);
                    //}
                    return of(part);
                })).pipe(catchError(function () {
                    return of(part);
                }));
            }
            else {
                for (var i = 0; i < sessionCart.cartItemsList.length; i++) {
                    if (sessionCart.cartItemsList[i].partNo === part.partNo) {
                        sessionCart.cartItemsList.splice(i, 1);
                    }
                }
                _this.updateCart(sessionCart);
                return of(part);
            }
        }));
    };
    CartService.prototype.sendEmail = function (cart) {
        var headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        var url = AppSettings.API_ENDPOINTS.get('shopparts') + "/protected/cart/sendEmail";
        var cartSession = this.storage.retrieve(CART_KEY);
        cartSession.emailId = cart.email;
        cartSession.captcha = cart.captcha;
        cartSession.username = cart.username;
        cartSession.sender = cart.sender;
        cartSession.phone = cart.phone;
        cartSession.comments = cart.comments;
        return this._http.post(url, JSON.stringify(cartSession), { headers: headers })
            .toPromise()
            .then(function (res) { return res.text; });
    };
    CartService.prototype.sendCartToBMS = function (desc) {
        var _this = this;
        var cart = this.storage.retrieve(CART_KEY);
        var headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        var url = AppSettings.API_ENDPOINTS.get('shopparts') + "/protected/cart/" + cart.cartId + "/send?to=bms";
        return this._http.post(url + ("&name=" + (desc || 'qsol')), cart.cartItemsList, { headers: headers })
            .toPromise()
            .then(function (res) {
            _this.sessionStorage.store("Items_Send", _this.storage.retrieve(CART_KEY).cartItemsList);
            _this.updateCart(null);
            return res;
        });
    };
    CartService.prototype.sendCartToIntegration = function (userId) {
        var _this = this;
        if (userId) {
            if (this.integrationService.data.vendorCartId) {
                return Promise.resolve(this.integrationService.data.vendorCartId);
            }
            return this.afterLogin(true)
                .then(function (cartId) {
                if (!cartId) {
                    return Promise.reject({
                        message: 'Cart syncing is unsuccessful'
                    });
                }
                _this.integrationService.data = Object.assign(_this.integrationService.data, {
                    vendorCartId: cartId
                });
                return cartId;
            });
        }
        return Promise.reject({
            message: 'Invalid invocation to sendCart'
        });
    };
    CartService.prototype.initializePaccarSession = function () {
        return this._http.get(AppSettings.API_ENDPOINTS.get('shopparts') + "/protected/paccarSession").toPromise()
            .then(function (res) { return res; });
    };
    CartService.prototype.isBrowserCart = function (limit) {
        var _this = this;
        if (limit === void 0) { limit = 1; }
        var observable = this.integrationService.getIntegrationUser()
            .pipe(map(function (integrationUser) {
            if (integrationUser) {
                return !_this.integrationService.data.vendorCartId;
            }
            return _this.userId === 'Login';
        }));
        if (limit > 0) {
            return observable.pipe(take(limit));
        }
        return observable;
    };
    CartService.prototype.getCart = function () {
        return this._cart.pipe(map(function (cart) { return cart || {}; }));
    };
    CartService.prototype.getCartItemsCount = function () {
        return this.getCart().pipe(map(function (cart) { return cart.cartItemsList; }))
            .pipe(map(function (cartItems) { return (cartItems || []).length; }))
            .pipe(distinctUntilChanged(), share());
    };
    CartService.prototype.cleanCart = function () {
        var cartItemsList = (this.storage.retrieve(CART_KEY) || {}).cartItemsList;
        this.updateCart(cartItemsList && { cartItemsList: cartItemsList });
    };
    CartService.prototype.updateCart = function (cart) {
        if (cart) {
            this.storage.store(CART_KEY, cart);
        }
        else {
            this.storage.clear(CART_KEY);
        }
        this._cart.next(cart);
    };
    CartService.prototype.notifyCartLoaded = function (completeCallback, initCallback) {
        if (initCallback === void 0) { initCallback = function () { }; }
        return this.cartLoadCompleteNotifier.subscribe(initCallback, function () { }, completeCallback);
    };
    __decorate([
        SessionStorage('USERID'),
        __metadata("design:type", String)
    ], CartService.prototype, "userId", void 0);
    __decorate([
        SessionStorage('esn'),
        __metadata("design:type", String)
    ], CartService.prototype, "esn", void 0);
    __decorate([
        DI.Inject(ɵ0),
        __metadata("design:type", HttpClient)
    ], CartService.prototype, "_http", void 0);
    __decorate([
        DI.Inject(ɵ1),
        __metadata("design:type", LocalStorageService)
    ], CartService.prototype, "storage", void 0);
    __decorate([
        DI.Inject(ɵ2),
        __metadata("design:type", IntegrationService)
    ], CartService.prototype, "integrationService", void 0);
    __decorate([
        DI.Inject(ɵ3),
        __metadata("design:type", CookieService)
    ], CartService.prototype, "_cookieService", void 0);
    __decorate([
        DI.Inject(ɵ4),
        __metadata("design:type", SessionStorageService)
    ], CartService.prototype, "sessionStorage", void 0);
    __decorate([
        DI.Inject(ɵ5),
        __metadata("design:type", AppState)
    ], CartService.prototype, "appState", void 0);
    __decorate([
        DI.Inject(ɵ6),
        __metadata("design:type", LoginSharedService)
    ], CartService.prototype, "loginService", void 0);
    __decorate([
        DI.Inject(ɵ7),
        __metadata("design:type", Store)
    ], CartService.prototype, "store", void 0);
    return CartService;
}(DI.AutoDependencyInjector));
export { CartService };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7 };
