import { Routes, RouterModule, UrlSegment } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ErrorComponent } from './error/error.component';
import { IntegrationComponent } from './integration/integration.component';
import { TicketCompleteComponent } from './ticket-complete/ticket-complete.component';
import { DealerNewslettersComponent } from './dealer-newsletters/dealer-newsletters.component'
import { LoginAuthGuard } from './auth.guard';
import { AccessCheckComponent } from './shared/check-access/check-access.component';
import { SAMLComponent } from './saml/saml.component';
import { RouteProtectionGuard } from './route-gaurds/route-protect.service';

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'saml',
                component: SAMLComponent
            },
            {
                path: 'integration',
                component: IntegrationComponent
            },
            {
                path: '',
                pathMatch: 'full',
                redirectTo: '/home'
            },
            {
                path: 'home',
                component: HomeComponent
            },
            {
                path: 'login',
                component: LoginComponent,
                data: {
                    breadcrumb: "Login"
                }
            },
            {
                path: 'error',
                component: ErrorComponent
            },
            {
                path: 'wish-list',
                loadChildren: () => import('../app/cart/cart.module').then(m => m.CartModule)
            },
            {
                path: 'contact-us',
                loadChildren: () => import('../app/contactUs/contactUs.module').then(m => m.ContactUsModule)
            },
            {
                path: 'esn-entry',
                loadChildren: () => import('../app/partsCatalog/partsCatalog.module').then(m => m.PartsCatalogModule)
            },
            {
                path: 'atsn-entry',
                loadChildren: () => import('../app/aftertreatment/aftertreatment.module').then(m => m.AftertreatmentModule)
            },
            {
                path: 'gsn-entry',
                loadChildren: () => import('../app/power-gen/power-gen.module').then(m => m.PowerGenModule)
            },
            {
                path: 'ctt-entry',
                loadChildren: () => import('../app/turbo-chargers/advanced-search/advanced-search.module').then(m => m.AdvancedSearchModule)
            },
            {
                path: 'mst-entry',
                loadChildren: () => import('../app/mechanical-service-tools/entry/mst-entry.module').then(m => m.MstEntryModule)
            },
            {
                path: 'mst-admin',
                loadChildren: () => import('../app/mechanical-service-tools/admin/mst-admin.module').then(m => m.MstAdminModule)
            },
            {
                path: 'promotions',
                loadChildren: () => import('../app/promotions/promotions.module').then(m => m.PromotionsModule)
            },
            {
                path: '',
                loadChildren: () => import('../app/generic/generic.module').then(m => m.GenericModule)
            },
            {
                path: 'global-search',
                loadChildren: () => import('../app/global-search/global-search.module').then(m => m.GlobalSearchModule)
            },
            {
                path: 'saved-esn',
                loadChildren: () => import('../app/saved-esn/savedEsn.module').then(m => m.SavedEsnModule)
            },
            {
                path: 'parts-compatibility',
                loadChildren: () => import('../app/parts-compatibility/parts-compatibility.module').then(m => m.PartsCompatibilityModule)
            },
            {
                path: 'ticket-complete/:ticketId',
                component: TicketCompleteComponent
            },
            {
                path: 'dealer-newsletters',
                component: DealerNewslettersComponent,
                data: {
                    breadcrumb: "Dealer Promotions Updates"
                },
                canActivate: [LoginAuthGuard]
            },
            {
                path: 'access/:path',
                component: AccessCheckComponent
            },
            {
                path: 'kits-set-view',
                loadChildren: () => import('../app/kits-set-view/kits-set-view.module').then(m => m.KitsSetViewModule)
            },
            {
                path: 'cms-editor',
                loadChildren: () => import('../app/cms-editor/cms-editor.module').then(m => m.CMSEditorModule)
            },
            {
                matcher: htmlFiles,
                loadChildren: () => import('../app/resource/resource.module').then(m => m.ResourceModule)
            },
            {
                path: 'upfits',
                loadChildren: () => import('./upfits/upfits.module').then(m => m.UpfitsModule)
            }, 
            {
                path: '**',
                redirectTo: 'home'
            },
        ],
        canActivateChild: [RouteProtectionGuard]
    }
];

export const ROUTES = RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
});

export function htmlFiles(url: UrlSegment[]) {
    return url.length > 1 && ['resource'].includes(url[0].path) ? ({ consumed: url }) : null;
}
