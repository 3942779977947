import { Injectable }       from '@angular/core';

import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild
}                           from '@angular/router';
import { SharedInfoService } from './partsCatalog/sharedInfo.service';
import { NotificationService } from './shared/header/notification.service';

@Injectable()
export class AuthGuard implements  CanActivateChild {
  constructor( private router: Router, private sharedInfoService:SharedInfoService, private notificationService: NotificationService) {}


  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if(this.sharedInfoService.inputEsn && (state.url.startsWith('/esn-entry/main/kit-list') || state.url.startsWith('/esn-entry/main/search/inner/kit-list') 
         || state.url.startsWith('/esn-entry/main/kit-set-view/kit-set-details/'))){
      return true;
     }
    if(route.params['source'] === 'parts-search' ) {
      this.setPartsCatalogView(route.params.partNo, 'parts-search');
    } else 
    if(route.params['source'] === 'parts-search-global' ) {
      this.setPartsCatalogView(route.params.partNo, 'parts-search-global');
    } else if(/.*part-details.*/.test(state.url)) {
      this.setPartsCatalogView(route.params.partNo, 'partViewUpdate');
    } else if((state.url.startsWith('/esn-entry/main/kits') || state.url.startsWith('/esn-entry/main/sets') || state.url.startsWith('/esn-entry/main/kit-list')) && this.sharedInfoService.currentOption) {
      const esn = this.sharedInfoService.currentEsnInfo && this.sharedInfoService.inputEsn;
      this.setPartsCatalogView(esn || ' ', 'kit/gasketViewUpdate');
    } else if(route.params['source'] !== 'option' ) {
      const esn = this.sharedInfoService.currentEsnInfo && this.sharedInfoService.inputEsn;
      this.setPartsCatalogView(esn || ' ', 'esnViewUpdate');
    }
   
    if (this.sharedInfoService.currentEsnInfo.esn ||  (state.url == '/esn-entry/main/find-esn' || state.url === '/esn-entry/main/esn-error' || state.url === '/esn-entry/npbumain/esn-error') 
        || state.url.startsWith('/esn-entry/main/kits') || 
        state.url.startsWith('/esn-entry/main/gasket-set') 
        || state.url.startsWith('/esn-entry/main/part-details/') ||
        state.url.startsWith('/esn-entry/main/option-details/')
        || ((state.url.startsWith('/esn-entry/main/search') || state.url.startsWith('/esn-entry/npbumain/search')) && !state.url.startsWith('/esn-entry/main/search/inner'))){
       if((state.url.startsWith('/esn-entry/main/kits') || state.url.startsWith('/esn-entry/main/gasket-set')
        || state.url.startsWith('/esn-entry/main/option-details/')) && !this.sharedInfoService.currentOption) {
         this.router.navigate(['/esn-entry/main']);
         return false;
       }
       return true;
    } else{
         this.router.navigate(['/esn-entry/main']);
        return false;
    }
  }

  private setPartsCatalogView(data: any, viewString: string) {
    setTimeout(() => {
        
        this.notificationService.setGlobalvalue(data);
        this.notificationService.setType = viewString;
        this.notificationService.notificationEBUUpdated(data);
    }, 0);
}

}
