import { ResourceLoaderService, ScriptModel } from "../../resource-loader.service";

export abstract class PartServiceAbstract {
    private scriptsLoaded: boolean = false;

    constructor(protected _resourceLoader: ResourceLoaderService) { }

    public loadModelerScripts() {
        if (!this.scriptsLoaded) {
            try {
                this._resourceLoader.loadResource({ src: 'assets/js/jsmodeler.js', type: 'script' })
                    .flatMap(_ => this._resourceLoader.loadResource({ src: 'assets/js/three.min.js', type: 'script' }))
                    .flatMap(_ => this._resourceLoader.loadResource({ src: 'assets/js/jsmodeler.ext.three.js', type: 'script' }))
                    .subscribe(_ => {
                        this._resourceLoader.loadResource({ src: 'assets/js/script.js', type: 'script' }).subscribe();
                        this.scriptsLoaded = true;
                    }, error => console.error(error));
            } catch (error) {
                console.error(error);
            }
        }
    }
}
