<div class="container content vert-offset-top-1">
    <div class="row">
        <div class="col-md-12">


            <!--<label>Please <a  [routerLink]="['/order-summary/main/order-details', order_id]">click here</a>To view more details for the order.</label> -->

            <h3>Ticket Confirmation</h3>

            <p>The ticket has been successfully submitted!. Your ticket number is <a [href]="('/feedback/status.php?ticketid=' + ticket_id) | resource: 'QSSERVICES'">{{ticket_id}}</a>
            </p>

            <p>A confirmation email has been sent to the contact person, and the appropriate support staff has been
                notified.
                Your ticket should be worked on shortly.</p>

            <p>Thank you for your feedback submission!</p>
        </div>
    </div>
</div>