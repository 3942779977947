import { NgModule } from '@angular/core';

import { MatTabsModule, MatTableModule, MatFormFieldModule, MatPaginatorModule, MatInputModule,
  MatSortModule, MatDialogModule, MatOptionModule, MatSelectModule,
  MatCheckboxModule, MatDatepickerModule, MatNativeDateModule, DateAdapter,
  MatButtonModule, MatAutocompleteModule, MatRadioModule, MatSliderModule, MatSlideToggleModule,
  MatMenuModule, MatSidenavModule, MatToolbarModule, MatListModule, MatGridListModule, MatCardModule,
  MatStepperModule, MatExpansionModule, MatButtonToggleModule, MatChipsModule, MatIconModule,
  MatProgressSpinnerModule, MatProgressBarModule, MatTooltipModule, MatSnackBarModule,
  MatDividerModule} from '@angular/material';
  // import { MaterialMenuComponent } from './material-menu/material-menu.component';
@NgModule({
  imports: [
    MatTabsModule, MatTableModule, MatFormFieldModule, MatPaginatorModule, MatInputModule, MatSortModule,
    MatDialogModule, MatOptionModule, MatSelectModule, MatCheckboxModule, MatNativeDateModule,
    MatDatepickerModule, MatButtonModule, MatAutocompleteModule, MatRadioModule, MatSliderModule,
    MatSlideToggleModule, MatMenuModule, MatSidenavModule, MatToolbarModule, MatListModule,
    MatGridListModule, MatCardModule, MatStepperModule, MatExpansionModule, MatButtonToggleModule,
    MatChipsModule, MatIconModule, MatProgressSpinnerModule, MatProgressBarModule, MatTooltipModule,
    MatSnackBarModule,  MatDividerModule
  ],
  declarations: [],
  exports: [
    MatTabsModule, MatTableModule, MatFormFieldModule, MatPaginatorModule, MatInputModule, MatSortModule,
    MatDialogModule, MatOptionModule, MatSelectModule, MatCheckboxModule, MatNativeDateModule,
    MatDatepickerModule, MatButtonModule, MatAutocompleteModule, MatRadioModule, MatSliderModule,
    MatSlideToggleModule, MatMenuModule, MatSidenavModule, MatToolbarModule, MatListModule,
    MatGridListModule, MatCardModule, MatStepperModule, MatExpansionModule, MatButtonToggleModule,
    MatChipsModule, MatIconModule, MatProgressSpinnerModule, MatProgressBarModule, MatTooltipModule,
    MatSnackBarModule,  MatDividerModule

  ]
})
export class AngularMaterialModule { }
