import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ExtendPrototype } from '../state/core';

@Component({
  selector: 'app-ticket-complete',
  templateUrl: './ticket-complete.component.html',
  styleUrls: ['./ticket-complete.component.css']
})
@ExtendPrototype({
  persistStateOnRefresh: true
})
export class TicketCompleteComponent implements OnInit {
  public ticket_id = 0;

  constructor(route: ActivatedRoute) {
    route.params.subscribe(params => {
      this.ticket_id = params['ticketId'];
    });
  }

  ngOnInit() {
  }
}
