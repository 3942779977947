var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient } from '@angular/common/http';
import { InjectionToken, Injector } from '@angular/core';
import { of } from 'rxjs';
import { catchError, first, map, retryWhen, scan, shareReplay } from 'rxjs/operators';
import { DI } from 'src/app/state/core';
import { AppSettings } from 'src/app/app.settings';
import * as i0 from "@angular/core";
export var FEATURE_FUNCTIONS = new InjectionToken('FEATURE_FUNCTIONS');
var ɵ0 = function () { return HttpClient; };
var FeatureChecker = /** @class */ (function (_super) {
    __extends(FeatureChecker, _super);
    function FeatureChecker(_injector, featureFunctions) {
        var _this = _super.call(this, _injector) || this;
        _this.featureFunctions = new Map((featureFunctions || []).map(function (featureFunction) {
            var prototype = Object.getPrototypeOf(featureFunction).constructor;
            if (!prototype.reference) {
                throw new Error('Feature Function should bind reference value.');
            }
            return [prototype.reference, featureFunction];
        }));
        return _this;
    }
    FeatureChecker.prototype.applyFeature = function (featureName) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            var siteFeatures, feature, featureFunction;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.refreshSiteFeatures()];
                    case 1:
                        siteFeatures = _a.sent();
                        feature = siteFeatures.get(featureName);
                        if (feature) {
                            featureFunction = this.featureFunctions.get(feature.reference);
                            if (!featureFunction) {
                                console.warn('FeatureFunction Should be valid to apply feature.');
                                return [2 /*return*/, Promise.resolve(null)];
                            }
                            return [2 /*return*/, featureFunction.apply.apply(featureFunction, [feature].concat(args))];
                        }
                        console.warn("Feature reference / name is invalid.");
                        return [2 /*return*/, Promise.resolve(null)];
                }
            });
        });
    };
    FeatureChecker.prototype.refreshSiteFeatures = function () {
        return __awaiter(this, void 0, void 0, function () {
            var emptyMap_1;
            return __generator(this, function (_a) {
                if (!this.siteFeatures$) {
                    emptyMap_1 = new Map();
                    this.siteFeatures$ = this.http.get(AppSettings.GATEWAY_BASE_URL + "/public/api/getFeatures").pipe(retryWhen(function (errors) { return errors.pipe(scan(function (count, error) {
                        if (count >= FeatureChecker.RETRY_LIMIT && (error.status !== 403 || error.status !== 401)) {
                            console.error(error.message, error);
                            throw error;
                        }
                        return count + 1;
                    }, 1)); }), map(function (siteFeatures) {
                        return new Map(siteFeatures.map(function (feature) { return [feature.name, feature]; }));
                    }), catchError(function (_) { return of(emptyMap_1); })).pipe(shareReplay());
                }
                return [2 /*return*/, this.siteFeatures$.pipe(first()).toPromise()];
            });
        });
    };
    FeatureChecker.RETRY_LIMIT = 3;
    FeatureChecker.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FeatureChecker_Factory() { return new FeatureChecker(i0.ɵɵinject(i0.INJECTOR), i0.ɵɵinject(FEATURE_FUNCTIONS, 8)); }, token: FeatureChecker, providedIn: "root" });
    __decorate([
        DI.Inject(ɵ0),
        __metadata("design:type", HttpClient)
    ], FeatureChecker.prototype, "http", void 0);
    return FeatureChecker;
}(DI.AutoDependencyInjector));
export { FeatureChecker };
export { ɵ0 };
