import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Injector, Optional } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, first, map, retryWhen, scan, shareReplay } from 'rxjs/operators';
import { DI } from 'src/app/state/core';
import { AppSettings } from 'src/app/app.settings';
import { FeatureFunction } from './feature-function';

export interface Feature {
    name: string;
    type: string;
    reference: string;
    metadata1: string,
    metadata2: string
}

export const FEATURE_FUNCTIONS = new InjectionToken<FeatureFunction<any>[]>('FEATURE_FUNCTIONS');

@Injectable({ providedIn: 'root' })
export class FeatureChecker extends DI.AutoDependencyInjector {
    private static readonly RETRY_LIMIT = 3;

    private readonly featureFunctions: Map<string, FeatureFunction<any>>;

    private siteFeatures$: Observable<Map<String, Feature>>;

    @DI.Inject(() => HttpClient)
    private http: HttpClient;

    constructor(_injector: Injector, @Optional() @Inject(FEATURE_FUNCTIONS) featureFunctions: FeatureFunction<any>[]) {
        super(_injector);

        this.featureFunctions = new Map((featureFunctions || []).map(featureFunction => {
            const prototype = Object.getPrototypeOf(featureFunction).constructor;
            if (!prototype.reference) {
                throw new Error('Feature Function should bind reference value.');
            }
            return [<string>prototype.reference, featureFunction];
        }));
    }

    public async applyFeature<T>(featureName: string, ...args: any[]): Promise<T> {
        const siteFeatures = await this.refreshSiteFeatures();

        const feature = siteFeatures.get(featureName);
        if (feature) {
            const featureFunction = this.featureFunctions.get(feature.reference);
            if (!featureFunction) {
                console.warn('FeatureFunction Should be valid to apply feature.')
                return Promise.resolve(null);
            }
            return featureFunction.apply(feature, ...args);
        }

        console.warn("Feature reference / name is invalid.");
        return Promise.resolve(null);
    }

    public async refreshSiteFeatures(): Promise<Map<String, Feature>> {
        if (!this.siteFeatures$) {
            const emptyMap = new Map();
            this.siteFeatures$ = this.http.get<Feature[]>(`${AppSettings.GATEWAY_BASE_URL}/public/api/getFeatures`).pipe(
                retryWhen(errors => errors.pipe(
                    scan((count, error) => {
                        if (count >= FeatureChecker.RETRY_LIMIT && (error.status !== 403 || error.status !== 401)) {
                            console.error(error.message, error);
                            throw error;
                        }
                        return count + 1;
                    }, 1)
                )),
                map(siteFeatures => {
                    return new Map(siteFeatures.map(feature => [feature.name, feature]));
                }),
                catchError(_ => of(emptyMap))
            ).pipe(shareReplay());
        }
        return this.siteFeatures$.pipe(first()).toPromise();
    }
}
