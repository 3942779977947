
import {throwError as observableThrowError,  Observable ,  Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';

import { AppSettings } from '../../app.settings';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class HeaderService {

    public globalSearchUrl: string;

    private globalSearchObserver: Subject<any>;

    private globalSearchNotifier: Observable<any>;

    public constructor(private http: HttpClient) {
        this.globalSearchObserver = new Subject<any>();
        this.globalSearchNotifier = this.globalSearchObserver.asObservable();
        this.globalSearchUrl = `${AppSettings.API_ENDPOINTS.get('IACDataServices')}/protected/searchService/global?criteria=`;
    }

    public updateSearchInput(input: string) {
        this.globalSearchObserver.next({input: input, func: "input"});
    }

    public updateSearchView(input: string) {
        this.globalSearchObserver.next({input: input, func: "view"});
    }

    public registerSearchUpdates(callack: any) {
        return this.globalSearchNotifier.subscribe(callack);
    }

    public globalSearch(searchText: string): Promise<any> {
        return this.http.get(`${this.globalSearchUrl}${searchText}`)
                        .toPromise()
                        .then((res:Response) => res);
    }

    private handleError(error: Response) {
        console.error(error);
        return observableThrowError(error || 'Server Error');
    }

}