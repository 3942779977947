import { Component, Injector } from '@angular/core';
import { ErrorShared } from './ErrorShared';
import { Router } from '@angular/router';
import { NotificationService } from '../shared/header/notification.service';
import { SharedInfoService } from '../partsCatalog/sharedInfo.service';
import { DI } from '../state/core';

@Component({
    selector: 'iac-error',
    templateUrl: 'error.html',
    styles: [
        `
            .link {
                font-size: 10pt;
                text-decoration: underline;
                color: blue;
                cursor: pointer
            }
            .link:hover {
                text-decoration: none;
            }
        `
    ]
})
export class ErrorComponent extends DI.AutoDependencyInjector {
    @DI.Inject(() => ErrorShared)
    public errorInfo: ErrorShared;

    @DI.Inject(() => SharedInfoService)
    public sharedInfoService: SharedInfoService;

    @DI.Inject(() => NotificationService)
    private notificationService: NotificationService;

    @DI.Inject(() => Router)
    private router: Router;

    constructor(_injector: Injector) {
        super(_injector);
    }

    public goToInfoURL() {
        this.router.navigate([this.errorInfo.infoRoute]);
    }

    public setEsn() {
        const data = this.sharedInfoService.previousESN.esn;
        const buType = (this.sharedInfoService.previousESN.mbom === 'package' ? 'package ' : '') + 'serial number';
        const esn = this.sharedInfoService.previousESN.mbom === 'package' ? this.sharedInfoService.previousESN.ebuSN.esn : undefined;
        const psn = this.sharedInfoService.previousESN.mbom !== 'package' && this.sharedInfoService.previousESN.packageSN ? this.sharedInfoService.previousESN.packageSN.esn : undefined;
        this.notificationService.setGlobalvalue(data);
        this.notificationService.setType = (buType === 'serial number' || buType === 'package serial number') ? 'esn' : buType;
        if (buType === 'serial number' && psn) {
            this.notificationService.setPackageNoValue(psn);
            this.notificationService.setIsPackageESNvalue(true);
        } else if (buType === 'package serial number' && esn) {
            this.notificationService.setPackageNoValue(esn);
            this.notificationService.setIsPackageESNvalue(false);
        }
        this.notificationService.notificationEBUUpdated(data);
        this.router.navigate(['/esn-entry/main']);
    }
}
