
        <div *ngIf="showAlt; else showImage;" [class]="(altStyleClass || '') + ' alt-image-style'">
            <img src="assets/images/about-us/cbimage.jpg" [class]="styleClass || ''" [class.text-center]="true" [ngStyle]="style"
                [alt]="alt" />
        </div>
        <ng-template #showImage>
            <div [class]="parentClass || ''" *ngIf="src">
                <ng-container *ngIf="lazyLoad; else nonLazyLoadTemplate;">
                    <img [lazyLoad]="src" [class]="styleClass || ''" [title]="title || ''" [class.text-center]="true" [ngStyle]="style"
                        [defaultImage]="'../../assets/icons/loader_icon.gif'" [offset]="100" (load)="imageLoaded($event);" [errorImage]="src" [alt]="alt" />
                </ng-container>
                <ng-template #nonLazyLoadTemplate>
                    <img [src]="src" [class]="styleClass || ''" [title]="title || ''" [class.text-center]="true" [ngStyle]="style"
                        (load)="imageLoaded($event);" (error)="handleError($event)" [alt]="alt" />
                </ng-template>
            </div>
        </ng-template>
    