import { Component, HostListener, Injector, OnDestroy, OnInit, Type } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Params } from "@angular/router";
import "rxjs/add/operator/filter";
import { IBreadcrumb } from './IBreadcrumb';
import { SessionService } from "../session-maintenance/session.service";
import { Store } from "@ngxs/store";
import { ApplicationState } from "../state/app.state";
import { combineLatest, Subscription } from "rxjs";
import { DI, getOwnProtypeExtension } from "../state/core";
import { BreadcrumbsService } from "./breadcrumbs.service";
import { PersistAppState, RestoreAppState } from "../state/app.actions";
import { RecordRouteNaviation } from "./state/router.actions";
import { first } from "rxjs/operators";

const ROUTE_DATA_BREADCRUMB: string = "breadcrumb";

@Component({
  selector: "breadcrumb",
  templateUrl: "breadcrumbs.html"
})
export class BreadcrumbComponent extends DI.AutoDependencyInjector implements OnInit, OnDestroy {
  public breadcrumbs: IBreadcrumb[];
  public show: boolean = false;

  private subscription: Subscription;

  @DI.Inject(() => ActivatedRoute)
  private activatedRoute: ActivatedRoute;

  @DI.Inject(() => Router)
  private router: Router;

  @DI.Inject(() => SessionService)
  private sessionService: SessionService;

  @DI.Inject(() => BreadcrumbsService)
  private service: BreadcrumbsService;

  @DI.Inject(() => Store)
  private store: Store;

  constructor(_injector: Injector) {
    super(_injector);
    this.store.dispatch(new RestoreAppState());
  }

  public ngOnInit() {
    this.breadcrumbs = [];

    //subscribe to the NavigationEnd event
    this.subscription = combineLatest([
      this.router.events.filter(event => event instanceof NavigationEnd),
      this.store.select(ApplicationState.popupMode)
    ]).subscribe(async ([event, popupMode]) => {
      const routeParams = await this.service.resolveActivatedRoute().params.pipe(first()).toPromise();

      //set breadcrumbs
      this.sessionService.updateSessionLastAccessedTime();
      this.store.dispatch(new RecordRouteNaviation(<NavigationEnd>event));
      let root: ActivatedRoute = this.activatedRoute.root;
      this.breadcrumbs = [
        {
          params: [],
          url: '/home',
          label: 'Home',
          passive: popupMode
        },
        ...this.getBreadcrumbs(root, popupMode).map(breadcrumb => this.parseBreadCrumb(breadcrumb, routeParams))
      ];

      if (this.breadcrumbs.length === 1) {
        this.show = false;
      } else {
        this.show = true;
      }
    });
  }

  private parseBreadCrumb(breadcrumb: IBreadcrumb, routeParams: Params): IBreadcrumb {
    if (breadcrumb.url) {
      return {
        ...breadcrumb,
        url: breadcrumb.url.replace(/:[a-z0-9_]+/ig,
          (match: string, _token: string) => routeParams[match.substring(1)] || match)
      };
    }
    return breadcrumb;
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  @HostListener('window:beforeunload')
  public beforeunloadEventHandler() {
    const component = <any>this.service.resolveComponentType(this.router.url, false);
    this.store.dispatch(new PersistAppState(getOwnProtypeExtension(component).persistStateOnRefresh));
  }

  private getBreadcrumbs(route: ActivatedRoute, popupMode: boolean, url: string = "", breadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {
    //get the child routes
    const children: ActivatedRoute[] = route.children;

    //return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }
    //iterate over each children
    for (const child of children) {
      //verify primary route
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      if (child.snapshot.data[ROUTE_DATA_BREADCRUMB] instanceof Array) {
        for (const bread of child.snapshot.data[ROUTE_DATA_BREADCRUMB]) {
          const breadCrumb: IBreadcrumb = {
            label: bread.label,
            passive: this.isPassiveBreadCrumb(bread.path, popupMode),
            params: bread.params ? bread.params : [],
            url: bread.path
          };
          breadcrumbs.push(breadCrumb);
        }
        return breadcrumbs;
      }

      //verify the custom data property "breadcrumb" is specified on the route
      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB) && !child.snapshot.data.hasOwnProperty('0')) {
        return this.getBreadcrumbs(child, popupMode, url, breadcrumbs);
      }

      //get the route's URL segment
      const routeURL: string = this.getRouteUrl(child, url);

      let bData: any;
      if (child.snapshot.data.hasOwnProperty('0')) {
        bData = child.snapshot.data['0'].breadcrumb;
      } else {
        bData = child.snapshot.data[ROUTE_DATA_BREADCRUMB];
      }

      //add breadcrumb
      const breadcrumb: IBreadcrumb = {
        label: bData,
        passive: this.isPassiveBreadCrumb(routeURL, popupMode),
        params: child.snapshot.params,
        url: routeURL
      };
      breadcrumbs.push(breadcrumb);

      //recursive
      return this.getBreadcrumbs(child, popupMode, url, breadcrumbs);
    }

    //we should never get here, but just in case
    return breadcrumbs;
  }

  private getRouteUrl(route: ActivatedRoute, url: string) {
    const routeURL: string = route.snapshot.url.map(segment => segment.path).join("/");
    if (route.parent.snapshot.url) {
      url += `${route.parent.snapshot.url}`;
    }

    return (url ? url + '/' : '') + routeURL;
  }

  private isPassiveBreadCrumb(routeUrl: string, popupMode: boolean) {
    const component = <any>this.service.resolveComponentType(routeUrl, false);
    return popupMode && !(component instanceof Type && getOwnProtypeExtension(component).popupModeVisibility);
  }
}
