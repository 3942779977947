import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { AppSettings } from "../app.settings";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SbomService = /** @class */ (function () {
    function SbomService(_httpClient) {
        this._httpClient = _httpClient;
        this._loaderEvent = new BehaviorSubject(null);
    }
    Object.defineProperty(SbomService.prototype, "loaderEvent$", {
        get: function () {
            return this._loaderEvent.filter(function (event) { return !!event; });
        },
        enumerable: true,
        configurable: true
    });
    SbomService.prototype.downloadSbom = function (_sharedService) {
        var _this = this;
        this._loaderEvent.next({ type: "pending" });
        var headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        var url = AppSettings.API_ENDPOINTS.get('IACDataServices') + "/getSbom";
        var res = _sharedService.currentEsnInfo;
        var preCheck = res.buildDate ? new Date(res.buildDate).getFullYear() : 2014;
        var sbomParameters = {
            "engineBuildDate": res.buildDate,
            "esn": res.esn
        };
        if (preCheck >= 2015) {
            if (res.kitSets) {
                sbomParameters['kitSets'] = res.kitSets;
            }
            else {
                var engineType = (res.mbom === "mbom" ? "mbom_" : (res.mbom === "package" ? "package_" : "")) + "options";
                sbomParameters['optionList'] = res.optionList;
                sbomParameters['cplNbr'] = res.cpl;
                sbomParameters['enginePlantCode'] = res.enginePlantCode;
                sbomParameters['engineType'] = engineType;
            }
            if (res.gasketSets) {
                sbomParameters['gasketSets'] = res.gasketSets;
            }
            else {
                sbomParameters['configuration'] = res.marketingConfig;
            }
        }
        this._httpClient.post(url, JSON.stringify(sbomParameters), { headers: headers, responseType: 'blob' }).toPromise()
            .then(function (blob) {
            if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob, 'serviceBOM.xlsx');
            }
            else {
                var url_1 = window.URL.createObjectURL(blob);
                var anchor = document.createElement('a');
                anchor.href = url_1;
                anchor.style.visibility = 'hidden';
                anchor.download = 'serviceBOM.xlsx';
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
            }
            _this._loaderEvent.next({ type: "done" });
        }).catch(function (errorResponse) {
            var reader = new FileReader();
            reader.onload = function (e) {
                var error = JSON.parse(e.target.result);
                _this._loaderEvent.next({ type: "error", message: error });
            };
            reader.readAsText(errorResponse._body);
        });
    };
    SbomService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SbomService_Factory() { return new SbomService(i0.ɵɵinject(i1.HttpClient)); }, token: SbomService, providedIn: "root" });
    return SbomService;
}());
export { SbomService };
