var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Injector } from '@angular/core';
import { AppSettings } from '../app.settings';
import { SharedInfoService } from '../partsCatalog/sharedInfo.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginSharedService } from './login-shared.service';
import { Store } from '@ngxs/store';
import { ApplicationState } from '../state/app.state';
import { flatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { DI } from '../state/core';
var ɵ0 = function () { return HttpClient; }, ɵ1 = function () { return SharedInfoService; }, ɵ2 = function () { return LoginSharedService; }, ɵ3 = function () { return Store; };
var LoginService = /** @class */ (function (_super) {
    __extends(LoginService, _super);
    function LoginService(_injector) {
        return _super.call(this, _injector) || this;
    }
    LoginService.prototype.provideUserConsent = function () {
        var headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        var loginInfo = this.loginShared.loginInfo;
        return this._http.post(AppSettings.AUTH_ENDPOINT + "/userconsent", JSON.stringify({ q: true }), { headers: headers }).toPromise()
            .then(function (res) { return Object.assign({}, res, { mergeCartItems: loginInfo.mergeCartItems || false }); });
    };
    LoginService.prototype.saveRequest = function () {
        var url = AppSettings.AUTH_ENDPOINT + "/saveRedirect/1";
        var headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this._http.get(url, { headers: headers, responseType: 'text' }).toPromise();
    };
    LoginService.prototype.authenticate = function (credentials, accept) {
        var url = AppSettings.AUTH_ENDPOINT + "/authenticate";
        if (accept) {
            url += '?accept=' + accept;
        }
        return this._http.get(url, { headers: new HttpHeaders({ 'Authorization': btoa(credentials.user + ":" + credentials.pass) }) }).toPromise();
    };
    LoginService.prototype.saveESNMDB = function (data) {
        var _this = this;
        var headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        var url = AppSettings.API_ENDPOINTS.get('IACDataServices') + "/storeEsnData?esn=" + data.esn + "&smn=" + data.smn + "&esnGraphic=" + data.esnGraphic;
        return this._http.post(url, JSON.stringify(data), { headers: headers })
            .toPromise()
            .then(function (res) {
            if (res != null) {
                var esn = res;
                if (_this.sharedInfoService.savedESNs) {
                    if (_this.sharedInfoService.savedESNs.length === 5)
                        _this.sharedInfoService.savedESNs.pop();
                    _this.sharedInfoService.savedESNs.unshift(esn);
                }
            }
            return _this.getEsnData();
        });
    };
    LoginService.prototype.getESNMDB = function () {
        var _this = this;
        if (this.sharedInfoService.savedESNs)
            return new Promise(function (resolve) { return resolve(_this.getEsnData()); });
        var _url = AppSettings.API_ENDPOINTS.get('IACDataServices') + "/protected/getEsnData";
        return this.store.selectOnce(ApplicationState.popupMode)
            .pipe(flatMap(function (popupMode) { return popupMode ? of({}) : _this._http.get(_url); }))
            .toPromise().then(function (res) {
            _this.sharedInfoService.savedESNs = res.esnCollection;
            return _this.getEsnData();
        });
    };
    LoginService.prototype.getEsnData = function () {
        var esnArray = this.sharedInfoService.savedESNs || [];
        return {
            collection: esnArray,
            hasFavorite: function (esn) {
                for (var _i = 0, esnArray_1 = esnArray; _i < esnArray_1.length; _i++) {
                    var esnElement = esnArray_1[_i];
                    if (esnElement.esn === esn)
                        return true;
                }
                return false;
            }
        };
    };
    LoginService.prototype.removeESNMDB = function (esn) {
        var _this = this;
        var url = AppSettings.API_ENDPOINTS.get('IACDataServices') + "/protected/removeEsnData?esn=" + esn;
        return this._http.get(url)
            .toPromise().then(function (res) {
            var deleted = res;
            if (deleted) {
                _this.sharedInfoService.savedESNs = _this.sharedInfoService.savedESNs.filter(function (esnInfo) { return esnInfo.esn !== esn; });
            }
            return deleted;
        });
    };
    __decorate([
        DI.Inject(ɵ0),
        __metadata("design:type", HttpClient)
    ], LoginService.prototype, "_http", void 0);
    __decorate([
        DI.Inject(ɵ1),
        __metadata("design:type", SharedInfoService)
    ], LoginService.prototype, "sharedInfoService", void 0);
    __decorate([
        DI.Inject(ɵ2),
        __metadata("design:type", LoginSharedService)
    ], LoginService.prototype, "loginShared", void 0);
    __decorate([
        DI.Inject(ɵ3),
        __metadata("design:type", Store)
    ], LoginService.prototype, "store", void 0);
    return LoginService;
}(DI.AutoDependencyInjector));
export { LoginService };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
