import { Injectable } from '@angular/core';
import { Cart } from '../cart/cart'

@Injectable()
export class CartInfoService {
 
    public picklist:Cart;
   
    constructor() {
        //this.picklist = new Cart(null,null,null,null,null,null,null,null,null,null);
    }
    
    public setCartObj(cart: Cart): void {
        this.picklist = cart;
    }
    
    public getCartObj(): Cart {
        return this.picklist;
    }
    
    
}
