var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { getActionTypeFromInstance } from "@ngxs/store";
import { PersistAppState, RestoreAppState } from "src/app/state/app.actions";
import { StateStorageService } from "../state-storage.service";
import { DI } from "./core";
export var NGXS_STORE_STATE_KEY = 'IAC-Store-State';
var ɵ0 = function () { return StateStorageService; };
var PesistStateOnPageRefreshPlugin = /** @class */ (function (_super) {
    __extends(PesistStateOnPageRefreshPlugin, _super);
    function PesistStateOnPageRefreshPlugin(_injector) {
        var _this = _super.call(this, _injector) || this;
        _this.default = { router: { url: '/' } };
        return _this;
    }
    PesistStateOnPageRefreshPlugin.prototype.handle = function (state, action, next) {
        var actionType = getActionTypeFromInstance(action);
        if (actionType === RestoreAppState.type) {
            var storeState = __assign({}, this.default, this.stateStorage.fetchState());
            if (storeState.router.url === this.routeUrl) {
                state = __assign({}, state, storeState.state);
            }
            this.stateStorage.clearState();
        }
        else if (actionType === PersistAppState.type && action.persist) {
            var storeState = {
                router: { url: this.routeUrl },
                state: __assign({}, state)
            };
            this.stateStorage.persistState(storeState);
        }
        return next(state, action);
    };
    Object.defineProperty(PesistStateOnPageRefreshPlugin.prototype, "routeUrl", {
        get: function () {
            return location.pathname + location.search;
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        DI.Inject(ɵ0),
        __metadata("design:type", StateStorageService)
    ], PesistStateOnPageRefreshPlugin.prototype, "stateStorage", void 0);
    return PesistStateOnPageRefreshPlugin;
}(DI.AutoDependencyInjector));
export { PesistStateOnPageRefreshPlugin };
export { ɵ0 };
