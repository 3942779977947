var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Injector } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { Store } from '@ngxs/store';
import { EngineBOM } from 'src/app/partsCatalog/state/engine-bom.actions';
import { DI } from 'src/app/state/core';
var ɵ0 = function () { return Store; };
var NotificationService = /** @class */ (function (_super) {
    __extends(NotificationService, _super);
    function NotificationService(_injector) {
        var _this = _super.call(this, _injector) || this;
        _this.setGlobal = '';
        _this.setType = '';
        _this.setBUType = '';
        _this.setVinEsn = '';
        _this.setRoute = '';
        _this.setPackageNo = '';
        _this.isPackageESN = false;
        _this.setBu = "";
        _this.notifications = new Subject();
        _this.notifications$ = _this.notifications.asObservable();
        _this.translateInfo = [];
        _this.saveEsnData = [];
        _this.notificationtranslate = new BehaviorSubject('en');
        _this.notificationTranslateView$ = _this.notificationtranslate.asObservable();
        _this.notificationEBUSearch = new BehaviorSubject('');
        _this.notificationEBUSearchView$ = _this.notificationEBUSearch.asObservable().filter(function (value) { return !!value; });
        _this.notificationPGBUSearch = new BehaviorSubject('');
        _this.notificationPGBUSearch$ = _this.notificationPGBUSearch.asObservable();
        _this.notificationCESSearch = new BehaviorSubject('');
        _this.notificationCESSearch$ = _this.notificationCESSearch.asObservable();
        _this.notificationCTTSearch = new BehaviorSubject('');
        _this.notificationCTTSearch$ = _this.notificationCTTSearch.asObservable();
        return _this;
    }
    NotificationService.prototype.sharedInfoInstance = function (_sharedInfoService) {
        this._sharedInfoService = _sharedInfoService;
    };
    NotificationService.prototype.getSharedInfoInstance = function () {
        return this._sharedInfoService;
    };
    NotificationService.prototype.getRoute = function () {
        var route = this.setRoute;
        this.setRoute = null;
        return route;
    };
    NotificationService.prototype.powerGenSharedInfoInstance = function (_PowerGensharedInfoService) {
        this._PowerGensharedInfoService = _PowerGensharedInfoService;
    };
    NotificationService.prototype.clearSharedInfoInstance = function (field) {
        if (!this._sharedInfoService)
            return;
        var data = {};
        for (var key in this._sharedInfoService[field])
            data[key] = undefined;
        if (field === 'currentEsnInfo') {
            this.store.dispatch(new EngineBOM.SetESNInfo(data));
        }
        else {
            this._sharedInfoService[field] = data;
        }
    };
    NotificationService.prototype.setGlobalvalue = function (val) {
        this.setGlobal = val;
    };
    NotificationService.prototype.getGlobalValue = function () {
        return this.setGlobal;
    };
    NotificationService.prototype.setPackageNoValue = function (val) {
        this.setPackageNo = val;
    };
    NotificationService.prototype.getPackageNoValue = function () {
        return this.setPackageNo;
    };
    NotificationService.prototype.setIsPackageESNvalue = function (val) {
        this.isPackageESN = val;
    };
    NotificationService.prototype.getIsPackageESNValue = function () {
        return this.isPackageESN;
    };
    NotificationService.prototype.clearPackageData = function () {
        this.setPackageNoValue(null);
    };
    NotificationService.prototype.notify = function (toast) {
        this.notifications.next(toast);
    };
    NotificationService.prototype.notificationtranslate1 = function (selectedLanguage) {
        this.notificationtranslate.next(selectedLanguage);
    };
    NotificationService.prototype.notificationEBUUpdated = function (value) {
        this.notificationEBUSearch.next(value);
    };
    NotificationService.prototype.notificationPGBUUpdated = function (value) {
        this.notificationPGBUSearch.next(value);
    };
    NotificationService.prototype.notificationCESUpdated = function (value) {
        this.notificationCESSearch.next(value);
    };
    NotificationService.prototype.notificationCTTUpdated = function (value) {
        this.notificationCTTSearch.next(value);
    };
    __decorate([
        DI.Inject(ɵ0),
        __metadata("design:type", Store)
    ], NotificationService.prototype, "store", void 0);
    return NotificationService;
}(DI.AutoDependencyInjector));
export { NotificationService };
export { ɵ0 };
