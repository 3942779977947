<div class="container-fluid vert-offset-top-2 homepage-container">
    <div class="row eight-cols">
        <div class="col-md-1 col-sm-6" *ngFor="let component of components; let  i = index">
            <a (click)="navigate(component, '_blank')" style="cursor: pointer;">
                <div class="thumbnail clearfix ">
                    <background-xhr-image [background]="getAssetsLink('/images/homepage/components_sprite_8.png')" [map]="componentsMap" [sprite]="component.position" 
                        styleClass="img-responsive" [style]="{padding: '50px 0', margin: '0 12.5%', width: (i === components.length - 1) ? '100px' : '150px'}">
                    </background-xhr-image>
                    <div class="caption text-center">
                        <b>{{ component.name | translate }}</b>
                    </div>
                </div>
            </a>
        </div>
    </div>
</div>
<span class="clearfix"></span>
<div class="container-fluid ">
    <div class="row">
        <div id="carousel-example-generic" class="carousel slide " data-ride="carousel">
            <ol class="carousel-indicators">
                <li *ngFor="let banner of banners; let i = index;" [attr.data-target]="'#carousel-example-generic'" [attr.data-slide-to]="i" [class.active]="i === 0"></li>
            </ol>
            
            <div class="carousel-inner homeCarousel ">
                <div class="item" [class.active]="i === 0" *ngFor="let banner of banners; let i = index">

                    <a *ngIf="banner.href!=null" [href]="banner.href" target="_blank">
                        <background-xhr-image [background]="getAssetsLink('/images/homepage/banners/' + getEffectiveLanguage(banner.name) + '/banner_sprite.png', banner.position)" [title]=banner.name [sprite]="getPosition(banner.position)" [map]="getBannerMap(banner.name)" [style]="{padding: '14% 0'}">
                        </background-xhr-image>
                    </a>
                    <a *ngIf="banner.href==null" [routerLink]="banner.link">
                        <background-xhr-image [background]="getAssetsLink('/images/homepage/banners/' + getEffectiveLanguage(banner.name) + '/banner_sprite.png', banner.position)" [title]=banner.name [sprite]="getPosition(banner.position)" [map]="getBannerMap(banner.name)" [style]="{padding: '14% 0'}">
                        </background-xhr-image>
                    </a>
                </div>
            </div>
            <a class="left carousel-control" href="#carousel-example-generic" data-slide="prev">
                <span class="glyphicon glyphicon-chevron-left"></span>
            </a>
            <a class="right carousel-control" href="#carousel-example-generic" data-slide="next">
                <span class="glyphicon glyphicon-chevron-right"></span>
            </a>
        </div>
    </div>

</div>