/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ticket-complete.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared-components/env-resource.pipe";
import * as i3 from "./ticket-complete.component";
import * as i4 from "@angular/router";
var styles_TicketCompleteComponent = [i0.styles];
var RenderType_TicketCompleteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TicketCompleteComponent, data: {} });
export { RenderType_TicketCompleteComponent as RenderType_TicketCompleteComponent };
export function View_TicketCompleteComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.EnvironmentResourcePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "container content vert-offset-top-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 11, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Ticket Confirmation"])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["The ticket has been successfully submitted!. Your ticket number is "])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "a", [], [[8, "href", 4]], null, null, null, null)), i1.ɵppd(9, 2), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["A confirmation email has been sent to the contact person, and the appropriate support staff has been notified. Your ticket should be worked on shortly."])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Thank you for your feedback submission!"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v, 0), ("/feedback/status.php?ticketid=" + _co.ticket_id), "QSSERVICES")); _ck(_v, 8, 0, currVal_0); var currVal_1 = _co.ticket_id; _ck(_v, 10, 0, currVal_1); }); }
export function View_TicketCompleteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ticket-complete", [], null, null, null, View_TicketCompleteComponent_0, RenderType_TicketCompleteComponent)), i1.ɵdid(1, 114688, null, 0, i3.TicketCompleteComponent, [i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TicketCompleteComponentNgFactory = i1.ɵccf("app-ticket-complete", i3.TicketCompleteComponent, View_TicketCompleteComponent_Host_0, {}, {}, []);
export { TicketCompleteComponentNgFactory as TicketCompleteComponentNgFactory };
