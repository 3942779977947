import { Injectable, InjectionToken, Injector } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import { DI, Store } from "src/app/state/core";
import { RecordRouteNaviation } from "./router.actions";

const ROUTE_HISTORY_DEFAULT_LIMIT = 10;
export const ROUTE_HISTORY_LIMIT = new InjectionToken<number>('ROUTE_HISTORY_LIMIT');

export interface RouterStateModel {
    breadcrumbs: string[];
}

@Injectable()
@State<RouterStateModel>({
    name: 'routerEvents',
    defaults: {
        breadcrumbs: []
    }
})
export class RouterState extends DI.AutoDependencyInjector {
    @DI.Inject(() => ROUTE_HISTORY_LIMIT, { defaultValue: ROUTE_HISTORY_DEFAULT_LIMIT })
    private routeHistoryLimit: number;

    constructor(_injector: Injector) {
        super(_injector);
    }

    @Action(RecordRouteNaviation)
    recordRouteNaviationEvent(ctx: StateContext<RouterStateModel>, { event }: RecordRouteNaviation) {
        const state = ctx.getState().breadcrumbs;
        const routeUrl = event.urlAfterRedirects || event.url;
        if (state[state.length - 1] === routeUrl) return;

        const breadcrumbs = [...state];
        breadcrumbs.push(routeUrl);
        while (breadcrumbs.length > this.routeHistoryLimit) {
            breadcrumbs.shift();
        }
        ctx.patchState({ breadcrumbs });
    }

    static getBreadCrumbsTrail(size: number = 5) {
        return Store.createSelector([RouterState], (state: RouterStateModel) => {
            const breadcrumbs = [...state.breadcrumbs];
            return breadcrumbs.slice(-size).reverse();
        });
    }
}
