import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";
import { share } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class NotificationService {

    public loadingEvent: Subject<LoadingEvent>;
    public loading$: Observable<LoadingEvent>;

    constructor() {
        this.loadingEvent = new Subject<LoadingEvent>();
        this.loading$ = this.loadingEvent.asObservable().pipe(share());
    }

}

export interface LoadingEvent {
    showLoading: boolean;
    loadingMessage?: string;
}