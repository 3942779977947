import { environment } from "../environments/environment";

export class AppSettings {

    public static HOST_NAME = ((): string => {
        return !environment.production ? `http://localhost` : `https://${window.location.hostname}`;
    })();
    public static HOST_PORT = ((): string => {
        return !environment.production ? `:3000` : ``;
    })();
    public static GATEWAY_BASE_URL = `${AppSettings.HOST_NAME}${environment.AUTH_PORT}/gateway`;
    public static AUTH_ENDPOINT = `${AppSettings.GATEWAY_BASE_URL}/auth`;
    public static QUICK_SERVE = environment.QSOL;
    public static QUICK_SERVE_LOGIN = environment.QSOL_LOGIN;
    public static PUBLICATIONS_STORE = environment.STORE;

    public static AUTH_API_ENDPOINTS = {
        API_PORTS: environment.API_PORTS,
        get: (resource: string) => {
            if (AppSettings.API_ENDPOINTS.API_PORTS[resource]) return `${AppSettings.HOST_NAME}:${AppSettings.API_ENDPOINTS.API_PORTS[resource]}/${resource}`;
            else return `${AppSettings.HOST_NAME}/${resource}`;
        }
    };

    public static API_ENDPOINTS = {
        API_PORTS: environment.API_PORTS,
        get: (resource: string) => {
            if (AppSettings.API_ENDPOINTS.API_PORTS[resource]) return `${AppSettings.HOST_NAME}:${AppSettings.API_ENDPOINTS.API_PORTS[resource]}/gateway/api/${resource}`;
            else return `${AppSettings.HOST_NAME}/gateway/api/${resource}`;
        }
    };

    public static API_ENDPOINTS_FOR_FILE = {
        get: (resource: string) => {
            return `/gateway/api/${resource}`;
        }
    }

    public static CUMPASS_PACCAR_URL = `${environment.CUMPASS_PACCAR_URL}`;
    public static CUMPASS_URL = `${environment.CUMPASS_URL}`;
    public static ADMIN_VIEW = `${environment.ADMIN_VIEW || (AppSettings.HOST_NAME + '/admin')}`;
    public static CMS_BLOB_NAME = `${environment.CMS_BLOB_NAME}`;
    public static CMS_PROTECTED_BLOB_NAME = `${environment.CMS_PROTECTED_BLOB_NAME}`;
    public static CMS_GRAPHICS = `${environment.CMS_GRAPHICS}`;
    public static CMS_IMAGE_ROOT = `${environment.CMS_IMAGE_ROOT}`;
    public static CMS_TEMPLATE_ROOT = `${environment.CMS_TEMPLATE_ROOT}`;
    public static CMS_FILES_ROOT = `${environment.CMS_FILES_ROOT}`;
    public static CMS_APP_NAME = `${environment.CMS_APP_NAME}`;
    public static CMS_DOCTYPE = `${environment.CMS_DOCTYPE}`;
}


