import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/Subject';

@Injectable()
export class MSTSharedService {

    public partNumber:string;
    public advancedSearchCritera : any;
    public kitPart:any;
    public kitContents:any[];
    
    advancedSearchMSTView = new Subject<any>();
    advancedSearchMSTView$ = this.advancedSearchMSTView.asObservable();

    toolsPrintView = new Subject<any>();
    toolsPrintView$ = this.toolsPrintView.asObservable();

    toolDetailView = new Subject<any>();
    toolDetailView$ = this.toolDetailView.asObservable();

    kitView = new Subject<any>();
    kitView$ = this.kitView.asObservable();

    public advancedSearchMSTViewUpdated(): void {
        this.advancedSearchMSTView.next();
    }

    public printViewUpdated(): void {
        this.toolsPrintView.next();
    }

    public toolDetailViewUpdated(): void {
        this.toolDetailView.next();
    }

    public kitViewUpdated(): void {
        this.kitView.next();
    }
}