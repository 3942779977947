import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { OptionDetailComponent } from './optionDetail.component';
import { FormsModule } from '@angular/forms';
//import { GasketComponent }    from './gaskets.component';
//import { KitsComponent }    from './kits.component';
import { PartDetailComponent } from './partDetail/partDetail.component';
import { PartDetailApiService } from './partDetail/partDetail-api.service';
import { PartApiService } from './part-api.service';
// import { CarouselModule } from 'ngx-bootstrap';
import { TreeTableModule } from './CustomTreeView';
import { AccordionModuleCustom } from './accordion';
import { DateAdapter, MatDateFormats, MatDatepickerModule, MatTabsModule, MAT_DATE_FORMATS } from '@angular/material';
import { ReversePipe } from './partDetail/partDetail-pipe';
import { IndexOfPipe } from './partDetail/indexOf.pipe';
import { CartSharedModule } from "../cartShared/cartShared.module";
import { DialogModule } from 'primeng/primeng';
import { CarouselModule as CarouselModuleNG } from 'primeng/primeng';
import { TabViewModule } from 'primeng/primeng';
import { ResponsiveModule } from 'ngx-responsive';
import { SwiperModule } from 'angular2-useful-swiper';
import { TranslateModule } from '@ngx-translate/core';
import { TitleCasePipe } from './title-case.pipe';
import { MatProgressBarModule } from '@angular/material';
import { MatProgressSpinnerModule } from '@angular/material';
import { SharedModule } from '../shared.module';
import {CheckboxModule,RadioButtonModule} from 'primeng/primeng';
import { DataTableModule } from '../optionView/dataTableCustom';
import { CustomDateAdapter } from './date-adapter';
import { AgmCoreModule } from '@agm/core';

const DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: null,
  },
  display: {
    dateInput: 'MMM dd, y',
    monthYearLabel: 'MMM y',
    dateA11yLabel: null,
    monthYearA11yLabel: null,
  },
};

@NgModule({
  imports: [MatProgressBarModule, CarouselModuleNG, TranslateModule.forChild(), SwiperModule, FormsModule, TabViewModule,
    CommonModule, TreeTableModule, AccordionModuleCustom, CartSharedModule, MatTabsModule, DialogModule,
    ResponsiveModule, MatProgressSpinnerModule, SharedModule,CheckboxModule,RadioButtonModule,DataTableModule,
    MatDatepickerModule
],
  declarations: [PartDetailComponent, OptionDetailComponent, ReversePipe, IndexOfPipe, TitleCasePipe],
  providers: [
    PartDetailApiService, PartApiService, IndexOfPipe, DatePipe,
    { provide: DateAdapter, useClass: CustomDateAdapter, deps: [DatePipe] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }
  ]
})
export class OptionDetailModule { }
