<style>
    .navbar-right .dropdown-menu li:last-child {
        border-bottom: 0;
    }
</style>
<div class="top">
    <div class="{{(popupMode$ | async) ? 'container-fluid' : 'container'}}">
        <div id="global-buttons" class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="btn-group navbar-right" role="group" aria-label="...">
                <ng-template #popupContactLink>
                    <div class="btn global-btn phone-btn">
                        <a (click)="openHelpDeskPage()">
                            <span>{{ 'Contact Us - Help Ticket' | translate }}</span>
                        </a>
                    </div>
                </ng-template>
                <ng-container *ngIf="!(popupMode$ | async); else popupContactLink;">
                    <div class="btn global-btn phone-btn hide">
                        <a href="">
                            <em class="icon-help">
                            </em>
                            <span class="hidden-xs">HELP</span>
                        </a>
                    </div>
                    <div class="btn global-btn phone-btn">
                        <a routerLink="/resource/iac/Training_Modules.html" target="_blank" rel="noopener noreferrer">
                            <em class="icon-training"></em>
                            <span class="hidden-xs"> {{ 'Training' | translate }}</span>
                        </a>
                    </div>
                    <div class="btn global-btn phone-btn">
                        <a routerLink="/resource/iac/contact-us.html">
                            <em class="icon-phone">
                            </em>
                            <span class="hidden-xs"> {{ 'Contact Us' | translate }}</span>
                        </a>
                    </div>
                    <div class="btn-group" role="group">
    
                        <div class="btn global-btn globe-btn dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                            <a href="#" (mouseenter)="mouseEnter() " (mouseleave)="mouseLeave()">
                                <img src={{lang_img}} class="  " [alt]="lang_img">
                                <span class="hidden-xs">{{ 'Languages' | translate }}</span>
                            </a>
                        </div>
                        <ul class="dropdown-menu" role="menu">
                            <li>
                                <a (click)="switchLanguage('zh')">Chinese</a>
                            </li>
                            <li>
                                <a (click)="switchLanguage('en')">English</a>
                            </li>
                            <li>
                                <a (click)="switchLanguage('fr')">French</a>
                            </li>
    
                            <li>
                                <a (click)="switchLanguage('pt-br')">Portuguese (Brazilian)</a>
                            </li>
                            <li>
                                <a (click)="switchLanguage('pt')">Portuguese (European)</a>
                            </li>
    
                            <li>
                                <a (click)="switchLanguage('ru')">Russian</a>
                            </li>
                            <li>
                                <a (click)="switchLanguage('es')">Spanish </a>
                            </li>
                        </ul>
                    </div>
                    <div class="btn global-btn phone-btn">
                        <a href="https://www.cummins.com/locations/" target="_blank" rel="noopener noreferrer">
                            <em class="icon-locator">
                            </em>
                            <span class="hidden-xs">{{ 'Service Locator' | translate }}</span>
                        </a>
                    </div>
                    <div *ngIf="accessFlags.isCMSAdmin" class="btn global-btn phone-btn">
                            <a (click)="gotoEditor()">
                                <span class="hidden-xs">CMS Editor</span>
                            </a>
                    </div>
                    <div class="btn-group" role="group" *ngIf="userId != 'Login'">
                        <div class="btn global-btn globe-btn dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                            <a href="#">
                                <span class="hidden-xs">QuickServe Online</span>
                            </a>
                        </div>
                        <ul class="dropdown-menu" role="menu" style="min-width: 140px;">
                            <li>
                                <a (click)="redirectToQsol()">Home</a>
                            </li>
                            <li>
                                <a [href]="'/qs3/portal/parts/index.html#tab_recon' | resource: 'QSOL'" target="_blank">ReCon Tools</a>
                            </li>
                        </ul>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<div class="header">
    <div class=" navbar" role="navigation">
        <div class="container-fluid">
            <div class="navbar-header">
                <div class="logo col-sm-3 col-md-3 col-lg-3">
                    <a routerLink="/" *ngIf="!(popupMode$ | async); else logo;">
                        <ng-container *ngTemplateOutlet="logo"></ng-container>
                    </a>
                    <ng-template #logo>
                        <div class="logo_img" title="Cummins">
                            <img-xhr [src]="getAssetsLink('/navigation/cummins_logo.png')" alt="logo"></img-xhr>
                        </div>
                        <div class="sub-brand">
                            <h2>{{'Genuine Parts' | translate }}</h2>
                        </div>
                    </ng-template>
                </div>

                <div class="col-lg-4 col-md-4" style="margin-top: 15px;" *ngIf="!(popupMode$ | async)">
                    <form class="form-search" id="searchForm" (ngSubmit)="goToSearch()" [formGroup]="searchForm">
                        <div class="input-group">
                            <span class="input-group-btn">
                                <button (click)="goToSearchHelp()" title="{{ 'How to Find What You Want' | translate }}?" class="btn btn-default" type="button">
                                    <em class="fa fa-question-circle" aria-hidden="true"></em>
                                </button>
                            </span>

                            <input name="criteria" [(ngModel)]="srchCriteria" id="criteria" class="form-control" placeholder="{{placeholder.title | translate}}" required autocomplete="off" formControlName="searchValue">

                            <div class="input-group-btn">
                                <span class="input-group-btn">
                                    <button type="submit" name="search" class="btn btn-default" [disabled]="searchForm?.invalid">
                                        <em *ngIf="showSearch" class="fa fa-search"></em>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </form>
                    <div *ngIf="searchForm.invalid && (searchForm.dirty || searchForm.touched)" class="error-msg">
                        <div *ngIf="searchForm.controls['searchValue'].errors.needsAplhaNumeric">
                            Input should be 3 characters long.
                        </div>
                        <div *ngIf="searchForm.controls['searchValue'].errors.needsDigit">
                            <span>Too few numbers were entered into the search.</span>
                            <span class="clearfix"></span>
                            <span>Please enter a minimum of 4 and try again.</span>
                        </div>
                    </div>

                </div>
                <div class="col-sm-4 col-md-4 col-lg-4 d-flex justify-content-between" *ngIf="accessFlags.showSurveyLink">
                    <span class="text-right" id="surveylink"
                        [innerHTML]="'survey_link.html' | cmsResource | async | sanitizeHtml"></span>
                </div>
                <div class="hidden-md hidden-sm" style="display: inline-block;">
                    <ng-template #userIdNavLabel>
                        <span class="navbar-toggle" *ngIf="isLoggedIn"
                            style="position: absolute; bottom: 0; right: 0; vertical-align: bottom;">
                            {{ userId }}</span>
                    </ng-template>
                    <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse"
                        *ngIf="!(popupMode$ | async); else userIdNavLabel;">
                        <span class="sr-only">Toggle navigation</span>
                        <span class="fa fa-bars"></span>
                    </button>
                </div>


            </div>
            <nav class="collapse navbar-collapse hidden-xs hidden-sm">
                <ul class="nav navbar-nav">
                    <ng-template #userIdLabel>
                        <li *ngIf="isLoggedIn">
                            <a [class.passive]="popupMode$ | async">{{ userId }}</a>
                        </li>
                    </ng-template>
                    <ng-container *ngIf="!(popupMode$ | async); else userIdLabel;">
                        <li>
                            <a routerLink="/">
                                <span class=""></span>{{ 'Home' | translate }}</a>
                        </li>
                        <li class="divider"></li>
                        <li class="banner-dropdown">
                            <a href="#" class="dropdown-toggle" data-toggle="dropdown">{{ 'Products' | translate }}
                                <strong class="caret"></strong>
                            </a>
                            <ul class="dropdown-menu multi-level">
                                <li>
                                    <a routerLink="/esn-entry">{{ 'Engine Parts' | translate }}</a>
                                </li>
                                <li>
                                    <a href="https://catalog.cumminsfiltration.com/catalog/CatalogSearch.do?_locale=en&_region" target="_blank"
                                        rel="noopener noreferrer">{{ 'Filtration' | translate }}</a>
                                </li>
                                <li>
                                    <a routerLink="/gsn-entry">{{ 'Power Generation' | translate }}</a>
                                </li>
                                <li>
                                    <a routerLink="/ctt-entry">{{ 'Turbochargers' | translate }}</a>
                                </li>
                                <li>
                                    <a routerLink="/esn-entry/npbu">{{ 'Electrified Power Products' | translate }}</a>
                                </li>
                                <li>
                                    <a routerLink="/atsn-entry">{{ 'Aftertreatment' | translate }}</a>
                                </li>
                                <li>
                                    <a href="{{storeUrl}}" target="_blank" rel="noopener noreferrer">{{ 'Publications' | translate }}</a>                               </li>
                                <li>
                                    <a routerLink="/mst-entry/main/home-page">{{ 'Service Tools and Consumables' | translate }}</a>
                                </li>
                            </ul>
                        </li>
                        <li class="divider"></li>
                        <li class="banner-dropdown">
                            <a href="#" class="dropdown-toggle" data-toggle="dropdown">{{ 'Resources' | translate }}
                                <strong class="caret"></strong>
                            </a>
                            <ul class="dropdown-menu">
                                <li>
                                    <a routerLink="/mst-entry/main/home-page">{{ 'Electrical Connectors' | translate }}</a>
                                </li>
                                <li *ngIf="showSbomMenu$ | async">
                                    <ng-container [ngSwitch]="(sbomLoaderEvents$ | async)?.type">
                                        <ng-container *ngSwitchCase="'pending'">
                                            <a class="disabled-link" type="menu" role="link">
                                                <strong><em class="fa fa-spinner fa-spin"></em> Downloading..!</strong>
                                            </a>
                                        </ng-container>
                                        <ng-container *ngSwitchDefault>
                                            <a type="menu" role="link" (click)="downloadSbom($event);">{{ 'Service BOM' |
                                                translate }} (<strong>{{esn$ | async}}</strong>)</a>
                                        </ng-container>
                                    </ng-container>
                                </li>
                                <li *ngIf="userId != 'Login'">
                                    <a href="https://commerce.cummins.com" target="_blank" rel="noopener noreferrer">{{ 'Cumpas 2.0' | translate }}</a>
                                </li>
                                <li *ngIf="accessFlags.isUploadUpfitAllowed">
                                    <a routerLink="/upfits/add-upfits">{{ 'Add Upfit' | translate }}</a>
                                </li>
                                <li *ngIf="accessFlags.isAdmin || accessFlags.isSuperAdmin">
                                    <a [href]="adminView" target="_blank">{{ 'User Management' | translate }}</a>
                                </li>
                                <!--Added for CMS testing starts-->
                                <li *ngFor="let items of menu" [ngClass]="{'dropdown-submenu' : items.type == 'D'}">
                                    <ng-container *ngIf="items.title !== 'About Us' && items.title !=='Contact Us'">
                                        <a *ngIf="items.type !='D'" routerLink="/resource/{{application}}/{{items.name}}"
                                            [title]="items.title | translate | titlecase">
                                            {{items.title | translate | titlecase}}</a>
                                        <a *ngIf="items.type == 'D'" [title]="items.title | translate | titlecase">
                                            {{items.title || items.name | translate | titlecase }}</a>
                                        <ul  class="dropdown-menu level-two" *ngIf="items.type == 'D'">
                                            <li *ngFor="let item of items.children">
                                                <a *ngIf="item.type !='D'" routerLink="/resource/{{application}}/{{items.name}}/{{item.name}}"
                                                    [title]="item.title | translate | titlecase">
                                                    {{item.title | translate | titlecase}}</a>
                                            </li>
                                            <li *ngIf="items.name == 'media-center'">
                                                <a href="https://www.cummins.com/news" target="_blank" rel="noopener noreferrer">{{ 'News and Press Releases' | translate }}</a>
                                            </li>
                                            <li *ngIf="items.name == 'media-center'">
                                                <a href="https://www.youtube.com/playlist?list=PL01FC88B16F359DCC" target="_blank" rel="noopener noreferrer">{{ 'Videos' | translate }}</a>
                                            </li>
                                        </ul>                          
                                    </ng-container>
                                </li>
                                <li>
                                    <a href="https://www.cummins.com/company/global-websites" target="_blank" rel="noopener noreferrer">{{ 'Other Cummins Websites' | translate }}</a>
                                </li>
                                <li>
                                    <a href="https://www.cummins.com/support/product-registration" target="_blank" rel="noopener noreferrer">{{ 'Product Registration' | translate }}</a>
                                </li>
                                <li *ngIf="userId != 'Login'">
                                        <a [href]="'/qs3/portal/parts/index.html#tab_recon' | resource: 'QSOL'" target="_blank">{{ 'ReCon Tools' | translate }}</a>
                                </li>
                            </ul>
                        </li>
                        <li class="divider"></li>
                        <li *ngIf="checkLogout()" class="banner-dropdown">
                            <a href="#" class="dropdown-toggle" data-toggle="dropdown">{{userId}}
                                <strong class="caret"></strong>
                            </a>
                            <ul class="dropdown-menu multi-level">
                                <ng-container *ngIf="!isIntegrationFlow">
                                    <li>
                                        <a class="" [href]="'/qs3/portal/settings/index.html' | resource: 'QSOL'" target="_blank">{{'My Profile' |  translate}}</a>
                                    </li>
                                    <li>
                                        <a (click)="gotoSavedEsn()">My Saved ESNs</a>
                                    </li>
                                    <li *ngIf="isServiceToolsAdmin">
                                        <a routerLink="/mst-admin">Service Tools Admin</a>
                                    </li>
                                </ng-container>
                                <li>
                                    <a (click)="logout()">{{ 'Not' | translate }} {{userId}}? {{ 'Sign Out' | translate }}</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a routerLink="/login" *ngIf="!checkLogout()">{{userId | translate}} </a>
                        </li>
                        <li class="divider"></li>
                        <li>
                            <a routerLink="/wish-list" [title]="cartLoading ? 'Loading User Pick List..!' : 'Pick List'">
                                <em class=""></em> {{ 'Pick List' | translate }}
                                <span class="badge picklist-icon">
                                    <ng-container *ngIf="cartLoading && initLoading">
                                        <em class="fa fa-circle-o-notch fa-spin"></em>
                                    </ng-container>
                                    <ng-container *ngIf="cartLoading && !initLoading">-</ng-container>
                                    <ng-container *ngIf="!cartLoading && initLoading">{{cnt}}</ng-container>
                                </span>
                            </a>
                        </li>
                    </ng-container>
                </ul>
            </nav>
        </div>
        <div class="mobile-menu hidden-md hidden-sm" *ngIf="!(popupMode$ | async)">
            <div id="navbar" class="navbar-collapse collapse">
                <ul class="nav navbar-nav">
                    <li *ngIf="checkLogout()" class="banner-dropdown">
                        <a href="#" class="dropdown-toggle" data-toggle="dropdown">{{userId | translate }}
                            <strong class="caret"></strong>
                        </a>
                        <ul class="dropdown-menu multi-level">
                            <ng-container *ngIf="!isIntegrationFlow">
                                <li>
                                    <a class="" [href]="'/qs3/portal/settings/index.html' | resource: 'QSOL'" target="_blank">{{'My Profile' |  translate}}</a>
                                </li>
                                <li>
                                    <a (click)="gotoSavedEsn()">My Saved ESNs</a>
                                </li>
                                <li *ngIf="isServiceToolsAdmin">
                                    <a routerLink="/mst-admin">Service Tools Admin</a>
                                </li>
                            </ng-container>
                            <li>
                                <a (click)="logout()">{{ 'Not' | translate }} {{userId}}? {{ 'Sign Out' | translate }}</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a routerLink="/login" *ngIf="!checkLogout()">{{userId | translate}} </a>
                    </li>
                    <li class="active">
                        <a routerLink="/">
                            <em class="fa fa-home"></em> {{ 'Home' | translate }}</a>
                    </li>
                    <li class="dropdown">
                        <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">{{ 'Products' | translate }}
                            <span class="caret"></span>
                        </a>
                        <ul class="dropdown-menu">
                            <li>
                                <a routerLink="/esn-entry">{{ 'Engine Parts' | translate }}</a>
                            </li>
                            <li>
                                <a href="https://catalog.cumminsfiltration.com/catalog/CatalogSearch.do?_locale=en&_region" target="_blank"
                                    rel="noopener noreferrer">{{ 'Filtration' | translate }}</a>
                            </li>
                            <li>
                                <a routerLink="/gsn-entry">{{ 'Power Generation' | translate }}</a>
                            </li>
                            <li>
                                <a routerLink="/ctt-entry">{{ 'Turbochargers' | translate }} </a>
                            </li>
                            <li>
                                <a routerLink="/atsn-entry">{{ 'Aftertreatment' | translate }}</a>
                            </li>
                            <li>
                                <a href="{{storeUrl}}" target="_blank" rel="noopener noreferrer">{{ 'Publications'| translate }}</a> </li>          <li>
                                <a routerLink="/mst-entry/main/home-page">{{ 'Service Tools and Consumables' | translate }}</a>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <a href="#" class="dropdown-toggle" data-toggle="dropdown">{{ 'Resources' | translate }}
                            <strong class="caret"></strong>
                        </a>
                        <ul class="dropdown-menu">
                            <li>
                                <a routerLink="/mst-entry/main/home-page">{{ 'Electrical Connectors' | translate }}</a>
                            </li>
                            <li *ngIf="showSbomMenu$ | async">
                                <ng-container [ngSwitch]="(sbomLoaderEvents$ | async)?.type">
                                    <ng-container *ngSwitchCase="'pending'">
                                        <a class="disabled-link" type="menu" role="link">
                                            <strong><em class="fa fa-spinner fa-spin"></em> Downloading..!</strong>
                                        </a>
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        <a type="menu" role="link" (click)="downloadSbom($event);">{{ 'Service BOM' |
                                            translate }} (<strong>{{esn$ | async}}</strong>)</a>
                                    </ng-container>
                                </ng-container>
                            </li>
                            <li *ngIf="userId != 'Login'">
                                <a href="https://commerce.cummins.com" target="_blank" rel="noopener noreferrer">{{ 'Cumpas 2.0' | translate }}</a>
                            </li>
                            <li *ngIf="accessFlags.isUploadUpfitAllowed">
                                <a routerLink="/upfits/add-upfits">{{ 'Add Upfit' | translate }}</a>
                            </li>
                            <li *ngIf="accessFlags.isAdmin || accessFlags.isSuperAdmin">
                                <a [href]="adminView" target="_blank">{{ 'User Management' | translate }}</a>
                            </li>        
                            <li>
                            </li>    
                            <!--Added for CMS testing starts-->
                            <li *ngFor="let items of menu">
                                <ng-container *ngIf="items.title !== 'About Us' && items.title !=='Contact Us'">
                                    <a *ngIf="items.type !='D'" routerLink="/resource/{{application}}/{{items.name}}"> {{items.title | translate | titlecase}}</a>
                                    <a *ngIf="items.type == 'D'" class="dropdown-toggle" data-toggle="dropdown">
                                        {{items.title || items.name | translate | titlecase }}
                                        <strong class="caret"></strong>
                                    </a>
                                    <ul  class="dropdown-menu level-two" *ngIf="items.type == 'D'">
                                        <li *ngFor="let item of items.children">
                                            <a *ngIf="item.type !='D'" routerLink="/resource/{{application}}/{{items.name}}/{{item.name}}">
                                                {{item.title | translate | titlecase}}</a>
                                        </li>
                                        <li *ngIf="items.name == 'media-center'">
                                            <a href="https://www.cummins.com/news" target="_blank" rel="noopener noreferrer">{{ 'News and Press Releases' | translate }}</a>
                                        </li>
        
                                        <li *ngIf="items.name == 'media-center'">
                                            <a href="https://www.youtube.com/playlist?list=PL01FC88B16F359DCC" target="_blank" rel="noopener noreferrer">{{ 'Videos' | translate }}</a>
                                        </li>
                                    </ul>
                                </ng-container>
                            </li>
                            <li>
                                <a href="https://www.cummins.com/company/global-websites" target="_blank" rel="noopener noreferrer">{{ 'Other Cummins Websites'| translate }}</a>
                            </li>
                            <li>
                                <a href="https://www.cummins.com/support/product-registration" target="_blank" rel="noopener noreferrer">{{ 'Product Registration'| translate }}</a>
                            </li>
                            <li *ngIf="userId != 'Login'">
                                    <a [href]="'/qs3/portal/parts/index.html#tab_recon' | resource: 'QSOL'" target="_blank">{{ 'ReCon Tools' | translate }}</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a routerLink="/wish-list">
                            <em class=""></em> {{ 'Pick List' | translate }}
                            <span class="badge picklist-icon"> {{cnt}}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div id="popover-content" class="hide">
    <div class="" *ngIf="!checkLogout()">
        <div class="">
            <a class="col-md-12  primary-cta btn  btn-lg " routerLink="/login">{{ 'Sign In'| translate }}</a>
        </div>
        <div class="col-md-12 text-center">
            <label>
                <span>New Customer - </span>
            </label>&nbsp;&nbsp;&nbsp;
            <a class="" routerLink="/signUp">{{ 'Register Now?' | translate }}</a>
        </div>
    </div>
    <div class="" *ngIf="checkLogout()">
        <ng-container *ngIf="!isIntegrationFlow">
            <div class="col-md-12">
                <a class="col-md-12  primary-cta btn  btn-lg " [href]="'/qs3/portal/settings/index.html' | resource: 'QSOL'" target="_blank">{{'My Profile' |  translate}}</a>
            </div>
        </ng-container>
        <div class="col-md-12">
            <a class="col-md-12  primary-cta btn  btn-lg " #signout (click)="onChange($event)">{{ 'Not' | translate }} {{userId | translate }}? {{ 'Sign Out' | translate }}</a>
        </div>
        <div class="col-md-12 text-center">
        </div>
    </div>
</div>
<!-- Login Popover Section-->
<div class="">
    <toaster-container [toasterconfig]="toasterconfig"></toaster-container>
</div>
