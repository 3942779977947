import { InjectionToken, Injector, Type } from "@angular/core";
import { getValue, Selector as StoreSelector, createSelector as createStoreSelector } from "@ngxs/store";

export namespace DI {
    export type PropertyOptions<T> = { lazy?: boolean, defaultValue?: T };
    type PropertyDefinition<T> = { key: string } & PropertyOptions<T>;

    const INJECTED_PROPS_REF = new WeakMap<any, { [key: string]: any }>();

    export function Inject<T>(typeResolverFactory: () => Type<T> | Function | InjectionToken<T>,
        propOptions?: PropertyOptions<T>) {
        return function (target: any, propKey: string) {
            if (!target.hasOwnProperty('INJECTED_PROPS_MAP')) {
                target['INJECTED_PROPS_MAP'] = new Set<PropertyDefinition<any>>();
            }
            const propDefinition: PropertyDefinition<T> = {
                key: propKey,
                lazy: false,
                defaultValue: undefined,
                ...propOptions
            };
            (<Set<PropertyDefinition<any>>>target['INJECTED_PROPS_MAP']).add(propDefinition);

            return <any>{
                enumerable: false,
                configurable: false,
                get() {
                    if (!INJECTED_PROPS_REF.has(this)) {
                        INJECTED_PROPS_REF.set(this, {
                            _injector: this._injector
                        });
                        delete this._injector;
                    }

                    const valueMap = INJECTED_PROPS_REF.get(this);
                    if (!valueMap.hasOwnProperty(propKey)) {
                        const resolvedType = typeResolverFactory();
                        valueMap[propKey] = valueMap._injector.get(resolvedType, propDefinition.defaultValue);
                    }
                    return valueMap[propKey];
                }
            };
        };
    }

    export abstract class AutoDependencyInjector {
        protected constructor(_injector: Injector) {
            Object.defineProperties(this, {
                '_injector': {
                    writable: false,
                    value: _injector,
                    enumerable: false,
                    configurable: true
                }
            });
            this.checkInjectedValues();
        }

        private checkInjectedValues() {
            const propDefinitions: Set<PropertyDefinition<any>> = Object.getPrototypeOf(this)['INJECTED_PROPS_MAP'];
            if (!propDefinitions) {
                return;
            }

            propDefinitions.forEach(propDefinition => {
                if (!propDefinition.lazy && !this[propDefinition.key]) {
                    throw new Error(`NullInjectorError: '${this.constructor.name}' missing required dependency '${propDefinition.key}'`);
                }
            });
        }
    }
}

export namespace Store {
    type SelectorFunc = (...args: any[]) => any;

    function wrapSelectorFunc(selectors: any[] | undefined, originalFn: SelectorFunc): SelectorFunc {
        function getState(selectorIndex: number, state: any) {
            if (selectorIndex < selectors.length && isIE() === 10) {
                const selector = selectors[selectorIndex];
                const ngxsMeta = getStateMetadata(selector);
                return getValue(state, ngxsMeta.path);
            }
            return state;
        }

        return (...args: any[]) => {
            const resolvedArgs = args.map((arg, ind) => getState(ind, arg));
            return originalFn(...resolvedArgs);
        };
    }

    export function Selector<T>(selectors?: T[]) {
        return (target: any, propKey: string, propDesc: PropertyDescriptor) => {
            const descriptor = { ...propDesc };
            descriptor.value = wrapSelectorFunc(selectors, propDesc.value);
            return StoreSelector(selectors)(target, propKey, descriptor);
        }
    }

    export function createSelector(selectors: any[] | undefined, originalFn: SelectorFunc, creationMetadata?: any): SelectorFunc {
        return createStoreSelector(selectors, wrapSelectorFunc(selectors, originalFn), creationMetadata);
    }

    export function getStateMetadata<T>(selector: T): { path: string } {
        return selector['NGXS_META'];
    }
}

type PrototypeExtension = {
    popupModeVisibility?: boolean;
    persistStateOnRefresh?: boolean;
};

export function ExtendPrototype(prototype: PrototypeExtension) {
    return (target: Type<any>): void => {
        target['PROTOTYPE_EXTENSION'] = prototype;
    };
}

export function getOwnProtypeExtension(type: Type<any>): PrototypeExtension {
    return {
        popupModeVisibility: false,
        persistStateOnRefresh: false,
        ...type['PROTOTYPE_EXTENSION']
    };
}
