import { Type } from "@angular/core";
import { StateOperator, getValue, setValue } from "@ngxs/store";
import { patch } from "@ngxs/store/operators";
import { PatchSpec } from "@ngxs/store/operators/patch";
import { Store } from "./core";

export function patchState<T>(selector: Type<any>, patchObject: PatchSpec<T>): StateOperator<any> {
    return (state: any): any => {
        const path: string = Store.getStateMetadata(selector).path;
        const patchedState = patch(patchObject)(getValue(state, path));
        return setValue(state, path, patchedState);
    };
}
