import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionStorageService, LocalStorageService } from 'ngx-webstorage';
import { AppSettings } from '../app.settings';
import { tap } from 'rxjs/operators';
import { LoginSharedService } from '../login/login-shared.service';


@Injectable()
export class LogoutService {
  constructor(private _http: HttpClient, private storage: SessionStorageService,
    private localStorage: LocalStorageService, private loginShared: LoginSharedService) {
  }

  public killSession(): Promise<any> {
    this.storage.store('USERID', 'Login');
    this.localStorage.clear('userid');
    return this._http.post(`${AppSettings.AUTH_ENDPOINT}/logout`, new HttpHeaders(), { responseType: 'text' })
      .pipe(tap(_ => this.loginShared.setLoginInfo(null))).toPromise();
  }
}
