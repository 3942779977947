import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LoginSharedService } from "../../login/login-shared.service";

@Component({
    template: `
        <div class="row">
            <div class="col-md-offset-3 col-md-6 col-sm-offset-2 col-sm-2">
                <p align="center" style="font-size: 12pt; margin-top: 75px; margin-bottom: 50px;">
                    <b>Checking Access.</b><br/>
                    <b style="font-size: 10pt;">Please wait while we redirect you.</b>
                </p>
            </div>
        </div>
    `,
})
export class AccessCheckComponent {

    constructor(private route: ActivatedRoute, private loginShared: LoginSharedService, private router: Router) {
        this.route.paramMap.subscribe(params => this.checkAndNavigate(params.get('path')));
    }

    private checkAndNavigate(path: string) {
        if(this.loginShared.userAttributes.length === 0) {
            setTimeout(() => this.checkAndNavigate(path));
            return;
        }
        const parameters: any[] = this.loginShared.registeredFeatures.get(path);
        if(!parameters || parameters.length === 0) {
            setTimeout(() => this.router.navigate(['home']));
            return;
        }
        parameters.push(true);
        const flag = this.loginShared.isLoggedIn() && this.loginShared.checkFeatureAccess.apply(this.loginShared, parameters);
        setTimeout(() => this.router.navigate([flag ? path : 'login']));
    }

}