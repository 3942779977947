/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./saml.component";
import * as i2 from "@angular/router";
import * as i3 from "../app.service";
import * as i4 from "./saml.service";
import * as i5 from "../shared/generic-notification/notification.service";
import * as i6 from "ngx-webstorage";
var styles_SAMLComponent = [];
var RenderType_SAMLComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SAMLComponent, data: {} });
export { RenderType_SAMLComponent as RenderType_SAMLComponent };
export function View_SAMLComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_SAMLComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "saml", [], null, null, null, View_SAMLComponent_0, RenderType_SAMLComponent)), i0.ɵdid(1, 49152, null, 0, i1.SAMLComponent, [i2.ActivatedRoute, i3.AppState, i4.SamlService, i5.NotificationService, i6.SessionStorageService], null, null)], null, null); }
var SAMLComponentNgFactory = i0.ɵccf("saml", i1.SAMLComponent, View_SAMLComponent_Host_0, {}, {}, []);
export { SAMLComponentNgFactory as SAMLComponentNgFactory };
