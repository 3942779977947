import { Injectable, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class SharingServices {

    private role = new BehaviorSubject('');
    sharedMessage = this.role.asObservable();
    publishToken = new Subject<string>();

    image = new BehaviorSubject({'id':null,'image':null});
    sharedImageMap = this.image.asObservable();

    constructor() { }
    
    nextMessage(message: string) {
        this.role.next(message)
    }

    add(id,image: any) {
        let item = {'id':id,'image':image};
        this.image.next(item);
        //this.image.set(id,image);
    }

    /*data:any={};

    public push(key,value){
        this.data[key] = value;
    }

    public pop(key):any{
       return this.data[key];
    }*/
}