import { Injectable } from '@angular/core';
import { EMPTY, Observable} from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionStorageService } from 'ngx-webstorage';
import { catchError, map, tap, concatMap, flatMap } from 'rxjs/operators';
import { ToasterService } from 'angular2-toaster';
import { CMSParentComponent } from '../../common/cms-parent';
import { AppSettings } from '../../../app.settings';
import { TranslateService } from '@ngx-translate/core';
import { LoginSharedService } from '../../../login/login-shared.service';
import { Store } from '@ngxs/store';
import { ApplicationState } from 'src/app/state/app.state';

/**
 * Azure blob services to store, list or delete the files
 */
@Injectable()
export class BlobStorageService extends CMSParentComponent {
  public _url: any;

  constructor(private http: HttpClient,
    public sessionStorageService: SessionStorageService,
    protected toasterService:ToasterService,
    protected translation: TranslateService,
    private loginSharedService: LoginSharedService,
    private store: Store) {
    super(translation,sessionStorageService,toasterService);
    this._url = `${AppSettings.API_ENDPOINTS.get('IACDataServices')}/protected/cms`;
  }

  /**
   * Upload or save HTML data on Azure blob services
   * @param data HTML Data to store on Azure blob
   * @param filename Name of the File
   * @param username Name of the user/owner who is uploading file
   */
  public uploadFile(data: File, serverData:any): Observable<any> {

    const formData = new FormData();
    formData.append('file', data);
    formData.append('path', serverData.path);
    formData.append('lang', serverData.lang ? serverData.lang:this.getLang());
    formData.append('reference', serverData.reference);

    if (serverData.metadata) {
      serverData.metadata.map(obj => {
        formData.append(obj.name, obj.path);
      });
    }
    
    
    let _url = `${AppSettings.API_ENDPOINTS.get('IACDataServices')}/protected/cms/uploadBlob`;
    return this.http.post<any>(_url, formData).pipe(catchError(error => {
      return super.catchIACError(error);
      }));
  } 

  public menu(path){
    const _url = `${AppSettings.API_ENDPOINTS.get('cmsReaderServices')}/protected/summary?path=${path}`;
    const headers = new HttpHeaders({
      'accept': 'application/json',
      'accept-language': this.getLang(),
      'x-application': 'IACDataServices'
    });
    return this.store.selectOnce(ApplicationState.popupMode).pipe(
      flatMap(popupMode => popupMode ? EMPTY : this.http.get<any>(_url, { headers })),
      map(data => this.fetchMenuItem(data.cmseditor.summary, path)),
      map(data => this.parseMenuItem(data)),
      map(data => {
        data.name = path;
        return data
      }),
      catchError(error => {
        return super.catchIACError(error);
      })
    );
  }

  private fetchMenuItem(data, path) {
    if (data.path === path) return data;
    let result = null;
    for (const child of (data.children || [])) {
      result = this.fetchMenuItem(child, path);
      if (!result) break;
    }
    return result;
  }

  private parseMenuItem(item) {
    const parts = item.path.split('/');
    item.title = item.name;
    item.name = parts[parts.length - 1].replace(/\.xml$/, '.html');
    (item.children || []).sort((a, b) => a.path.localeCompare(b.path));
    (item.children || []).forEach(child => this.parseMenuItem(child));
    return item;
  }

  public rename(data){

    return this.http.post<any>(`${AppSettings.API_ENDPOINTS.get('IACDataServices')}/protected/cms/rename`,data,{headers:new HttpHeaders({ 
      'x-source': 'editor'})}).pipe(catchError(error => {
      return super.catchIACError(error);
    }));
  }

  /**
   * Get File content
   * @param fileName File name to get the content
   */
  public getCMSContent(filename: string, type: string, source?:string): Promise<any> {

    let url = null;
    const headers = {} as any;
    if (source) {
      url = AppSettings.API_ENDPOINTS.get('IACDataServices');
      if (this.loginSharedService.isLoggedIn()) {
        url += `/protected`
      }
      url += `/downloadBlob?path=${filename}&lang=${this.getLang()}`;
      headers['x-source'] = source;
    } else {
      const isHtmlRequest = filename.endsWith('.html');
      const endpoint = isHtmlRequest ? '/content' : '/resource';
      url = `${AppSettings.API_ENDPOINTS.get('cmsReaderServices')}/protected${endpoint}?path=${filename}`;
      if (isHtmlRequest) {
        headers['accept'] = 'text/html';
        headers['accept-language'] = this.getLang();
      }
      headers['x-application'] = 'IACDataServices';
    }

    return this.http.get(url, { headers, responseType: 'blob' }).toPromise()
    .then(res => {
        if(type == 'site'){
          return res;
        }else{
          return this.readBase64(res, type);
        }
      
    }).catch(error=>{
        return this.readBase64(error.error, type).then(e => super.catchIACError(JSON.parse(e)));
    });
  }

  /**
   * Get File content
   * @param fileName File name to get the content
   */
  public getTemplateContent(filename: string, type: string, source:string,application:string): Promise<any> {

    let url = null;
    const headers = {} as any;
    if (source === 'editor') {
      url = `${AppSettings.API_ENDPOINTS.get('IACDataServices')}/protected/downloadTemplate?path=${filename}&lang=${this.getLang()}`;
      headers['x-source'] = source;
    } else {
      url = `${AppSettings.API_ENDPOINTS.get('cmsReaderServices')}/protected/content?path=${filename}`;
      headers['accept'] = 'text/html';
      headers['accept-language'] = this.getLang();
      headers['x-application'] = 'IACDataServices';
    }

    return this.http.get(url, {responseType: "blob", headers }).toPromise().then(res => {
        return this.readBase64(res, type);
    }).catch(error=>{
        return super.catchIACError(error);
    });
  }

  /**
  * Delete the file from Azuer blob
  * @param filename Name of the file to delete from Azure blob
  */
  public delete(request,source?:string, application?:string): Observable<any> {

    if (request) {
      let _url = `${AppSettings.API_ENDPOINTS.get('IACDataServices')}/protected/cms/deleteContent`;

      const options = {

        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'x-source': 'editor'
        }),
      };
      
      let workflowURL = this._url = `${AppSettings.API_ENDPOINTS.get('IACDataServices')}/protected/cms/workflow/`;
      let _authURL = `${AppSettings.AUTH_API_ENDPOINTS.get('adminservices')}/cmseditor3/content?path=`+request[0].path;

      return this.http.post<any>(workflowURL,request[0]).pipe(
        tap(res => console.log('First result', res)),
        concatMap((res: { timeout: number }) => this.http.delete(_authURL)),
        tap(res => console.log('Second result', res)),
        concatMap((res: { timeout: number }) => this.http.post(_url,request,options)),
        tap(res => console.log('Third result', res)),
      );
    }    
  } 

  public getHTML(filename): Observable<any> {
    let url = `${AppSettings.HOST_NAME}` + `/assets/` + filename;
    return this.http.get(url, { responseType: 'text' }).pipe(catchError(error => {
      return super.catchIACError(error);
      }));
  }

  public generateSummary(data){
    return this.http.post<any>(`${AppSettings.API_ENDPOINTS.get('IACDataServices')}/protected/cms/summary`, data,{headers:new HttpHeaders({ 
      'x-source': 'editor'})}).pipe(catchError(error => {
      return super.catchIACError(error);
    }));
  }
}
