<div class="container">
    <div class="row">
        <div class="col-md-12 text-center" *ngIf="!errorInfo.invalidateEsn">
            <h2 class="text-danger" *ngIf="errorInfo.header" [innerHTML]="errorInfo.header  | translate | safe: 'html'"></h2>
            <h4 *ngIf="errorInfo.details">
                {{errorInfo.details | translate}} 
            </h4>
            <span *ngIf="errorInfo.infoRoute" > {{ 'Please' | translate}} <a (click)="goToInfoURL()" >{{ 'click here' | translate}}</a> {{ 'for more information' | translate}}. </span>   

        </div>
        <div *ngIf="errorInfo.invalidateEsn">
            <span class="text-center vert-offset-top-2">
                <h2 class="text-danger">No results were found.</h2>
                <h4>Please modify the information entered and try again.</h4>
                <h5>For help, click on the
                    <i class="fa fa-question-circle" aria-hidden="true"></i> next to the search box for additional information.</h5>
                <h5 *ngIf="sharedInfoService.previousESN && sharedInfoService.previousESN.esn"><span (click)="setEsn()" class="link">Click here</span> to reset the previous ESN (<b>{{sharedInfoService.previousESN.esn}}</b>)</h5>
            </span>
        </div>
    </div>
</div>


