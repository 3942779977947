var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getValue, Selector as StoreSelector, createSelector as createStoreSelector } from "@ngxs/store";
export var DI;
(function (DI) {
    var INJECTED_PROPS_REF = new WeakMap();
    function Inject(typeResolverFactory, propOptions) {
        return function (target, propKey) {
            if (!target.hasOwnProperty('INJECTED_PROPS_MAP')) {
                target['INJECTED_PROPS_MAP'] = new Set();
            }
            var propDefinition = __assign({ key: propKey, lazy: false, defaultValue: undefined }, propOptions);
            target['INJECTED_PROPS_MAP'].add(propDefinition);
            return {
                enumerable: false,
                configurable: false,
                get: function () {
                    if (!INJECTED_PROPS_REF.has(this)) {
                        INJECTED_PROPS_REF.set(this, {
                            _injector: this._injector
                        });
                        delete this._injector;
                    }
                    var valueMap = INJECTED_PROPS_REF.get(this);
                    if (!valueMap.hasOwnProperty(propKey)) {
                        var resolvedType = typeResolverFactory();
                        valueMap[propKey] = valueMap._injector.get(resolvedType, propDefinition.defaultValue);
                    }
                    return valueMap[propKey];
                }
            };
        };
    }
    DI.Inject = Inject;
    var AutoDependencyInjector = /** @class */ (function () {
        function AutoDependencyInjector(_injector) {
            Object.defineProperties(this, {
                '_injector': {
                    writable: false,
                    value: _injector,
                    enumerable: false,
                    configurable: true
                }
            });
            this.checkInjectedValues();
        }
        AutoDependencyInjector.prototype.checkInjectedValues = function () {
            var _this = this;
            var propDefinitions = Object.getPrototypeOf(this)['INJECTED_PROPS_MAP'];
            if (!propDefinitions) {
                return;
            }
            propDefinitions.forEach(function (propDefinition) {
                if (!propDefinition.lazy && !_this[propDefinition.key]) {
                    throw new Error("NullInjectorError: '" + _this.constructor.name + "' missing required dependency '" + propDefinition.key + "'");
                }
            });
        };
        return AutoDependencyInjector;
    }());
    DI.AutoDependencyInjector = AutoDependencyInjector;
})(DI || (DI = {}));
export var Store;
(function (Store) {
    function wrapSelectorFunc(selectors, originalFn) {
        function getState(selectorIndex, state) {
            if (selectorIndex < selectors.length && isIE() === 10) {
                var selector = selectors[selectorIndex];
                var ngxsMeta = getStateMetadata(selector);
                return getValue(state, ngxsMeta.path);
            }
            return state;
        }
        return function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            var resolvedArgs = args.map(function (arg, ind) { return getState(ind, arg); });
            return originalFn.apply(void 0, resolvedArgs);
        };
    }
    function Selector(selectors) {
        return function (target, propKey, propDesc) {
            var descriptor = __assign({}, propDesc);
            descriptor.value = wrapSelectorFunc(selectors, propDesc.value);
            return StoreSelector(selectors)(target, propKey, descriptor);
        };
    }
    Store.Selector = Selector;
    function createSelector(selectors, originalFn, creationMetadata) {
        return createStoreSelector(selectors, wrapSelectorFunc(selectors, originalFn), creationMetadata);
    }
    Store.createSelector = createSelector;
    function getStateMetadata(selector) {
        return selector['NGXS_META'];
    }
    Store.getStateMetadata = getStateMetadata;
})(Store || (Store = {}));
export function ExtendPrototype(prototype) {
    return function (target) {
        target['PROTOTYPE_EXTENSION'] = prototype;
    };
}
export function getOwnProtypeExtension(type) {
    return __assign({ popupModeVisibility: false, persistStateOnRefresh: false }, type['PROTOTYPE_EXTENSION']);
}
