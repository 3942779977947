var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Injector } from '@angular/core';
import { ErrorShared } from './ErrorShared';
import { Router } from '@angular/router';
import { NotificationService } from '../shared/header/notification.service';
import { SharedInfoService } from '../partsCatalog/sharedInfo.service';
import { DI } from '../state/core';
var ɵ0 = function () { return ErrorShared; }, ɵ1 = function () { return SharedInfoService; }, ɵ2 = function () { return NotificationService; }, ɵ3 = function () { return Router; };
var ErrorComponent = /** @class */ (function (_super) {
    __extends(ErrorComponent, _super);
    function ErrorComponent(_injector) {
        return _super.call(this, _injector) || this;
    }
    ErrorComponent.prototype.goToInfoURL = function () {
        this.router.navigate([this.errorInfo.infoRoute]);
    };
    ErrorComponent.prototype.setEsn = function () {
        var data = this.sharedInfoService.previousESN.esn;
        var buType = (this.sharedInfoService.previousESN.mbom === 'package' ? 'package ' : '') + 'serial number';
        var esn = this.sharedInfoService.previousESN.mbom === 'package' ? this.sharedInfoService.previousESN.ebuSN.esn : undefined;
        var psn = this.sharedInfoService.previousESN.mbom !== 'package' && this.sharedInfoService.previousESN.packageSN ? this.sharedInfoService.previousESN.packageSN.esn : undefined;
        this.notificationService.setGlobalvalue(data);
        this.notificationService.setType = (buType === 'serial number' || buType === 'package serial number') ? 'esn' : buType;
        if (buType === 'serial number' && psn) {
            this.notificationService.setPackageNoValue(psn);
            this.notificationService.setIsPackageESNvalue(true);
        }
        else if (buType === 'package serial number' && esn) {
            this.notificationService.setPackageNoValue(esn);
            this.notificationService.setIsPackageESNvalue(false);
        }
        this.notificationService.notificationEBUUpdated(data);
        this.router.navigate(['/esn-entry/main']);
    };
    __decorate([
        DI.Inject(ɵ0),
        __metadata("design:type", ErrorShared)
    ], ErrorComponent.prototype, "errorInfo", void 0);
    __decorate([
        DI.Inject(ɵ1),
        __metadata("design:type", SharedInfoService)
    ], ErrorComponent.prototype, "sharedInfoService", void 0);
    __decorate([
        DI.Inject(ɵ2),
        __metadata("design:type", NotificationService)
    ], ErrorComponent.prototype, "notificationService", void 0);
    __decorate([
        DI.Inject(ɵ3),
        __metadata("design:type", Router)
    ], ErrorComponent.prototype, "router", void 0);
    return ErrorComponent;
}(DI.AutoDependencyInjector));
export { ErrorComponent };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
