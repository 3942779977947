import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import { Store } from "src/app/state/core";
import { SetCurrentOption } from "./option-detail.actions";

export interface OptionDetailComponentStateModel {
    currentOption: any;
}

@Injectable()
@State<OptionDetailComponentStateModel>({
    name: 'optionDetailComponent',
    defaults: {
        currentOption: null
    }
})
export class OptionDetailComponentState {
    @Action(SetCurrentOption)
    setCurrentOption(ctx: StateContext<OptionDetailComponentStateModel>, { option }: SetCurrentOption) {
        ctx.patchState({ currentOption: option });
    }

    @Store.Selector([OptionDetailComponentState])
    static getCurrentOption(state: OptionDetailComponentStateModel) {
        return state.currentOption;
    }
}
