import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';
import { AppState } from '../app.service';
import { Integration } from '../integration/Integration';
import { NotificationService } from '../shared/generic-notification/notification.service';
import { SamlService } from './saml.service';

@Component({
    selector: 'saml',
    template: ''
})
export class SAMLComponent {
    constructor(private route: ActivatedRoute, public appState: AppState,
        private samlService: SamlService, private notificationService: NotificationService,
        private storage: SessionStorageService) {
        this.notificationService.loadingEvent.next({ showLoading: true, loadingMessage: 'Loading App..!' });
        this.route.queryParams.subscribe(_params => {
            this.samlService.handleSessionInitiation(() => this.appState.getInitialToken(true, this.storage.retrieve('INTEGRATION_DATA') as Integration))
                .then(_res => {
                    this.notificationService.loadingEvent.next({ showLoading: false });
                }).catch(_error => {
                    this.notificationService.loadingEvent.next({
                        showLoading: true,
                        loadingMessage: 'Error Initiating App..!'
                    });
                });
        });
    }
}
