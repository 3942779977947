var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { DateAdapter } from "@angular/material";
var CustomDateAdapter = /** @class */ (function (_super) {
    __extends(CustomDateAdapter, _super);
    function CustomDateAdapter(datePipe) {
        var _this = _super.call(this) || this;
        _this.datePipe = datePipe;
        return _this;
    }
    CustomDateAdapter.prototype.getYear = function (date) {
        return date.getFullYear();
    };
    CustomDateAdapter.prototype.getMonth = function (date) {
        return date.getMonth();
    };
    CustomDateAdapter.prototype.getDate = function (date) {
        return date.getDate();
    };
    CustomDateAdapter.prototype.getDayOfWeek = function (date) {
        return date.getDay();
    };
    CustomDateAdapter.prototype.getMonthNames = function (style) {
        if (!CustomDateAdapter.MONTH_NAMES[style]) {
            throw new Error('Method not implemented.');
        }
        return CustomDateAdapter.MONTH_NAMES[style];
    };
    CustomDateAdapter.prototype.getDateNames = function () {
        return CustomDateAdapter.DATE_NAMES;
    };
    CustomDateAdapter.prototype.getDayOfWeekNames = function (style) {
        if (!CustomDateAdapter.WEEK_NAMES[style]) {
            throw new Error('Method not implemented.');
        }
        return CustomDateAdapter.WEEK_NAMES[style];
    };
    CustomDateAdapter.prototype.getYearName = function (date) {
        return this.getYear(date).toString();
    };
    CustomDateAdapter.prototype.getFirstDayOfWeek = function () {
        return CustomDateAdapter.WEEK_NAMES['long'].length - 1;
    };
    CustomDateAdapter.prototype.getNumDaysInMonth = function (date) {
        return this.getDate(this.createDate(this.getYear(date), this.getMonth(date) + 1, 0));
    };
    CustomDateAdapter.prototype.clone = function (date) {
        return this.createDate(this.getYear(date), this.getMonth(date), this.getDate(date));
    };
    CustomDateAdapter.prototype.createDate = function (year, month, date) {
        return new Date(year, month, date);
    };
    CustomDateAdapter.prototype.today = function () {
        return new Date();
    };
    CustomDateAdapter.prototype.parse = function (_value, _parseFormat) {
        throw new Error('Method not implemented.');
    };
    CustomDateAdapter.prototype.format = function (date, displayFormat) {
        return this.datePipe.transform(date, displayFormat);
    };
    CustomDateAdapter.prototype.addCalendarYears = function (date, years) {
        return this.createDate(this.getYear(date) + years, this.getMonth(date), this.getDate(date));
    };
    CustomDateAdapter.prototype.addCalendarMonths = function (date, months) {
        return this.createDate(this.getYear(date), this.getMonth(date) + months, this.getDate(date));
    };
    CustomDateAdapter.prototype.addCalendarDays = function (date, days) {
        return this.createDate(this.getYear(date), this.getMonth(date), this.getDate(date) + days);
    };
    CustomDateAdapter.prototype.toIso8601 = function (date) {
        return date.toISOString();
    };
    CustomDateAdapter.prototype.isDateInstance = function (obj) {
        return Object.prototype.toString.call(obj) === '[object Date]';
    };
    CustomDateAdapter.prototype.isValid = function (_date) {
        return true;
    };
    CustomDateAdapter.prototype.invalid = function () {
        throw new Error('Method not implemented.');
    };
    CustomDateAdapter.MONTH_NAMES = {
        'long': ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        'short': ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    };
    CustomDateAdapter.WEEK_NAMES = {
        'long': ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        'short': ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        'narrow': ['M', 'T', 'W', 'T', 'F', 'S', 'S']
    };
    CustomDateAdapter.DATE_NAMES = [
        '1', '2', '3', '4', '5', '6', '7', '8', '9', '10',
        '11', '12', '13', '14', '15', '16', '17', '18', '19',
        '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
        '30', '31'
    ];
    return CustomDateAdapter;
}(DateAdapter));
export { CustomDateAdapter };
