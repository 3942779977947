/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "ng-lazyload-image";
import * as i3 from "./img-xhr.component";
var styles_AjaxImageLoader = [".alt-image-style[_ngcontent-%COMP%] {\n                position: relative;\n                height: 100%;\n            }\n            .alt-image-style[_ngcontent-%COMP%]    > img[_ngcontent-%COMP%] {\n                margin: 0;\n                position: absolute;\n                top: 50%;\n                left: 50%;\n                transform: translate(-50%, -50%);\n            }\n            .alt-text[_ngcontent-%COMP%] {\n                position: relative;\n                background-color: rgba(150, 150, 150, 0.15);\n                cursor: pointer;\n                pointer-events: none;\n                transition: height 0.25s ease-in;\n            }\n            .alt-text[_ngcontent-%COMP%]:hover {\n                background-color: rgba(150, 150, 150, 0.25);\n            }\n            .alt-text[_ngcontent-%COMP%]    > .text-center[_ngcontent-%COMP%] {\n                position: absolute;\n                top: 50%;\n                left: 0;\n                width: 100%;\n                text-align: center;\n            }"];
var RenderType_AjaxImageLoader = i0.ɵcrt({ encapsulation: 0, styles: styles_AjaxImageLoader, data: {} });
export { RenderType_AjaxImageLoader as RenderType_AjaxImageLoader };
function View_AjaxImageLoader_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "img", [["src", "assets/images/about-us/cbimage.jpg"]], [[8, "className", 0], [2, "text-center", null], [8, "alt", 0]], null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgStyleImpl, i1.ɵNgStyleR2Impl, [i0.ElementRef, i0.KeyValueDiffers, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i1.NgStyle, [i1.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.style; _ck(_v, 3, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.altStyleClass || "") + " alt-image-style"); _ck(_v, 0, 0, currVal_0); var currVal_1 = (_co.styleClass || ""); var currVal_2 = true; var currVal_3 = _co.alt; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3); }); }
function View_AjaxImageLoader_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "img", [], [[8, "className", 0], [8, "title", 0], [2, "text-center", null], [8, "alt", 0]], [[null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.imageLoaded($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgStyleImpl, i1.ɵNgStyleR2Impl, [i0.ElementRef, i0.KeyValueDiffers, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i1.NgStyle, [i1.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i0.ɵdid(4, 1720320, null, 0, i2.LazyLoadImageDirective, [i0.ElementRef, i0.NgZone, i0.PLATFORM_ID, [2, "options"]], { lazyImage: [0, "lazyImage"], defaultImage: [1, "defaultImage"], errorImage: [2, "errorImage"], offset: [3, "offset"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.style; _ck(_v, 3, 0, currVal_4); var currVal_5 = _co.src; var currVal_6 = "../../assets/icons/loader_icon.gif"; var currVal_7 = _co.src; var currVal_8 = 100; _ck(_v, 4, 0, currVal_5, currVal_6, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.styleClass || ""); var currVal_1 = (_co.title || ""); var currVal_2 = true; var currVal_3 = _co.alt; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_AjaxImageLoader_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "img", [], [[8, "src", 4], [8, "className", 0], [8, "title", 0], [2, "text-center", null], [8, "alt", 0]], [[null, "load"], [null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.imageLoaded($event) !== false);
        ad = (pd_0 && ad);
    } if (("error" === en)) {
        var pd_1 = (_co.handleError($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgStyleImpl, i1.ɵNgStyleR2Impl, [i0.ElementRef, i0.KeyValueDiffers, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgStyle, [i1.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.style; _ck(_v, 2, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.src; var currVal_1 = (_co.styleClass || ""); var currVal_2 = (_co.title || ""); var currVal_3 = true; var currVal_4 = _co.alt; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
function View_AjaxImageLoader_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AjaxImageLoader_4)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["nonLazyLoadTemplate", 2]], null, 0, null, View_AjaxImageLoader_5))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.lazyLoad; var currVal_2 = i0.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.parentClass || ""); _ck(_v, 0, 0, currVal_0); }); }
function View_AjaxImageLoader_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AjaxImageLoader_3)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.src; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AjaxImageLoader_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AjaxImageLoader_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["showImage", 2]], null, 0, null, View_AjaxImageLoader_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showAlt; var currVal_1 = i0.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_AjaxImageLoader_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "img-xhr", [], null, null, null, View_AjaxImageLoader_0, RenderType_AjaxImageLoader)), i0.ɵdid(1, 245760, null, 0, i3.AjaxImageLoader, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AjaxImageLoaderNgFactory = i0.ɵccf("img-xhr", i3.AjaxImageLoader, View_AjaxImageLoader_Host_0, { src: "src", showAlt: "showAlt", alt: "alt", style: "style", altStyleClass: "altStyleClass", styleClass: "styleClass", parentClass: "parentClass", width: "width", height: "height", title: "title", lazyLoad: "lazyLoad" }, { onerror: "onerror", onLoad: "onLoad" }, []);
export { AjaxImageLoaderNgFactory as AjaxImageLoaderNgFactory };
