import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { AppState } from '../app.service';
import { map } from 'rxjs/operators';
import { LoginSharedService } from '../login/login-shared.service';
import { NotificationService } from '../shared/generic-notification/notification.service';
import { SharingServices } from '../cms-editor/services/sharing-service';
import { BlobStorageService } from '../cms-editor/services/api-services/cms-blob.service';
import { FeatureChecker } from '../shared/feature-checker.service';
import { DI } from '../state/core';

@Injectable({ providedIn: 'root' })
export class RouteProtectionGuard extends DI.AutoDependencyInjector implements CanActivateChild {
    @DI.Inject(() => AppState)
    private appService: AppState;

    @DI.Inject(() => LoginSharedService)
    private loginSharedService: LoginSharedService;

    @DI.Inject(() => NotificationService)
    private notificationService: NotificationService;

    @DI.Inject(() => BlobStorageService)
    private blobservice: BlobStorageService;

    @DI.Inject(() => SharingServices)
    private sharing: SharingServices;

    @DI.Inject(() => FeatureChecker)
    private featureChecker: FeatureChecker;

    constructor(_injector: Injector) {
        super(_injector);
    }

    public async canActivateChild(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        if ((this.loginSharedService.userAttributes.length > 0)
            || state.url.startsWith('/saml') || state.url.startsWith('/integration')) {
            return Promise.resolve(true);
        }
        this.notificationService.loadingEvent.next({ showLoading: true, loadingMessage: 'Loading App..!' });

        try {
            await this.appService.getInitialToken().pipe(
                map(res => {
                    this.appService.publishSessionChange(res);
                    return true;
                })
            ).toPromise();

            this.blobservice.menu('/iac').subscribe(res_2 => {
                if (res_2) {
                    this.sharing.publishToken.next(res_2);
                }
            }, _error => {
                return state.url === '/' || state.url === '/home';
            });

            this.featureChecker.refreshSiteFeatures();

            this.notificationService.loadingEvent.next({ showLoading: false });
            return true;
        } catch (_) {
            this.notificationService.loadingEvent.next({ showLoading: true, loadingMessage: 'Error Initiating App..!' });
            return state.url === '/' || state.url === '/home';
        }
    }
}
