/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/common";
import * as i3 from "./integration.component";
import * as i4 from "@angular/router";
import * as i5 from "../app.service";
import * as i6 from "../shared/header/notification.service";
import * as i7 from "../iacTranslations/iacTranslations.service";
import * as i8 from "ngx-webstorage";
import * as i9 from "../shared/generic-notification/notification.service";
import * as i10 from "../saml/saml.service";
var styles_IntegrationComponent = [];
var RenderType_IntegrationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IntegrationComponent, data: {} });
export { RenderType_IntegrationComponent as RenderType_IntegrationComponent };
function View_IntegrationComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("Invalid Token Used")); _ck(_v, 1, 0, currVal_0); }); }
function View_IntegrationComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "container-fluid vert-offset-top-4 vert-offset-bottom-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "col-md-6 col-md-offset-3 text text-danger"]], [[4, "text-align", null]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "i", [["class", "glyphicon glyphicon-alert"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IntegrationComponent_2)), i0.ɵdid(6, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 4, "div", [["class", "row vert-offset-top-1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 3, "div", [["class", "col-md-6 col-md-offset-3"]], [[4, "text-align", null]], null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "i", [["class", "fa fa-spinner fa-spin"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Please wait while we redirect you to home page."]))], function (_ck, _v) { var currVal_1 = 401; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = "center"; _ck(_v, 3, 0, currVal_0); var currVal_2 = "center"; _ck(_v, 8, 0, currVal_2); }); }
export function View_IntegrationComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_IntegrationComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.error > 0); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_IntegrationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "integration", [], null, null, null, View_IntegrationComponent_0, RenderType_IntegrationComponent)), i0.ɵdid(1, 245760, null, 0, i3.IntegrationComponent, [i4.ActivatedRoute, i4.Router, i5.AppState, i6.NotificationService, i1.TranslateService, i7.IACTranslationsService, i8.SessionStorageService, i9.NotificationService, i10.SamlService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IntegrationComponentNgFactory = i0.ɵccf("integration", i3.IntegrationComponent, View_IntegrationComponent_Host_0, {}, {}, []);
export { IntegrationComponentNgFactory as IntegrationComponentNgFactory };
