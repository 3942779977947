/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./shared-components/env-resource.pipe";
import * as i3 from "./shared/generic-notification/generic-notification.component.ngfactory";
import * as i4 from "./shared/generic-notification/generic-notification.component";
import * as i5 from "./shared/generic-notification/notification.service";
import * as i6 from "./shared/header/header.component.ngfactory";
import * as i7 from "./shared/header/header.component";
import * as i8 from "./breadcrumbs/breadcrumbs.component.ngfactory";
import * as i9 from "./breadcrumbs/breadcrumbs.component";
import * as i10 from "@angular/router";
import * as i11 from "./shared/footer/footer.component.ngfactory";
import * as i12 from "./shared/footer/footer.component";
import * as i13 from "./cookie-popup/cookie.popup.component.ngfactory";
import * as i14 from "./cookie-popup/cookie.popup.component";
import * as i15 from "./cookie-popup/cookie.service";
import * as i16 from "ngx-webstorage";
import * as i17 from "./shared-components/notices.component.ngfactory";
import * as i18 from "./shared-components/notices.component";
import * as i19 from "./language.service";
import * as i20 from "@ngx-translate/core";
import * as i21 from "./app.component";
import * as i22 from "./app.service";
import * as i23 from "./login/login-shared.service";
import * as i24 from "./login/login.service";
import * as i25 from "./shared/header/notification.service";
import * as i26 from "./session-maintenance/session.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" By registering for and using QuickServe Online, you agree to Cummins Inc. "])), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), i1.ɵppd(2, 2), (_l()(), i1.ɵeld(3, 0, null, null, 1, "u", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["terms of use"])), (_l()(), i1.ɵted(-1, null, [" and "])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "a", [["href", "https://www.cummins.com/privacy-and-legal"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "u", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["privacy policy"])), (_l()(), i1.ɵted(-1, null, [", including our use of cookies. To manage your consent settings, click on My Profile. "]))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), "/info/qsol/terms.html", "QSOL")); _ck(_v, 1, 0, currVal_0); }); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" An issue was detected with your session. "])), (_l()(), i1.ɵeld(1, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please clear your browser\u2019s cookies and login again. "]))], null, null); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" Click on 'Continue' to keep working. "]))], null, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.EnvironmentResourcePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 1, "generic-notification", [], null, null, null, i3.View_GenericNotificationComponent_0, i3.RenderType_GenericNotificationComponent)), i1.ɵdid(2, 49152, null, 0, i4.GenericNotificationComponent, [i5.NotificationService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "header", [], null, null, null, i6.View_HeaderComponent_0, i6.RenderType_HeaderComponent)), i1.ɵdid(4, 4440064, null, 0, i7.HeaderComponent, [i1.Injector], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "breadcrumb", [], null, [["window", "beforeunload"]], function (_v, en, $event) { var ad = true; if (("window:beforeunload" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).beforeunloadEventHandler() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_BreadcrumbComponent_0, i8.RenderType_BreadcrumbComponent)), i1.ɵdid(6, 245760, null, 0, i9.BreadcrumbComponent, [i1.Injector], null, null), (_l()(), i1.ɵeld(7, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(8, 212992, null, 0, i10.RouterOutlet, [i10.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "footer", [], null, null, null, i11.View_FooterComponent_0, i11.RenderType_FooterComponent)), i1.ɵdid(10, 49152, null, 0, i12.FooterComponent, [i1.Injector], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "cookie-popup", [], null, null, null, i13.View_CookiePopupComponent_0, i13.RenderType_CookiePopupComponent)), i1.ɵdid(12, 4243456, null, 0, i14.CookiePopupComponent, [i15.CookieService, i16.LocalStorageService], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 3, "notice-dialog", [["width", "500"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.acceptConsent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i17.View_NoticesComponent_0, i17.RenderType_NoticesComponent)), i1.ɵdid(14, 49152, null, 1, i18.NoticesComponent, [], { showProgress: [0, "showProgress"], visible: [1, "visible"], width: [2, "width"], showActions: [3, "showActions"] }, { click: "onClick" }), i1.ɵqud(603979776, 1, { content: 0 }), (_l()(), i1.ɵand(0, [[1, 2], ["content", 2]], null, 0, null, View_AppComponent_1)), (_l()(), i1.ɵeld(17, 0, null, null, 3, "notice-dialog", [["width", "400"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = ((_co.sessionEvents.clearCookies = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i17.View_NoticesComponent_0, i17.RenderType_NoticesComponent)), i1.ɵdid(18, 49152, null, 1, i18.NoticesComponent, [], { showProgress: [0, "showProgress"], visible: [1, "visible"], width: [2, "width"], showActions: [3, "showActions"] }, { click: "onClick" }), i1.ɵqud(603979776, 2, { content: 0 }), (_l()(), i1.ɵand(0, [[2, 2], ["content", 2]], null, 0, null, View_AppComponent_2)), (_l()(), i1.ɵeld(21, 0, null, null, 3, "notice-dialog", [["width", "400"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        _co.sessionEvents.showSessionResetNotification = false;
        var pd_0 = (_co.refreshSession((_co.sessionEvents.showSessionRefreshConfirmation || _co.sessionEvents.showWindowInactiveNotification)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i17.View_NoticesComponent_0, i17.RenderType_NoticesComponent)), i1.ɵdid(22, 49152, null, 1, i18.NoticesComponent, [], { showProgress: [0, "showProgress"], visible: [1, "visible"], width: [2, "width"], showActions: [3, "showActions"] }, { click: "onClick" }), i1.ɵqud(603979776, 3, { content: 0 }), (_l()(), i1.ɵand(0, [[3, 2], ["content", 2]], null, 0, null, View_AppComponent_3))], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); _ck(_v, 6, 0); _ck(_v, 8, 0); var currVal_0 = _co.showProgressAccept; var currVal_1 = _co.agreementModal; var currVal_2 = "500"; var currVal_3 = true; _ck(_v, 14, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = false; var currVal_5 = _co.sessionEvents.clearCookies; var currVal_6 = "400"; var currVal_7 = false; _ck(_v, 18, 0, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = _co.sessionEvents.showSessionRefreshConfirmationProgress; var currVal_9 = ((_co.sessionEvents.showSessionRefreshConfirmation || _co.sessionEvents.showSessionResetNotification) || _co.sessionEvents.showWindowInactiveNotification); var currVal_10 = "400"; var currVal_11 = true; _ck(_v, 22, 0, currVal_8, currVal_9, currVal_10, currVal_11); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "my-app", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵprd(512, null, i19.LanguageService, i19.LanguageService, [i20.TranslateService]), i1.ɵprd(131584, null, i7.HeaderComponent, i7.HeaderComponent, [i1.Injector]), i1.ɵdid(3, 245760, null, 0, i21.AppComponent, [i22.AppState, i10.Router, i23.LoginSharedService, i24.LoginService, i25.NotificationService, i26.SessionService, i19.LanguageService, i7.HeaderComponent], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("my-app", i21.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
