<!-- Begin Footer Section -->
<div class="footer">
    <!-- Begin Container Section -->
    <div class="container sitemap" [class.popupMode]="popupMode$ | async">
        <div class="row fat-footer large-padding" *ngIf="!(popupMode$ | async)">
            <div class="ff-column col-sm-3 col-md-3 col-lg-3">
                <ul class="dl-horizontal">
                    <li>
                        <a routerLink="/home">{{ 'Home' | translate }}</a>
                    </li>
                    <li>
                        <a routerLink="/resource/iac/about-us.html">{{ 'About Us' | translate }}</a>
                    </li>

                </ul>
            </div>
            <div class="ff-column col-sm-3 col-md-3 col-lg-3">
                <ul class="dl-horizontal">

                    <li>
                        <a>{{ 'Products' | translate }}</a>
                    </li>
                    <li>
                        <a routerLink="/esn-entry">{{ 'Engine Parts' | translate }}</a>
                    </li>
                    <li>
                        <a href="https://catalog.cumminsfiltration.com/catalog/CatalogSearch.do?_locale=en&_region"
                            target="_blank" rel="noopener noreferrer">{{ 'Filtration' | translate }}</a>
                    </li>

                    <li>
                        <a routerLink="/gsn-entry">{{ 'Power Generation' | translate }}</a>
                    </li>
                    <li>
                        <a routerLink="/ctt-entry">{{ 'Turbochargers' | translate }}</a>
                    </li>
                    <li>
                        <a routerLink="/esn-entry/npbu">{{ 'Electrified Power Products' | translate }}</a>
                    </li>
                    <li>
                        <a routerLink="/atsn-entry">{{ 'Aftertreatment' | translate }}</a>
                    </li>
                    <li>
                        <a href="{{storeUrl}}" target="_blank">{{ 'Publications' | translate }}</a>
                    </li>
                    <li>
                        <a routerLink="/mst-entry/main/home-page">{{ 'Service Tools and Consumables' | translate }}</a>
                    </li>
                </ul>
            </div>
            <div class="ff-column col-sm-3 col-md-3 col-lg-3">
                <ul class="dl-horizontal">

                    <li>
                        <a>{{ 'Resources' | translate }}</a>
                    </li>

                    <li>
                        <a routerLink="/resource/iac/features-updates.html">{{ 'Features and Updates' | translate }}
                        </a>
                    </li>
                    <li>
                        <a routerLink="/resource/iac/media-center.html">{{ 'Media Center' | translate }}</a>
                    </li>
                    <li>
                        <a href="https://www.cummins.com/support/product-registration" target="_blank" rel="noopener noreferrer">{{ 'Product
                            Registration' | translate }}</a>
                    </li>

                    <li>
                        <a href="https://www.cummins.com/company/global-websites" target="_blank" rel="noopener noreferrer">{{ 'Other Cummins
                            Websites' | translate }}</a>
                    </li>
                </ul>
            </div>
            <div class="ff-column col-sm-3 col-md-3 col-lg-3">
                <ul class="dl-horizontal">
                    <li>
                        <a routerLink="/resource/iac/contact-us.html">{{ 'Support' | translate }}</a>
                    </li>

                    <li>
                        <a routerLink="/resource/iac/contact-us.html">{{ 'Contact Us' | translate }}</a>
                    </li>

                    <li>
                        <a routerLink="contact-us/faq">{{ 'FAQs' | translate }}</a>
                    </li>
                    <li>
                        <a href="https://www.cummins.com/locations/" target="_blank" rel="noopener noreferrer">
                            {{ 'Service Locator' | translate }}</a>
                    </li>
                </ul>
            </div>
        </div>
        <!-- End 1024 and 800 Fat-Footer -->

        <!-- End Fat Footer Section -->
        <!-- Begin Global Footer Row -->
        <div class="copyright" [class.popupMode]="popupMode$ | async">
            <img-xhr styleClass="eprivacy {{(popupMode$ | async) ? 'popupMode' : ''}}"
                [src]="getAssetsLink('/icons/ccc-icons_black.gif')"></img-xhr>
            <!-- Begin Copyright/Logo/ePrivacy section -->
            <div class="col-xs-12 col-sm-6 col-md-6">
                <ng-template #logoLink>
                    <img-xhr [ngStyle]="{display: 'inline-block'}" styleClass="global-footer-logo" alt="Cummins logo"
                        [src]="getAssetsLink('/navigation/cummins-logo-footer.png')"></img-xhr>
                </ng-template>
                <a routerLink="/home" *ngIf="!(popupMode$ | async); else logoLink">
                    <ng-container *ngTemplateOutlet="logoLink"></ng-container>
                </a>

                <span class="col-xs-9 col-sm-10 col-md-10 col-lg-12 global-footer-copy">{{ '&copy;&nbsp;Cummins Inc.,
                    Box 3005, Columbus, IN 47202-3005 U.S.A.' | translate }}</span>
            </div>
            <!-- End Copyright/Logo/ePrivacy section -->

            <!-- Only visible on larger resolution browsers -->
            <div class="hidden-xs col-sm-6 col-md-6 global-footer-links">
                <a href="http://www.cummins.com/website-privacy-policy" target="_blank" rel="noopener noreferrer">{{ 'Privacy Policy' | translate
                    }}</a> | <a href="http://www.cummins.com/terms-and-conditions" target="_blank" rel="noopener noreferrer">{{ 'Terms and
                    Conditions' | translate }}</a>
                <ng-container *ngIf="!(popupMode$ | async)">
                    | <a routerLink="/site-map">{{ 'Site Map' | translate }}</a>
                </ng-container>
            </div>
            <!-- End Larger Resolution Links -->

            <!-- Begin Smaller Resolution Links -->
            <!-- Only visible on lower resolutions -->
            <div class="visible-xs col-xs-12 vert-offset-bottom-3">
                <span class="col-xs-10">
                    <a class="mobile-footer-links col-xs-9" href="http://www.cummins.com/website-privacy-policy"
                        target="_blank" rel="noopener noreferrer">{{ 'Privacy Policy' | translate }}</a>
                    <a class="mobile-footer-links col-xs-9" href="http://www.cummins.com/terms-and-conditions"
                        target="_blank" rel="noopener noreferrer">{{ 'Terms and Conditions' | translate }}</a>
                    <a class="mobile-footer-links col-xs-9" routerLink="/site-map" *ngIf="!(popupMode$ | async)">{{
                        'Site Map' | translate }}</a>
                </span>
            </div>
            <!-- End Smaller Resolution Links -->
        </div>
        <!-- End Global Footer Row -->
    </div>
    <!-- End Container Section -->
</div>