
import { throwError as observableThrowError, Observable, of, from, interval as ofInterval } from 'rxjs';
import { Response as HttpResponse } from "@angular/http";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";
import "rxjs/add/observable/empty";
import { AppState } from '../app.service';
import { LoginSharedService } from '../login/login-shared.service';
import { ResourceCacheService } from "./resource-cache.service";
import { catchError, map, flatMap, take } from 'rxjs/operators';
import { HttpRequest, HttpHandler, HttpEvent, HttpXsrfTokenExtractor, HttpHeaders, HttpInterceptor } from '@angular/common/http';
import { AppSettings } from '../app.settings';
import { SessionService } from '../session-maintenance/session.service';

export class SessionInterceptor implements HttpInterceptor {

    private static SESSION_CALL_REPEATS: number = 3;

    constructor(private loginShared: LoginSharedService, private _cache: ResourceCacheService,
        private appService: AppState, private tokenExtractor: HttpXsrfTokenExtractor,
        private sessionService: SessionService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const headerName = 'X-XSRF-TOKEN';
        let token = this.tokenExtractor.getToken() as string;
        let copiedReq;
        if (token !== null && !req.headers.has(headerName)) {
            copiedReq = req.clone({ withCredentials: true, headers: req.headers.set(headerName, token) });
        } else {
            copiedReq = req.clone({ withCredentials: true });
        }
        if (!req.url.startsWith(AppSettings.AUTH_ENDPOINT)) {
            this.sessionService.updateSessionLastAccessedTime();
        }
        return this.request(copiedReq, next);
    }

    request(req: HttpRequest<any>, next: HttpHandler, counter: number = 1): Observable<any> {
        return from(this._cache.get(req.url)).pipe(flatMap(cachedResponse => {
            if (cachedResponse) return of(cachedResponse);
            return next.handle(req)
                .pipe(map((response: any) => {
                    if (response.status === 200) this._cache.save(req.url as string, response);
                    return response;
                })).pipe(catchError(error => {
                    if (error.status === 403 && !req.url.startsWith(AppSettings.AUTH_ENDPOINT)) {
                        if (this.sessionService.isSessionCallActive()) return this.repeatRequest(req, next, counter, 100);

                        if (counter >= SessionInterceptor.SESSION_CALL_REPEATS) {
                            this.loginShared.publishSessionEvent('Clear Cookies');
                            return observableThrowError('Cannot reach the service');
                        }

                        counter++;
                        const addAuthorization = counter <= 2;
                        const headers = new HttpHeaders({ 'Expire-Cookies': addAuthorization ? 'None' : 'In-Validate' });
                        return this.appService.refreshToken(headers, addAuthorization)
                            .pipe(flatMap(() => this.repeatRequest(req, next, counter)))
                            .pipe(catchError(err => {
                                this.loginShared.publishSessionEvent('Clear Cookies');
                                return observableThrowError(err);
                            }));
                    } else {
                        return observableThrowError(error);
                    }
                }));
        }));
    }

    private repeatRequest(req: HttpRequest<any>, next: HttpHandler, counter: number = SessionInterceptor.SESSION_CALL_REPEATS, interval: number = 0): Observable<HttpResponse> {
        return ofInterval(interval).pipe(
            take(1),
            flatMap(() => {
                if (this.sessionService.isSessionCallActive()) {
                    return this.repeatRequest(req, next, counter, interval);
                }
                return this.request(req, next, counter);
            }),
            catchError(error => {
                if (error.status === 403) return this.request(req, next, counter);
                return observableThrowError(error);
            })
        );
    }

}
