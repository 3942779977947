import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { AppSettings } from "../app.settings";
import { SharedInfoService } from "../partsCatalog/sharedInfo.service";

@Injectable({ providedIn: 'root' })
export class SbomService {
    private _loaderEvent: BehaviorSubject<DownloadEvent> = new BehaviorSubject<DownloadEvent>(null);

    constructor(private _httpClient: HttpClient) { }

    get loaderEvent$(): Observable<DownloadEvent> {
        return this._loaderEvent.filter(event => !!event);
    }

    public downloadSbom(_sharedService: SharedInfoService) {
        this._loaderEvent.next({ type: "pending" });
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url = `${AppSettings.API_ENDPOINTS.get('IACDataServices')}/getSbom`;
        const res = _sharedService.currentEsnInfo;
        const preCheck = res.buildDate ? new Date(res.buildDate).getFullYear() : 2014;
        const sbomParameters = {
            "engineBuildDate": res.buildDate,
            "esn": res.esn
        };

        if (preCheck >= 2015) {
            if (res.kitSets) {
                sbomParameters['kitSets'] = res.kitSets;
            } else {
                let engineType = (res.mbom === "mbom" ? "mbom_" : (res.mbom === "package" ? "package_" : "")) + "options";
                sbomParameters['optionList'] = res.optionList;
                sbomParameters['cplNbr'] = res.cpl;
                sbomParameters['enginePlantCode'] = res.enginePlantCode;
                sbomParameters['engineType'] = engineType;
            }

            if (res.gasketSets) {
                sbomParameters['gasketSets'] = res.gasketSets;
            }
            else {
                sbomParameters['configuration'] = res.marketingConfig;
            }
        }

        this._httpClient.post(url, JSON.stringify(sbomParameters), { headers, responseType: 'blob' }).toPromise()
            .then(blob => {
                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, 'serviceBOM.xlsx');
                } else {
                    const url = window.URL.createObjectURL(blob);
                    const anchor = document.createElement('a');
                    anchor.href = url;
                    anchor.style.visibility = 'hidden';
                    anchor.download = 'serviceBOM.xlsx';
                    document.body.appendChild(anchor);
                    anchor.click();
                    document.body.removeChild(anchor);
                }
                this._loaderEvent.next({ type: "done" });
            }).catch(errorResponse => {
                const reader = new FileReader();
                reader.onload = (e: Event) => {
                    const error = JSON.parse((<any>e.target).result);
                    this._loaderEvent.next({ type: "error", message: error });
                };
                reader.readAsText(errorResponse._body);
            });
    }
}

export interface DownloadEvent {
    type: 'pending' | 'done' | 'error';
    message?: any;
}
