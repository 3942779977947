import { NgModule, ModuleWithProviders } from '@angular/core';
import { CartInfoService } from './cartInfoService.service';


@NgModule({
  declarations: [],
  exports: []
})
export class CartSharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CartSharedModule,
      providers: [CartInfoService]
    };
  }
}
