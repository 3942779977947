import { Injectable, Injector } from '@angular/core';
import { AppSettings } from '../app.settings';
import { Credentials } from './Credentials';
import { SharedInfoService } from '../partsCatalog/sharedInfo.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginSharedService } from './login-shared.service';
import { Store } from '@ngxs/store';
import { ApplicationState } from '../state/app.state';
import { flatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { DI } from '../state/core';

@Injectable()
export class LoginService extends DI.AutoDependencyInjector {
  @DI.Inject(() => HttpClient)
  private _http: HttpClient;

  @DI.Inject(() => SharedInfoService)
  private sharedInfoService: SharedInfoService;

  @DI.Inject(() => LoginSharedService)
  private loginShared: LoginSharedService;

  @DI.Inject(() => Store)
  private store: Store;

  constructor(_injector: Injector) {
    super(_injector);
  }

  public provideUserConsent(): Promise<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const loginInfo = this.loginShared.loginInfo
    return this._http.post(`${AppSettings.AUTH_ENDPOINT}/userconsent`, JSON.stringify({ q: true }), { headers: headers }).toPromise()
      .then(res => Object.assign({}, res, { mergeCartItems: loginInfo.mergeCartItems || false }));
  }

  public saveRequest(): Promise<any> {
    let url = `${AppSettings.AUTH_ENDPOINT}/saveRedirect/1`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.get(url, { headers: headers, responseType: 'text' }).toPromise();
  }

  public authenticate(credentials: Credentials, accept): Promise<any> {
    let url = `${AppSettings.AUTH_ENDPOINT}/authenticate`;
    if (accept) {
      url += '?accept=' + accept;
    }
    return this._http.get(url,
      { headers: new HttpHeaders({ 'Authorization': btoa(`${credentials.user}:${credentials.pass}`) }) }).toPromise();
  }

  public saveESNMDB(data: any): Promise<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = `${AppSettings.API_ENDPOINTS.get('IACDataServices')}/storeEsnData?esn=${data.esn}&smn=${data.smn}&esnGraphic=${data.esnGraphic}`;
    return this._http.post(url, JSON.stringify(data), { headers: headers })
      .toPromise()
      .then((res) => {
        if (res != null) {
          const esn = res;
          if (this.sharedInfoService.savedESNs) {
            if (this.sharedInfoService.savedESNs.length === 5) this.sharedInfoService.savedESNs.pop();
            this.sharedInfoService.savedESNs.unshift(esn);
          }
        }
        return this.getEsnData();
      });
  }

  public getESNMDB(): Promise<any> {
    if (this.sharedInfoService.savedESNs) return new Promise<any>(resolve => resolve(this.getEsnData()));
    let _url = `${AppSettings.API_ENDPOINTS.get('IACDataServices')}/protected/getEsnData`;
    return this.store.selectOnce(ApplicationState.popupMode)
      .pipe(flatMap(popupMode => popupMode ? of({}) : this._http.get(_url)))
      .toPromise().then((res: any) => {
        this.sharedInfoService.savedESNs = res.esnCollection;
        return this.getEsnData();
      });
  }

  private getEsnData(): any {
    const esnArray = this.sharedInfoService.savedESNs || [];
    return {
      collection: esnArray,
      hasFavorite: (esn: string): boolean => {
        for (let esnElement of esnArray) if (esnElement.esn === esn) return true;
        return false;
      }
    };
  }

  public removeESNMDB(esn: string): Promise<any> {
    let url = `${AppSettings.API_ENDPOINTS.get('IACDataServices')}/protected/removeEsnData?esn=${esn}`;
    return this._http.get(url)
      .toPromise().then((res) => {
        const deleted = res;
        if (deleted) {
          this.sharedInfoService.savedESNs = this.sharedInfoService.savedESNs.filter(esnInfo => esnInfo.esn !== esn);
        }
        return deleted;
      });
  }
}
