import { Pipe, PipeTransform } from "@angular/core";
import { BlobStorageService } from "../cms-editor/services/api-services/cms-blob.service";

/**
 * Angular Pipe to read any cms page content to represent on HTML templates
 */
@Pipe({ name: 'cmsResource' })
export class CMSResourceInjector implements PipeTransform {
    readonly parser: DOMParser;

    constructor(private blobStorageService: BlobStorageService) {
        this.parser = new DOMParser();
    }

    async transform(path: string): Promise<string> {
        try {
            const htmlText = await this.blobStorageService.getCMSContent(encodeURIComponent(`/iac/${path}`), 'fetch');
            const htmlNode = this.parser.parseFromString(htmlText, 'text/html');
            const innerHtml = htmlNode.querySelector('body section').innerHTML;
            return `<section>${innerHtml}</section>`;
        } catch (error) {
            const errorText = `Resource fetch failed [${error.status}].`;
            return `<section>${errorText}</section>`;
        }
    }
}
