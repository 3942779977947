import { Injectable } from '@angular/core';
@Injectable()
export class IACTranslationsService {

    private validLanguages = ['en','es','fr','pt-br','pt','ru','zh']; 

    public isValidLanguage(language: any):boolean{
        if(this.validLanguages.indexOf(language) >=0)
            return true;
        else
            return false;

    }
}