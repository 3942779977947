import { NavigationExtras } from "@angular/router";
import { StateOperator } from "@ngxs/store";

export class RestoreAppState {
    static readonly type = "[App State] Restore";
}

export class PersistAppState {
    static readonly type = "[App State] Persist";

    constructor(public persist: boolean) { }
}

export namespace Navigation {
    export interface Options {
        extras?: NavigationExtras;
        stateModifiers?: StateOperator<any>[];
    }

    export interface PopupOptions extends Options {
        windowTarget?: string;
    }

    export class Route {
        static readonly type = "[Router] In App Navigation";

        constructor(public url: string[], public options?: Options) { }
    }

    export class TabRoute {
        static readonly type = "[Router] NewTab Navigation";

        constructor(public url: string[], public options?: Options) { }
    }

    export class PopupRoute {
        static readonly type = "[Router] Popup Navigation";

        constructor(public url: string[], public options?: PopupOptions) { }
    }
}
