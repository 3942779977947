import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs-compat';
import { debounceTime, map, share } from 'rxjs/operators';
import { LoginSharedService } from '../login/login-shared.service';
import { Integration } from './Integration';
import { DI } from '../state/core';

@Injectable()
export class IntegrationService extends DI.AutoDependencyInjector {
    private _data: Integration;
    public valid: boolean = false;

    @DI.Inject(() => LoginSharedService)
    private loginService: LoginSharedService;

    set data(_data: Integration) {
        this.valid = _data ? true : false;
        this._data = _data;
    }
    get data(): Integration {
        return this._data || {
            features: [],
            language: 'en',
            redirectTo: '/home',
            vendor: 'IAC',
            vendorCartId: '',
            userType: '',
            searchCriteria: '',
            transactionId: ''
        };
    }

    constructor(_injector: Injector) {
        super(_injector);
    }

    public getIntegrationUser(): Observable<string> {
        return this.loginService.notificationLoginInfo$
            .pipe(map(loginInfo => {
                if (!this.loginService.isIntegrationAccess(loginInfo)) {
                    return undefined;
                }
                const regex: RegExp = /^INTEGRATION_FLOW \((.+)\)$/;
                const attributes = loginInfo || [];
                for (let attribute of attributes) {
                    for (let feature of (attribute.iacFeaturesCollection || [])) {
                        if (regex.test(feature.featureDescription)
                            && (feature.contexts || []).filter(x => x === 'IACDataServices').length === 1) {
                            return feature.featureDescription.replace(regex, "$1");
                        }
                    }
                }
                return undefined;
            })).pipe(share(), debounceTime(0));
    }

    public isIntegrationAccess(): boolean {
        return this.loginService.isIntegrationAccess();
    }
}
