import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { TreeNodeComponent } from './tree-node/treenode.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule, MatProgressBarModule } from '@angular/material';
//import { AjaxBackGroundLoader } from './shared-components/background-xhr.component';
import { AjaxImageLoader } from './shared-components/img-xhr.component';
import { SpriteDirective } from './shared-components/sprite.directive';
import { PopoverModule } from 'ngx-popover';
import { MyPopoverRenderer } from './shared-components/my-popover.component';
import { AjaxBackGroundLoader } from './shared-components/background-xhr-image.component';
import { NoticesComponent } from './shared-components/notices.component';
import { DialogModule } from 'primeng/primeng';
import { ResponsiveModule } from 'ngx-responsive';
import { InjectorFrameComponent } from './shared/injector-frame/injectorFrame.component';
import { LazyLoadImageModule, scrollPreset } from 'ng-lazyload-image';
import { setErrorImage } from './shared-components/img-xhr.component';
import { FocusDirective } from './shared-components/focusDirective.directive';
import { EnvironmentPipe } from './shared-components/env.pipe';
import { EnvironmentResourcePipe } from './shared-components/env-resource.pipe';
import { CarouselComponent } from './shared-components/app-carousel.component';
import { NavigationDirective } from './shared-components/navigation.directive';
import { LinkWrapperComponent } from './shared-components/link-wrapper/link-wrapper.component';
import { CMSResourceInjector } from './shared/cms-resource-injector.pipe';
import { SanitizeHtmlPipe } from './shared/sanitizeHtmlPipe';
import { FPTComponent } from './shared/freq-purchase-together/fpt.component';
import { CarouselModule as CarouselModuleNG } from 'primeng/primeng';
import { PartGraphicPathPipe } from './shared/part-graphics.pipe';
import { SanitizeUrlPipe } from './shared/sanitize-url.pipe';
import { PageLinkPipe } from './shared/page-links.pipe';
import { AjaxDownloadDirective } from './shared-components/ajax-download.directive';

export function TranslateLoaderFactory(http1: HttpClient) {
  return new TranslateHttpLoader(http1, './assets/i18n/', '.json');
}

//autofocus removed
@NgModule({
  imports: [
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: (TranslateLoaderFactory),
        deps: [HttpClient]
      }
    }),
    CommonModule,
    PopoverModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    DialogModule,
    ResponsiveModule,
    LazyLoadImageModule.forRoot({
      preset: scrollPreset,
      setErrorImage
    }),
    CarouselModuleNG
  ],
  declarations: [
    TreeNodeComponent,
    AjaxImageLoader,
    AjaxBackGroundLoader,
    SpriteDirective,
    FocusDirective,
    MyPopoverRenderer,
    NoticesComponent,
    InjectorFrameComponent,
    EnvironmentPipe,
    EnvironmentResourcePipe,
    CarouselComponent,
    LinkWrapperComponent,
    NavigationDirective,
    CMSResourceInjector,
    SanitizeHtmlPipe,
    FPTComponent,
    PartGraphicPathPipe,
    SanitizeUrlPipe,
    PageLinkPipe,
    AjaxDownloadDirective
  ],
  exports: [
    TranslateModule,
    TreeNodeComponent,
    AjaxImageLoader,
    AjaxBackGroundLoader,
    SpriteDirective,
    FocusDirective,
    PopoverModule,
    MyPopoverRenderer,
    NoticesComponent,
    InjectorFrameComponent,
    EnvironmentPipe,
    EnvironmentResourcePipe,
    CarouselComponent,
    LinkWrapperComponent,
    NavigationDirective,
    CMSResourceInjector,
    SanitizeHtmlPipe,
    FPTComponent,
    PartGraphicPathPipe,
    SanitizeUrlPipe,
    PageLinkPipe,
    AjaxDownloadDirective
  ]
})

export class SharedModule {
}
