import { Component, AfterViewInit, OnInit, OnDestroy, Injector } from '@angular/core';
import { SessionStorageService, SessionStorage } from 'ngx-webstorage';
import { LogoutService } from '../../logout/logout.service';
import { LoginSharedService } from '../../login/login-shared.service';
import { Router } from "@angular/router";
import { ToasterService, ToasterConfig, Toast } from 'angular2-toaster';
import { NotificationService } from './notification.service';
import { Subscription } from 'rxjs/Subscription';
import { TranslateService } from '@ngx-translate/core';
import { HeaderService } from './header.service';
import {IntegrationService } from '../../integration/integration.service';
import {
    FormControl, FormGroup, FormBuilder
} from '@angular/forms';
import { AppSettings } from '../../app.settings';
import { AppState } from '../../app.service';
import { SharingServices } from '../../cms-editor/services/sharing-service';
import { SharedInfoService } from '../../partsCatalog/sharedInfo.service';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { DownloadEvent, SbomService } from '../sbom.service';
import { ResourceLoaderService } from '../../resource-loader.service';
import { CartService } from '../../cart/cart.service';
import { ApplicationState } from 'src/app/state/app.state';
import { Select, Store } from '@ngxs/store';
import { DI } from 'src/app/state/core';
import { Navigation } from 'src/app/state/app.actions';
import { FeatureChecker } from '../feature-checker.service';
import { NotificationService as GenericNotificationService } from '../generic-notification/notification.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'header',
    templateUrl: 'header.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent extends DI.AutoDependencyInjector implements AfterViewInit, OnInit, OnDestroy {
    @Select(ApplicationState.popupMode)
    popupMode$: Observable<boolean>;

    @SessionStorage('USERID')
    private _userId: string;

    private integrationUserId: string;

    public get userId(): string {
        return this.integrationUserId || this._userId;
    }

    public get isIntegrationFlow(): boolean {
        return !!this.integrationUserId;
    }

    public cnt: string;
    public cartLoading: boolean = true;
    public initLoading: boolean = false;

    @SessionStorage('esn')
    private esn: string;

    private subscription: Subscription;
    public languageFlag: string;
    public toasterconfig: ToasterConfig =
        new ToasterConfig({
            showCloseButton: true,
            tapToDismiss: true,
            positionClass: 'toast-top-full-width',
            timeout: -1,
            limit: 3
        });

    public showSearch: boolean = true;

    public storeUrl : string = `${AppSettings.PUBLICATIONS_STORE}`;
    public lang_img: string;
    public searchForm: FormGroup;
    public srchCriteria: any;
    public menu;
    public application;
    public placeholder = {
        title: 'Search ESN/ATSN/GSN/Part Number or Description across all products'
    }

    public adminView = `${AppSettings.ADMIN_VIEW}/applications`;
    
    public accessFlags = {
        dealerNewsLetterPage: false,
        isSuperAdmin: false,
        isAdmin: false,
        isCMSAdmin : false,
        showSurveyLink:false,
        isUploadUpfitAllowed: false
    };
    public isServiceToolsAdmin: boolean;

    @DI.Inject(() => SessionStorageService)
    private storage: SessionStorageService;

    @DI.Inject(() => TranslateService)
    private translate: TranslateService;

    @DI.Inject(() => LogoutService)
    private logoutService: LogoutService;

    @DI.Inject(() => LoginSharedService)
    private loginShared: LoginSharedService;

    @DI.Inject(() => Router)
    private router: Router;

    @DI.Inject(() => ToasterService)
    private toasterService: ToasterService;

    @DI.Inject(() => NotificationService)
    private notificationService: NotificationService;

    @DI.Inject(() => HeaderService)
    private headerService: HeaderService;

    @DI.Inject(() => FormBuilder)
    private fb: FormBuilder;

    @DI.Inject(() => IntegrationService)
    private integration: IntegrationService;

    @DI.Inject(() => AppState)
    private appService: AppState;

    @DI.Inject(() => SharingServices)
    protected sharing: SharingServices;

    @DI.Inject(() => SharedInfoService)
    private _partsSharedService: SharedInfoService;

    @DI.Inject(() => SbomService)
    private _sbomService: SbomService;

    @DI.Inject(() => CartService)
    private cartService: CartService;

    @DI.Inject(() => ResourceLoaderService)
    private _resourceLoader: ResourceLoaderService;

    @DI.Inject(() => Store)
    private store: Store;

    @DI.Inject(() => FeatureChecker)
    private featureChecker: FeatureChecker;

    @DI.Inject(() => GenericNotificationService)
    private genericNotificationService: GenericNotificationService;

    constructor(_injector: Injector) {
        super(_injector);
        this.lang_img = this.appService.getAssetsLink('/icons/language_change_icon.png', true);
        this.subscription = this.cartService.getCartItemsCount().subscribe(cnt => this.cnt = cnt + '');
        this.subscription.add(this._sbomService.loaderEvent$
            .pipe(filter(event => event && event.type === 'error'))
            .subscribe(event => {
                let errMsg = 'Service BOM download has failed. Please try again later or contact help desk.';
                this.translate.get(errMsg).subscribe((toast: string) => {
                    var esndataToast: Toast = {
                        type: 'error',
                        title: toast,
                        timeout: 3000
                    };
                    this.toasterService.pop(esndataToast);

                });
            }));
        this.subscription.add(this.integration.getIntegrationUser()
            .subscribe(userId => this.integrationUserId = userId));
        this.translate.setDefaultLang('en');
        this.createForm();
    }

    public getAssetsLink(path: string): string {
        return this.appService.getAssetsLink(path, true);
    }

    createForm() {
        this.searchForm = this.fb.group({
            searchValue: ['', checkSearchValidation]

        });
    }

    public ngAfterViewInit() {
        this._resourceLoader.loadResource({ src:'assets/js/common.js', type: 'script' })
            .subscribe(() => {}, error => console.error(error));
        this.sharing.publishToken.subscribe((message:any) => {
            if(message && message.children != null){
                this.menu = message.children;
                this.application = message.name;
            }
        });        
    }

    public ngOnInit() {
        this.subscription.add(this.notificationService.notifications$.subscribe(x => this.toasterService.pop(x)));
        this.subscription.add(this.loginShared.checkFeatureAccess('DEALER_NEWS').subscribe(flag => this.accessFlags.dealerNewsLetterPage = flag));
        this.subscription.add(this.loginShared.checkFeatureAccess('Admin', 'adminservices').subscribe(flag => this.accessFlags.isAdmin = flag));
        this.subscription.add(this.loginShared.checkFeatureAccess('Super Admin', 'adminservices').subscribe(flag => this.accessFlags.isSuperAdmin = flag));
        this.subscription.add(this.loginShared.checkFeatureAccess('CMS_USER', 'IACDataServices').subscribe(flag => this.accessFlags.isCMSAdmin = flag));
        this.subscription.add(this.headerService.registerSearchUpdates((data) => {
            if (data.func === "input") this.srchCriteria = data.input;
        }));

        this.subscription.add(this.loginShared.checkFeatureAccess('MST_ADMIN','mstwebservice').subscribe(flag => this.isServiceToolsAdmin = flag));
        this.subscription.add(this.loginShared.checkFeatureAccess('UPFITS_UPLOAD').subscribe(flag => this.accessFlags.isUploadUpfitAllowed = flag));
        
        this.sharing.publishToken.subscribe((message:any) => {
            if(message && message.children != null){
                this.menu = message.children;
                this.application = message.name;
            }
        });

        this.cartService.notifyCartLoaded(() => this.cartLoading = false, () => this.initLoading = true);
        
        this.storage.observe('USERID')
            .subscribe((newValue) => {
                console.log('USER' + newValue);
            })
        if (this._userId == null || this._userId == '') {
            this._userId = 'Login';
        }
        if (this.cnt == null || this.cnt == '') {
            this.cnt = '0';
        }
        this.genericNotificationService.loading$.subscribe(async event => {
            if (!event.showLoading) {
                this.accessFlags.showSurveyLink = await this.featureChecker.applyFeature('SHOW_SURVEY_LINK');
            }
        });
    }

    public redirectToQsol(): void {
        const time = Date.now();
        let token = "";
        let urlToRedirect =  "";
        if(this.integration.data.vendor !== 'IAC' && this.integration.data.vendorCartId !== ''){
            token = this.integration.data.vendor + "|" + this.integration.data.vendorCartId + "|" + time;
            token =  btoa(token);
            urlToRedirect = `${AppSettings.QUICK_SERVE}/cola2/login/lnkmgmt/parts.html?tokenId=${token}&esn=${this.esn}
                &usertype=${btoa(this.integration.data.userType || '')}&transaction=${btoa(this.integration.data.transactionId || '')}`;
        }else{
            token = 'IAC|'+time;  
            token =  btoa(token);
            if(this.esn)
                 urlToRedirect = `${AppSettings.QUICK_SERVE}/cola2/login/lnkmgmt/home.html?tokenId=${token}&esn=${this.esn}`;
            else
                urlToRedirect =  `${AppSettings.QUICK_SERVE_LOGIN}`;
        }
      
        window.open(urlToRedirect,"_blank");
    }

    public checkLogout(): boolean {
        if (this.userId != 'Login') {
            return true;
        }
        else {
            return false;
        }
    }

    public logout(): void {
        this.logoutService.killSession().then(logoutURL => {
            this.appService.processResult(null);
            window.location.href = logoutURL;
        });
    }

    public ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public switchLanguage(language: string) {
        this.translate.use(language);

        this.notificationService.notificationtranslate1(language);
        this.notificationService.notificationTranslateView$.subscribe(message => this.languageFlag = message)

    }

    public mouseEnter() {
        this.lang_img = this.appService.getAssetsLink('/icons/language_dark.png', true);
    }


    public mouseLeave() {
        this.lang_img = this.appService.getAssetsLink('/icons/language_change_icon.png', true);
    }

    public goToSearch() {

        this.showSearch = false;
        this.router.navigate(['/global-search/main/', this.searchForm.value.searchValue]);
        this.headerService.updateSearchView(this.srchCriteria);
        this.showSearch = true;

    }
    public gotoSavedEsn() {
        this.router.navigate(['/saved-esn']);
    }
    public goToSearchHelp() {
        this.router.navigate(['/global-search/help/']);
    }

    public gotoEditor() :void{
        this.router.navigate(['/cms-editor']);
        
    }
    
    cilckme(){
        window.open('https://www.cummins.com/support/product-registration', '_blank', 'noopener,noreferrer');
    }

    public async openHelpDeskPage() {
        this.store.dispatch(new Navigation.PopupRoute([environment.HELPDESK.PRODUCT], {
            windowTarget: 'help-desk-page'
        }
        ));
    }

    get showSbomMenu$(): Observable<boolean> {
        return combineLatest([
            this.loginShared.checkFeatureAccess('SHOW_SBOM'),
            this._partsSharedService.currentEsnInfo$
        ]).pipe(map(([showSbomMenu, esnInfo]: [boolean, any]) => showSbomMenu 
            && (esnInfo.mbom === 'mbom' || esnInfo.mbom === 'legacy')));
    }

    get esn$(): Observable<string> {
        return this._partsSharedService.currentEsnInfo$.pipe(map(esnInfo => esnInfo.esn));
    }

    get sbomLoaderEvents$(): Observable<DownloadEvent> {
        return this._sbomService.loaderEvent$;
    }

    public downloadSbom(event: MouseEvent) {
        this._sbomService.downloadSbom(this._partsSharedService);
        event.preventDefault();
    }

    get isLoggedIn(): boolean {
        return this.loginShared.isLoggedIn();
    }
}

function checkSearchValidation(input: FormControl) {
    var patt = new RegExp("^[0-9]+$");
    let dispAplhaNumericError: boolean = false;
    let dispDigitError: boolean = false;
    if (input.value != '' && typeof input.value != 'undefined') {

        if (patt.test(input.value)) {
            dispDigitError = true;
            if (input.value.length > 3) { return null; }

        } else if (input.value.match(/[a-z]/i)) {
            dispAplhaNumericError = true;
            if (input.value.length > 2) { return null; }
        }

        if (dispAplhaNumericError && dispDigitError === false) {
            return { needsAplhaNumeric: true };
        }

        if (dispDigitError && dispAplhaNumericError === false) {
            return { needsDigit: true };
        }
    }
}
