<ng-container *ngIf="error > 0">
    <div class="container-fluid vert-offset-top-4 vert-offset-bottom-4">
        <div class="row">
            <div class="col-md-6 col-md-offset-3 text text-danger" [style.text-align]="'center'">
                <i class="glyphicon glyphicon-alert"></i>
                <span *ngIf="401">{{'Invalid Token Used' | translate}}</span>
            </div>
        </div>
        <div class="row vert-offset-top-1">
            <div class="col-md-6 col-md-offset-3" [style.text-align]="'center'">
                <i class="fa fa-spinner fa-spin"></i>
                <span>Please wait while we redirect you to home page.</span>
            </div>
        </div>
    </div>
</ng-container>