import { RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ErrorComponent } from './error/error.component';
import { IntegrationComponent } from './integration/integration.component';
import { TicketCompleteComponent } from './ticket-complete/ticket-complete.component';
import { DealerNewslettersComponent } from './dealer-newsletters/dealer-newsletters.component';
import { LoginAuthGuard } from './auth.guard';
import { AccessCheckComponent } from './shared/check-access/check-access.component';
import { SAMLComponent } from './saml/saml.component';
import { RouteProtectionGuard } from './route-gaurds/route-protect.service';
var ɵ0 = {
    breadcrumb: "Login"
}, ɵ1 = function () { return import("./cart/cart.module.ngfactory").then(function (m) { return m.CartModuleNgFactory; }); }, ɵ2 = function () { return import("./contactUs/contactUs.module.ngfactory").then(function (m) { return m.ContactUsModuleNgFactory; }); }, ɵ3 = function () { return import("./partsCatalog/partsCatalog.module.ngfactory").then(function (m) { return m.PartsCatalogModuleNgFactory; }); }, ɵ4 = function () { return import("./aftertreatment/aftertreatment.module.ngfactory").then(function (m) { return m.AftertreatmentModuleNgFactory; }); }, ɵ5 = function () { return import("./power-gen/power-gen.module.ngfactory").then(function (m) { return m.PowerGenModuleNgFactory; }); }, ɵ6 = function () { return import("./turbo-chargers/advanced-search/advanced-search.module.ngfactory").then(function (m) { return m.AdvancedSearchModuleNgFactory; }); }, ɵ7 = function () { return import("./mechanical-service-tools/entry/mst-entry.module.ngfactory").then(function (m) { return m.MstEntryModuleNgFactory; }); }, ɵ8 = function () { return import("./mechanical-service-tools/admin/mst-admin.module.ngfactory").then(function (m) { return m.MstAdminModuleNgFactory; }); }, ɵ9 = function () { return import("./promotions/promotions.module.ngfactory").then(function (m) { return m.PromotionsModuleNgFactory; }); }, ɵ10 = function () { return import("./generic/generic.module.ngfactory").then(function (m) { return m.GenericModuleNgFactory; }); }, ɵ11 = function () { return import("./global-search/global-search.module.ngfactory").then(function (m) { return m.GlobalSearchModuleNgFactory; }); }, ɵ12 = function () { return import("./saved-esn/savedEsn.module.ngfactory").then(function (m) { return m.SavedEsnModuleNgFactory; }); }, ɵ13 = function () { return import("./parts-compatibility/parts-compatibility.module.ngfactory").then(function (m) { return m.PartsCompatibilityModuleNgFactory; }); }, ɵ14 = {
    breadcrumb: "Dealer Promotions Updates"
}, ɵ15 = function () { return import("./kits-set-view/kits-set-view.module.ngfactory").then(function (m) { return m.KitsSetViewModuleNgFactory; }); }, ɵ16 = function () { return import("./cms-editor/cms-editor.module.ngfactory").then(function (m) { return m.CMSEditorModuleNgFactory; }); }, ɵ17 = function () { return import("./resource/resource.module.ngfactory").then(function (m) { return m.ResourceModuleNgFactory; }); }, ɵ18 = function () { return import("./upfits/upfits.module.ngfactory").then(function (m) { return m.UpfitsModuleNgFactory; }); };
var routes = [
    {
        path: '',
        children: [
            {
                path: 'saml',
                component: SAMLComponent
            },
            {
                path: 'integration',
                component: IntegrationComponent
            },
            {
                path: '',
                pathMatch: 'full',
                redirectTo: '/home'
            },
            {
                path: 'home',
                component: HomeComponent
            },
            {
                path: 'login',
                component: LoginComponent,
                data: ɵ0
            },
            {
                path: 'error',
                component: ErrorComponent
            },
            {
                path: 'wish-list',
                loadChildren: ɵ1
            },
            {
                path: 'contact-us',
                loadChildren: ɵ2
            },
            {
                path: 'esn-entry',
                loadChildren: ɵ3
            },
            {
                path: 'atsn-entry',
                loadChildren: ɵ4
            },
            {
                path: 'gsn-entry',
                loadChildren: ɵ5
            },
            {
                path: 'ctt-entry',
                loadChildren: ɵ6
            },
            {
                path: 'mst-entry',
                loadChildren: ɵ7
            },
            {
                path: 'mst-admin',
                loadChildren: ɵ8
            },
            {
                path: 'promotions',
                loadChildren: ɵ9
            },
            {
                path: '',
                loadChildren: ɵ10
            },
            {
                path: 'global-search',
                loadChildren: ɵ11
            },
            {
                path: 'saved-esn',
                loadChildren: ɵ12
            },
            {
                path: 'parts-compatibility',
                loadChildren: ɵ13
            },
            {
                path: 'ticket-complete/:ticketId',
                component: TicketCompleteComponent
            },
            {
                path: 'dealer-newsletters',
                component: DealerNewslettersComponent,
                data: ɵ14,
                canActivate: [LoginAuthGuard]
            },
            {
                path: 'access/:path',
                component: AccessCheckComponent
            },
            {
                path: 'kits-set-view',
                loadChildren: ɵ15
            },
            {
                path: 'cms-editor',
                loadChildren: ɵ16
            },
            {
                matcher: htmlFiles,
                loadChildren: ɵ17
            },
            {
                path: 'upfits',
                loadChildren: ɵ18
            },
            {
                path: '**',
                redirectTo: 'home'
            },
        ],
        canActivateChild: [RouteProtectionGuard]
    }
];
export var ROUTES = RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
});
export function htmlFiles(url) {
    return url.length > 1 && ['resource'].includes(url[0].path) ? ({ consumed: url }) : null;
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18 };
