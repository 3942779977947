var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Injector, Type } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { DI } from '../state/core';
var ɵ0 = function () { return Router; }, ɵ1 = function () { return ActivatedRoute; };
var BreadcrumbsService = /** @class */ (function (_super) {
    __extends(BreadcrumbsService, _super);
    function BreadcrumbsService(_injector) {
        var _this = _super.call(this, _injector) || this;
        _this.routeComponentMap = new Map();
        _this.currentBreadCrumb = new Subject();
        _this.currentBreadCrumb$ = _this.currentBreadCrumb.asObservable();
        return _this;
    }
    BreadcrumbsService.prototype.updateBreadCrumb = function (routes) {
        this.currentBreadCrumb.next(routes);
    };
    BreadcrumbsService.prototype.resolveActiveComponent = function (outletContext) {
        var _this = this;
        if (outletContext === void 0) { outletContext = 'primary'; }
        var getActiveRouterOutlet = function (routeContext, childOutletContexts) {
            if (childOutletContexts === void 0) { childOutletContexts = _this.router['rootContexts']; }
            var routerOutletContext = childOutletContexts.getContext(routeContext) || childOutletContexts.getContext('primary');
            if (!routerOutletContext)
                return null;
            var outlet = getActiveRouterOutlet(routeContext, routerOutletContext.children);
            if (outlet && outlet.isActivated)
                return outlet;
            return routerOutletContext.outlet && routerOutletContext.outlet.isActivated ? routerOutletContext.outlet : null;
        };
        var routerOutlet = getActiveRouterOutlet(outletContext);
        return routerOutlet && routerOutlet.component;
    };
    BreadcrumbsService.prototype.resolveActivatedRoute = function (outletContext) {
        if (outletContext === void 0) { outletContext = 'primary'; }
        function postFixTraversal(component, route) {
            if (route.children && route.children.length > 0) {
                return route.children.map(function (childRoute) { return postFixTraversal(component, childRoute); }).find(function (r) { return !!r; }) || null;
            }
            return route.component === component && route.outlet === outletContext ? route : null;
        }
        var component = this.resolveComponentType(this.router.url);
        return postFixTraversal(component, this.activeRoute);
    };
    BreadcrumbsService.prototype.resolveComponentType = function (routeUrl, persist) {
        if (persist === void 0) { persist = true; }
        function matchRoute(_urlParts, route, exact) {
            if (exact === void 0) { exact = false; }
            if (typeof route.path !== 'string')
                return false;
            var counter = _urlParts.length;
            var routeParts = route.path.split('/').map(function (r) { return r.trim(); });
            for (var _i = 0, _urlParts_1 = _urlParts; _i < _urlParts_1.length; _i++) {
                var urlPart = _urlParts_1[_i];
                if (routeParts.length === 0) {
                    return !exact || counter === 0;
                }
                else if (routeParts[0] === urlPart || routeParts[0].startsWith(':')) {
                    --counter;
                    routeParts.shift();
                }
            }
            return routeParts.length === 0;
        }
        function resolve(_urlParts, routes) {
            var _loop_1 = function (urlPart) {
                var route = routes.filter(function (_route) { return matchRoute([urlPart], _route) || matchRoute(_urlParts, _route); })
                    .sort(function (a, b) { return b.path.length - a.path.length; }).find(function (_) { return true; });
                if (!route)
                    return "continue";
                if ((matchRoute([urlPart], route) && _urlParts.length === 1) || matchRoute(_urlParts, route, true)) {
                    return { value: route.component };
                }
                else if (route.children) {
                    return { value: resolve(_urlParts.slice(1), route.children) };
                }
                else if (route.loadChildren) {
                    return { value: resolve(_urlParts.slice(1), route['_loadedConfig'].routes) };
                }
            };
            for (var _i = 0, _urlParts_2 = _urlParts; _i < _urlParts_2.length; _i++) {
                var urlPart = _urlParts_2[_i];
                var state_1 = _loop_1(urlPart);
                if (typeof state_1 === "object")
                    return state_1.value;
            }
        }
        if (typeof routeUrl !== 'string') {
            return undefined;
        }
        else if (routeUrl === '/' || routeUrl.trim() === '') {
            routeUrl = '';
        }
        else if (!routeUrl.startsWith('/')) {
            routeUrl = "/" + routeUrl;
        }
        var url = routeUrl.trim();
        if (this.routeComponentMap.has(url)) {
            return this.routeComponentMap.get(url);
        }
        var urlParts = url.split('/').map(function (r) { return r.trim(); });
        var component = resolve(urlParts, this.router.config);
        if ((persist || this.router.url === url) && component) {
            this.routeComponentMap.set(url, component);
        }
        return component;
    };
    __decorate([
        DI.Inject(ɵ0),
        __metadata("design:type", Router)
    ], BreadcrumbsService.prototype, "router", void 0);
    __decorate([
        DI.Inject(ɵ1),
        __metadata("design:type", ActivatedRoute)
    ], BreadcrumbsService.prototype, "activeRoute", void 0);
    return BreadcrumbsService;
}(DI.AutoDependencyInjector));
export { BreadcrumbsService };
export { ɵ0, ɵ1 };
