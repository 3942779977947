var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppSettings } from '../../app.settings';
import { HttpClient } from '@angular/common/http';
import { PartServiceAbstract } from './part.service';
import { ResourceLoaderService } from '../../resource-loader.service';
var PartDetailApiService = /** @class */ (function (_super) {
    __extends(PartDetailApiService, _super);
    function PartDetailApiService(http, _resourceLoader) {
        var _this = _super.call(this, _resourceLoader) || this;
        _this.http = http;
        _this.baseUrl = AppSettings.API_ENDPOINTS.get('IACDataServices') + "/protected/partDetails?partNo=";
        _this.gomsUrl = AppSettings.API_ENDPOINTS.get('gomsclient') + "/protected/parts/getPartAvailability?part=";
        _this.loadUrlAsBuffer = _this.loadUrlAsBuffer.bind(_this);
        _this.relatedPartsUrl = AppSettings.API_ENDPOINTS.get('IACDataServices') + "/protected/relatedParts?partNo=";
        return _this;
    }
    PartDetailApiService.prototype.loadUrlAsBuffer = function (url, type, callback) {
        var responseTypeVal;
        switch (type) {
            case "array":
                responseTypeVal = 'arraybuffer';
                break;
            case "blob":
                responseTypeVal = 'blob';
                break;
            case "json":
                responseTypeVal = 'json';
                break;
            default: responseTypeVal = 'text';
        }
        this.http.get(url, { responseType: responseTypeVal })
            .toPromise()
            .then(function (resp) {
            // let body :any = null;
            // switch(type) {
            //     case "array": body = resp.arrayBuffer();
            //         break;
            //     case "blob": body = resp.blob();
            //         break;
            //     case "json": body = resp.json();
            //         break;
            //     default: body = resp.text();
            // }
            // if(resp.status === 200 && callback && callback.onReady) callback.onReady(body);
            // else callback.onError();
            callback.onReady(resp);
        })
            .catch(function (err) { return callback.onError(); });
    };
    PartDetailApiService.prototype.fetchPartDetails = function (partNo, smn) {
        return this.http.get("" + this.baseUrl + partNo + "&smn=" + (smn || ''))
            .pipe(map(function (response) { return response; }))
            //.map((response) => response)
            .pipe(map(function (data) {
            if (data && data.whereUsed) {
                data.filteredPartsCompatibility = data.whereUsed
                    .filter(function (x) { return x.itemType &&
                    (x.itemType.toLocaleLowerCase() === 'p' || x.itemType.toLocaleLowerCase() === 'k' || x.itemType.toLocaleLowerCase() === 'c'); });
            }
            if (data && data.relatedParts) {
                data.associatedPartsData = data.relatedParts.filter(function (x) { return x.rank; });
            }
            return data;
        })).pipe(catchError(this.handleError));
    };
    PartDetailApiService.prototype.fetchAvailability = function (partNo) {
        return this.http.get("" + this.gomsUrl + partNo)
            .pipe(map(function (response) { return response; }))
            .pipe(catchError(this.handleError));
    };
    PartDetailApiService.prototype.handleError = function (error) {
        console.error(error);
        return observableThrowError(error || 'Server Error');
    };
    PartDetailApiService.prototype.getRelatedParts = function (partNo, size, smn) {
        var _url = "" + this.relatedPartsUrl + partNo + "&size=" + size;
        if (smn)
            _url += "&smn=" + smn;
        return this.http.get(_url).pipe(catchError(this.handleError));
    };
    return PartDetailApiService;
}(PartServiceAbstract));
export { PartDetailApiService };
