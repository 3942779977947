export namespace EngineBOM {
    export class SetESNInfo {
        static readonly type = "[ESN Info] Set Info";

        constructor(public esnInfo: any) { }
    }

    export class SetCurrentESN {
        static readonly type = "[ESN] Set Current Active";

        constructor(public esn: string) { }
    }

    export class SetKitSets {
        static readonly type = "[ESN Info] Set Kitsets";

        constructor(public kitSets?: any[]) { }
    }

    export class SetGaskets {
        static readonly type = "[ESN Info] Set Gaskets";

        constructor(public gasketSets?: any[]) { }
    }

    export class SetDataplate {
        static readonly type = "[ESN Info] Set Dataplate";

        constructor(public dataplate: any[]) { }
    }

    export class SetPackageDataplate {
        static readonly type = "[ESN Info] Set Package Dataplate";

        constructor(public dataplate: any[]) { }
    }

    export class SetPackageInfo {
        static readonly type = "[ESN Info] Set Package Info";

        constructor(public packageInfo: any) { }
    }

    export class SetInjectorTrims {
        static readonly type = "[ESN Info] Set Injector Trims";

        constructor(public injectorTrims?: any[]) { }
    }

    export namespace Package {
        export class SetEngineInfo {
            static readonly type = "[Package ESN Info] Set Engine Info";

            constructor(public esnInfo: any) { }
        }

        export class SetEngineDataplate {
            static readonly type = "[Package ESN Info] Set Engine Dataplate";

            constructor(public dataplate: any[]) { }
        }

        export class SetEngineInjectorTrims {
            static readonly type = "[Package ESN Info] Set Injector Trims";

            constructor(public injectorTrims?: any[]) { }
        }
    }
}
