var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from '@angular/core';
import { LoginService } from './login.service';
import { Router } from "@angular/router";
import { LocalStorage } from 'ngx-webstorage';
import { Credentials } from './Credentials';
import { NotificationService } from '../shared/header/notification.service';
import { CartService } from '../cart/cart.service';
import { AppSettings } from '../app.settings';
import { AppState } from '../app.service';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(loginService, router, cartService, notificationService, appState) {
        this.loginService = loginService;
        this.router = router;
        this.cartService = cartService;
        this.notificationService = notificationService;
        this.appState = appState;
        this.placeholder = {
            username: 'Username',
            password: 'Password',
        };
        this.showProgress = false;
        this.agreementModal = false;
        this.showProgressAccept = false;
        this.disabledContinue = false;
        this.disabledLogin = false;
        this.completeSubscription = false;
        this.showProgressSub = false;
        this.QUICK_SERVE_COLA = AppSettings.QUICK_SERVE + "/cola2/acctchk/update.html";
        this.PARTS_HOST_NAME = AppSettings.HOST_NAME + "/";
        this.quickserveLinks = {
            terms: AppSettings.QUICK_SERVE + "/info/qsol/terms.html"
        };
        this.subscriptionLink = AppSettings.PUBLICATIONS_STORE + "/#/registration/main";
        this.saveEsn = [];
    }
    LoginComponent.prototype.login = function () {
        var _this = this;
        this.loginService.authenticate(this.credentials, this.accept)
            .then(function (res) {
            //type: type, title: title
            _this.showProgress = false;
            _this.agreementModal = false;
            _this.disabledLogin = false;
            if ((res.extraAttributes || {}).usertype === "2") {
                _this.agreementModal = true;
            }
            else {
                _this.notificationService.notify({ type: 'success', title: 'Successfully logged in', timeout: 3000 });
                _this.appState.processResult(res);
                _this.router.navigate(['home'])
                    .then(function (didNavigate) { return didNavigate && _this.appState.publishSessionChange(res); });
                _this.saveEsnMDB();
            }
        })
            .catch(function (err) {
            _this.showProgress = false;
            _this.showProgressAccept = false;
            _this.disabledLogin = false;
            if (err.status === 401) {
                _this.notificationService.notify({ type: 'error', title: 'Username/Password incorrect, please try again', timeout: 3000 });
            }
            else if (err.status == 302) {
                _this.completeSubscription = true;
            }
            else {
                console.log("Error ==>" + JSON.stringify(err));
                _this.notificationService.notify({ type: 'error', title: 'Login service unavailable, please try again', timeout: 3000 });
            }
        });
    };
    LoginComponent.prototype.ngOnInit = function () {
        this.credentials = new Credentials(null, null);
    };
    LoginComponent.prototype.saveRequest = function () {
        this.loginService.saveRequest()
            .then(function (loginURL) { return window.location.href = loginURL; });
    };
    LoginComponent.prototype.getAssetsLink = function (path) {
        return this.appState.getAssetsLink(path, true);
    };
    LoginComponent.prototype.saveEsnMDB = function () {
        if (this.notificationService.saveEsnData.length > 0) {
            this.saveEsn = [];
            this.loginService.saveESNMDB(this.notificationService.saveEsnData[0])
                .then(function (item) {
                console.log("Data save successfully");
            }).catch(function (error) {
                console.log("ERROR : while data save");
            });
        }
    };
    __decorate([
        LocalStorage('esns'),
        __metadata("design:type", Array)
    ], LoginComponent.prototype, "esnsOrigin", void 0);
    return LoginComponent;
}());
export { LoginComponent };
