var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Injector } from "@angular/core";
import { SessionStorageService } from "ngx-webstorage";
import { STORAGE_PREFIX, STORAGE_SEPARATOR } from "./app.module";
import { DI } from "./state/core";
import { NGXS_STORE_STATE_KEY } from "./state/page-refresh.plugin";
import * as i0 from "@angular/core";
var EMPTY_WEAK_MAP = {
    delete: function (_key) {
        return false;
    },
    get: function (_key) {
        return null;
    },
    set: function (_key, _value) {
        return null;
    }
};
var ɵ0 = function () { return SessionStorageService; };
var StateStorageService = /** @class */ (function (_super) {
    __extends(StateStorageService, _super);
    function StateStorageService(_injector) {
        var _this = _super.call(this, _injector) || this;
        _this.storageKey = ("" + STORAGE_PREFIX + STORAGE_SEPARATOR + NGXS_STORE_STATE_KEY).toLowerCase();
        if (!_this.sessionStoreSupported) {
            var state = _this.fetchState(true);
            if (state) {
                _this.clearState(true);
                _this.persistState(state);
            }
        }
        return _this;
    }
    StateStorageService.prototype.fetchState = function (initial) {
        if (initial === void 0) { initial = false; }
        if (initial) {
            if (this.sessionStoreSupported)
                return null;
            var state = this.openerWindowStates.get(window);
            return state;
        }
        return this.sessionStore.retrieve(NGXS_STORE_STATE_KEY);
    };
    StateStorageService.prototype.clearState = function (initial) {
        if (initial === void 0) { initial = false; }
        if (initial) {
            if (this.sessionStoreSupported)
                return false;
            return this.openerWindowStates.delete(window);
        }
        this.sessionStore.clear(NGXS_STORE_STATE_KEY);
        return !this.sessionStore.retrieve(NGXS_STORE_STATE_KEY);
    };
    StateStorageService.prototype.persistState = function (state, win) {
        if (!state)
            return;
        if (this.sessionStoreSupported || !win) {
            if (win) {
                win.sessionStorage.setItem(this.storageKey, JSON.stringify(state));
            }
            else {
                this.sessionStore.store(NGXS_STORE_STATE_KEY, state);
            }
        }
        else if (win) {
            this.windowStates.set(win, state);
        }
    };
    Object.defineProperty(StateStorageService.prototype, "openerWin", {
        get: function () {
            return window.opener || {};
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateStorageService.prototype, "windowStates", {
        get: function () {
            try {
                return window.OPENED_WIN_STATES || EMPTY_WEAK_MAP;
            }
            catch (_a) {
                return EMPTY_WEAK_MAP;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateStorageService.prototype, "openerWindowStates", {
        get: function () {
            try {
                return this.openerWin.OPENED_WIN_STATES || EMPTY_WEAK_MAP;
            }
            catch (_a) {
                return EMPTY_WEAK_MAP;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StateStorageService.prototype, "sessionStoreSupported", {
        get: function () {
            var notSupported = !!isIE();
            return !notSupported;
        },
        enumerable: true,
        configurable: true
    });
    StateStorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StateStorageService_Factory() { return new StateStorageService(i0.ɵɵinject(i0.INJECTOR)); }, token: StateStorageService, providedIn: "root" });
    __decorate([
        DI.Inject(ɵ0),
        __metadata("design:type", SessionStorageService)
    ], StateStorageService.prototype, "sessionStore", void 0);
    return StateStorageService;
}(DI.AutoDependencyInjector));
export { StateStorageService };
export { ɵ0 };
