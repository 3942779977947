import { Injector } from "@angular/core";
import { NgxsNextPluginFn, NgxsPlugin, getActionTypeFromInstance } from "@ngxs/store";
import { PersistAppState, RestoreAppState } from "src/app/state/app.actions";
import { StateStorageService } from "../state-storage.service";
import { DI } from "./core";

export const NGXS_STORE_STATE_KEY = 'IAC-Store-State';

export class PesistStateOnPageRefreshPlugin extends DI.AutoDependencyInjector implements NgxsPlugin {
    private readonly default = { router: { url: '/' } };

    @DI.Inject(() => StateStorageService)
    private stateStorage: StateStorageService;

    constructor(_injector: Injector) {
        super(_injector);
    }

    handle(state: any, action: any, next: NgxsNextPluginFn) {
        const actionType = getActionTypeFromInstance(action);

        if (actionType === RestoreAppState.type) {
            const storeState = {
                ...this.default,
                ...this.stateStorage.fetchState()
            };
            if (storeState.router.url === this.routeUrl) {
                state = {
                    ...state,
                    ...storeState.state
                };
            }
            this.stateStorage.clearState();
        } else if (actionType === PersistAppState.type && action.persist) {
            const storeState = {
                router: { url: this.routeUrl },
                state: { ...state }
            };
            this.stateStorage.persistState(storeState);
        }

        return next(state, action);
    }

    private get routeUrl() {
        return location.pathname + location.search;
    }
}
