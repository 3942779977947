import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from '../error/error.component';
import { ErrorShared } from '../error/ErrorShared';
import { TranslateModule } from '@ngx-translate/core';
import { SafePipe } from './safe.pipe';

@NgModule({
    imports: [TranslateModule.forChild(),CommonModule],
    declarations: [ErrorComponent,SafePipe],
    providers: [ErrorShared,SafePipe]
})
export class ErrorModule { }
