import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
export function TranslateLoaderFactory(http1) {
    return new TranslateHttpLoader(http1, './assets/i18n/', '.json');
}
var ɵ0 = (TranslateLoaderFactory);
//autofocus removed
var SharedModule = /** @class */ (function () {
    function SharedModule() {
    }
    return SharedModule;
}());
export { SharedModule };
export { ɵ0 };
