import { Injectable } from '@angular/core';
 

@Injectable()
export class CookieService {

  public getCookie(name: string):string {
    let cookies: Array<string> = document.cookie.split(';');
    let cookieName = `${name}=`;

    for (let cookie of cookies) {
        cookie = cookie.replace(/^\s+/g, '');
        if (cookie.indexOf(cookieName) == 0) {
            return cookie.substring(cookieName.length, cookie.length);
        }
    }
    return '';
  }

  public setCookie(name: string, value: string) {
    var expires = new Date(new Date().setTime(new Date().getTime() + 2147483647 * 1000));
    document.cookie = `${name}=${value}; expires=${expires}`;
  }
}
