import { Pipe, PipeTransform } from "@angular/core";
import { EnvironmentPipe } from "./env.pipe";

@Pipe({ name: 'resource' })
export class EnvironmentResourcePipe implements PipeTransform {
    private static ENV: EnvironmentPipe = new EnvironmentPipe();

    transform(value: string, prefix: string, ..._args: any[]) {
        if (!prefix) {
            throw new Error(`Missing required 'prefix' argument for pipe.`);
        }
        const prefixValue = EnvironmentResourcePipe.ENV.transform(prefix);
        if (prefixValue) {
            try {
                return new URL(value, prefixValue).toString()
            } catch {
                return `${prefixValue}/${value}`;
            }
        }
        throw new Error(`Missing '${prefix}' prop from environment variables.`);
    }
}
