var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { State } from '@ngxs/store';
import { Store } from './core';
var ApplicationState = /** @class */ (function () {
    function ApplicationState() {
    }
    ApplicationState_1 = ApplicationState;
    ApplicationState.popupMode = function (state) {
        return state.popupMode;
    };
    var ApplicationState_1;
    __decorate([
        Store.Selector([ApplicationState_1]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], ApplicationState, "popupMode", null);
    ApplicationState = ApplicationState_1 = __decorate([
        State({
            name: 'app',
            defaults: {
                popupMode: false
            }
        })
    ], ApplicationState);
    return ApplicationState;
}());
export { ApplicationState };
