import { Input, OnInit, OnDestroy, OnChanges, SimpleChanges, Component, ContentChild, TemplateRef } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { distinctUntilChanged, filter, share } from "rxjs/operators";

@Component({
    selector: 'background-xhr-image',
    template: `
        <div [title]="title || ''" [ngStyle]="style" [class]="styleClass || ''" [class.alternate]="showAlt">
            <div class="bodyDiv" *ngIf="bodyTemplate && !showAlt">
                <ng-container *ngTemplateOutlet="bodyTemplate"></ng-container>
            </div>
            <div class="altDiv" *ngIf="showAlt">
                <ng-container *ngTemplateOutlet="altTemplate || defaultAltTemplate"></ng-container>
            </div>
            
            <ng-template #defaultAltTemplate>
                <span>{{alt || 'Image Not Available'}}</span>
            </ng-template>
        </div>
    `,
    styles: [
        `
            div.alternate {
                position: relative;
                min-width: 150px;
                min-height: 100px;
            }
            div.bodyDiv {
                display: inherit;
            }
            div.altDiv {
                position: absolute;
                top: 47.5%;
                left: 0;
                width: 100%;
                display: inline-block;
                color: black;
                cursor: pointer;
                text-align: center;
            }
        `
    ]
})
export class AjaxBackGroundLoader implements OnInit, OnDestroy, OnChanges {

    @Input('background') public src: string;
    @Input() public alt: string;
    @Input() public showAlt: boolean;
    @Input() public style: any;
    @Input() public title: string;
    @Input() public styleClass: string;
    @Input() @ContentChild('bodyTemplate', { read: TemplateRef, static: false }) public bodyTemplate: TemplateRef<any>;
    @Input() @ContentChild('altTemplate', { read: TemplateRef, static: false }) public altTemplate: TemplateRef<any>;

    private subscription: Subscription;
    private srcNotifier: BehaviorSubject<string>;

    constructor() { }

    ngOnInit() {
        this.srcNotifier = new BehaviorSubject<any>(this.src);
        this.style = this.style || {};
        this.subscription = this.srcNotifier.pipe(
            distinctUntilChanged((previous, current) => previous === current),
            filter(src => src !== null && src !== undefined), share()
        ).subscribe(imageUrl => {
                this.style.backgroundImage = `url(${imageUrl})`;
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.src) {
            this.srcNotifier && this.srcNotifier.next(this.src);
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

}
