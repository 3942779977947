var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Action, State } from "@ngxs/store";
import { EngineBOM } from "./engine-bom.actions";
import { patch } from "@ngxs/store/operators";
import { Store } from "src/app/state/core";
var EngineBOMState = /** @class */ (function () {
    function EngineBOMState() {
    }
    EngineBOMState_1 = EngineBOMState;
    EngineBOMState.prototype.setESNInfo = function (ctx, _a) {
        var esnInfo = _a.esnInfo;
        ctx.setState({ esnInfo: esnInfo });
    };
    EngineBOMState.prototype.SetCurrentESN = function (ctx, _a) {
        var esn = _a.esn;
        ctx.patchState({ currentESN: esn });
    };
    EngineBOMState.prototype.setKitSetsForESN = function (ctx, payload) {
        ctx.setState(patch({
            esnInfo: patch(__assign({}, payload, { nokit: (payload.kitSets || []).length === 0 }))
        }));
    };
    EngineBOMState.prototype.setGasketsForESN = function (ctx, payload) {
        ctx.setState(patch({
            esnInfo: patch(__assign({}, payload, { nogasket: (payload.gasketSets || []).length === 0 }))
        }));
    };
    EngineBOMState.prototype.setESNDataplate = function (ctx, payload) {
        ctx.setState(patch({
            esnInfo: patch(__assign({}, payload))
        }));
    };
    EngineBOMState.prototype.setPackageDataplate = function (ctx, payload) {
        ctx.setState(patch({
            esnInfo: patch({
                packageSN: patch(__assign({}, payload))
            })
        }));
    };
    EngineBOMState.prototype.setPackageESNInfo = function (ctx, _a) {
        var packageInfo = _a.packageInfo;
        ctx.setState(patch({
            esnInfo: patch({
                packageSN: packageInfo,
                packageAsset: packageInfo.esn
            })
        }));
    };
    EngineBOMState.prototype.setInjectorTrimsForESN = function (ctx, payload) {
        ctx.setState(patch({
            esnInfo: patch(__assign({}, payload, { hasInjector: (payload.injectorTrims || []).length > 0 }))
        }));
    };
    EngineBOMState.prototype.setEngineInfoForPSN = function (ctx, _a) {
        var esnInfo = _a.esnInfo;
        ctx.setState(patch({
            esnInfo: patch({
                ebuSN: esnInfo
            })
        }));
    };
    EngineBOMState.prototype.setEngineDataplateForPSN = function (ctx, payload) {
        ctx.setState(patch({
            esnInfo: patch({
                ebuSN: patch(__assign({}, payload))
            })
        }));
    };
    EngineBOMState.prototype.setEngineInjectorTrimsForESN = function (ctx, payload) {
        ctx.setState(patch({
            esnInfo: patch({
                ebuSN: patch(__assign({}, payload, { hasInjector: (payload.injectorTrims || []).length > 0 }))
            })
        }));
    };
    EngineBOMState.getCurrentESN = function (state) {
        return state.currentESN || '';
    };
    EngineBOMState.getESNInfo = function (state) {
        return state.esnInfo || {};
    };
    EngineBOMState.getCurrentESNInfo = function (esn, esnInfo) {
        if (esn && esnInfo.esn !== esn) {
            if (esnInfo.mbom === 'package')
                esnInfo = esnInfo.ebuSN;
            else if (esnInfo.packageAsset)
                esnInfo = esnInfo.packageSN;
        }
        return esnInfo;
    };
    EngineBOMState.getCurrentOptionList = function (esnInfo) {
        return {
            esn: esnInfo.esn || '',
            mbom: esnInfo.mbom || '',
            optionList: esnInfo.optionList || []
        };
    };
    var EngineBOMState_1;
    __decorate([
        Action(EngineBOM.SetESNInfo),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, EngineBOM.SetESNInfo]),
        __metadata("design:returntype", void 0)
    ], EngineBOMState.prototype, "setESNInfo", null);
    __decorate([
        Action(EngineBOM.SetCurrentESN),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, EngineBOM.SetCurrentESN]),
        __metadata("design:returntype", void 0)
    ], EngineBOMState.prototype, "SetCurrentESN", null);
    __decorate([
        Action(EngineBOM.SetKitSets),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, EngineBOM.SetKitSets]),
        __metadata("design:returntype", void 0)
    ], EngineBOMState.prototype, "setKitSetsForESN", null);
    __decorate([
        Action(EngineBOM.SetGaskets),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, EngineBOM.SetGaskets]),
        __metadata("design:returntype", void 0)
    ], EngineBOMState.prototype, "setGasketsForESN", null);
    __decorate([
        Action(EngineBOM.SetDataplate),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, EngineBOM.SetDataplate]),
        __metadata("design:returntype", void 0)
    ], EngineBOMState.prototype, "setESNDataplate", null);
    __decorate([
        Action(EngineBOM.SetPackageDataplate),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, EngineBOM.SetPackageDataplate]),
        __metadata("design:returntype", void 0)
    ], EngineBOMState.prototype, "setPackageDataplate", null);
    __decorate([
        Action(EngineBOM.SetPackageInfo),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, EngineBOM.SetPackageInfo]),
        __metadata("design:returntype", void 0)
    ], EngineBOMState.prototype, "setPackageESNInfo", null);
    __decorate([
        Action(EngineBOM.SetInjectorTrims),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, EngineBOM.SetInjectorTrims]),
        __metadata("design:returntype", void 0)
    ], EngineBOMState.prototype, "setInjectorTrimsForESN", null);
    __decorate([
        Action(EngineBOM.Package.SetEngineInfo),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, EngineBOM.Package.SetEngineInfo]),
        __metadata("design:returntype", void 0)
    ], EngineBOMState.prototype, "setEngineInfoForPSN", null);
    __decorate([
        Action(EngineBOM.Package.SetEngineDataplate),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, EngineBOM.Package.SetEngineDataplate]),
        __metadata("design:returntype", void 0)
    ], EngineBOMState.prototype, "setEngineDataplateForPSN", null);
    __decorate([
        Action(EngineBOM.Package.SetEngineInjectorTrims),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, EngineBOM.Package.SetEngineInjectorTrims]),
        __metadata("design:returntype", void 0)
    ], EngineBOMState.prototype, "setEngineInjectorTrimsForESN", null);
    __decorate([
        Store.Selector([EngineBOMState_1]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], EngineBOMState, "getCurrentESN", null);
    __decorate([
        Store.Selector([EngineBOMState_1]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], EngineBOMState, "getESNInfo", null);
    __decorate([
        Store.Selector([EngineBOMState_1.getCurrentESN, EngineBOMState_1.getESNInfo]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String, Object]),
        __metadata("design:returntype", void 0)
    ], EngineBOMState, "getCurrentESNInfo", null);
    __decorate([
        Store.Selector([EngineBOMState_1.getCurrentESNInfo]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], EngineBOMState, "getCurrentOptionList", null);
    EngineBOMState = EngineBOMState_1 = __decorate([
        State({
            name: 'engineBOM'
        })
    ], EngineBOMState);
    return EngineBOMState;
}());
export { EngineBOMState };
