import { Injectable, Injector, Type } from '@angular/core';
import { ActivatedRoute, ChildrenOutletContexts, Route, Router, RouterOutlet } from '@angular/router';
import { Subject } from 'rxjs';
import { IBreadcrumb } from './IBreadcrumb';
import { DI } from '../state/core';

@Injectable()
export class BreadcrumbsService extends DI.AutoDependencyInjector {
    private routeComponentMap = new Map<string, Type<any>>();
    private currentBreadCrumb = new Subject<IBreadcrumb[]>();
    currentBreadCrumb$ = this.currentBreadCrumb.asObservable();

    @DI.Inject(() => Router)
    private router: Router;

    @DI.Inject(() => ActivatedRoute)
    private activeRoute: ActivatedRoute;

    constructor(_injector: Injector) {
        super(_injector);
    }

    public updateBreadCrumb(routes: IBreadcrumb[]): void {
        this.currentBreadCrumb.next(routes);
    }

    public resolveActiveComponent(outletContext: string = 'primary'): any {
        const getActiveRouterOutlet = (routeContext: string,
            childOutletContexts: ChildrenOutletContexts = this.router['rootContexts']): RouterOutlet => {
            const routerOutletContext = childOutletContexts.getContext(routeContext) || childOutletContexts.getContext('primary');
            if (!routerOutletContext) return null;

            const outlet = getActiveRouterOutlet(routeContext, routerOutletContext.children);
            if (outlet && outlet.isActivated) return outlet;
            return routerOutletContext.outlet && routerOutletContext.outlet.isActivated ? routerOutletContext.outlet : null;
        }

        const routerOutlet = getActiveRouterOutlet(outletContext);
        return routerOutlet && routerOutlet.component;
    }

    public resolveActivatedRoute(outletContext: string = 'primary') {
        function postFixTraversal(component: Type<any>, route: ActivatedRoute): ActivatedRoute {
            if (route.children && route.children.length > 0) {
                return route.children.map(childRoute => postFixTraversal(component, childRoute)).find(r => !!r) || null;
            }

            return route.component === component && route.outlet === outletContext ? route : null;
        }

        const component = this.resolveComponentType(this.router.url);
        return postFixTraversal(component, this.activeRoute)
    }

    public resolveComponentType(routeUrl: string, persist: boolean = true): Type<any> {
        function matchRoute(_urlParts: string[], route: Route, exact: boolean = false): boolean {
            if (typeof route.path !== 'string') return false;

            let counter = _urlParts.length;
            const routeParts = route.path.split('/').map(r => r.trim());
            for (const urlPart of _urlParts) {
                if (routeParts.length === 0) {
                    return !exact || counter === 0;
                } else if (routeParts[0] === urlPart || routeParts[0].startsWith(':')) {
                    --counter;
                    routeParts.shift();
                }
            }
            return routeParts.length === 0;
        }

        function resolve(_urlParts: string[], routes: Route[]): Type<any> {
            for (const urlPart of _urlParts) {
                const route = routes.filter(_route => matchRoute([urlPart], _route) || matchRoute(_urlParts, _route))
                    .sort((a, b) => b.path.length - a.path.length).find(_ => true);

                if (!route) continue;

                if ((matchRoute([urlPart], route) && _urlParts.length === 1) || matchRoute(_urlParts, route, true)) {
                    return route.component;
                } else if (route.children) {
                    return resolve(_urlParts.slice(1), route.children);
                } else if (route.loadChildren) {
                    return resolve(_urlParts.slice(1), route['_loadedConfig'].routes);
                }
            }
        }

        if (typeof routeUrl !== 'string') {
            return undefined;
        } else if (routeUrl === '/' || routeUrl.trim() === '') {
            routeUrl = '';
        } else if (!routeUrl.startsWith('/')) {
            routeUrl = `/${routeUrl}`;
        }

        const url = routeUrl.trim();
        if (this.routeComponentMap.has(url)) {
            return this.routeComponentMap.get(url);
        }

        const urlParts = url.split('/').map(r => r.trim());
        const component = resolve(urlParts, this.router.config);
        if ((persist || this.router.url === url) && component) {
            this.routeComponentMap.set(url, component);
        }
        return component;
    }
}
