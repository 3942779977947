import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";
import { TreeNodeComponent } from "./treenode.component";
import { debounceTime } from "rxjs/operators";

@Injectable()
export class TreeManipulator {

    private treeNodes: any;

    private subjectMap: {key: string, value: Subject<any>};

    constructor() {
        this.treeNodes = {};
        this.subjectMap = {} as {key: string, value: Subject<any>};
        this.getObservable('searchEvent').pipe(debounceTime(200))
            .subscribe(event => this.publish('searchEvent-1', event));
    }

    public addNode(node: any, treeInstance: TreeNodeComponent) {
        this.treeNodes[node.parent] = this.getNode(node.parent);
        this.treeNodes[node.parent].push({data: node, instance: treeInstance});
    }

    public getNodeRef(key: any, node: any): TreeNodeComponent {
        const nodeRef = this.getNode(key).filter(nodeRef => nodeRef.data.key === node);
        return nodeRef.length === 1 ? nodeRef[0].instance : null;
    }

    public getNode(parent: any): Array<any> {
        return this.treeNodes[parent] || [];
    }

    public getNodes() {
        return this.treeNodes;
    }

    public hasNode(node: any): boolean {
        const nodes = this.getNode(node.parent).filter(nodeObj => nodeObj.data.key === node.key);
        return nodes.length === 1;
    }

    public removeNode(node: any) {
        if(this.getNode(node.parent).length === 0) return;
        if(this.getNode(node.parent).length === 1) delete this.treeNodes[node.parent];
        else this.treeNodes[node.parent] = this.getNode(node.parent).filter(nodeObj => nodeObj.data.key !== node.key);
    }

    public publish(key: string, data?: any) {
        setTimeout(() => this.getSubject(key).next(data));
    }

    private getObservable(key: string): Observable<any> {
        return this.getSubject(key).asObservable();
    }

    public subscribe(key: string, mapper?: any): Observable<any> {
        const observable = this.getSubject(key).asObservable();
        return mapper ? observable.map(mapper) : observable;
    }

    private getSubject(key: string): Subject<any> {
        this.subjectMap[key] = this.subjectMap[key] || new Subject<any>();
        return this.subjectMap[key];
    }

}