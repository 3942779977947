import { PipeTransform } from "@angular/core";
import { EnvironmentPipe } from "./env.pipe";
var EnvironmentResourcePipe = /** @class */ (function () {
    function EnvironmentResourcePipe() {
    }
    EnvironmentResourcePipe.prototype.transform = function (value, prefix) {
        var _args = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            _args[_i - 2] = arguments[_i];
        }
        if (!prefix) {
            throw new Error("Missing required 'prefix' argument for pipe.");
        }
        var prefixValue = EnvironmentResourcePipe.ENV.transform(prefix);
        if (prefixValue) {
            try {
                return new URL(value, prefixValue).toString();
            }
            catch (_a) {
                return prefixValue + "/" + value;
            }
        }
        throw new Error("Missing '" + prefix + "' prop from environment variables.");
    };
    EnvironmentResourcePipe.ENV = new EnvironmentPipe();
    return EnvironmentResourcePipe;
}());
export { EnvironmentResourcePipe };
