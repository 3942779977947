import { Injectable } from "@angular/core";
import { TeardownLogic } from "rxjs";
import { Observable } from "rxjs/Observable";
import { Observer } from "rxjs/Observer";
import { environment } from "../environments/environment";
import { hashes } from "./app-plugins/sri-hashes";

@Injectable({ providedIn: 'root' })
export class ResourceLoaderService {
    private resources: Map<string, Observable<boolean>> = new Map<string, Observable<boolean>>();

    private getResourceElement(resource: ResourceModel): HTMLElement {
        let resourceElement = null;
        if (resource.type === 'script') {
            const script = resource as ScriptModel;
            resourceElement = document.createElement('script');
            resourceElement.src = script.src;
            resourceElement.type = 'text/javascript';
            resourceElement.async = script.async || false;
            resourceElement.defer = script.defer || false;
            resourceElement.noModule = script.noModule || false;
        } else if (resource.type === 'link') {
            resourceElement = document.createElement('link');
            resourceElement.rel = 'stylesheet';
            resourceElement.href = resource.src;
        } else {
            throw new Error('Trying to load unknown resource.');
        }

        return resourceElement;
    }

    private isResourceAttached(resource: ResourceModel) {
        if (resource.type === 'script') {
            return !!document.querySelector(`script[src="${resource.src}"]`);
        } else if (resource.type === 'link') {
            return !!document.querySelector(`link[href="${resource.src}"]`);
        }
        return false;
    }

    private attachResource(resourceElement: HTMLElement) {
        if (resourceElement.tagName.toLowerCase() === 'script') {
            document.getElementsByTagName('body')[0].appendChild(resourceElement);
        } else if (resourceElement.tagName.toLowerCase() === 'link') {
            document.getElementsByTagName('head')[0].appendChild(resourceElement);
        }
    }

    private getResourceLoader(resource: ResourceModel, strictSRICheck: boolean): Observable<boolean> {
        let loaded = this.isResourceAttached(resource);
        const observers: Observer<boolean>[] = [];

        function next(observer?: Observer<boolean>) {
            if (observer) {
                observer.next(loaded);
                return;
            }
            observers.forEach(next);
        }

        function complete(observer?: Observer<boolean>) {
            if (observer) {
                observer.complete();
                return;
            }
            observers.forEach(complete);
        }

        function error(value: any) {
            observers.forEach(observer => observer.error(value));
        }

        return new Observable<boolean>((observer: Observer<boolean>): TeardownLogic => {
            observers.push(observer);
            if (loaded) complete(observer);
            else if (observers.length === 1) {
                const integrityHash = hashes[resource.src] || resource.integrity;
                strictSRICheck = environment.production && strictSRICheck;
                if (strictSRICheck && !integrityHash) {
                    throw this.getError('SRI Missing', 'Can\'t load script dynamically without Subresource Integrity.');
                }
                const resourceElement = this.getResourceElement(resource);
                if (strictSRICheck) {
                    (resourceElement as any).integrity = integrityHash;
                    (resourceElement as any).crossOrigin = integrityHash && 'anonymous';
                }
                resourceElement.onload = () => {
                    loaded = true;
                    next();
                    complete();
                };
                resourceElement.onerror = error;
                this.attachResource(resourceElement);

                return {
                    unsubscribe: () => observers.splice(observers.indexOf(observer), 1)
                };
            }
        });
    }

    public loadResource(resource: ResourceModel, strictSRICheck: boolean = true): Observable<boolean> {
        if (!resource.src) {
            throw this.getError('Source Missing', 'Can\'t load script dynamically without resource source.');
        }
        let resourceLoader = this.resources.get(resource.src);
        if (!this.resources.has(resource.src)) resourceLoader = this.getResourceLoader(resource, strictSRICheck);
        return resourceLoader;
    }

    private getError(name: string, message: string): Error {
        return { name, message } as Error;
    }
}

interface ResourceModel {
    type: 'script' | 'link',
    src: string,
    integrity?: string
}

export interface ScriptModel extends ResourceModel {
    type: 'script',
    async?: boolean,
    defer?: boolean,
    noModule?: boolean
}

export interface LinkModel extends ResourceModel {
    type: 'link'
}
