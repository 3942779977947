import { Component, Input, ContentChild, TemplateRef, Output, EventEmitter } from "@angular/core";

@Component({
    selector: 'notice-dialog',
    template: `
        <p-dialog *responsive="{bootstrap: ['lg','md','xl']}" [width] = "width" [visible]="visible"  [modal] = "true" blockScroll = "true"  [closeOnEscape] = "false" [closable] = "false" [resizable] = "true">
            <div align="justify">
                <ng-container *ngTemplateOutlet="content"></ng-container>
            </div>
            <div align="center" *ngIf="showActions">
                <br/>
                <form>
                    <button [disabled]="showProgress" (click) = "click.emit($event);"  id = "acceptDialog" class ="btn btn-sm btn-primary btn-login">{{ 'Continue' | translate }}</button>
                    <mat-progress-bar style="margin-top: 10px;" *ngIf="showProgress" color="primary" mode="indeterminate"></mat-progress-bar>
                </form>
            </div>
            <br/>    
        </p-dialog>

        <p-dialog *responsive="{bootstrap: ['sm','xs']}" [visible]="visible"  [modal] = "true" blockScroll = "true"  [closeOnEscape] = "false" [closable] = "false" [resizable] = "true">
            <div align="justify">
            <ng-container *ngTemplateOutlet="content"></ng-container>
            </div>
            <div align="center" *ngIf="showActions">
                <br/>
                <form>
                    <button [disabled]="showProgress" (click) = "click.emit($event);"  id = "acceptDialog" class ="btn btn-sm btn-primary btn-login">{{ 'Continue' | translate }}</button>
                    <mat-progress-bar style="margin-top: 10px;" *ngIf="showProgress" color="primary" mode="indeterminate"></mat-progress-bar>
                </form>
            </div>
            <br/>    
        </p-dialog>
    `
})
export class NoticesComponent {

    @Input() @ContentChild("content", { read: TemplateRef, static: false }) public content: TemplateRef<any>;

    @Input() public showProgress: boolean;

    @Input() public visible: boolean;

    @Input() public width: number;

    @Input() public showActions: boolean;

    @Output('onClick') public click: EventEmitter<any>;

    constructor() {
        this.click = new EventEmitter<any>();
    }

}