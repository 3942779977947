const PARTS_URL = 'https://partsdev.cummins.com';

export const environment = {
  production: true,
  AUTH_PORT: '',
  CUMPASS_PACCAR_URL: 'https://cumminscss--csodev.sandbox.my.site.com/cw/idp/login?app=0sp4N0000004CXh&RelayState=/DCOM_PartOrder_Entry_Page_Partner',
  CUMPASS_URL: 'https://cumpas2dev-cumminscommerce.cs201.force.com/DCOM_PartOrder_Entry_Page',
  CMS_BLOB_NAME: 'protected/cms/',
  CMS_PROTECTED_BLOB_NAME: 'protected',
  CMS_APP_NAME: 'cmseditor3',
  CMS_GRAPHICS: '/graphics',
  CMS_IMAGE_ROOT: 'graphics/common/',
  CMS_TEMPLATE_ROOT: '/template/',
  CMS_FILES_ROOT: '/files',
  CMS_DOCTYPE: '',
  API_PORTS: {},
  QSOL_LOGIN:"https://qsdev.cummins.com/qs3/portal/index.html",
  QSOL: "https://qsdev.cummins.com",
  QSSERVICES: "https://qsservicesdev.cummins.com",
  ADMIN_VIEW: `${PARTS_URL}/admin`,
  STORE: 'https://storedev.cummins.com',
  PARTS: PARTS_URL,
  DDA: 'https://dda.cummins.com',
  METRICS_VIEW: '',
  SEND_CART_LINKS: {
    'BAUER': {
      location: 'https://shop.motoren-bauer.de/de/productfinder_finish/',
      target: '_self'
    },
    'CUMPAS2/ISUZU Flow': {
      location: 'https://cumminscss--csodev.sandbox.my.site.com/cw/IAM_Authorize?appid=a1a1F000001RmhW&RelayState=/PartOrder_Entry_Page_Partner_LWC',
      target: '_blank'
    }
  }, 
  HELPDESK: {
    PRODUCT: 'https://cumminscss--qafullcopy.sandbox.my.site.com/CumminsDigitalSupport/s/?productId=3'
  }
};
