var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AfterViewInit, OnInit, OnDestroy, Injector } from '@angular/core';
import { SessionStorageService, SessionStorage } from 'ngx-webstorage';
import { LogoutService } from '../../logout/logout.service';
import { LoginSharedService } from '../../login/login-shared.service';
import { Router } from "@angular/router";
import { ToasterService, ToasterConfig } from 'angular2-toaster';
import { NotificationService } from './notification.service';
import { TranslateService } from '@ngx-translate/core';
import { HeaderService } from './header.service';
import { IntegrationService } from '../../integration/integration.service';
import { FormBuilder } from '@angular/forms';
import { AppSettings } from '../../app.settings';
import { AppState } from '../../app.service';
import { SharingServices } from '../../cms-editor/services/sharing-service';
import { SharedInfoService } from '../../partsCatalog/sharedInfo.service';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SbomService } from '../sbom.service';
import { ResourceLoaderService } from '../../resource-loader.service';
import { CartService } from '../../cart/cart.service';
import { ApplicationState } from 'src/app/state/app.state';
import { Select, Store } from '@ngxs/store';
import { DI } from 'src/app/state/core';
import { Navigation } from 'src/app/state/app.actions';
import { FeatureChecker } from '../feature-checker.service';
import { NotificationService as GenericNotificationService } from '../generic-notification/notification.service';
import { environment } from 'src/environments/environment';
var ɵ0 = function () { return SessionStorageService; }, ɵ1 = function () { return TranslateService; }, ɵ2 = function () { return LogoutService; }, ɵ3 = function () { return LoginSharedService; }, ɵ4 = function () { return Router; }, ɵ5 = function () { return ToasterService; }, ɵ6 = function () { return NotificationService; }, ɵ7 = function () { return HeaderService; }, ɵ8 = function () { return FormBuilder; }, ɵ9 = function () { return IntegrationService; }, ɵ10 = function () { return AppState; }, ɵ11 = function () { return SharingServices; }, ɵ12 = function () { return SharedInfoService; }, ɵ13 = function () { return SbomService; }, ɵ14 = function () { return CartService; }, ɵ15 = function () { return ResourceLoaderService; }, ɵ16 = function () { return Store; }, ɵ17 = function () { return FeatureChecker; }, ɵ18 = function () { return GenericNotificationService; };
var HeaderComponent = /** @class */ (function (_super) {
    __extends(HeaderComponent, _super);
    function HeaderComponent(_injector) {
        var _this = _super.call(this, _injector) || this;
        _this.cartLoading = true;
        _this.initLoading = false;
        _this.toasterconfig = new ToasterConfig({
            showCloseButton: true,
            tapToDismiss: true,
            positionClass: 'toast-top-full-width',
            timeout: -1,
            limit: 3
        });
        _this.showSearch = true;
        _this.storeUrl = "" + AppSettings.PUBLICATIONS_STORE;
        _this.placeholder = {
            title: 'Search ESN/ATSN/GSN/Part Number or Description across all products'
        };
        _this.adminView = AppSettings.ADMIN_VIEW + "/applications";
        _this.accessFlags = {
            dealerNewsLetterPage: false,
            isSuperAdmin: false,
            isAdmin: false,
            isCMSAdmin: false,
            showSurveyLink: false,
            isUploadUpfitAllowed: false
        };
        _this.lang_img = _this.appService.getAssetsLink('/icons/language_change_icon.png', true);
        _this.subscription = _this.cartService.getCartItemsCount().subscribe(function (cnt) { return _this.cnt = cnt + ''; });
        _this.subscription.add(_this._sbomService.loaderEvent$
            .pipe(filter(function (event) { return event && event.type === 'error'; }))
            .subscribe(function (event) {
            var errMsg = 'Service BOM download has failed. Please try again later or contact help desk.';
            _this.translate.get(errMsg).subscribe(function (toast) {
                var esndataToast = {
                    type: 'error',
                    title: toast,
                    timeout: 3000
                };
                _this.toasterService.pop(esndataToast);
            });
        }));
        _this.subscription.add(_this.integration.getIntegrationUser()
            .subscribe(function (userId) { return _this.integrationUserId = userId; }));
        _this.translate.setDefaultLang('en');
        _this.createForm();
        return _this;
    }
    Object.defineProperty(HeaderComponent.prototype, "userId", {
        get: function () {
            return this.integrationUserId || this._userId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HeaderComponent.prototype, "isIntegrationFlow", {
        get: function () {
            return !!this.integrationUserId;
        },
        enumerable: true,
        configurable: true
    });
    HeaderComponent.prototype.getAssetsLink = function (path) {
        return this.appService.getAssetsLink(path, true);
    };
    HeaderComponent.prototype.createForm = function () {
        this.searchForm = this.fb.group({
            searchValue: ['', checkSearchValidation]
        });
    };
    HeaderComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this._resourceLoader.loadResource({ src: 'assets/js/common.js', type: 'script' })
            .subscribe(function () { }, function (error) { return console.error(error); });
        this.sharing.publishToken.subscribe(function (message) {
            if (message && message.children != null) {
                _this.menu = message.children;
                _this.application = message.name;
            }
        });
    };
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription.add(this.notificationService.notifications$.subscribe(function (x) { return _this.toasterService.pop(x); }));
        this.subscription.add(this.loginShared.checkFeatureAccess('DEALER_NEWS').subscribe(function (flag) { return _this.accessFlags.dealerNewsLetterPage = flag; }));
        this.subscription.add(this.loginShared.checkFeatureAccess('Admin', 'adminservices').subscribe(function (flag) { return _this.accessFlags.isAdmin = flag; }));
        this.subscription.add(this.loginShared.checkFeatureAccess('Super Admin', 'adminservices').subscribe(function (flag) { return _this.accessFlags.isSuperAdmin = flag; }));
        this.subscription.add(this.loginShared.checkFeatureAccess('CMS_USER', 'IACDataServices').subscribe(function (flag) { return _this.accessFlags.isCMSAdmin = flag; }));
        this.subscription.add(this.headerService.registerSearchUpdates(function (data) {
            if (data.func === "input")
                _this.srchCriteria = data.input;
        }));
        this.subscription.add(this.loginShared.checkFeatureAccess('MST_ADMIN', 'mstwebservice').subscribe(function (flag) { return _this.isServiceToolsAdmin = flag; }));
        this.subscription.add(this.loginShared.checkFeatureAccess('UPFITS_UPLOAD').subscribe(function (flag) { return _this.accessFlags.isUploadUpfitAllowed = flag; }));
        this.sharing.publishToken.subscribe(function (message) {
            if (message && message.children != null) {
                _this.menu = message.children;
                _this.application = message.name;
            }
        });
        this.cartService.notifyCartLoaded(function () { return _this.cartLoading = false; }, function () { return _this.initLoading = true; });
        this.storage.observe('USERID')
            .subscribe(function (newValue) {
            console.log('USER' + newValue);
        });
        if (this._userId == null || this._userId == '') {
            this._userId = 'Login';
        }
        if (this.cnt == null || this.cnt == '') {
            this.cnt = '0';
        }
        this.genericNotificationService.loading$.subscribe(function (event) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!!event.showLoading) return [3 /*break*/, 2];
                        _a = this.accessFlags;
                        return [4 /*yield*/, this.featureChecker.applyFeature('SHOW_SURVEY_LINK')];
                    case 1:
                        _a.showSurveyLink = _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); });
    };
    HeaderComponent.prototype.redirectToQsol = function () {
        var time = Date.now();
        var token = "";
        var urlToRedirect = "";
        if (this.integration.data.vendor !== 'IAC' && this.integration.data.vendorCartId !== '') {
            token = this.integration.data.vendor + "|" + this.integration.data.vendorCartId + "|" + time;
            token = btoa(token);
            urlToRedirect = AppSettings.QUICK_SERVE + "/cola2/login/lnkmgmt/parts.html?tokenId=" + token + "&esn=" + this.esn + "\n                &usertype=" + btoa(this.integration.data.userType || '') + "&transaction=" + btoa(this.integration.data.transactionId || '');
        }
        else {
            token = 'IAC|' + time;
            token = btoa(token);
            if (this.esn)
                urlToRedirect = AppSettings.QUICK_SERVE + "/cola2/login/lnkmgmt/home.html?tokenId=" + token + "&esn=" + this.esn;
            else
                urlToRedirect = "" + AppSettings.QUICK_SERVE_LOGIN;
        }
        window.open(urlToRedirect, "_blank");
    };
    HeaderComponent.prototype.checkLogout = function () {
        if (this.userId != 'Login') {
            return true;
        }
        else {
            return false;
        }
    };
    HeaderComponent.prototype.logout = function () {
        var _this = this;
        this.logoutService.killSession().then(function (logoutURL) {
            _this.appService.processResult(null);
            window.location.href = logoutURL;
        });
    };
    HeaderComponent.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    HeaderComponent.prototype.switchLanguage = function (language) {
        var _this = this;
        this.translate.use(language);
        this.notificationService.notificationtranslate1(language);
        this.notificationService.notificationTranslateView$.subscribe(function (message) { return _this.languageFlag = message; });
    };
    HeaderComponent.prototype.mouseEnter = function () {
        this.lang_img = this.appService.getAssetsLink('/icons/language_dark.png', true);
    };
    HeaderComponent.prototype.mouseLeave = function () {
        this.lang_img = this.appService.getAssetsLink('/icons/language_change_icon.png', true);
    };
    HeaderComponent.prototype.goToSearch = function () {
        this.showSearch = false;
        this.router.navigate(['/global-search/main/', this.searchForm.value.searchValue]);
        this.headerService.updateSearchView(this.srchCriteria);
        this.showSearch = true;
    };
    HeaderComponent.prototype.gotoSavedEsn = function () {
        this.router.navigate(['/saved-esn']);
    };
    HeaderComponent.prototype.goToSearchHelp = function () {
        this.router.navigate(['/global-search/help/']);
    };
    HeaderComponent.prototype.gotoEditor = function () {
        this.router.navigate(['/cms-editor']);
    };
    HeaderComponent.prototype.cilckme = function () {
        window.open('https://www.cummins.com/support/product-registration', '_blank', 'noopener,noreferrer');
    };
    HeaderComponent.prototype.openHelpDeskPage = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.store.dispatch(new Navigation.PopupRoute([environment.HELPDESK.PRODUCT], {
                    windowTarget: 'help-desk-page'
                }));
                return [2 /*return*/];
            });
        });
    };
    Object.defineProperty(HeaderComponent.prototype, "showSbomMenu$", {
        get: function () {
            return combineLatest([
                this.loginShared.checkFeatureAccess('SHOW_SBOM'),
                this._partsSharedService.currentEsnInfo$
            ]).pipe(map(function (_a) {
                var showSbomMenu = _a[0], esnInfo = _a[1];
                return showSbomMenu
                    && (esnInfo.mbom === 'mbom' || esnInfo.mbom === 'legacy');
            }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HeaderComponent.prototype, "esn$", {
        get: function () {
            return this._partsSharedService.currentEsnInfo$.pipe(map(function (esnInfo) { return esnInfo.esn; }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HeaderComponent.prototype, "sbomLoaderEvents$", {
        get: function () {
            return this._sbomService.loaderEvent$;
        },
        enumerable: true,
        configurable: true
    });
    HeaderComponent.prototype.downloadSbom = function (event) {
        this._sbomService.downloadSbom(this._partsSharedService);
        event.preventDefault();
    };
    Object.defineProperty(HeaderComponent.prototype, "isLoggedIn", {
        get: function () {
            return this.loginShared.isLoggedIn();
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        Select(ApplicationState.popupMode),
        __metadata("design:type", Observable)
    ], HeaderComponent.prototype, "popupMode$", void 0);
    __decorate([
        SessionStorage('USERID'),
        __metadata("design:type", String)
    ], HeaderComponent.prototype, "_userId", void 0);
    __decorate([
        SessionStorage('esn'),
        __metadata("design:type", String)
    ], HeaderComponent.prototype, "esn", void 0);
    __decorate([
        DI.Inject(ɵ0),
        __metadata("design:type", SessionStorageService)
    ], HeaderComponent.prototype, "storage", void 0);
    __decorate([
        DI.Inject(ɵ1),
        __metadata("design:type", TranslateService)
    ], HeaderComponent.prototype, "translate", void 0);
    __decorate([
        DI.Inject(ɵ2),
        __metadata("design:type", LogoutService)
    ], HeaderComponent.prototype, "logoutService", void 0);
    __decorate([
        DI.Inject(ɵ3),
        __metadata("design:type", LoginSharedService)
    ], HeaderComponent.prototype, "loginShared", void 0);
    __decorate([
        DI.Inject(ɵ4),
        __metadata("design:type", Router)
    ], HeaderComponent.prototype, "router", void 0);
    __decorate([
        DI.Inject(ɵ5),
        __metadata("design:type", ToasterService)
    ], HeaderComponent.prototype, "toasterService", void 0);
    __decorate([
        DI.Inject(ɵ6),
        __metadata("design:type", NotificationService)
    ], HeaderComponent.prototype, "notificationService", void 0);
    __decorate([
        DI.Inject(ɵ7),
        __metadata("design:type", HeaderService)
    ], HeaderComponent.prototype, "headerService", void 0);
    __decorate([
        DI.Inject(ɵ8),
        __metadata("design:type", FormBuilder)
    ], HeaderComponent.prototype, "fb", void 0);
    __decorate([
        DI.Inject(ɵ9),
        __metadata("design:type", IntegrationService)
    ], HeaderComponent.prototype, "integration", void 0);
    __decorate([
        DI.Inject(ɵ10),
        __metadata("design:type", AppState)
    ], HeaderComponent.prototype, "appService", void 0);
    __decorate([
        DI.Inject(ɵ11),
        __metadata("design:type", SharingServices)
    ], HeaderComponent.prototype, "sharing", void 0);
    __decorate([
        DI.Inject(ɵ12),
        __metadata("design:type", SharedInfoService)
    ], HeaderComponent.prototype, "_partsSharedService", void 0);
    __decorate([
        DI.Inject(ɵ13),
        __metadata("design:type", SbomService)
    ], HeaderComponent.prototype, "_sbomService", void 0);
    __decorate([
        DI.Inject(ɵ14),
        __metadata("design:type", CartService)
    ], HeaderComponent.prototype, "cartService", void 0);
    __decorate([
        DI.Inject(ɵ15),
        __metadata("design:type", ResourceLoaderService)
    ], HeaderComponent.prototype, "_resourceLoader", void 0);
    __decorate([
        DI.Inject(ɵ16),
        __metadata("design:type", Store)
    ], HeaderComponent.prototype, "store", void 0);
    __decorate([
        DI.Inject(ɵ17),
        __metadata("design:type", FeatureChecker)
    ], HeaderComponent.prototype, "featureChecker", void 0);
    __decorate([
        DI.Inject(ɵ18),
        __metadata("design:type", GenericNotificationService)
    ], HeaderComponent.prototype, "genericNotificationService", void 0);
    return HeaderComponent;
}(DI.AutoDependencyInjector));
export { HeaderComponent };
function checkSearchValidation(input) {
    var patt = new RegExp("^[0-9]+$");
    var dispAplhaNumericError = false;
    var dispDigitError = false;
    if (input.value != '' && typeof input.value != 'undefined') {
        if (patt.test(input.value)) {
            dispDigitError = true;
            if (input.value.length > 3) {
                return null;
            }
        }
        else if (input.value.match(/[a-z]/i)) {
            dispAplhaNumericError = true;
            if (input.value.length > 2) {
                return null;
            }
        }
        if (dispAplhaNumericError && dispDigitError === false) {
            return { needsAplhaNumeric: true };
        }
        if (dispDigitError && dispAplhaNumericError === false) {
            return { needsDigit: true };
        }
    }
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18 };
