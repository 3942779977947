import { Component, OnInit } from '@angular/core';
import { LoginService } from './login.service';
import { Router } from "@angular/router";
import { LocalStorage } from 'ngx-webstorage';
import { Credentials } from './Credentials';
import { NotificationService } from '../shared/header/notification.service';
import { CartService } from '../cart/cart.service';
import { AppSettings } from '../app.settings';
import { AppState } from '../app.service';


@Component({
    selector: 'login',
    templateUrl: 'login.html',
    styleUrls: ['./login.component.css']

})
export class LoginComponent implements OnInit {

    public placeholder = {
        username: 'Username',
        password: 'Password',
    }
    public credentials: Credentials;
    public showProgress: boolean = false;

    @LocalStorage('esns')
    public esnsOrigin: any[];
    public agreementModal = false;
    public accept;
    public showProgressAccept = false;
    public disabledContinue = false;
    public disabledLogin = false;
    public completeSubscription = false;
    public showProgressSub = false;
    public QUICK_SERVE_COLA = `${AppSettings.QUICK_SERVE}/cola2/acctchk/update.html`
    public PARTS_HOST_NAME = `${AppSettings.HOST_NAME}/`;
    public quickserveLinks = {
        terms: `${AppSettings.QUICK_SERVE}/info/qsol/terms.html`
    }
    public subscriptionLink = `${AppSettings.PUBLICATIONS_STORE}/#/registration/main`;

    public saveEsn:any [] = [];

    constructor(private loginService: LoginService, private router: Router,
        private cartService: CartService,
        private notificationService: NotificationService,
        public appState: AppState) { }

    public login(): void {

        this.loginService.authenticate(this.credentials, this.accept)
            .then((res) => {

                //type: type, title: title
                this.showProgress = false;
                this.agreementModal = false;
                this.disabledLogin = false;
                if ((res.extraAttributes || {}).usertype === "2"){
                    this.agreementModal = true;
                }else {
                    this.notificationService.notify({ type: 'success', title: 'Successfully logged in', timeout: 3000 });
                    this.appState.processResult(res);
                    this.router.navigate(['home'])
                        .then(didNavigate => didNavigate && this.appState.publishSessionChange(res));
                    this.saveEsnMDB();
                }
            })
            .catch((err) => {
               
                this.showProgress = false;
                this.showProgressAccept = false;
                this.disabledLogin = false;
                if (err.status === 401) {
                    this.notificationService.notify({ type: 'error', title: 'Username/Password incorrect, please try again', timeout: 3000 });
                } else if (err.status == 302){
                    this.completeSubscription = true;
                } else {
                    console.log("Error ==>" + JSON.stringify(err));
                    this.notificationService.notify({ type: 'error', title: 'Login service unavailable, please try again', timeout: 3000 });
                }

            });

    }

    ngOnInit(): void {
        this.credentials = new Credentials(null, null);
    }

    public saveRequest() : void {
        this.loginService.saveRequest()
            .then(loginURL => window.location.href = loginURL);   
    }

    public getAssetsLink(path: string): string {
        return this.appState.getAssetsLink(path, true);
    }
    
    public saveEsnMDB():void {
        
        if(this.notificationService.saveEsnData.length > 0){
            this.saveEsn = [];
            
            this.loginService.saveESNMDB(this.notificationService.saveEsnData[0])
            .then(item => {
                console.log("Data save successfully");
            }).catch(error => {
                console.log("ERROR : while data save");
            })
        }
       
    }
}
