import { Component ,OnInit} from '@angular/core';
import { AppState } from '../app.service';
@Component({
    selector: 'dealer-newsletters',
    templateUrl: 'dealer-newsletters.html',
})
export class DealerNewslettersComponent implements OnInit{
    
    constructor(private appService: AppState) {}

    public getAssetsLink(path: string): string {
        return this.appService.getAssetsLink(`/protected${path}`);
    }

   public ngOnInit() {
       window.scrollTo(0,0);
   }
}
