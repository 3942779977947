<generic-notification></generic-notification>
<header></header>
<breadcrumb></breadcrumb>
<router-outlet></router-outlet>
<footer></footer>
<cookie-popup></cookie-popup>

<notice-dialog [visible]="agreementModal" width="500" (onClick)="acceptConsent();" [showProgress]="showProgressAccept" [showActions]="true">
    <ng-template #content>
        By registering for and using QuickServe Online, you agree to Cummins Inc. <a [href]="'/info/qsol/terms.html' | resource: 'QSOL'" target="_blank"><u>terms of use</u></a> and <a target="_blank" href= "https://www.cummins.com/privacy-and-legal"><u>privacy policy</u></a>, including our use of cookies. To manage your consent settings, click on My Profile.
    </ng-template>
</notice-dialog>

<notice-dialog [visible]="sessionEvents.clearCookies" width="400" (onClick)="sessionEvents.clearCookies = false;" [showProgress]="false" [showActions]="false">
    <ng-template #content>
        An issue was detected with your session.
        <br/>
        Please clear your browser’s cookies and login again.
    </ng-template>
</notice-dialog>

<notice-dialog [visible]="sessionEvents.showSessionRefreshConfirmation || 
        sessionEvents.showSessionResetNotification || sessionEvents.showWindowInactiveNotification"
    (onClick)="sessionEvents.showSessionResetNotification = false; 
        refreshSession(sessionEvents.showSessionRefreshConfirmation || sessionEvents.showWindowInactiveNotification);"
    [showProgress]="sessionEvents.showSessionRefreshConfirmationProgress" width="400" [showActions]="true">
    <ng-template #content>
        Click on 'Continue' to keep working.
    </ng-template>
</notice-dialog>
