<span class="clearfix"></span>
<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="page-header">
                <h1>{{ 'Genuine Cummins Parts - Dealer Promotions Updates' | translate }}</h1>
            </div>
            <div class="clearfix vert-offset-top-1">
                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <h3>2019 - Q1</h3>
                    <ul class="list-group">
                        <li class="list-group-item">
                            <a target="_blank" href="{{getAssetsLink('/pdf/dealer-newsletters/Q1_2019GenuineCumminsParts_DealerPromoUpdate_EN.pdf')}}">English</a>
                        </li>
                        <li class="list-group-item">
                            <a target="_blank" href="{{getAssetsLink('/pdf/dealer-newsletters/Q1_2019GenuineCumminsParts_DealerPromoUpdate_Spanish.pdf')}}">Spanish</a>
                        </li>
                        <li class="list-group-item">
                            <a target="_blank" href="{{getAssetsLink('/pdf/dealer-newsletters/Q1_2019GenuineCumminsParts_DealerPromoUpdate_French.pdf')}}">French</a>
                        </li>
                    </ul>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <h3>2018 - Q4</h3>
                    <ul class="list-group">
                        <li class="list-group-item">
                            <a target="_blank" href="{{getAssetsLink('/pdf/dealer-newsletters/GenuineCumminsPartsNewsletterQ4-18.pdf')}}">English</a>
                        </li>
                    </ul>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <h3>2017 - Q3</h3>
                    <ul class="list-group">
                        <li class="list-group-item">
                            <a target="_blank" href="{{getAssetsLink('/pdf/dealer-newsletters/dealer_newsletter_2017-08.pdf')}}">English</a>
                        </li>
                        <li class="list-group-item">
                            <a target="_blank" href="{{getAssetsLink('/pdf/dealer-newsletters/dealer_newsletter_2017-08_fr.pdf')}}">French</a>
                        </li>
                    </ul>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <h3>2017 - Q1</h3>
                    <ul class="list-group">
                        <li class="list-group-item">
                            <a target="_blank" href="{{getAssetsLink('/pdf/dealer-newsletters/genuine_cummins_parts_nl_2017-q1.pdf')}}">English</a>
                        </li>
                        <li class="list-group-item">
                            <a target="_blank" href="{{getAssetsLink('/pdf/dealer-newsletters/genuine_cummins_parts_nl_2017-q1-fr.pdf')}}">French</a>
                        </li>
                    </ul>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <h3>2016 - Q2</h3>
                    <ul class="list-group">
                        <li class="list-group-item">
                            <a target="_blank" href="{{getAssetsLink('/pdf/dealer-newsletters/dealer_newsletter_2016-06.pdf')}}">English</a>
                        </li>
                    </ul>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                    <img-xhr [src]="getAssetsLink('/images/dealer-newsletter/genuine_parts_promotions.jpg')" [ngStyle]="{width: '200px'}" styleClass="img-responsive"></img-xhr>
                </div>
            </div>
            <div class="clearfix "></div>
        </div>
    </div>
</div>