
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { catchError, map } from 'rxjs/operators';
import { AppSettings } from '../../app.settings';
import { HttpClient } from '@angular/common/http';
import { PartServiceAbstract } from './part.service';
import { ResourceLoaderService } from '../../resource-loader.service';

@Injectable()
export class PartDetailApiService extends PartServiceAbstract {
    public baseUrl: string;
    public gomsUrl: string;
    public relatedPartsUrl: string;

    constructor(private http: HttpClient, _resourceLoader: ResourceLoaderService) {
        super(_resourceLoader);
        this.baseUrl = `${AppSettings.API_ENDPOINTS.get('IACDataServices')}/protected/partDetails?partNo=`;
        this.gomsUrl = `${AppSettings.API_ENDPOINTS.get('gomsclient')}/protected/parts/getPartAvailability?part=`;
        this.loadUrlAsBuffer = this.loadUrlAsBuffer.bind(this);

        this.relatedPartsUrl = `${AppSettings.API_ENDPOINTS.get('IACDataServices')}/protected/relatedParts?partNo=`;
    }

    public loadUrlAsBuffer(url: string, type: string, callback) {
        
        let responseTypeVal: any;
        switch(type) {
            case "array": responseTypeVal = 'arraybuffer';
                break;
            case "blob": responseTypeVal = 'blob';
                break;
            case "json": responseTypeVal = 'json';
                break;
            default: responseTypeVal = 'text';
        }

        this.http.get(url, {responseType: responseTypeVal})
            .toPromise()
            .then((resp:any) => {
                // let body :any = null;
                // switch(type) {
                //     case "array": body = resp.arrayBuffer();
                //         break;
                //     case "blob": body = resp.blob();
                //         break;
                //     case "json": body = resp.json();
                //         break;
                //     default: body = resp.text();
                // }
                // if(resp.status === 200 && callback && callback.onReady) callback.onReady(body);
                // else callback.onError();
                callback.onReady(resp);
            })
            .catch(err => callback.onError());
    }

    public fetchPartDetails(partNo: any, smn: any): Observable<any> {
        return this.http.get(`${this.baseUrl}${partNo}&smn=${smn || ''}`)
            .pipe(map((response:any) => response))
            //.map((response) => response)
            .pipe(map((data:any) =>  {
                if(data && data.whereUsed){
                    data.filteredPartsCompatibility = data.whereUsed
                            .filter(x => x.itemType && 
                            ( x.itemType.toLocaleLowerCase() === 'p' || x.itemType.toLocaleLowerCase() === 'k' ||  x.itemType.toLocaleLowerCase() === 'c' ));
                }
                
                if (data && data.relatedParts) {
                    data.associatedPartsData = data.relatedParts.filter(x => x.rank);
                }

                return data;
            })).pipe(catchError(this.handleError));
    }

    public fetchAvailability(partNo: any): Observable<any> {
        return this.http.get(`${this.gomsUrl}${partNo}`)
            .pipe(map((response :Response) => response))
            .pipe(catchError(this.handleError));
    }


    private handleError(error: Response) {
        console.error(error);
        return observableThrowError(error || 'Server Error');
    }

    public getRelatedParts(partNo: any, size: number, smn?: any): Observable<any> {
        let _url = `${this.relatedPartsUrl}${partNo}&size=${size}`;
        if (smn) _url += `&smn=${smn}`;
        return this.http.get(_url).pipe(catchError(this.handleError))
    }
}
