import { MatDateFormats } from '@angular/material';
var DATE_FORMATS = {
    parse: {
        dateInput: null,
    },
    display: {
        dateInput: 'MMM dd, y',
        monthYearLabel: 'MMM y',
        dateA11yLabel: null,
        monthYearA11yLabel: null,
    },
};
var ɵ0 = DATE_FORMATS;
var OptionDetailModule = /** @class */ (function () {
    function OptionDetailModule() {
    }
    return OptionDetailModule;
}());
export { OptionDetailModule };
export { ɵ0 };
