import { DatePipe } from "@angular/common";
import { DateAdapter } from "@angular/material";

export class CustomDateAdapter extends DateAdapter<Date> {

  private static MONTH_NAMES: { [style: string]: string[] } = {
    'long': ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    'short': ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  };

  private static WEEK_NAMES: { [style: string]: string[] } = {
    'long': ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    'short': ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    'narrow': ['M', 'T', 'W', 'T', 'F', 'S', 'S']
  };

  private static DATE_NAMES = [
    '1', '2', '3', '4', '5', '6', '7', '8', '9', '10',
    '11', '12', '13', '14', '15', '16', '17', '18', '19',
    '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
    '30', '31'
  ];

  constructor(private datePipe: DatePipe) {
    super();
  }

  getYear(date: Date): number {
    return date.getFullYear();
  }

  getMonth(date: Date): number {
    return date.getMonth();
  }

  getDate(date: Date): number {
    return date.getDate();
  }

  getDayOfWeek(date: Date): number {
    return date.getDay();
  }

  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    if (!CustomDateAdapter.MONTH_NAMES[style]) {
      throw new Error('Method not implemented.');
    }
    return CustomDateAdapter.MONTH_NAMES[style];
  }

  getDateNames(): string[] {
    return CustomDateAdapter.DATE_NAMES;
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    if (!CustomDateAdapter.WEEK_NAMES[style]) {
      throw new Error('Method not implemented.');
    }
    return CustomDateAdapter.WEEK_NAMES[style];
  }

  getYearName(date: Date): string {
    return this.getYear(date).toString();
  }

  getFirstDayOfWeek(): number {
    return CustomDateAdapter.WEEK_NAMES['long'].length - 1;
  }

  getNumDaysInMonth(date: Date): number {
    return this.getDate(this.createDate(this.getYear(date), this.getMonth(date) + 1, 0));
  }

  clone(date: Date): Date {
    return this.createDate(this.getYear(date), this.getMonth(date), this.getDate(date));
  }

  createDate(year: number, month: number, date: number): Date {
    return new Date(year, month, date);
  }

  today(): Date {
    return new Date();
  }

  parse(_value: any, _parseFormat: any): Date {
    throw new Error('Method not implemented.');
  }

  format(date: Date, displayFormat: any): string {
    return this.datePipe.transform(date, displayFormat);
  }

  addCalendarYears(date: Date, years: number): Date {
    return this.createDate(this.getYear(date) + years, this.getMonth(date), this.getDate(date));
  }

  addCalendarMonths(date: Date, months: number): Date {
    return this.createDate(this.getYear(date), this.getMonth(date) + months, this.getDate(date));
  }

  addCalendarDays(date: Date, days: number): Date {
    return this.createDate(this.getYear(date), this.getMonth(date), this.getDate(date) + days);
  }

  toIso8601(date: Date): string {
    return date.toISOString();
  }

  isDateInstance(obj: any): boolean {
    return Object.prototype.toString.call(obj) === '[object Date]';
  }

  isValid(_date: Date): boolean {
    return true;
  }

  invalid(): Date {
    throw new Error('Method not implemented.');
  }

}