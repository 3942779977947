var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Action, State } from "@ngxs/store";
import { Store } from "src/app/state/core";
import { SetCurrentOption } from "./option-detail.actions";
var OptionDetailComponentState = /** @class */ (function () {
    function OptionDetailComponentState() {
    }
    OptionDetailComponentState_1 = OptionDetailComponentState;
    OptionDetailComponentState.prototype.setCurrentOption = function (ctx, _a) {
        var option = _a.option;
        ctx.patchState({ currentOption: option });
    };
    OptionDetailComponentState.getCurrentOption = function (state) {
        return state.currentOption;
    };
    var OptionDetailComponentState_1;
    __decorate([
        Action(SetCurrentOption),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, SetCurrentOption]),
        __metadata("design:returntype", void 0)
    ], OptionDetailComponentState.prototype, "setCurrentOption", null);
    __decorate([
        Store.Selector([OptionDetailComponentState_1]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], OptionDetailComponentState, "getCurrentOption", null);
    OptionDetailComponentState = OptionDetailComponentState_1 = __decorate([
        State({
            name: 'optionDetailComponent',
            defaults: {
                currentOption: null
            }
        })
    ], OptionDetailComponentState);
    return OptionDetailComponentState;
}());
export { OptionDetailComponentState };
