import {NgModule,Component,Input,Output,EventEmitter,ElementRef,ContentChild,IterableDiffers,ContentChildren,QueryList,Inject,forwardRef,OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TreeNode} from 'primeng/primeng';
import {Header,Footer,Column} from 'primeng/primeng';
import {SharedModule} from 'primeng/primeng';
import {OverlayPanel} from 'primeng/primeng';


@Component({
    selector: '[pTreeRowCustom]',
    template: `
        <div style ="border-bottom: 1px solid #ddd;border-collapse: collapse;" class="ui-treetable-row" [style.background-color]="getStyle()" [ngClass]="{'ui-treetable-row-selectable':treeTable.selectionMode && node.selectable !== false}">
            
            <td style="width: 30px;" [style.display]="getDisplay()" [style.vertical-align]="'middle'"> 
               <span>{{getReferenceNumber(node.data)}}</span>
             </td>
             <td style="width: 30px;" [style.display]="getDisplayInverse()"> 
               
             </td>
           <td style="width: 30px;" [style.vertical-align]="'middle'">
                <i *ngIf="node.data.latestPart == 'true'" data-toggle="tooltip" title="Superseded Part!" class="glyphicon glyphicon-random" aria-hidden="true" ></i>
                <i *ngIf="(node.data.nonSaleInd == 'false') || (node.data.sellable == 'true')" data-toggle="tooltip" title="Saleable Part!" class="glyphicon glyphicon-tag" aria-hidden="true" ></i>
               
                <!-- <i *ngIf="node.data.reconEquivalent == 'Y'" class="fa fa-superpowers" aria-hidden="true"></i> 
                 <img *ngIf="node.data.nonSaleInd == 'N'" src="assets/icons/checkmark.png" alt="ToolTip">
                 <img *ngIf="node.data.latestPart == 'Y'" src="assets/icons/supersession.png"  alt="ToolTip"> -->
              
           </td>
            
            <td *ngFor="let col of treeTable.columns; let i=index" [ngStyle]="col.style" [class]="col.styleClass" (click)="onRowClick($event)" (touchend)="onRowTouchEnd()" (contextmenu)="onRowRightClick($event)"
                [style.vertical-align]="'middle'">
                 
                <a href="#" *ngIf="i==0" class="ui-treetable-toggler fa fa-fw ui-c" [ngClass]="{'fa-caret-down':node.expanded,'fa-caret-right':!node.expanded}"
                    [ngStyle]="{'margin-left': level*20 + 'px','visibility': isLeaf() ? 'hidden' : 'visible'}"
                    (click)="toggle($event)"
                    [title]="node.expanded ? labelCollapse : labelExpand">
                </a>
                <div class="ui-chkbox ui-treetable-checkbox" *ngIf="treeTable.selectionMode == 'checkbox' && i==0"><div class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                    <span class="ui-chkbox-icon ui-c fa" 
                        [ngClass]="{'fa-check':isSelected(),'fa-minus':node.partialSelected}"></span></div></div
                ><span *ngIf="!col.template">{{resolveFieldData(node.data,col.field)}}</span>
                <p-columnBodyTemplateLoader [column]="col" [rowData]="node" *ngIf="col.template"></p-columnBodyTemplateLoader>
            </td>
            
           
        </div>
        <div *ngIf="node.children && node.expanded" class="ui-treetable-row" style="display:table-row">
            <td [attr.colspan]="treeTable.columns.length + 2" class="ui-treetable-child-table-container">
                <table>
                    <tbody pTreeRowCustom *ngFor="let childNode of node.children;let cou=index;" [ref]="ref" [mbom]="mbom" [count] = "cou" [node]="childNode" [level]="level+1" [labelExpand]="labelExpand" [labelCollapse]="labelCollapse" [parentNode]="node"></tbody>
                </table>
            </td>
        </div>
    `
})
export class UITreeRowCustom implements OnInit {

    @Input() node: TreeNode;

    @Input() ref: string;
    
    @Input() parentNode: TreeNode;
    
    @Input() level: number = 0;

    @Input() count: number = 0;

    @Input() mbom: string;

    @Input() labelExpand: string = "Expand";
    
    @Input() labelCollapse: string = "Collapse";
    	
    constructor(@Inject(forwardRef(() => TreeTableCustom)) public treeTable:TreeTableCustom) {}
    
    ngOnInit() {
        this.node.parent = this.parentNode;
        //this.node.expanded = false;
        if(!this.node.parent){
            this.node.expanded = true;
        }
    }


     afterShow(panel : OverlayPanel){
       panel.container.style.top = (parseInt(this.getNumber(panel.container.style.top)) - 100) + 'px';
    }

    getStyle():string {
        return this.isSelected() ? '#51d053' : '';
    }

    getDisplay(){
        //console.log('customtree'+this.mbom);
         if (this.mbom =='recon' || this.mbom =='jv'){
             return 'none';
         }
         else{
             return '';
         }
    }

    getDisplayInverse(){
        if (this.mbom =='recon' || this.mbom =='jv'){
            return '';
        }
        else{
            return 'none';
        }
    }

    getNumber(data:string) : string {
        if(data === null){
            return '';
        }else{
            let thenum = data.match('\\d+');
            if (thenum != null){
                return thenum[0];
            }else{
                return "";
    
            }
        }
    
    }

    public getReferenceNumber(data: any): string {
        if (this.mbom === 'mbom' && this.ref === 'Ref') {
            return data.callOut ? this.getNumber(data.callOut) : '';
        }
        return this.getNumber(data.lvlNo);
    }
    
    toggle(event: Event) {
        if(this.node.expanded)
            this.treeTable.onNodeCollapse.emit({originalEvent: event, node: this.node});
        else
            this.treeTable.onNodeExpand.emit({originalEvent: event, node: this.node});
            
        this.node.expanded = !this.node.expanded;
        
        event.preventDefault();
    }
    
    isLeaf() {
        return this.node.leaf == false ? false : !(this.node.children&&this.node.children.length);
    }
    
    isSelected() {
        //return this.treeTable.isSelected(this.node);
        return this.node.selectable;
    }
    
    onRowClick(event: MouseEvent) {
        this.treeTable.onRowClick(event, this.node);
    }
    
    onRowRightClick(event: MouseEvent) {
        this.treeTable.onRowRightClick(event, this.node);
    }
    
    onRowTouchEnd() {
        this.treeTable.onRowTouchEnd();
    }
    
    resolveFieldData(data: any, field: string): any {
        if(data && field) {
            if(field.indexOf('.') == -1) {
                return data[field];
            }
            else {
                let fields: string[] = field.split('.');
                let value = data;
                for(var i = 0, len = fields.length; i < len; ++i) {
                    value = value[fields[i]];
                }
                return value;
            }
        }
        else {
            return null;
        }
    }
}

@Component({
    selector: 'p-treeTable-custom',
    template: `
        <div [ngClass]="'ui-treetable ui-widget'" [ngStyle]="style" [class]="styleClass">
            <div class="ui-treetable-header ui-widget-header" *ngIf="header">
                <ng-content select="p-header"></ng-content>
            </div>
            <div class="ui-treetable-tablewrapper">
                <table class="ui-widget-content">
                    <thead>
                        <tr class="ui-state-default">
                             <th #headerCell
                                [ngClass]="'ui-state-default ui-unselectable-text'" style="width: 30px;">
                                <span class="ui-column-title">{{ref}}</span>
                             </th> 
                              <!--
                             <th #headerCell
                                [ngClass]="'ui-state-default ui-unselectable-text'" style="width: 30px;">
                                <span class="ui-column-title"></span>
                             </th>  -->
                             <th #headerCell
                                [ngClass]="'ui-state-default ui-unselectable-text'" style="width: 30px;">
                                <span class="ui-column-title"></span>
                             </th>   
                            <th #headerCell *ngFor="let col of columns" [ngStyle]="col.style" [class]="col.styleClass" 
                                [ngClass]="'ui-state-default ui-unselectable-text'">
                                <span class="ui-column-title" *ngIf="!col.headerTemplate">{{col.header}}</span>
                                <span class="ui-column-title" *ngIf="col.headerTemplate">
                                    <p-columnHeaderTemplateLoader [column]="col"></p-columnHeaderTemplateLoader>
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tfoot *ngIf="hasFooter()">
                        <tr>
                            <td *ngFor="let col of columns" [ngStyle]="col.style" [class]="col.styleClass" [ngClass]="{'ui-state-default':true}">
                                <span class="ui-column-footer" *ngIf="!col.footerTemplate">{{col.footer}}</span>
                                <span class="ui-column-footer" *ngIf="col.footerTemplate">
                                    <p-columnFooterTemplateLoader [column]="col"></p-columnFooterTemplateLoader>
                                </span>
                            </td>
                        </tr>
                    </tfoot>
                    <tbody pTreeRowCustom  *ngFor="let node of value;let cou=index;" [ref]="ref" [mbom]="mbom" [count]="cou" [node]="node" [level]="0" [labelExpand]="labelExpand" [labelCollapse]="labelCollapse"></tbody>
                </table>
            </div>
            <div class="ui-treetable-footer ui-widget-header" *ngIf="footer">
                <ng-content select="p-footer"></ng-content>
            </div>
        </div>
    `
})
export class TreeTableCustom {

    @Input() value: TreeNode[];
    
    @Input() ref: string = "Def";

    public test: string = "Def 2";
        
    @Input() selectionMode: string;
    
    @Input() selection: any;
        
    @Input() style: any;

    @Input() mbom: string ;
        
    @Input() styleClass: string;

    @Input() labelExpand: string = "Expand";
    
    @Input() labelCollapse: string = "Collapse";
    
    @Input() metaKeySelection: boolean = true;
    
    @Input() contextMenu: any;
    
    @Output() selectionChange: EventEmitter<any> = new EventEmitter();
    
    @Output() onNodeSelect: EventEmitter<any> = new EventEmitter();
    
    @Output() onNodeUnselect: EventEmitter<any> = new EventEmitter();
    
    @Output() onNodeExpand: EventEmitter<any> = new EventEmitter();
    
    @Output() onNodeCollapse: EventEmitter<any> = new EventEmitter();
    
    @Output() onContextMenuSelect: EventEmitter<any> = new EventEmitter();
    
    @ContentChild(Header,{static:true}) header: Header;

    @ContentChild(Footer,{static:true}) footer: Footer;
    
    @ContentChildren(Column) columns: QueryList<Column>;
    
    public rowTouched: boolean;
        
    onRowClick(event: MouseEvent, node: TreeNode) {
        let eventTarget = (<Element> event.target);
        if(eventTarget.className && eventTarget.className.indexOf('ui-treetable-toggler') === 0) {
            return;
        }
        else if(this.selectionMode) {
            if(node.selectable === false) {
                return;
            }
            
            let metaSelection = this.rowTouched ? false : this.metaKeySelection;
            let index = this.findIndexInSelection(node);
            let selected = (index >= 0);
            
            if(this.isCheckboxSelectionMode()) {
                if(selected) {
                    this.propagateSelectionDown(node, false);
                    if(node.parent) {
                        this.propagateSelectionUp(node.parent, false);
                    }
                    this.selectionChange.emit(this.selection);
                    this.onNodeUnselect.emit({originalEvent: event, node: node});
                }
                else {
                    this.propagateSelectionDown(node, true);
                    if(node.parent) {
                        this.propagateSelectionUp(node.parent, true);
                    }
                    this.selectionChange.emit(this.selection);
                    this.onNodeSelect.emit({originalEvent: event, node: node});
                }
            }
            else {
                if(metaSelection) {
                    let metaKey = (event.metaKey||event.ctrlKey);
                    
                    if(selected && metaKey) {
                        if(this.isSingleSelectionMode()) {
                            this.selectionChange.emit(null);
                        }
                        else {
                            this.selection = this.selection.filter((val,i) => i!=index);
                            this.selectionChange.emit(this.selection);
                        }

                        this.onNodeUnselect.emit({originalEvent: event, node: node});
                    }
                    else {
                        if(this.isSingleSelectionMode()) {
                            this.selectionChange.emit(node);
                        }
                        else if(this.isMultipleSelectionMode()) {
                            this.selection = (!metaKey) ? [] : this.selection||[];
                            this.selection = [...this.selection,node];
                            this.selectionChange.emit(this.selection);
                        }

                        this.onNodeSelect.emit({originalEvent: event, node: node});
                    }
                }
                else {
                    if(this.isSingleSelectionMode()) {
                        if(selected) {
                            this.selection = null;
                            this.onNodeUnselect.emit({originalEvent: event, node: node});
                        }
                        else {
                            this.selection = node;
                            this.onNodeSelect.emit({originalEvent: event, node: node});
                        }
                    }
                    else {
                        if(selected) {
                            this.selection = this.selection.filter((val,i) => i!=index);
                            this.onNodeUnselect.emit({originalEvent: event, node: node});
                        }
                        else {
                            this.selection = [...this.selection||[],node];
                            this.onNodeSelect.emit({originalEvent: event, node: node});
                        }
                    }
                    
                    this.selectionChange.emit(this.selection);
                }
            }
        }
        
        this.rowTouched = false;
    }
        
    onRowTouchEnd() {
        this.rowTouched = true;
    }
    
    onRowRightClick(event: MouseEvent, node: TreeNode) {
        if(this.contextMenu) {
            let index = this.findIndexInSelection(node);
            let selected = (index >= 0);
            
            if(!selected) {
                if(this.isSingleSelectionMode()) {
                    this.selection = node;
                }
                else if(this.isMultipleSelectionMode()) {
                    this.selection = [node];
                    this.selectionChange.emit(this.selection);
                }
                
                this.selectionChange.emit(this.selection);
            }
            
            this.contextMenu.show(event);
            this.onContextMenuSelect.emit({originalEvent: event, node: node});
        }
    }
    
    findIndexInSelection(node: TreeNode) {
        let index: number = -1;

        if(this.selectionMode && this.selection) {
            if(this.isSingleSelectionMode()) {
                index = (this.selection == node) ? 0 : - 1;
            }
            else {
                for(let i = 0; i  < this.selection.length; i++) {
                    if(this.selection[i] == node) {
                        index = i;
                        break;
                    }
                }
            }
        }

        return index;
    }
    
    propagateSelectionUp(node: TreeNode, select: boolean) {
        if(node.children && node.children.length) {
            let selectedCount: number = 0;
            let childPartialSelected: boolean = false;
            for(let child of node.children) {
                if(this.isSelected(child)) {
                    selectedCount++;
                }
                else if(child.partialSelected) {
                    childPartialSelected = true;
                }
            }
            
            if(select && selectedCount == node.children.length) {
                this.selection = [...this.selection||[],node];
                node.partialSelected = false;
            }
            else {                
                if(!select) {
                    let index = this.findIndexInSelection(node);
                    if(index >= 0) {
                        this.selection = this.selection.filter((val,i) => i!=index);
                    }
                }
                
                if(childPartialSelected || selectedCount > 0 && selectedCount != node.children.length)
                    node.partialSelected = true;
                else
                    node.partialSelected = false;
            }
        }
                
        let parent = node.parent;
        if(parent) {
            this.propagateSelectionUp(parent, select);
        }
    }
    
    propagateSelectionDown(node: TreeNode, select: boolean) {
        let index = this.findIndexInSelection(node);
        
        if(select && index == -1) {
            this.selection = [...this.selection||[],node];
        }
        else if(!select && index > -1) {
            this.selection = this.selection.filter((val,i) => i!=index);
        }
        
        node.partialSelected = false;
        
        if(node.children && node.children.length) {
            for(let child of node.children) {
                this.propagateSelectionDown(child, select);
            }
        }
    }
    
    isSelected(node: TreeNode) {
        return this.findIndexInSelection(node) != -1;         
    }
    
    isSingleSelectionMode() {
        return this.selectionMode && this.selectionMode == 'single';
    }
    
    isMultipleSelectionMode() {
        return this.selectionMode && this.selectionMode == 'multiple';
    }
    
    isCheckboxSelectionMode() {
        return this.selectionMode && this.selectionMode == 'checkbox';
    }
    
    hasFooter() {
        if(this.columns) {
            let columnsArr = this.columns.toArray();
            for(let i = 0; i < columnsArr.length; i++) {
                if(columnsArr[i].footer) {
                    return true;
                }
            }
        }
        return false;
    }

    getDisplay(){
        //console.log('customtree'+this.mbom);
         if (this.mbom == 'Y'){
             return 'none';
         }
         else{
             return '';
         }
    }
}

@NgModule({
    imports: [CommonModule,SharedModule],
    exports: [TreeTableCustom,SharedModule],
    declarations: [TreeTableCustom,UITreeRowCustom]
})
export class TreeTableModule { }
